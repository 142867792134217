import { useEffect, useState } from'react';
import supabase from '../utils/supabaseClient';

const useFetchMacroSummary = ({ logError }) => {
    const development = ['dev', 'localhost'].some(env => window.location.href.includes(env));
    const [macroSummaryLoading, setMacroSummaryLoading] = useState(true);
    const [macroSummary, setMacroSummary] = useState({});

    const getMacroSummary = ({ retry = true }) => {
        supabase.from('get_macro_summary').select('*').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    getMacroSummary({ retry: false });
    
                    return;
                }
            }

            if (res?.data?.[0]?.type === 'broad_macro') {
                setMacroSummary(res?.data?.[0]);
            }
            setMacroSummaryLoading(false);
        });
    };

    useEffect(() => {
        if (!development) {
            getMacroSummary({ retry: true });
    
            const interval = setInterval(() => getMacroSummary({ retry: true }), 600000);
    
            return () => {
                clearInterval(interval);
            }
        } else {
            setMacroSummaryLoading(false);
        }
    }, []);

    return {
        macroSummary,
        macroSummaryLoading,
    };
};

export default useFetchMacroSummary;
