import styled from 'styled-components';

import { AppColors, CardColors } from '../../../StyledComponents/Graphics';
import Tooltip from '../../Tooltip';

const NameBlock = styled.div`
    line-height: 20px;
    background: ${AppColors.NEUTRAL};
    width: auto;
    height: 20px;
    border-radius: 10px;
    font-size: 11px;
    padding: 0px 10px;
    text-transform: uppercase;
    text-align: center;
    color: ${AppColors.MRWHITE};
    font-family: Mulish, sans-serif;
    letter-spacing: 0.03em;

    background-color: ${CardColors.ALPH};
    border-radius: 20px;
    margin-right: ${props => props.table ? null : '5px'};
    margin-bottom: ${props => props.table ? '5px' : null};
    overflow: hidden;
    padding: 0px 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: context-menu;
`;

const WatchlistNames = ({ table, watchlistItem }) => {

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: table ? 'column' : 'row',
                marginTop: table ? '5px' : null,
            }}
        >
            {watchlistItem?.watchlists?.slice(0, 2).map((name) => (
                <Tooltip
                    position={'bottom'}
                    text={name}
                    width={table ? '150px' : watchlistItem?.watchlists?.length > 2 ? '40%' : '50%'}
                >
                <NameBlock table={table}>
                    {name}
                </NameBlock>
            </Tooltip>
            ))}
            {watchlistItem?.watchlists?.slice(2).length ?
                <Tooltip
                    position={'bottom'}
                    text={watchlistItem?.watchlists?.slice(2)}
                    width={table ? '150px' : '20%'}
                >
                    <NameBlock table={table}>More...</NameBlock>
                </Tooltip> :
                null
            }
        </div>
    );
};

export default WatchlistNames;
