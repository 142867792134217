import { useContext, useEffect, useState, useRef } from 'react';
import MainView from './components/MainView';
import {
    CardContainer,
} from '../../StyledComponents/StockCardStyledComponents';
import { StateContext } from '../../../Controllers/context/state';
import { round } from '../../../Controllers/utils';
import useOnScreen from '../../../Controllers/hooks/onScreenHook';
import supabase from '../../../Controllers/utils/supabaseClient';
import Loading from '../LoadingSpinner';

const StockCard = ({
    correlatedAsset,
    readOnly,
    requestedTicker,
    watchlistCard,
    watchlistDashboard,
    watchlistItem,
}) => {
    const [loading, setLoading] = useState(true);
    const [percentileMove, setpercentileMove] = useState('');
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);

    const {
        cardData,
        cardDimensions,
        logError,
        setCardData,
        setExpandedViewTicker,
        setShowErrorWindow,
        setShowExpandedView,
        setShowFeed,
        setVisibleCards,
        sort,
    } = useContext(StateContext);

    const fetchData = async ({ retry = true }) => {
        const res = await supabase.rpc('card_data', {
            _symbol: requestedTicker,
            _corr_symbol: correlatedAsset || sort.correlationAsset || 'SPY', 
        });

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                fetchData({ retry: false });

                return;
            }

            if (res?.error?.message !== 'FetchError: Failed to fetch') {
                setShowErrorWindow(true);
            }

            return;
        }

        if (res?.data?.length) {
            const data = res.data[0];
            
            setCardData(prevState => ({
                ...prevState,
                [requestedTicker]: data
            }));
            setLoading(false);

            const percentile = (round(data.date_return_oc_percentile * 100, 2));

            if (percentile >= 97) {
                setpercentileMove('ALPH');
            } else if (percentile >= 93) {
                setpercentileMove('BETA');
            } else if (percentile >= 84) {
                setpercentileMove('GAMM');
            } else if (percentile >= 68) {
                setpercentileMove('DELT');
            } else if (percentile >= 34) {
                setpercentileMove('EPIS');
            } else if (percentile >= 16) {
                setpercentileMove('IOTA');
            } else if (percentile >= 7) {
                setpercentileMove('KAPP');
            } else if (percentile >= 3) {
                setpercentileMove('LAMB');
            } else if (percentile >= 0) {
                setpercentileMove('ZETA');
            }

            return;
        }
    };

    useEffect(() => {
        if (isVisible) {
            fetchData({ retry: true });
        }
    }, [isVisible, sort]);

    useEffect(() => {
        let interval;
        if (isVisible) {
            interval = setInterval(() => fetchData({ retry: true }), 60000);
            setVisibleCards(prevState => ({
                ...prevState,
                [requestedTicker]: requestedTicker,
            }));
        } else {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        }

        return () => {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        };
    }, [isVisible, sort])

    const handleExpand = () => {
        setExpandedViewTicker(requestedTicker);
        setShowFeed(false);
        setShowExpandedView(true);
    };
     
    return (
        <CardContainer
            cardDimensions={cardDimensions.data}
            percentileMove={percentileMove}
            ref={ref}
            watchlistDashboard={watchlistDashboard}
        > 
            {loading ?
                <Loading /> :
                <MainView
                    data={cardData[requestedTicker]}
                    handleExpand={handleExpand}
                    percentileMove={percentileMove}
                    readOnly={readOnly}
                    watchlistCard={watchlistCard}
                    watchlistDashboard={watchlistDashboard}
                    watchlistItem={watchlistItem}
                />
            }
        </CardContainer>

    );
};

export default StockCard;
