import Flag from 'react-world-flags';
import { useContext, useEffect, useState, useRef } from 'react';

import { AppColors } from '../../../StyledComponents/Graphics';
import {
    formatDateTime,
    formatPrice,
    ordinalSuffixOf,
    round,
    setSortValue,
} from '../../../../Controllers/utils';
import {
    DisplayInlineHalfLT,
    DisplayInlineHalfRT,
    StockInfoTextBoxLeft,
} from '../../../StyledComponents/StockCardStyledComponents';
import {
    DeleteIcon,
    ExtendedHoursIcon,
} from '../../../Icons/Icons';
import Loading from '../../../Components/LoadingSpinner';
import { StateContext } from '../../../../Controllers/context/state';
import { StyledName, StyledSector, TableCell, TableRow } from '../styledComponents';
import supabase from '../../../../Controllers/utils/supabaseClient';
import useOnScreen from '../../../../Controllers/hooks/onScreenHook';
import WatchlistNames from '../../../Components/StockCard/components/WatchlistNames';

const Row = ({
    requestedTicker,
    watchlistCard,
    watchlistDashboard,
    watchlistItem,
}) => {
    const [loading, setLoading] = useState(true);
    const [percentileMove, setpercentileMove] = useState('');
    const [moveColor, setMoveColor] = useState('MRLITE80');
    const [plusSign, setPlusSign] = useState('');

    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const {
        cardData,
        fetchAllWatchlists,
        logError,
        selectedWatchlist,
        setCardData,
        setExpandedViewTicker,
        setSelectedWatchlist,
        setShowConfirmationWindow,
        setShowErrorWindow,
        setShowExpandedView,
        setShowFeed,
        setVisibleCards,
        sort,
    } = useContext(StateContext);

    const fetchData = async ({ retry = true }) => {
        const res = await supabase.rpc('card_data', {
            _symbol: requestedTicker,
            _corr_symbol: sort.correlationAsset || 'SPY', 
        });

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                fetchData({ retry: false });

                return;
            }

            if (res?.error?.message !== 'FetchError: Failed to fetch') {
                setShowErrorWindow(true);
            }

            return;
        }

        if (res?.data?.length) {
            const data = res.data[0];

            setCardData(prevState => ({
                ...prevState,
                [requestedTicker]: data,
            }));
            setLoading(false);

            const percentile = (round(data?.date_return_oc_percentile * 100, 2));

            if (round(data?.date_return_oc * 100, 1) > 0) {
                setPlusSign('+');
                setMoveColor('MRGREEN');
            } else if (round(data?.date_return_oc * 100, 1) < 0) {
                setMoveColor('MRRED');
            } else if (round(data?.date_return_oc * 100, 1) === 0) {
                setMoveColor('MRLITE80');
            }

            if (percentile >= 97) {
                setpercentileMove('ALPH');
            } else if (percentile >= 93) {
                setpercentileMove('BETA');
            } else if (percentile >= 84) {
                setpercentileMove('GAMM');
            } else if (percentile >= 68) {
                setpercentileMove('DELT');
            } else if (percentile >= 34) {
                setpercentileMove('EPIS');
            } else if (percentile >= 16) {
                setpercentileMove('IOTA');
            } else if (percentile >= 7) {
                setpercentileMove('KAPP');
            } else if (percentile >= 3) {
                setpercentileMove('LAMB');
            } else if (percentile >= 0) {
                setpercentileMove('ZETA');
            }
        }
    };

    useEffect(() => {
        if (isVisible) {
            fetchData({ retry: true });
        }
    }, [isVisible, sort]);

    useEffect(() => {
        let interval;
        if (isVisible) {
            interval = setInterval(() => fetchData({ retry: true }), 60000);
            setVisibleCards(prevState => ({
                ...prevState,
                [requestedTicker]: requestedTicker,
            }));
        } else {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        }

        return () => {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        };
    }, [isVisible, sort])



    const fetchWatchlist = ({ retry = true }) => {
        supabase.from('user_preferences').select('*').eq('id', selectedWatchlist?.id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    fetchWatchlist({ retry: false });
    
                    return;
                }

                return;
            }

            fetchAllWatchlists({ retry: true });
            setSelectedWatchlist(res.data[0]);
        });
    };

    const handleDeleteFromWatchList = () => {
        const watchlistTickers = selectedWatchlist?.data.filter((ticker) => ticker !== data?.symbol.toUpperCase());

        if (watchlistTickers.length) {
            const updatedWatchlist = {
                data: watchlistTickers,
                id: selectedWatchlist?.id,
                meta: selectedWatchlist?.meta,
                user_id: selectedWatchlist?.user_id,
            };

            setShowConfirmationWindow({
                handleCancel: () => setShowConfirmationWindow(false),
                handleConfirm: () => {
                    supabase.from('user_preferences').update(updatedWatchlist)
                        .eq('id', selectedWatchlist?.id).then((res) => {
                            if (res?.error) {
                                const error = new Error(res?.error?.message);
                                logError(error.message, undefined, error);
                    
                                return;
                            }
    
                            fetchWatchlist();
                            setShowConfirmationWindow(false);
                        });
                },
                headline: `Are you sure you want to delete ${data?.symbol} from your watchlist?`,
                show: true,
            });
        } else {
            setShowConfirmationWindow({
                handleCancel: () => setShowConfirmationWindow(false),
                handleConfirm: () => {
                    supabase.from('user_preferences').delete().eq('id', selectedWatchlist?.id).then((res) => {
                        if (res?.error) {
                            const error = new Error(res?.error?.message);
                            logError(error.message, undefined, error);
                
                            return;
                        }
    
                        fetchAllWatchlists({ retry: true });
                        setSelectedWatchlist(null);
                        setShowConfirmationWindow(false);
                    });
                },
                headline: `Are you sure you want to delete ${data?.symbol}?`,
                show: true,
                subheading: 'This will also delete the watchlist.'
            });
        }
    };

    const handleExpand = () => {
        setExpandedViewTicker(requestedTicker);
        setShowFeed(false);
        setShowExpandedView(true);
    };

    const rowData = cardData?.[requestedTicker];

    return (
        <>
            {loading ?
                <TableRow style={{ width: '100%' }} ref={ref}>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Loading dimensions={{ height: '30px', width: '30px' }} />
                    </TableCell>
                </TableRow> :
                <TableRow onClick={handleExpand} ref={ref}>
                    {watchlistCard && selectedWatchlist?.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' &&
                        <TableCell>
                            <div onClick={handleDeleteFromWatchList}>
                                <DeleteIcon style={{ float: 'left', backgroundColor: AppColors.MRGREY }} />
                            </div>
                        </TableCell>
                    }
                    <TableCell>
                        <StockInfoTextBoxLeft moveColor={moveColor}>
                            {rowData?.symbol}
                        </StockInfoTextBoxLeft>
                    </TableCell>
                    <TableCell>
                        <StyledName title={rowData?.name}>{rowData?.name}</StyledName>
                    </TableCell>
                    {watchlistDashboard &&
                        <TableCell>
                            <WatchlistNames table watchlistItem={watchlistItem} />
                        </TableCell>
                    }
                    <TableCell>
                        <Flag
                            code={rowData?.meta_data?.country}
                            fallback={
                                <div 
                                    style={{
                                        marginLeft: 15,
                                        height: 25,
                                        width: 25,
                                    }}
                                >
                                    {rowData?.meta_data?.country}
                                </div>
                            }
                            style={{
                                marginLeft: 15,
                                height: 25,
                                width: 25,
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <StyledSector title={`${rowData?.gsector}, ${rowData?.gsubind}`}>{rowData?.gsector}, {rowData?.gsubind}</StyledSector>
                    </TableCell>
                    <TableCell>
                        <StockInfoTextBoxLeft moveColor={moveColor}>
                            {formatPrice(rowData?.close)}
                        </StockInfoTextBoxLeft>
                    </TableCell>
                    <TableCell minWidth={!rowData?.market_rth ? '150px' : null}>
                        <StockInfoTextBoxLeft moveColor={moveColor}>
                            {plusSign}{round(rowData?.date_return_oc * 100, 1)}%
                        </StockInfoTextBoxLeft>
                        {!rowData?.market_rth &&
                            <ExtendedHoursIcon>EXT</ExtendedHoursIcon>
                        }
                    </TableCell>
                    <TableCell>
                        <DisplayInlineHalfLT percentileMove={percentileMove}>
                            {ordinalSuffixOf(round(rowData?.date_return_oc_percentile * 100, 0))}
                            <br />
                        </DisplayInlineHalfLT>
                    </TableCell>
                    <TableCell>
                        <DisplayInlineHalfRT moveColor={moveColor} table>
                            {sort?.key === 'symbol' ?
                                <>
                                    {sort?.asc ? 'A-Z' : 'Z-A'}
                                </> :
                                setSortValue(rowData, sort?.key)
                            }
                        </DisplayInlineHalfRT>
                    </TableCell>
                    <TableCell>
                        {rowData.dtm_summary?.dtm_headline}&nbsp;
                        {rowData.dtm_summary?.time_utc &&
                            `(${formatDateTime(rowData?.dtm_summary?.time_utc)})`
                        }
                    </TableCell>
                </TableRow>
            }
        </>
    );
};

export default Row;
