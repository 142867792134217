export const DEFAULT_FILTERS = {
    impactScore: {
        label: 'Information Score',
        values: [
            {
                label: '0',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0 ELSE false END", 
            },
            {
                label: '0.05',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.05 ELSE false END", 
            },
            {
                label: '0.1',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.1 ELSE false END", 
            },
            {
                label: '0.15',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.15 ELSE false END", 
            },
            {
                label: '0.2',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.2 ELSE false END", 
            },
            {
                label: '0.25',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.25 ELSE false END", 
            },
            {
                label: '0.3',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.3 ELSE false END", 
            },
            {
                label: '0.35',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.35 ELSE false END", 
            },
            {
                label: '0.4',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.4 ELSE false END", 
            },
            {
                label: '0.45',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.45 ELSE false END", 
            },
            {
                label: '0.5',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.5 ELSE false END", 
            },
            {
                label: '0.55',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.55 ELSE false END", 
            },
            {
                label: '0.6',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.6 ELSE false END", 
            },
            {
                label: '0.65',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.65 ELSE false END", 
            },
            {
                label: '0.7',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.7 ELSE false END", 
            },
            {
                label: '0.75',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.75 ELSE false END", 
            },
            {
                label: '0.8',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.8 ELSE false END", 
            },
            {
                label: '0.85',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.85 ELSE false END", 
            },
            {
                label: '0.9',
                value: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.9 ELSE false END", 
            },
        ],
    },
    informationScore: {
        label: 'Explanation Information Score',
        values: [
            {
                label: 'Any',
                value: null, 
            },
            {
                label: 'Very Low or Better',
                value: "(dtm_summary->'dtm_summary_meta'->'information_score'->'level')::int >= 1", 
            },
            {
                label: 'Low or Better',
                value: "(dtm_summary->'dtm_summary_meta'->'information_score'->'level')::int >= 2", 
            },
            {
                label: 'Medium or Better',
                value: "(dtm_summary->'dtm_summary_meta'->'information_score'->'level')::int >= 3", 
            },
            {
                label: 'High or Better',
                value: "(dtm_summary->'dtm_summary_meta'->'information_score'->'level')::int >= 4", 
            },
            {
                label: 'Very High or Better',
                value: "(dtm_summary->'dtm_summary_meta'->'information_score'->'level')::int >= 5", 
            },
        ],
    },
    marketReaderHot: {
        label: 'Move Filter',
        values: [
            {
                label: 'Any',
                value: null
            },
            {
                label: 'Unusual Moves Today',
                value: '(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)',
            },
            {
                label: 'Unusual Over 10min',
                value: 'return_oc_percentile NOT BETWEEN 0.01 AND 0.99', 
            },
        ]
    },
    marketCap: {
        label: 'Market Cap',
        values: [
            {
                label: 'Any',
                value: null,
            },
            {
                label: '> 500B',
                value: 'mcap_mm>500000',
            },
            {
                label: '> 100B',
                value: 'mcap_mm>100000',
            },
            {
                label: '> 50B',
                value: 'mcap_mm>50000',
            },
            {
                label: '> 40B',
                value: 'mcap_mm>40000',
            },
            {
                label: '> 30B',
                value: 'mcap_mm>30000',
            },
            {
                label: '> 20B',
                value: 'mcap_mm>20000',
            },
            {
                label: '> 10B',
                value: 'mcap_mm>10000',
            },
            {
                label: '> 5B',
                value: 'mcap_mm>5000',
            },
            {
                label: '> 1B',
                value: 'mcap_mm>1000',
            },
            {
                label: '> 500M',
                value: 'mcap_mm>500',
            },
            {
                label: '> 100M',
                value: 'mcap_mm>100',
            },
        ],
    },
    // unusualVolume: {
    //     label: 'Unusual Volume',
    //     values: [
    //         {
    //             label: 'Any',
    //             value: null,
    //         },
    //         {
    //             label: 'Today',
    //             value: 'date_volume_percentile>0.99',
    //         },
    //         {
    //             label: 'Last 10min',
    //             value: 'volume_percentile>0.99',
    //         },
    //     ],
    // },
};

export const EXPLANATION_FILTERS = {
    excludedExplanations: {
        label: 'Exclude selected explanations',
        values: [
            {
                label: 'Company Event',
                value: "'Company Event' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Correlated Move',
                value: "'Correlated Move' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'News',
                value: "'News' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Associated News',
                value: "'Associated News' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Social Chatter',
                value: "'Social Chatter' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Macro Event',
                value: "'Macro Event' NOT IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
        ],
    },
    includedExplanations: {
        label: 'Show only selected explanations',
        values: [
            {
                label: 'Company Event',
                value: "'Company Event' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Correlated Move',
                value: "'Correlated Move' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'News',
                value: "'News' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Associated News',
                value: "'Associated News' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Social Chatter',
                value: "'Social Chatter' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
            {
                label: 'Macro Event',
                value: "'Macro Event' IN (SELECT jsonb_array_elements_text(NULLIF(dtm_tags,'{}')))"
            },
        ],
    },
};
