import { useContext, useState } from 'react';
import {
    CardContainer,
    DisplayInlineHalfLTop
} from '../../../Global/StyledComponents/StockCardStyledComponents';
import {
    Input,
    WarningText,
    SuccessMessage,
    StyledButton
} from '../../../Global/StyledComponents/UserFormStyledComponents';
import supabase from '../../../Controllers/utils/supabaseClient';
import { StateContext } from '../../../Controllers/context/state';
import DropdownMenu from '../../../Global/Components/DropdownMenu';
import { AddToListIcon } from '../../../Global/Icons/Icons';
import { AuthContext } from '../../../Controllers/context/auth';

const WatchlistPopup = () => {
    const [watchlistName, setWatchlistName] = useState('');
    const [error, setError] = useState(false);
    const [tickerExists, setTickerExists] = useState(false);
    const [addSuccess, setAddSuccess] = useState(false);
    const { user } = useContext(AuthContext);

    const {
        expandedViewTicker,
        fetchAllWatchlists,
        fetchAllWatchlistTickers,
        logError,
        selectedWatchlist,
        setSelectedWatchlist,
        setShowWatchlistPopup,
        watchlists,
    } = useContext(StateContext);

    const fetchWatchlist = ({ retry = true }) => {
        supabase.from('user_preferences').select('*').eq('id', selectedWatchlist.id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    fetchWatchlist({ retry: false });
                }
    
                return;
            }

            setSelectedWatchlist(res.data[0]);
        });
    };

    const handleAddToWatchList = () => {
        const updatedWatchlist = {
            data: [
                ...selectedWatchlist.data,
                expandedViewTicker.toUpperCase(),
            ],
            id: selectedWatchlist.id,
            meta: selectedWatchlist.meta,
            user_id: selectedWatchlist.user_id,
        };

        supabase.from('user_preferences').update(updatedWatchlist)
            .eq('id', selectedWatchlist.id).then((res) => {
                if (res?.error) {
                    setError(true);
                } else {
                    fetchWatchlist({ retry: true });
                    fetchAllWatchlists({ retry: true });
                    fetchAllWatchlistTickers({ retry: true });
                    setError(false);
                    setAddSuccess(true);
                    setTimeout(() => {
                        setAddSuccess(false);
                        setShowWatchlistPopup(false);
                    }, 2000)
                }
            });
    };

    const handleCreateNewWatchlist = () => {
        supabase.from('user_preferences').insert([{
            data: [expandedViewTicker.toUpperCase()],
            meta: {
                name: watchlistName,
                created_at: new Date(),
            },
            type: 'watchlist',
            user_id: user.id,
        }]).then((res) => {
            if (res?.error) {
                setError(true);
            } else {
                fetchAllWatchlists({ retry: true });
                fetchAllWatchlistTickers({ retry: true });
                setError(false);
                setWatchlistName('');
                setAddSuccess(true);
                setTimeout(() => {
                    setAddSuccess(false);
                    setShowWatchlistPopup(false);
                }, 2000)
            }
        })
    };

    const handleSelectWatchList = (e, value) => {
        const watchlist = value ?? e.target.value;
        setError(false);
        setTickerExists(false);

        if (watchlist === 'Assign to exsisting watchlist') {
            setSelectedWatchlist(null);
        } else {
            setSelectedWatchlist(JSON.parse(watchlist));
            setWatchlistName('');
        }
    };

    const handleSubmit = () => {
        if (selectedWatchlist && !selectedWatchlist.data.includes(expandedViewTicker.toUpperCase())) {
            handleAddToWatchList();
        } else if (watchlistName.length) {
            handleCreateNewWatchlist();
        } else {
            setTickerExists(true);
        }
    };

    const handleWatchlistNameChange = (e) => {
        setError(false);
        setWatchlistName(e.target.value);
        setSelectedWatchlist(null);
        setTickerExists(false);
    };

    return (
        <CardContainer percentileMove='EPIS' style={{ width: '350px', flexDirection: 'column', height: 'auto' }}>
            {addSuccess ? 
                <SuccessMessage>
                    {expandedViewTicker} has been added to your watchlist.
                </SuccessMessage> :
            <>
                <DisplayInlineHalfLTop>
                    Create or Add to Watchlist &nbsp; <AddToListIcon />
                </DisplayInlineHalfLTop>
                <div style={{ margin: '5px', padding: '5px' }}>
                    <DropdownMenu
                        handleChange={handleSelectWatchList}
                        id='watchlists'
                        options={watchlists}
                        value={selectedWatchlist}
                    />
                    <Input
                        onChange={handleWatchlistNameChange}
                        placeholder='Create New Watchlist'
                        style={{ marginTop: '5px', width: '100%' }}
                        value={watchlistName}
                    />
                    {error ?
                        <WarningText style={{ textAlign: 'center' }}>
                            Failed to add ticker to watchlist. Please try again.
                        </WarningText> :
                        null
                    }
                    {tickerExists ?
                        <WarningText style={{ textAlign: 'center' }}>
                            Watchlist already includes {expandedViewTicker}.
                        </WarningText> :
                        null
                    }
                    <StyledButton id='submit' onClick={handleSubmit} type='submit'>
                        Add to Watchlist
                    </StyledButton>
                </div>
            </>
            }
        </CardContainer>
    );
};

export default WatchlistPopup;
