import { useContext } from "react";

import { ExportIcon } from "../Icons/Icons";
import { StateContext } from "../../Controllers/context/state";
import { StyledButton } from "../StyledComponents/UserFormStyledComponents";

const ExportToCsv = ({ handleExportData }) => {
    const { setShowExportPopUp } = useContext(StateContext);

    const handleExport = () => {
        setShowExportPopUp(true);
    };

    return (
        <StyledButton style={{ margin: 0, marginLeft: 10 }} onClick={handleExportData ? handleExportData : handleExport}>
            Export to CSV
            <ExportIcon />
        </StyledButton>
    )
};

export default ExportToCsv;
