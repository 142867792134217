import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataOutputContainer } from '../../StyledComponents/AppStyledComponents';
import Loading from '../LoadingSpinner';
import supabase from '../../../Controllers/utils/supabaseClient';
import { StateContext } from '../../../Controllers/context/state';
import styled from 'styled-components';
import { AppColors } from '../../StyledComponents/Graphics';
import { copyTextToClipboard, formatToMonthYear,formatDateYearTimeTmz } from '../../../Controllers/utils';
import { IconBox } from '../../StyledComponents/StockCardStyledComponents';
import { CopyIcon } from '../../Icons/Icons';

const StyledParagraph = styled.p`
    font-size: 14px;
    margin-bottom: 10px;
`;

const SummaryWrapper = styled.div`
    padding: 0 10px 10px;
    height: 100%;
`;


const MonthlySummaryWrapper = ({ ticker }) => {
    const {
        customerProfile,
        logError,
        monthlySummaryTimestamp,
    } = useContext(StateContext);
    const [loadingMonthly, setLoadingMonthly] = useState(true);
    const [monthlySummary, setMonthlySummary] = useState(null);
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);
    const [timestamps, setTimestamps] = useState(null);
    const summaryContainerRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);
    const [startDate, setStartDate] = useState(null);



    const handleCopyClick = () => {
        copyTextToClipboard(monthlySummary?.summaryLines?.join('\n\n')).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    // const handleBack = () => {
    //     setSelectedTimestamp(prevState =>
    //         prevState !== 0 ? prevState - 1 : 0
    //     );
    // };

    // const handleForward = () => {
    //     setSelectedTimestamp(prevState =>
    //         prevState !== timestamps.length - 1 ? prevState + 1 : timestamps.length - 1
    //     );
    // };



    useEffect(() => {
        const fetchTimestamps = async ({ endPoint, retry = true, symbol }) => {
            const { data, error } = await supabase.rpc(endPoint, { _symbol: symbol });


            if (error) {
                const error = new Error(error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    fetchData({ retry: false });

                    return;
                }

                return;
            }

            const filteredData = data?.filter(item => item.hdl !== '' && item.hdl !== null && item.applicable_month !== null);
            setTimestamps(filteredData);
            setSelectedTimestamp(
                monthlySummaryTimestamp ?
                    filteredData?.findIndex(item => item.ts === monthlySummaryTimestamp) :
                    0
            );
        };

        fetchTimestamps({
            endPoint: 'get_monthly_marker_timestamps',
            retry: true,
            symbol: ticker,
        });
    }, [ticker]);


    const fetchMonthlySummary = async ({ retry = true }) => {
        let res;

        if (monthlySummaryTimestamp || timestamps?.[selectedTimestamp]?.ts) {
            res = await supabase.rpc('get_long_term_summary', {
                epoch_date_utc: timestamps[selectedTimestamp]?.ts / 1000,
                freq: '5W',
                ticker: ticker,
            })
        } else {
            res = await supabase.rpc('get_long_term_summary', {
                freq: '5W',
                ticker: ticker,
            })
        }
        if (res?.error) {

            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchMonthlySummary({ retry: false });
                return;
            }
            setMonthlySummary(null);
            setLoadingMonthly(true);

            return;
        }
        setLoadingMonthly(false);
        const startDate = new Date(res?.data?.[0]?.time_utc_end);
        const fourWeeksInMilliseconds = 4 * 7 * 24 * 60 * 60 * 1000;
        startDate.setTime(startDate.getTime() - fourWeeksInMilliseconds);
        setMonthlySummary({
            published: res?.data?.[0]?.time_utc_end,
            summaryLines: res?.data?.[0]?.data_row?.summary?.split('\n\n'),
            fromDate: res?.data?.[0]?.window_start ? res?.data?.[0]?.window_start : startDate.toISOString(),
            toDate: res?.data?.[0]?.window_end ? res?.data?.[0]?.window_end : res?.data?.[0]?.time_utc_end,
        });        
    };

    useEffect(() => {        
        fetchMonthlySummary({ retry: true });
    }, [selectedTimestamp, ticker]);

    useEffect(() => {
        if (summaryContainerRef.current) {
            summaryContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [monthlySummary]);

    useEffect(() => {
        if (selectedTimestamp && monthlySummary) {
            const date = new Date((timestamps?.[selectedTimestamp]?.ts || monthlySummary?.published));

            const fourWeeksInMilliseconds = 4 * 7 * 24 * 60 * 60 * 1000;
            date?.setTime(date?.getTime() - fourWeeksInMilliseconds);

            setStartDate(date?.toISOString());
        }
    }, [monthlySummary, selectedTimestamp]);



    if (loadingMonthly) {
        return <Loading />;
    }

    return (
        <div
            className='overlay'
            style={{ height: '100%', weigh: '100%' }}
        >
            <DataOutputContainer
                border={true}
                ref={summaryContainerRef}
            >
                <div style={{ width: '100%', position: 'sticky', top: '0', background: AppColors.MRNAVY18, padding: '15px 15px 3px 15px', zIndex: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                        <StyledParagraph style={{ fontWeight: 900, fontSize: '20px', margin: 0 }}>
                            {
                                timestamps?.[selectedTimestamp]?.hdl
                                // monthlySummary?.summaryLines?.[0]?.split('### ').length > 1 ?
                                //     monthlySummary?.summaryLines?.[0]?.split('### ')[1] :
                                //     monthlySummary?.summaryLines?.[0]?.split('### ')[0]
                            }
                        </StyledParagraph>
                        {customerProfile?.accessLevels?.includes('pro') &&
                            <IconBox onClick={handleCopyClick} title='Copy to Clipboard'>
                                <CopyIcon selected={isCopied} />
                            </IconBox>
                        }
                    </div>
                </div>
                <div>
                    <div style={{ padding: '0 10px' }}>
                        {monthlySummary?.summaryLines?.length === 1 ?
                            <StyledParagraph>
                                {monthlySummary?.summaryLines?.[0]}
                            </StyledParagraph> :
                            <SummaryWrapper>
                                {timestamps && <StyledParagraph style={{ fontSize: '12px', padding: '0 10px' }}>Last Updated on: {formatDateYearTimeTmz(timestamps?.[selectedTimestamp]?.ts)}</StyledParagraph>}
                                <StyledParagraph>
                                    <ul style={{ padding: '0 5px' }}>
                                        {monthlySummary?.summaryLines?.[1]?.split('$$ ').length === 1 ?
                                            monthlySummary?.summaryLines?.[1]?.split('** ')
                                                .map((line, index) => (
                                                    line === '' ?
                                                        null :
                                                        <>
                                                            <li key={index} style={{ marginBottom: 5 }} >
                                                                {line}
                                                            </li>
                                                        </>
                                                )) :
                                            monthlySummary?.summaryLines?.[1]?.split('$$ ')
                                                .map((line, index) => (
                                                    line === '' ?
                                                        null :
                                                        <>
                                                            <li key={index} style={{ marginBottom: 5 }} >
                                                                {line}
                                                            </li>
                                                        </>
                                                ))
                                        }
                                    </ul>
                                </StyledParagraph>
                                {monthlySummary?.summaryLines?.slice(2).map((line, index) => (
                                    <StyledParagraph key={index}>
                                        {line}
                                    </StyledParagraph>
                                ))}
                                <StyledParagraph style={{ fontSize: '16px', fontWeight: 900, marginTop: '10px' }}>You might also be interested in...</StyledParagraph>
                                <ul style={{ padding: '0 5px' }}>
                                    {/* //make a component for this */}
                                    {timestamps?.sort((a, b) => new Date(b?.applicable_month) - new Date(a?.applicable_month)).map((item, index) => (
                                        selectedTimestamp !== index &&
                                        <li
                                            key={index}
                                            style={{
                                                marginBottom: 5,
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                            onClick={() => setSelectedTimestamp(index)}
                                        >
                                            {formatToMonthYear(item?.applicable_month)} - {item?.hdl}
                                        </li>
                                    ))}
                                </ul>
                            </SummaryWrapper>
                        }
                    </div>
                </div>
            </DataOutputContainer>
        </div>
    );

};

export default MonthlySummaryWrapper;