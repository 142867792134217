import { useContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { CTAButton } from '../../StyledComponents/AppStyledComponents';
import NewsFeed from './components/NewsFeed';
import { StateContext } from '../../../Controllers/context/state';

const AlertFeed = () => {

    const {
        setChartTicker,
        setShowChart,
        watchlistAlerts,
    } = useContext(StateContext);

    const handleOpenChart = (symbol) => {
        setChartTicker(symbol);
        setShowChart(true);
    };

    return (
        <div style={{ height: (!isMobile || isTablet) ? '93%' : '90%' }}>
            <CTAButton style={{ cursor: 'default' }}>
                Watchlist Alerts
            </CTAButton>
            <NewsFeed feed={watchlistAlerts} handleOpenChart={handleOpenChart}/>
        </div>
    );
}
export default AlertFeed;
