import { useContext } from "react";

import NewsFeed from "./components/NewsFeed";
import { StateContext } from "../../../Controllers/context/state";
import { Header } from "../../StyledComponents/UserFormStyledComponents";

const MacroMovers = () => {
    const {
        feed
    } = useContext(StateContext);

    return (
        <div style={{ height: 'calc(100% - 45px)' }}>
            <Header style={{ margin: 5 }}>
                Macro Movers
            </Header>
            <br />
           <NewsFeed feed={feed.macroNews} />
        </div>
    )
};

export default MacroMovers;
