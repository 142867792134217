import { CardContainer, CompanyName, IconBox, StockInfoContainer, StockInfoTextBoxLeft, StockInfoTextBoxRight } from '../../StyledComponents/StockCardStyledComponents';
import { StyledLabel } from '../../StyledComponents/UserFormStyledComponents';
import { CenteredDiv } from '../../StyledComponents/AppStyledComponents';
import {
    DeleteIcon, DirectionDownIcon, DirectionMoveIcon, DirectionUpIcon,
} from '../../Icons/Icons';
import supabase from '../../../Controllers/utils/supabaseClient';
import { useContext } from 'react';
import { StateContext } from '../../../Controllers/context/state';
import ToggleSwitch from '../../Components/ToggleSwitch';
import { round } from '../../../Controllers/utils';

const WatchlistView = ({ handleSelectWatchList }) => {
    const {
        fetchAllWatchlists,
        logError,
        notificationSettings,
        setShowConfirmationWindow,
        sortWatchlistsByReturn,
        watchlists,
    } = useContext(StateContext);

    const handleDeleteWatchlist = ({ retry = true, value}) => {
        const { id, meta } = value;
        setShowConfirmationWindow({
            handleCancel: () => setShowConfirmationWindow(false),
            handleConfirm: () => {
                supabase.from('user_preferences').delete().eq('id', id).then((res) => {
                    if (res?.error) {
                        const error = new Error(res?.error?.message);
                        logError(error.message, undefined, error);

                        if (retry) {
                            handleDeleteWatchlist({ retry: false, value })
                        }
            
                        return;
                    }

                    fetchAllWatchlists({ retry: true });
                    setShowConfirmationWindow(false);
                });
            },
            headline: `Are you sure you want to delete ${meta.name}?`,
            show: true,
        });
    };

    const toggleWatchlistNotifications = ({ e, retry = true, value }) => {
        const { id } = e?.target;
        const newValue = {
            data: value?.data,
            id: value?.id,
            meta: {
                ...value?.meta,
                notifications: !value?.meta?.notifications,
            },
            user_id: value?.user_id
        };

        supabase.from('user_preferences').update(newValue).eq('id', id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    toggleWatchlistNotifications({ e, retry: false, value })
                }
    
                return;
            }

            fetchAllWatchlists({ retry: true });
        })
    };

    return (
        <>
            {
                watchlists?.filter(watchlist => watchlist.value)
                    .sort((a, b) => (
                        sortWatchlistsByReturn ? 
                        Math.abs(JSON.parse(b.value).date_return_oc_avg * 100) - Math.abs(JSON.parse(a.value).date_return_oc_avg * 100) :
                        JSON.parse(a.value).meta.name.localeCompare(JSON.parse(b.value).meta.name)
                    ))
                    .sort((a, b) => (
                        (JSON.parse(b.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') === (JSON.parse(a.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') ? 0 :
                        (JSON.parse(b.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') ? -1 : 1)
                    )
                    .map((watchlist) => {
                        const value = JSON.parse(watchlist.value);
                        let moveDirection;
                        let moveColor;
                        let plusSign;
                        
                        if (round(value?.date_return_oc_avg * 100, 1) > 0 ) {
                            plusSign = '+'
                            moveDirection = <DirectionUpIcon/>;
                            moveColor ='MRGREEN';
                        } else if (round(value?.date_return_oc_avg * 100, 1) < 0){
                            moveDirection = <DirectionDownIcon/>;
                            moveColor ='MRRED';
                        } else if (round(value?.date_return_oc_avg * 100, 1) === 0 ){
                            moveDirection = <DirectionMoveIcon/>;
                            moveColor ='MRLITE80';
                        }

                        return (
                            watchlist?.value ?
                                <CardContainer
                                    key={watchlist?.label}
                                    percentileMove='EPIS'
                                    style={{ height: '100px', position: 'relative' }}
                                >
                                    <div
                                        onClick={() => handleSelectWatchList(null, watchlist?.value)}
                                        style={{ cursor: 'pointer', display: 'flex', flexDirection: ' column', height: '65px', width: '100%' }}
                                    >
                                        <CompanyName style={{ margin: '10px 10px'}}>
                                            <h2 style={{ fontSize: 16 }}>{watchlist.label}</h2>
                                        </CompanyName>
                                        <StockInfoContainer moveColor={moveColor} style={{ padding: '0 10px' }}>
                                            <StockInfoTextBoxLeft style={{ fontSize: 14 }}>
                                                Today's Avg. Return:
                                            </StockInfoTextBoxLeft>
                                            <StockInfoTextBoxRight style={{ fontSize: 14 }}>
                                                {plusSign}{round(value?.date_return_oc_avg * 100, 1)}% 
                                                {moveDirection}
                                            </StockInfoTextBoxRight>
                                        </StockInfoContainer>
                                    </div>
                                    {value?.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' &&
                                        <>
                                            {notificationSettings?.data?.watchlistNotifications &&
                                                <div
                                                    style={{ display: 'flex', position: 'absolute', bottom: 2, left: 10 }}
                                                >
                                                    <StyledLabel style={{ marginTop: 0, marginRight: 5 }}>
                                                        Alerts:
                                                    </StyledLabel>
                                                    <ToggleSwitch
                                                        handleClick={(e) => toggleWatchlistNotifications({ e, retry: true, value })}
                                                        id={value?.id}
                                                        isOn={value?.meta?.notifications}
                                                        onOffSwitch
                                                        value={value}
                                                    />
                                                </div>
                                            }
                                            <IconBox style={{ position: 'absolute', bottom: 0, right: 0 }} onClick={() => handleDeleteWatchlist({ retry: true, value})}>
                                                <DeleteIcon />
                                            </IconBox>
                                        </>
                                    }
                                </CardContainer> :
                                null

                        );
                    })
            }
        </>
    )
};

export default WatchlistView;
