import styled from 'styled-components';
import { ReactComponent as AddSvg } from '../Images/addIcon.svg';
import { ReactComponent as AddToListSVG } from '../Images/addToList.svg';
import { ReactComponent as ArrowDownSvg } from '../Images/arrowDownIcon.svg';
import { ReactComponent as ArrowLineSvg } from '../Images/arrowIcon.svg';
import { ReactComponent as ArrowUpSvg } from '../Images/arrowUpIcon.svg';
import { ReactComponent as BackSvg } from '../Images/backIcon.svg';
import { ReactComponent as CalendarSvg } from '../Images/calendarIcon.svg';
import { ReactComponent as ChartSvg } from '../Images/chartIcon.svg';
import { ReactComponent as ClipboardSvg } from '../Images/clipboardIcon.svg';
import { ReactComponent as ClockSvg } from '../Images/clockIcon.svg';
import { ReactComponent as CloseSvg } from '../Images/closeIcon.svg';
import { ReactComponent as CopySvg } from '../Images/copyIcon.svg';
import { ReactComponent as DeleteSvg } from '../Images/deleteIcon.svg';
import { ReactComponent as DetailSvg } from '../Images/detailIcon.svg';
import { ReactComponent as EditSvg } from '../Images/editIcon.svg';
import { ReactComponent as EnlargenSvg } from '../Images/enlargenIcon.svg';
import { ReactComponent as ExpandSvg } from '../Images/expandIcon.svg';
import { ReactComponent as ExportSvg } from '../Images/exportIcon.svg';
import { ReactComponent as ExtendedSvg } from '../Images/extendedIcon.svg';
import { ReactComponent as FactorSvg } from '../Images/factorIcon.svg';
import { ReactComponent as FilterSvg } from '../Images/filterIcon.svg';
import { ReactComponent as MenuIconSvg } from '../Images/menuIcon.svg';
import { ReactComponent as MoreInfoSvg } from '../Images/moreInfoIcon.svg';
import { ReactComponent as NavyOpenArrowDownSvg } from '../Images/openArrowDownNAVY.svg';
import { ReactComponent as NewsSvg } from '../Images/newsIcon.svg';
import { ReactComponent as NotificationSvg } from '../Images/notificationIcon.svg';
import { ReactComponent as OpenArrowUpSvg } from '../Images/openArrowUp.svg';
import { ReactComponent as OpenArrowDownSvg } from '../Images/openArrowDown.svg';
import { ReactComponent as OpenArrowLeftSvg } from '../Images/openArrowLeft.svg';
import { ReactComponent as OpenArrowRightSvg } from '../Images/openArrowRight.svg';
import { ReactComponent as OpenArrowSvg } from '../Images/openArrowIcon.svg';
import { ReactComponent as ProfileSvg } from '../Images/profileIcon.svg';
import { ReactComponent as HelpSvg } from '../Images/helpIcon.svg';
import { ReactComponent as RemoveSvg } from '../Images/removeIcon.svg';
import { ReactComponent as RefreshSvg } from '../Images/refreshIcon.svg';
import { ReactComponent as SaveSvg } from '../Images/saveIcon.svg';
import { ReactComponent as ScreenerSvg } from '../Images/screenerIcon.svg';
import { ReactComponent as SearchSvg } from '../Images/searchIcon.svg';
import { ReactComponent as SettingsSvg } from '../Images/settingsIcon.svg';
import { ReactComponent as ShareSvg } from '../Images/shareIcon.svg';
import { ReactComponent as ThumbsUpSvg } from '../Images/thumbsUpIcon.svg';
import { ReactComponent as TwitterSvg } from '../Images/twitterIcon.svg';
import { ReactComponent as UnprocessedSvg } from '../Images/unprocessedIcon.svg';
import { AppColors, TagColors } from '../StyledComponents/Graphics';

export const moveDirection = {
    UP:'',
    DOWN:'',
    STRAIGHT:'',
}

export const AddIcon = styled(AddSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;
export const AddToListIcon = styled(AddToListSVG)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const BackIcon = styled(BackSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const CalendarIcon = styled(CalendarSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;
`;

export const ChartIcon = styled(ChartSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;
`;

export const ClipboardIcon = styled(ClipboardSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: copy;
`;

export const ClockIcon = styled(ClockSvg)` 
    float: right;
    cursor: pointer;
    height: 17px;
    width: 17px;
    padding: 1px;
`;

export const CloseIcon = styled(CloseSvg)` 
    float: right;
    cursor: pointer;
    height: 15px;
    width: 15px;
    padding: 1px;
`;

export const CopyIcon = styled(CopySvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;

    rect {
        fill: ${props => props.selected ? AppColors.MRGREEN : AppColors.MRGREY};
    }
`;
export const DeleteIcon = styled(DeleteSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;
`;

export const DetailIcon = styled(DetailSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;
`;

export const DirectionDownIcon = styled(ArrowDownSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const DirectionUpIcon = styled(ArrowUpSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const DirectionMoveIcon = styled(ArrowLineSvg)`
    float: right;
    margin-left: 5px;
    width: 20px;
`;

export const EditIcon = styled(EditSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const EnlargenIcon = styled(EnlargenSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const ExpandIcon = styled(ExpandSvg)` 
    float: right;
    cursor: pointer;
    height: 18px;
    width: 18px;
    padding: 1px;
`;

export const ExportIcon = styled(ExportSvg)` 
    float: right;
    cursor: pointer;
    height: 18px;
    width: 18px;
    padding: 1px;
    fill: ${AppColors.MRWHITE};
`;

export const ExtendedIcon = styled(ExtendedSvg)` 
    float: right;
    cursor: pointer;
    height: 18px;
    width: 18px;
    padding: 1px;
`;

export const ExtendedHoursIcon = styled.div` 
    position: ${props => props.table ? null : 'absolute'};
    right: ${props => props.table ? null : '40px'};
    top: -1px;
    content: "EXT";
    font-size: 11px;
    height: 25px;
    width: 30px;
    padding: 5px;
    border-radius: 0px 0px 6px 6px;
    background-color: ${props => TagColors["DTM"]};
`;

export const FactorIcon = styled(FactorSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;
`;

export const FilterIcon = styled(FilterSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const MenuIcon = styled(MenuIconSvg)` 
    float: right;
    cursor: pointer;
    height: 30px;
    width: 30px;
    padding: 0px;
`;

export const MoreInfoIcon = styled(MoreInfoSvg)` 
    float: right;
    cursor: pointer;
    height: 14px;
    width: 15px;
    padding: 0px;
`;

export const NewsIcon = styled(NewsSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const OpenArrowDownIcon = styled(OpenArrowDownSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'none'};
`;
export const OpenArrowLeftIcon = styled(OpenArrowLeftSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;
export const OpenArrowRightIcon = styled(OpenArrowRightSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;
export const OpenArrowUpIcon = styled(OpenArrowUpSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    transform: ${props => props.open ? 'rotate(180deg)' : 'none'};
    transition: transform 0.3s ease-in-out;
`;

export const NavyOpenArrowIcon = styled(NavyOpenArrowDownSvg)`
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    transform: ${props => props.open ? 'rotate(180deg)' : 'none'};
    transition: transform 0.3s ease-in-out;
`;

export const NotificationIcon = styled(NotificationSvg)` 
    float: right;
    cursor: pointer;
    height: 30px;
    width: 30px;
    fill: ${props => props.on ? AppColors.MRVIOLET : AppColors.MRNAVY15};
`;

export const OpenArrowIcon = styled(OpenArrowSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;

    transform: ${props => props.open ? 'rotate(180deg)' : 'none'};
    transition: transform 0.3s ease-in-out;
`;

export const ProfileIcon = styled(ProfileSvg)` 
    float: right;
    cursor: pointer;
    height: 30px;
    width: 30px;
`;

export const HelpIcon = styled(HelpSvg)` 
    float: right;
    cursor: pointer;
    height: 30px;
    width: 30px;
    fill: ${AppColors.MRNAVY15};
`;

export const RemoveIcon = styled(RemoveSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: no-drop;
`;

export const RefreshIcon = styled(RefreshSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: no-drop;
`;

export const SaveIcon = styled(SaveSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: cell;
`;

export const ScreenerIcon = styled(ScreenerSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: cell;
`;

export const SearchIcon = styled(SearchSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: cell;
`;

export const SettingsIcon = styled(SettingsSvg)` 
    float: right;
    cursor: pointer;
    height: 30px;
    width: 30px;
    padding: 1px;
    cursor: cell;
`;

export const ShareIcon = styled(ShareSvg)` 
float: right;
cursor: pointer;
height: 20px;
width: 20px;
padding: 1px;
cursor: cell;
`;

export const ThumbsDownIcon = styled(ThumbsUpSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    transform: rotateX(180deg);
    path {
        fill: ${props => props.selected ? AppColors.MRRED : AppColors.MRWHITE};
    }

    &:hover path {
        fill: ${AppColors.MRRED};
    }
`;

export const ThumbsUpIcon = styled(ThumbsUpSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
    path {
        fill: ${props => props.selected ? AppColors.MRGREEN : AppColors.MRWHITE};
    }

    &:hover path {
        fill: ${AppColors.MRGREEN};
    }
`;

export const TwitterIcon = styled(TwitterSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;

export const UnprocessedIcon = styled(UnprocessedSvg)` 
    float: right;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 1px;
`;
