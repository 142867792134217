import React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 999;
`;

const Spinner = styled.div.attrs({ 'data-testid': 'loading-spinner' })`
	display: inline-block;
	width: ${props => props?.dimensions?.width || '64px'};
	height: ${props => props?.dimensions?.height || '64px'};
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-top-color: #fff;
	border-radius: 50%;
	animation: spin 1s ease-in-out infinite;

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
`;

const Loading = ({ dimensions }) => {
	return (
		<LoadingWrapper>
			<Spinner dimensions={dimensions} />
		</LoadingWrapper>
	);
};

export default Loading;
