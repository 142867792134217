import { useEffect, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import { AppColors } from "../../../Global/StyledComponents/Graphics";
import { Header, SubmitButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import supabase from "../../../Controllers/utils/supabaseClient";
import { MarketReaderWatermark } from "../../../Global/Logos/Logos";
import { BlankSpacer } from "../../../Global/StyledComponents/AppStyledComponents";

const PlanWrapper = styled.div`
    background-color: ${(!isMobile || isTablet) ? AppColors.MRNAVY20 : AppColors.MRNAVY30};
    border-radius: 10px;
    color: ${AppColors.MRWHITE};
    display: flex;
    flex-direction: column;
    padding: 30px;
    min-width: ${({ isMobile, individual}) => isMobile ? '95%' : individual ? '800px' : '500px'};
    max-width: ${({ isMobile, individual }) => isMobile ? '95%' : individual ? '800px' : '500px'};
    margin: 10px;
    height: ${({ isMobile }) => isMobile ? 'auto' : '710px'};
`;

const PriceBlock = styled.div`
    display: flex;
    margin: 20px 0;
    justify-content: ${({ isMobile }) => isMobile ? 'center' : 'space-between'};
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
`;

const StyledList = styled.ul`
    height: auto;
    margin: 0;
    padding: 0 20px;
`;

const Subheading = styled.h3`
    line-height: 24px;
`;

const PriceCard = styled.div`
    position: relative;
    background: #0A2A3E;
    border-radius: 12px;
    padding: 16px;
    color: white;
    width: calc(30% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: ${props => props.thickBorder ? '3px solid' : '1px solid'} ${props => props.color};
    min-width: 200px;
`;

const Duration = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const Discount = styled.div`
    font-size: 36px;
    color: ${AppColors.MRGREEN};
    font-weight: bold;
    margin-bottom: 8px;
`;

const PriceDisplay = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const OriginalPrice = styled.span`
    text-decoration: line-through;
    font-size: 24px;
    opacity: 0.6;
`;

const CurrentPrice = styled.span`
    font-size: 32px;
    font-weight: bold;
    display: flex;
    align-items: start;
    justify-content: center;
`;

const OneTimeFee = styled.span`
    font-size: 14px;
    opacity: 0.7;
`;

const BenefitItem = styled.div`
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    
    &:before {
        content: "•";
        color: ${AppColors.MRGREEN};
        margin-right: 6px;
    }
`;

const PlanSelector = ({
    disabled,
    getUser,
    plan,
    planKey,
    promoData,
    readOnly,
    setDisabled,
    setPlan,
    trialStatus,
    user,
}) => {
    const {
        buttonText,
        description,
        includes,
        interval,
        price,
        priceModel,
        subheading,
        title,
    } = plan;
    const [readMore, setReadMore] = useState(true);
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/requestDemo');
    };

    const handleReadMore = () => {
        setReadMore(prevState => !prevState);
    };

    const handleSelectPlan = (id) => {
        setPlan(id);
    };

    const handleSubmit = async () => {
        setDisabled(true)
        const { data, error } = await supabase.functions.invoke('createSubscriptionV2', {
            body: {
                priceModel,
                promoId: promoData?.id,
                trialStatus,
                userEmail: user?.email,
            },
        });

        const { ok, session } = JSON.parse(data);

        if (ok && session) {
            window.open(session?.url, '_blank');
            setDisabled(false);
            return;
        }

        if (ok) {
            supabase.from('profiles').update({
                trial_status: true
            }).eq('id', user?.id).then((res) => {
                getUser();
            });
            return;
        }
        setDisabled(false);
        return;
    };

    useEffect(() => {
        if (window.innerHeight < 1000) {
            setReadMore(false);
        }
    }, [window]);

    return (
        <PlanWrapper isMobile={isMobile && !isTablet} individual={planKey === 'individual'}>
            <h2 style={{ marginTop: 0 }}>
                {planKey === 'individual' ?
                    <>
                        <MarketReaderWatermark style={{ width: 250 }} />
                    </>
                    :
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MarketReaderWatermark style={{ width: 250 }} /> <div style={{ color: AppColors.MRGREEN, fontSize: 36, fontWeight: 'bold', marginLeft: 5 }}>Pro</div>
                    </div>
                }
            </h2>
            {subheading &&
                <Subheading>{subheading}</Subheading>
            }
            <p style={{ height: 'auto' }}>
                {description}
            </p>
            {planKey === 'individual' &&
                <>
                    <PriceBlock isMobile={isMobile && !isTablet}>
                        <PriceCard color={AppColors.MRGREY70} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: isMobile && !isTablet ? 'auto' : 'calc(100% - 72px)', marginTop: (isMobile && !isTablet) ? 0 : 40 }}>
                            <Duration>Monthly Subscription</Duration>
                            <PriceDisplay style={{ justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CurrentPrice style={{ color: AppColors.MRGREEN, fontSize: 48 }}>
                                        {price}
                                        <div style={{ fontSize: 18 }}>
                                            99
                                        </div>
                                    </CurrentPrice>
                                    <OneTimeFee>{interval}</OneTimeFee>

                                </div>
                            </PriceDisplay>
                            <div>
                                <SubmitButton
                                    style={{ marginTop: 20, width: '100%' }}
                                    onClick={() => handleSelectPlan('monthly')}
                                >
                                    Get Started
                                </SubmitButton>
                                <br />
                                <OneTimeFee>*Includes 7 day free trial</OneTimeFee>
                            </div>
                        </PriceCard>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: (isMobile && !isTablet) ? '100%' : 'calc(70% - 40px)', height: '100%' }}>
                            <Header style={{ color: AppColors.MRGREEN, marginTop: (isMobile && !isTablet) ? 20 : 0, marginRight: 15 }}>
                                End Of Year Special: December Only Deals
                            </Header>
                            <div style={{ display: 'flex', flexWrap: (isMobile && !isTablet) ? 'wrap' : 'nowrap', justifyContent: (isMobile && !isTablet) ? 'center' : 'space-between', width: '100%', height: '100%' }}>
                                <PriceCard
                                    color={AppColors.MRGREY70}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        width: 'calc(50% - 50px)'
                                    }}
                                >
                                    <div>
                                        <Duration>6 Months</Duration>
                                        <Discount>58% Off</Discount>
                                        <PriceDisplay>
                                            <OriginalPrice>$239</OriginalPrice>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <CurrentPrice>$99</CurrentPrice>
                                                <OneTimeFee>one-time fee</OneTimeFee>
                                            </div>
                                        </PriceDisplay>
                                    </div>
                                    <div>
                                        <SubmitButton
                                            onClick={() => handleSelectPlan('6month')}
                                            style={{ marginTop: 20, width: '100%' }}
                                        >
                                            Get Started
                                        </SubmitButton>
                                        <br />
                                        <OneTimeFee>*Includes 7 day free trial</OneTimeFee>
                                    </div>
                                </PriceCard>
                                {isMobile && !isTablet &&
                                    <BlankSpacer />
                                }
                                <PriceCard
                                    thickBorder
                                    color={AppColors.MRGREEN}
                                    style={{
                                        width: 'calc(50% - 50px)'
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: AppColors.MRGREEN,
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: AppColors.MRNAVY,
                                        padding: '10px'
                                    }}>
                                        Best Value
                                    </div>
                                    <Duration>12 Months + 2 free</Duration>
                                    <Discount>70% Off</Discount>
                                    <PriceDisplay>
                                        <OriginalPrice>$699</OriginalPrice>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CurrentPrice>$199</CurrentPrice>
                                            <OneTimeFee>one-time fee</OneTimeFee>
                                        </div>
                                    </PriceDisplay>
                                    <BenefitItem>
                                        Includes Sector-Focused Newsletters (a $140 value)
                                    </BenefitItem>
                                    <SubmitButton
                                        onClick={() => handleSelectPlan('14month')}
                                        style={{ marginTop: 20, width: '100%' }}
                                    >
                                        Get Started
                                    </SubmitButton>
                                    <br />
                                    <OneTimeFee>*Includes 7 day free trial</OneTimeFee>
                                </PriceCard>
                            </div>
                            
                        </div>
                    </PriceBlock>
                </>
            }
            {planKey !== 'individual' &&
                <div style={{ margin: '20px 0' }}>
                    {!readOnly &&
                        <SubmitButton onClick={handleContact}>Request Demo</SubmitButton>
                    }
                </div>
            }
            <Subheading>
                {planKey === 'individual' ? 'This includes:' : 'Includes everything in MarketReader, plus:'}
            </Subheading>
            <StyledList style={{ height: readMore ? null : '110px', overflow: 'hidden' }}>
                {includes.map((item, index) => (
                    <li style={{ lineHeight: '18px', marginBottom: '8px '}} key={index}>
                        {item}
                    </li>
                ))}
            </StyledList>
                {window.innerHeight < 1100 &&
                    <div onClick={handleReadMore} style={{ cursor: 'pointer' }}>
                        {readMore ? 'Read Less...' : 'Read More...'}
                    </div>
                }
        </PlanWrapper>
    );
};

export default PlanSelector;
