import styled from 'styled-components';
import { ReactComponent as WhiteLogoSVG } from '../Images/MR_1line_White.svg';
import { ReactComponent as BlackLogoSVG } from '../Images/MR_1line_Black.svg';

export const BlackTextGlassLogo = styled(BlackLogoSVG)` 
    display: block;
    cursor: pointer;
    height: 40px;
    width: 200px;
    margin: 5px 20px;
`;

export const MarketReaderWatermark = styled(WhiteLogoSVG)`
    width: 100%;
    height: 50px;
    content: 'Market Reader WaterMark';
`;

export const WhiteTextGlassLogo = styled(WhiteLogoSVG)` 
    display: block;
    cursor: pointer;
    height: 40px;
    width: 200px;
`;
