import React from 'react';
import styled from 'styled-components';
import CalendarEvent from '../../CalendarMonitor/components/CalendarEvent';

const CalendarEventWrapper = styled.div`
    width: 100%;
    height: auto;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const CalendarEventPopUp = ({ selectedCalendarEvent }) => {
    return (
        <CalendarEventWrapper>
            <CalendarEvent width={'100%'} selectedCalendarEvent={selectedCalendarEvent} />
        </CalendarEventWrapper>
    );
};

export default CalendarEventPopUp;
