import React, { useContext, useEffect, useRef, useState } from 'react';
import CalendarTableRow from './components/CalendarTableRow';
import { formatDateYear } from '../../../Controllers/utils';
import { StateContext } from '../../../Controllers/context/state';
import { TableContainer, TableHeader } from '../TableView/styledComponents';

const CalendarTableView = ({ calendarEvents }) => {
    const {setSelectedCalendarEvent,setShowExpandedView,setExpandedViewTicker} = useContext(StateContext);
    const closestRowRef = useRef(null);
    const [closestEvent, setClosestEvent] = useState(null);
    const hasScrolled = useRef(false);
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 6);

    useEffect(() => {
        if (calendarEvents?.length && !hasScrolled.current) {
            const targetTime = currentDate.getTime();
            
            let closest = calendarEvents[0];
            let smallestDiff = Math.abs(new Date(calendarEvents[0].time_utc).getTime() - targetTime);
            
            calendarEvents.forEach(event => {
                const diff = Math.abs(new Date(event.time_utc).getTime() - targetTime);
                if (diff < smallestDiff) {
                    smallestDiff = diff;
                    closest = event;
                }
            });

            setClosestEvent(closest);

            if (closestRowRef.current) {
                closestRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                hasScrolled.current = true;
            }
        }
    }, [calendarEvents, currentDate]);

    const handleRowClick = (event) => {
        setSelectedCalendarEvent(event);
        setShowExpandedView(true);
        setExpandedViewTicker(event.symbol);
    };

    const groupedEvents = calendarEvents?.reduce((groups, event) => {
        const date = event.time_utc.split('T')[0];        
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(event);
        return groups;
    }, {});


    return (
        <>
            <TableContainer>
                {Object.entries(groupedEvents || {}).map(([date, events], index) => (
                    <React.Fragment key={date}>
                        {index === 0 && (
                            <thead style={{position: 'sticky', top: 0, zIndex: 2}}>
                                <tr style={{padding: '0 10px'}}>
                                    <TableHeader style={{paddingRight: "30px"}}>Date/Time</TableHeader>
                                    <TableHeader>Country</TableHeader>
                                    <TableHeader>Event</TableHeader>
                                    <TableHeader>Category</TableHeader>
                                    <TableHeader>Source</TableHeader>
                                    <TableHeader>Status</TableHeader>
                                    <TableHeader>Headline</TableHeader>
                                    <TableHeader>Actual</TableHeader>
                                    <TableHeader>Forecast</TableHeader>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            <tr 
                                style={{ 
                                    position: 'sticky', 
                                    top: '23px',
                                    zIndex: 1, 
                                    display: 'table-row',
                                    backgroundColor: 'white'
                                }}
                            >
                                <TableHeader colSpan="9" style={{zIndex: 0}}>
                                    {formatDateYear(new Date(date + 'T00:00:00').toLocaleDateString())}
                                </TableHeader>
                            </tr>
                            {events.map((event) => (
                                <CalendarTableRow
                                    key={event.id}
                                    event={event}
                                    ref={new Date(event.time_utc).getTime() === new Date(closestEvent?.time_utc).getTime() ? closestRowRef : null}
                                    onClick={() => handleRowClick(event)}
                                />
                            ))}
                        </tbody>
                    </React.Fragment>
                ))}
            </TableContainer>
        </>
    );
};

export default CalendarTableView;