import { useState } from 'react';
import NewUserManager from './components/NewUserManager';
import { Header, StyledButton, SubmitButton } from '../../Global/StyledComponents/UserFormStyledComponents';
import { ViewModule } from '../../Global/StyledComponents/AppStyledComponents';
import OrganizationManager from './components/OrganizationManager';
import EnterpriseApiUserManager from './components/EnterpriseApiUserManager';

const AdminMonitor = () => {
    const [showUserManagement, setShowUserManagement] = useState({
        newUser: false,
        organization: false,
        enterpriseApiUser: false,
    });

    const toggleUserManagement = (key) => () => {
        setShowUserManagement(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    return (
        <ViewModule>
            {Object.values(showUserManagement).some(value => value) && (
                <StyledButton
                    style={{ position: 'absolute', top: 0, left: 10 }}
                    onClick={() => {
                        const activeKey = Object.keys(showUserManagement).find(key => showUserManagement[key]);
                        if (activeKey) {
                            toggleUserManagement(activeKey)();
                        }
                    }}
                >
                    Back
                </StyledButton>
            )}
            {!Object.values(showUserManagement).some(value => value) && (
                <>
                    <Header style={{ position: 'absolute', top: 20, left: 0, right: 0, textAlign: 'center' }}>Admin Dashboard</Header>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '20px' }}>
                        <SubmitButton
                            style={{ textAlign: 'center', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={toggleUserManagement('newUser')}
                        >
                            Add New User Form
                        </SubmitButton>
                        <SubmitButton
                            style={{ textAlign: 'center', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={toggleUserManagement('organization')}
                        >
                            Organization Manager
                        </SubmitButton>
                        <SubmitButton
                            style={{ textAlign: 'center', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={toggleUserManagement('enterpriseApiUser')}
                        >
                            Enterprise API User Manager
                        </SubmitButton>
                    </div>
                </>
            )}
            {showUserManagement.newUser &&
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <NewUserManager height='100%' />
                </div>
            }
            {showUserManagement.organization &&
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <OrganizationManager height='100%' />
                </div>
            }
            {showUserManagement.enterpriseApiUser &&
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <EnterpriseApiUserManager height='100%' />
                </div>
            }
        </ViewModule>
    );
};

export default AdminMonitor;
