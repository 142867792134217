import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { CTAButtonTitle } from '../StyledComponents/AppStyledComponents';

export const Accordion = styled.div`
   cursor: context-menu;
`;

export const AccordionButtonTitle = styled(CTAButtonTitle)`
   background-color: ${(props) => props.isOpen ?  AppColors.MRNAVY50 : AppColors.MRNAVY30};  
`;

export const AccordionBox = styled.div`
   cursor: context-menu;
   background-color: ${AppColors.MRNAVY20};
   border-inline: 1px solid ${AppColors.MRNAVY40};
   overflow: hidden; 
   padding: ${(props) => props.isOpen ? '2px 0px 10px 0px': '0px'}; 
   height: auto;
   max-height: ${(props) => props.isOpen ? '2000px' : '0px'}; 
   transition: visibility 0.8s ease-in, max-height 0.8s ease-in-out;
`;

export const ModelIcon = styled.div`
   display: inline-block;
   height: 20px;
   padding-left: 10px;
   padding-top: 5px;
`;
export const ModelName = styled.h3`
   cursor: context-menu;
   display: inline-block;
   font-size: 14px;
   height: 20px;
   line-height: 20px;
   font-weight: 600;
   clear: both;
`;
export const ModelText = styled.p`
   cursor: context-menu;
   padding: 0 10px 0 45px;
`;