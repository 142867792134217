import { useEffect, useState } from'react';
import { filterFeedDuplicates } from '../utils';
import supabase from '../utils/supabaseClient';

const FeedTypes = [
    'Common Stock',
    'ADR',
    null,
];

const useFetchNewsFeedAndAlerts = ({ logError, notificationSettings, user, watchlistTickers }) => {
    const [feed, setFeed] = useState({
        breakingNews: [],
        dtmNews: [],
    });
    const [newsLoading, setNewsLoading] = useState(true);
    const [newAlerts, setNewAlerts] = useState(0);
    const [lastChecked, setLastChecked] = useState(notificationSettings?.data?.watchlistNotificationsLastChecked);
    const tickers = watchlistTickers.map(item => item.symbol);
    const [watchlistAlerts, setWatchlistAlerts] = useState([]);
    // const audio = new Audio('/NotificationDing.mp3');

    const handleSetLastChecked = ({ retry = true }) => {
        setLastChecked(() => {
            const date = new Date();
            supabase.from('user_preferences').upsert({
                ...notificationSettings,
                data: {
                    ...notificationSettings.data,
                    watchlistNotificationsLastChecked: date,
                },
                type: 'notificationSettings',
                user_id: user.id,
            }).eq('type', 'notificationSettings').then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);
        
                    if (retry) {
                        handleSetLastChecked({ retry: false });
        
                        return;
                    }
                }
            });

            return date;
        });

    }

    const triggerNotification = (alert) => {
        // audio.play();
        var options = {
            body: alert.headline,
            icon: './logo192.png',
            dir: 'ltr',
        };
        
        const title = `MarketReader (${alert.symbol})`
        
        const notification = new Notification(title, options);
    };

    const setWatchlistAlertsTitle = (alerts, feed) => {
        const newAlerts = alerts.filter(alert => alert.insert_timestamp >= lastChecked);

        if (newAlerts.length &&
            feed &&
            notificationSettings?.data?.watchlistNotifications &&
            !notificationSettings?.data?.breakingNewsNotifications
        ) {
            triggerNotification(newAlerts[0]);
        }
        
        setNewAlerts(newAlerts.length);
    };

    const filterDtmNews = (feed) => {
        const filtered = filterFeedDuplicates(feed
            .filter((item) => item.aggr === 'dtm')
            .filter(item => FeedTypes.includes(item.type))
            .sort((a, b) => new Date(b.insert_timestamp) - new Date(a.insert_timestamp))
        );

        return filtered;
    };

    const filterBreakingNews  = (feed) => {
        const filtered = filterFeedDuplicates(feed
            .filter((item) => item.aggr === '10min')
            .filter(item => FeedTypes.includes(item.type))
            .sort((a, b) => new Date(b.insert_timestamp) - new Date(a.insert_timestamp))
        );

        return filtered;
    };

    const filterMacroNews  = (feed) => {
        const filtered = filterFeedDuplicates(feed
            .filter(item => !FeedTypes.includes(item.type))
            .sort((a, b) => new Date(b.insert_timestamp) - new Date(a.insert_timestamp))
        );

        return filtered;
    };

    function findMatchingIndexAndSlice(list1, list2) {
        if (!list1.length || !list2.length) {
            return [];
        }
    
        const { symbol, time_utc } = list1[0];
    
        const matchIndex = list2.findIndex(item => item.symbol === symbol && item.time_utc === time_utc);
    
        if (matchIndex === -1) {
            return [];
        }
    
        return list2.slice(0, matchIndex);
    }

    const getSummaries = ({ retry = true }) => {
        supabase.from('get_summaries').select('*').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    getSummaries({ retry: false });
    
                    return;
                }
            }

            const breakingNews = filterBreakingNews(res?.data);
            const dtmNews = filterDtmNews(res?.data);
            const macroNews = filterMacroNews(res?.data);

            setFeed({
                breakingNews,
                dtmNews,
                macroNews,
            });
            setWatchlistAlertsTitle(breakingNews?.filter(({ symbol }) => tickers?.includes(symbol)));
            setWatchlistAlerts(breakingNews?.filter(({ symbol }) => tickers?.includes(symbol)));
            setNewsLoading(false);
        });
    };


    useEffect(() => {
        setLastChecked(notificationSettings?.data?.watchlistNotificationsLastChecked);
    }, [notificationSettings]);

    const updateSummaries = ({ retry = true }) => {
        supabase.from('get_summaries').select('*').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    updateSummaries({ retry: false });
    
                    return;
                }
            }
            
            const breakingNews = filterBreakingNews(res?.data);
            const dtmNews = filterDtmNews(res?.data);
            const macroNews = filterMacroNews(res?.data);
            
            if (feed?.breakingNews?.[0]?.time_utc !== breakingNews?.[0]?.time_utc) {
                const newItems = findMatchingIndexAndSlice(feed?.breakingNews, breakingNews);
                newItems?.reverse()?.forEach(item => {
                    if (tickers?.includes(item.symbol)) {
                        setWatchlistAlerts(prevState => {
                            setWatchlistAlertsTitle([item, ...prevState], true);
                            
                            return [item, ...prevState];
                        });
                    }

                    if (notificationSettings?.data?.breakingNewsNotifications) {
                        triggerNotification(item);
                    }
                });
                setFeed(prevState => ({
                    ...prevState,
                    breakingNews: breakingNews,
                }));
            }
            
            if (feed?.dtmNews?.[0]?.time_utc !== dtmNews?.[0]?.time_utc) {
                setFeed(prevState => ({
                    ...prevState,
                    dtmNews: dtmNews,
                }));
            }

            if (feed?.macroNews?.[0]?.time_utc !== macroNews?.[0]?.time_utc) {
                setFeed(prevState => ({
                    ...prevState,
                    macroNews: macroNews,
                }))
            }
        })
    }

    useEffect(() => {
        getSummaries({ retry: true });
    }, [watchlistTickers, notificationSettings]);

    useEffect(() => {
        const intervalId = setInterval(() => updateSummaries({ retry: true }), 6000);
        
        return () => {
            clearInterval(intervalId);
        }
    }, [feed, watchlistTickers, notificationSettings]);

    useEffect(() => {
        setWatchlistAlertsTitle(watchlistAlerts);
    }, [lastChecked]);


    return {
        feed,
        handleSetLastChecked,
        newAlerts,
        newsLoading,
        setFeed,
        setNewAlerts,
        watchlistAlerts,
    };
};

export default useFetchNewsFeedAndAlerts;
