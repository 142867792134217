import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { filterFeedDuplicates } from '../../../Controllers/utils';
import NewsFeed from './components/NewsFeed';
import { OpenArrowIcon } from '../../Icons/Icons';
import { StateContext } from '../../../Controllers/context/state';
import { Header } from '../../StyledComponents/UserFormStyledComponents';

const FeedViewer = ({ tickerList }) => {
    const [headings, setHeadings] = useState({
        breakingNews: 'Breaking News US Stocks/ADRs',
        currentNews: 'Current News',
    });
    const [isOpen, setIsOpen] = useState({ breakingNews: true });
    const { pathname } = useLocation();
    const [selectedFeed, setSelectedFeed] = useState('breakingNews');
    const [showCurrentNews, setShowCurrentNews] = useState(false);
    const { symbol } = useParams();
    const [tickers, setTickers] = useState(() => symbol ? [symbol] : tickerList ?? []);

    const {
        feed,
        setChartTicker,
        setChartType,
        setShowChart,
    } = useContext(StateContext);

    useEffect(() => {
        setTickers(() => symbol ? [symbol] : tickerList ?? []);
    }, [symbol, tickerList])

    // useEffect(() => {
    //     if (pathname === '/screener') {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //             ...prevState,
    //             currentNews: 'Screener News',
    //         }));
    //         setIsOpen({ currentNews: true });
    //         setSelectedFeed('currentNews');
    //     } else if (pathname === '/etf') {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //             ...prevState,
    //              currentNews: 'ETF News',
    //          }));
    //          setIsOpen({ currentNews: true });
    //          setSelectedFeed('currentNews');
    //     } else if (pathname === '/macro') {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //             ...prevState,
    //              currentNews: 'Macro News',
    //          }));
    //          setIsOpen({ currentNews: true });
    //          setSelectedFeed('currentNews');
    //     } else if (pathname === '/watchlists') {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //             ...prevState,
    //              currentNews: 'Watchlist News',
    //          }));
    //          setIsOpen({ currentNews: true });
    //          setSelectedFeed('currentNews');
    //     } else if (pathname === '/') {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //             ...prevState,
    //              currentNews: 'Unusual Moves News',
    //          }));
    //          setIsOpen({ currentNews: true });
    //          setSelectedFeed('currentNews');
    //     } else if (pathname === `/ticker/${symbol}`) {
    //         setShowCurrentNews(true);
    //         setHeadings(prevState => ({
    //            ...prevState,
    //             currentNews: `${symbol} News`,
    //         }));
    //         setIsOpen({ currentNews: true });
    //         setSelectedFeed('currentNews');
    //     }
    // }, [pathname]);

    const handleOpenChart = (symbol) => {
        setChartTicker(symbol);
        setShowChart(true);
        setChartType('10m');
    };

    const handleOpenFeed = (e) => {
        const { id } = e.target;
        setIsOpen(prevState => {


            if (!prevState[id]) {
                setSelectedFeed(id);
            } else {
                setSelectedFeed(null);
            }

            return {
                [id]: !prevState[id]
            };
        });
    };

    const filterDtmNews = (news) => {
        if (tickers.length) {
            return news.filter(({ symbol }) => tickers.includes(symbol));
        }

        return news;
    };

    return (
        <div style={{ height: 'calc(100% - 72px)' }}>
            {/* {showCurrentNews &&
                <FeedButton
                    id='currentNews'
                    onClick={handleOpenFeed}
                    open={isOpen.currentNews}
                >
                    {headings.currentNews}
                    <OpenArrowIcon id='currentNews' open={isOpen.currentNews}/>
                </FeedButton>
            } */}
                <Header style={{ margin: 5 }}>
                    <div>
                        Breaking News
                    </div>
                    <div>
                        US Stocks/ADRs
                    </div>
                </Header>
                <br/>
            {/* {selectedFeed === 'currentNews' && showCurrentNews &&
                <NewsFeed feed={filterFeedDuplicates(filterDtmNews(feed.dtmNews))} handleOpenChart={handleOpenChart}/>
            } */}
            {selectedFeed === 'breakingNews' &&
                <NewsFeed feed={filterFeedDuplicates(feed.breakingNews)} handleOpenChart={handleOpenChart}/>
            }
        </div>
    );
}
export default FeedViewer;
