import { useContext, useState } from 'react';
import DropdownMenu from '../../../Global/Components/DropdownMenu';
import supabase from '../../../Controllers/utils/supabaseClient';
import {
    Input,
    SuccessMessage,
    WarningText,
} from '../../../Global/StyledComponents/UserFormStyledComponents'
import { StyledButton, StyledLabel } from '../../../Global/StyledComponents/UserFormStyledComponents';
import { StateContext } from '../../../Controllers/context/state';
import { AddToListIcon } from "../../../Global/Icons/Icons";
import { CardContainer, DisplayInlineHalfLTop } from '../../../Global/StyledComponents/StockCardStyledComponents';
import { AuthContext } from '../../../Controllers/context/auth';

const SavedScreenersPopUp = () => {
    const [emptyName, setEmptyName] = useState(false);
    const [error, setError] = useState(false);
    const [screenerName, setScreenerName] = useState('');
    const [screenerNameExists, setScreenerNameExists] = useState(false);
    const { user } = useContext(AuthContext);
    const {
        fetchAllSavedScreeners,
        filter,
        refreshRate,
        savedScreeners,
        selectedScreener,
        setFilter,
        setRefreshRate,
        setSelectedScreener,
        setShowSaveScreenerPopup,
        setSort,
        sort,
    } = useContext(StateContext);
    const savedScreenerNames = savedScreeners.map((screener) => screener.label);
    const [screener, setScreener] = useState(selectedScreener);
    const [saveSuccess, setSaveSuccess] = useState(false);

    const handleCreateNewSavedScreener = () => {
        supabase.from('user_preferences').insert([{
            data: { filter, sort, refreshRate },
            meta: {
                name: screenerName,
                created_at: new Date(),
            },
            type: 'screener',
            user_id: user.id,
        }]).select().then((res) => {
            if (res?.error) {
                setError(true);
            } else {
                const response = res?.data?.[0];
                setFilter(response?.data?.filter);
                setRefreshRate(response?.data?.refreshRate || null);
                setSort(response?.data?.sort);
                setSelectedScreener(response);
                fetchAllSavedScreeners({ retry: true });
                setError(false);
                setSaveSuccess(true);
                setTimeout(() => {
                    setShowSaveScreenerPopup(false);
                    setScreenerName('');
                    setSaveSuccess(false);
                }, 2000)
            }
        });
    };

    const handleUpdateSavedScreener = () => {
        supabase.from('user_preferences').update([{
            ...screener,
            data: { filter, sort, refreshRate },
        }]).eq('id', screener.id).select().then((res) => {
            if (res?.error) {
                setError(true);
            } else {
                const response = res?.data?.[0];
                setFilter(response?.data?.filter);
                setRefreshRate(response?.data?.refreshRate || null);
                setSort(response?.data?.sort);
                setSelectedScreener(response);
                fetchAllSavedScreeners({ retry: true });
                setError(false);
                setSaveSuccess(true);
                setTimeout(() => {
                    setShowSaveScreenerPopup(false);
                    setScreenerName('');
                    setSaveSuccess(false);
                }, 2000)
            }
        });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'screenerName') {
            setError(false);
            setScreenerNameExists(savedScreenerNames.includes(value));
            setScreenerName(value);
            setScreener(null);
            setEmptyName(false);
        }
    };

    const handleSubmit = () => {
        if (!screenerName.length && !screener) {
            setEmptyName(true);
        } else if (screener) {
            handleUpdateSavedScreener();
        } else {
            handleCreateNewSavedScreener();
        }
    };

    const handleSelectScreener = (e) => {
        const screenerValue = e.target.value;
        if (screenerValue === 'Select a saved screener') {
            setScreener(null);
        } else {
            const parsedScreener = JSON.parse(screenerValue);
            setScreener(parsedScreener);
            setScreenerName('');
        }
    }

    return (
        <CardContainer percentileMove='EPIS' style={{ width: '350px', flexDirection: 'column', height: 'auto' }}>
            {saveSuccess ? 
                <SuccessMessage>
                    Screener has been saved successfully.
                </SuccessMessage> :
                <>
                    <DisplayInlineHalfLTop>
                        Update or Save New Screener &nbsp;<AddToListIcon />
                    </DisplayInlineHalfLTop>
                    <div style={{ margin: '5px', padding: '5px' }}>
                        <DropdownMenu
                            handleChange={handleSelectScreener}
                            id='screeners'
                            label='Update Selected Saved Screener'
                            options={savedScreeners}
                            value={screener}
                        />
                        <center style={{marginTop: 10}}>Or</center>
                        <StyledLabel style={{margin: 0}}>Save As New Screener:</StyledLabel>
                        <Input
                            style={{ marginTop: '5px', width: '100%' }}
                            id='screenerName'
                            onChange={handleChange}
                            placeholder='Enter screener name (required)'
                            type='text'
                            value={screenerName}
                        />
                        {screenerNameExists ?
                            <WarningText style={{ textAlign: 'center' }}>Screener name already exists.</WarningText> :
                            null
                        }
                        {emptyName ?
                            <WarningText style={{ textAlign: 'center' }}>Add screener name.</WarningText> :
                            null
                        }
                        {error ?
                            <WarningText style={{ textAlign: 'center' }}>Create saved screener failed. Please try again.</WarningText> :
                            null
                        }
                        <StyledButton
                            disabled={screenerNameExists}
                            onClick={handleSubmit}
                            title='Create a new saved screener with a unique name.'
                        >
                            {screener ?
                                'Update Screener ' :
                                'Save Screener '
                            }
                            <AddToListIcon />
                        </StyledButton>
                    </div>
                </>
            }
    </CardContainer> 
    )
};

export default SavedScreenersPopUp;

