import React, { useContext } from 'react'
import styled from 'styled-components'
import { WarningText } from '../StyledComponents/UserFormStyledComponents'
import { AppColors } from '../StyledComponents/Graphics'
import SearchDropdown from './SearchDropdown'
import { StateContext } from '../../Controllers/context/state'


const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${AppColors.MRNAVY18};
  color: ${AppColors.MRWHITE};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${AppColors.MRNAVY40};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TickerNotFound = ({ text, handleNavigation }) => {
    const {
        assets
    } = useContext(StateContext)
    
    return (
        <CardContainer>
            <WarningText style={{ marginBottom: '10px' }}>{text}</WarningText>
            <SearchDropdown
                handleSubmit={handleNavigation}
                options={assets}
                position='right'
            />
            
        </CardContainer>
    )
}

export default TickerNotFound