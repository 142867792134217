import { round } from '../../../../../Controllers/utils';
import { EFTInfoTextBoxLeft, EFTInfoTextBoxRight } from '../../../StyledComponents';

const ETFInfo = ({ data }) => {
    let plusSign;

    if (round(data.date_return_oc * 100, 1) > 0) {
        plusSign = '+'
    }

    return (
        <>
            <EFTInfoTextBoxLeft>
                {data.symbol}
            </EFTInfoTextBoxLeft>
            
            <EFTInfoTextBoxRight> 
                {plusSign}
                {round(data.date_return_oc * 100, 1)}% 
            </EFTInfoTextBoxRight>
        </>
    )
};

export default ETFInfo;
