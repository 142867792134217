import { ConfirmationButton, StyledLabel } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { ButtonContainer } from "../../../Global/StyledComponents/StockCardStyledComponents";
import styled from "styled-components";

const Container = styled.div`
    height: 350px;
    margin: 20px 20px 0px 20px;
    position: relative;
    text-align: center;
`;

const NotificationWindow = ({ setShowNotificationWindow }) => {
    const handleConfirm = () => {
        setShowNotificationWindow(false);
    }

    return (
        <Container>
            Please ensure that notifications are allowed for your browser and turned on in your device settings.
            <StyledLabel>
                Chrome Browser Settings:
            </StyledLabel>
            <a
                href='https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop&oco=0'
                target='_blank'
                rel="noopener noreferrer"
            >
                Use Notifications to Get Alerts
            </a>
            <StyledLabel>
                MacOS Desktop Settings:
            </StyledLabel>
            <a
                href='https://support.apple.com/guide/mac-help/change-notifications-settings-mh40583/14.0/mac/14.0'
                target='_blank'
                rel="noopener noreferrer"
            >
                Change Notifications Settings on Mac
            </a>
            <StyledLabel>
                Windows Desktop Settings:
            </StyledLabel>
            <a
                href='https://support.microsoft.com/en-us/windows/change-notification-and-quick-settings-in-windows-ddcbbcd4-0a02-f6e4-fe14-6766d850f294#'
                target='_blank'
                rel="noopener noreferrer"
            >
                Change Notification and Quick Settings in Windows
            </a>
            <ButtonContainer style={{ right: -20 }}>
                <ConfirmationButton onClick={handleConfirm}>
                    Confirm
                </ConfirmationButton>
            </ButtonContainer>
        </Container>
    )
};

export default NotificationWindow;
