import { useEffect, useState } from'react';
import supabase from '../utils/supabaseClient';

const useFetchMacroSummaries = ({ logError }) => {
    const development = ['dev', 'localhost'].some(env => window.location.href.includes(env));
    const [macroSummariesLoading, setMacroSummariesLoading] = useState(true);
    const [macroSummaries, setMacroSummaries] = useState([]);

    const getMacroSummaries = ({ retry = true }) => {
        supabase.from('get_macro_summaries').select('*').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    getMacroSummaries({ retry: false });
    
                    return;
                }
            }

            const broadMacro = res?.data?.filter(summary => summary?.type === 'broad_macro');
            setMacroSummaries(broadMacro);
            setMacroSummariesLoading(false);
        });
    };

    useEffect(() => {
        if (development) {
            getMacroSummaries({ retry: true });
    
            const interval = setInterval(() => getMacroSummaries({ retry: true }), 600000);
    
            return () => {
                clearInterval(interval);
            }
        } else {
            setMacroSummariesLoading(false);
        }
    }, []);

    return {
        macroSummaries,
        macroSummariesLoading,
    };
};

export default useFetchMacroSummaries;
