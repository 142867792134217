import React from 'react';
import { MoveClassRow, MoveClassificationTag, } from '../../../StyledComponents/StockCardStyledComponents';
import { round, ordinalSuffixOf, colorNameFromPercentile, } from '../../../../Controllers/utils';
import { BlankSpacer } from '../../../StyledComponents/AppStyledComponents';
import Tooltip from '../../Tooltip';


const MoveClassifications = ({macro , sector , micro, percentile, type}) => {
    
    percentile = (round(percentile * 100, 2))

    let macro_tagColor = colorNameFromPercentile(macro);
    let sector_tagColor = colorNameFromPercentile(sector);
    let micro_tagColor = colorNameFromPercentile(micro);
    
    return (
        <>
            {type !== 'ETP' &&
                <>
                    <BlankSpacer />
                    <MoveClassRow style={{ height: 'auto'}}>
                        <Tooltip position={'bottom'} text={`${ordinalSuffixOf(macro)} percentile`}>
                            <MoveClassificationTag 
                                tagColor={macro_tagColor}
                            >
                                    MACRO MOVE
                            </MoveClassificationTag>
                        </Tooltip>
                        <Tooltip position={'bottom'} text={`${ordinalSuffixOf(sector)} percentile`}>
                            <MoveClassificationTag 
                                tagColor={sector_tagColor} 
                            >
                                SECTOR MOVE
                            </MoveClassificationTag>
                        </Tooltip>
                        <Tooltip position={'bottom'} text={`${ordinalSuffixOf(micro)} percentile`}>
                            <MoveClassificationTag 
                                tagColor={micro_tagColor} 
                            >
                                MICRO MOVE
                            </MoveClassificationTag>
                        </Tooltip>
                    </MoveClassRow>
                    <BlankSpacer />
                </>
            }
        </>
    );
};

export default MoveClassifications;