import { useEffect } from 'react';

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    script.onload = () => {
      if (window.hbspt) {
        console.log('HubSpot script loaded, creating form...');
        window.hbspt.forms.create({
          region: "na1",
          portalId: "21404769",
          formId: "733dc128-3296-4384-8150-925ee73379dc",
          target: "#hubspot-form"
        });
      } else {
        console.error('HubSpot script did not load correctly.');
      }
    };

    script.onerror = () => {
      console.error('Error loading HubSpot script.');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div id="hubspot-form"></div>
    </div>
  );
};

export default HubSpotForm;
