import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import {
  StyledLabel
} from '../StyledComponents/UserFormStyledComponents';

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const DropdownButton = styled.div`
    width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: ${AppColors.MRNAVY60};
    border-radius: 2px;
    background: ${AppColors.MRNAVY20};
    font-size: 14px;
    line-height: 24px;

    &:focus, &:hover {
        background-color: ${AppColors.MRNAVY40};
        border-color: ${AppColors.MRBLUE};
    }
`;

const DropdownMenu = styled.div`
    background: ${AppColors.MRNAVY20};
    color: ${AppColors.MRLITE90};
    border: 1px solid ${AppColors.MRNAVY60};
    box-sizing: border-box;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 999;
`;

const DropdownItem = styled.div`
    padding: 5px 10px;

    &:hover {
        background: ${AppColors.MRBLUE};
        color: ${AppColors.MRNAVY20};
    }
`;

const RemoveButton = styled.button`
    background: ${AppColors.MRBLUE};
    border: none;
    border-radius: 0;
    cursor: pointer;
    margin: 5px;
    color: ${AppColors.MRNAVY20};
    display: inline-block;
    white-space: pre-wrap;
    font-size: 14px;
    height: 24px;
    padding: 4px 10px;
    overflow: hidden;
    max-width: 95%;
    height: 100%;

    &:hover {
      background-color: ${AppColors.MRNAVY60};
      border: none;
    }
`;

const SearchBox = styled.input`
    width: 100%;
    padding: 11.5px;
    &:focus {
      outline-width: 0;
    }
`;

const MultiSelectDropdown = ({ handleChange, id, label, options, placeholder, value }) => {
    const [selectedOptions, setSelectedOptions] = useState(options.filter((option) => value.includes(option.value)));
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        setSelectedOptions(options.filter((option) => value.includes(option.value)));
    }, [value])

    const handleSelect = (option) => {
      setSelectedOptions(prev => {
        const newState = [...prev, option];
        handleChange(id, newState);

        return newState;
      });
        setSearchText('');
    };

    const handleRemove = (e, removed) => {
        e.stopPropagation();
        setSelectedOptions(prev => {
          const newState = prev.filter(option => option.value !== removed.value);
          handleChange(id, newState);

          return newState;
        });
    };

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleSearch = event => {
        setSearchText(event.target.value);
    };

    const filteredOptions = options.filter(option => {
        return option?.label?.toLowerCase().includes(searchText?.toLowerCase()) &&
            !selectedOptions.some(selected => selected.value === option.value);
    });

    return (
        <DropdownWrapper ref={wrapperRef}>
            {label ?
                <StyledLabel>
                    {label}:
                </StyledLabel> :
                null
            }
            <DropdownButton onClick={toggleDropdown}>
                {selectedOptions.map(option => (
                    <RemoveButton onClick={(e) => handleRemove(e, option)}>
                            {option.label} X
                    </RemoveButton>
                ))}
                <SearchBox
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder={placeholder || 'Search...'}
                />
            </DropdownButton>
            {dropdownOpen && (
                <DropdownMenu>
                    {filteredOptions.sort((a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }).map(option => (
                        <DropdownItem key={option.value} onClick={() => handleSelect(option)}>
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            )}
        </DropdownWrapper>
    );
};

export default MultiSelectDropdown;
