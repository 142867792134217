import React, { useContext, useEffect, useState, useRef } from 'react';

import { ETFCardContainer } from '../../StyledComponents';
import ETFFrontView from './components/ETFFrontView';
import Loading from '../../../../Global/Components/LoadingSpinner';
import { round } from '../../../../Controllers/utils';
import { StateContext } from '../../../../Controllers/context/state';
import supabase from '../../../../Controllers/utils/supabaseClient';
import useOnScreen from '../../../../Controllers/hooks/onScreenHook';
import StockCard from '../../../../Global/Components/StockCard/StockCard';
import styled from 'styled-components';

const StockCardWrapper = styled.div`
    position: absolute;
    top: ${({ top }) => top ? '-165px' : '-10px'};
    left: ${({ position }) => !position ? '-10px' : null};
    right: ${({ position }) => position === 'left' ? 
        '10px' : null};
    width: 322.5px;
    height: 215px;
    z-index: 1000;
`;

const ETFCard = ({
    etfGridRef,
    id,
    name,
    position,
    requestedTicker,
}) => {
    const [loading, setLoading] = useState(true);
    const [percentileMove, setpercentileMove] = useState('EPIS');
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const {
        cardData,
        logError,
        setCardData,
        setExpandedViewTicker,
        setShowErrorWindow,
        setShowExpandedView,
        setShowFeed,
        setVisibleCards,
        sort,
    } = useContext(StateContext);
    const [hover, setHover] = useState(false);
    const stockCardRef = useRef(null);
    const hoverTimeoutRef = useRef(null);

    const fetchData = async ({ retry = true }) => {
        const res = await supabase.rpc('card_data', {
            _symbol: requestedTicker,
            _corr_symbol: sort.correlationAsset || 'SPY', 
        });

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                fetchData({ retry: false });

                return;
            }

            if (res?.error?.message !== 'FetchError: Failed to fetch') {
                setShowErrorWindow(true);
            }

            return;
        }

        if (res?.data?.length) {
            const data = res?.data[0];
            
            setCardData(prevState => ({
                ...prevState,
                [requestedTicker]: data
            }));
            setLoading(false);

            const percentile = (round(data?.date_return_oc_percentile * 100, 2));

            if (percentile >= 97) {
                setpercentileMove('ALPH');
            } else if (percentile >= 93) {
                setpercentileMove('BETA');
            } else if (percentile >= 84) {
                setpercentileMove('GAMM');
            } else if (percentile >= 68) {
                setpercentileMove('DELT');
            } else if (percentile >= 34) {
                setpercentileMove('EPIS');
            } else if (percentile >= 16) {
                setpercentileMove('IOTA');
            } else if (percentile >= 7) {
                setpercentileMove('KAPP');
            } else if (percentile >= 3) {
                setpercentileMove('LAMB');
            } else if (percentile >= 0) {
                setpercentileMove('ZETA');
            }
        }
    };

    useEffect(() => {
        if (isVisible) {
            fetchData({ retry: true });
        }
    }, [isVisible]);

    useEffect(() => {
        let interval;
        if (isVisible) {
            interval = setInterval(() => fetchData({ retry: true }), 60000);
            setVisibleCards(prevState => ({
                ...prevState,
                [requestedTicker]: requestedTicker,
            }));
        } else {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        }

        return () => {
            clearInterval(interval);
            setVisibleCards(prevState => {
                const visibleCards = prevState;
                delete visibleCards[requestedTicker];

                return visibleCards;
            });
        };
    }, [isVisible])

    const handleExpand = () => {
        setExpandedViewTicker(requestedTicker);
        setShowFeed(false);
        setShowExpandedView(true);
    };

    return (
        <div 
            style={{ position: 'relative' }}
            onMouseEnter={() => {
                hoverTimeoutRef.current = setTimeout(() => {
                    setHover(true);
                }, 500);
            }}
            onMouseLeave={() => {
                clearTimeout(hoverTimeoutRef.current);
                setHover(false);
            }}
        >
            {hover &&

                <StockCardWrapper
                    position={position}
                    top={stockCardRef?.current?.getBoundingClientRect()?.bottom + 225 > etfGridRef?.current?.getBoundingClientRect()?.bottom}
                    ref={stockCardRef}
                >
                    <StockCard
                        key={requestedTicker}
                        requestedTicker={requestedTicker}
                    />
                </StockCardWrapper>
            }
            <ETFCardContainer
                percentileMove={percentileMove}
                ref={ref}
            >
                {loading ?
                    <Loading dimensions={{ width: '30px', height: '30px' }} /> :
                    <ETFFrontView
                        data={cardData[requestedTicker]}
                        handleExpand={handleExpand}
                        id={id}
                        name={name}
                        percentileMove={percentileMove}
                    />
                }
            </ETFCardContainer>
        </div>
    );
};

export default ETFCard;
