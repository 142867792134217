const insertInOrder = (array, value) => {
    if (array.includes(value)) {
        return array;
    }

    const index = array.findIndex(element => element > value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 0, value);
    }

    return array;
};

export default insertInOrder;
