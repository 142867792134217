import React, { useContext, useEffect, useState } from 'react';
import { detectUserTimeZone } from '../../Controllers/utils';
import { Header } from '../../Global/StyledComponents/UserFormStyledComponents';
import { isMobile, isTablet } from 'react-device-detect';
import { StateContext } from '../../Controllers/context/state';
import { ViewModule } from '../../Global/StyledComponents/AppStyledComponents';
import CalendarEvent from './components/CalendarEvent';
import CalendarFilter from './components/CalendarFilter';
import CalendarTableView from '../../Global/Views/CalendarTableView/CalendarTableView';
import Drawer from '../../Global/Components/Drawer';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';
import styled from 'styled-components';
import supabase from '../../Controllers/utils/supabaseClient';
import formatTimeToTimezone from '../../Controllers/utils/formatTimeToTimezone';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const CalendarMonitor = () => {

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const sameDayFilter = {
        marketReaderHot: "(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)",
        marketCap: "mcap_mm>500",
        assetType: "type='Common Stock' OR type='ADR'",
        sameDay: "market_date = (SELECT CASE WHEN date_trunc('day', NOW()) = date_trunc('day',market_open) THEN date ELSE date_trunc('day',market_close_prev) END as ref_date FROM market_calendar_ref mcr where market = 'NYSE' and NOW() BETWEEN market_close_prev and market_close)",
        highImpact: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.135 ELSE false END", 
        summary: "dtm_summary NOTNULL"
    };
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedImportance, setSelectedImportance] = useState(0);
    const [tickerList, setTickerList] = useState([]);
    const {
        customerProfile,
        development,
        logError, 
        selectedCalendarEvent, 
        setSelectedCalendarEvent,
        setShowErrorWindow,
        setShowExportPopUp,  
        setVisibleCards, 
        setVisibleEvents,
        showExpandedView,
        sort
    } = useContext(StateContext);

    const handleCategoryChange = (e) => {
        if (e.target.value === 'Category') {
            setSelectedCategory(null);
            return;

        }
        setSelectedCategory(e.target.value);
    };

    const handleTimezoneChange = (e) => {
        setSelectedTimeZone(e.target.value);
    };

    const handleCountryChange = (id, selections) => {
        setSelectedCountries(selections);

        const filtered = calendarEvents.filter(event => {
            if (selections.length === 0) return true;
            return selections.some(country => country.value === event.country);
        });

        setFilteredEvents(filtered);
    };

    const handleExportData = () => {
        setVisibleCards({});
        setVisibleEvents(filteredEvents);
        setShowExportPopUp(true);
    };

    const handleImportanceChange = (e) => {
        setSelectedImportance(e.target.value);
    };

    const fetchTickerList = ({ retry = true }) => {
        supabase.rpc('getfilteredsortedlist', { request: {
            filter: sameDayFilter,
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchTickerList({ retry: false });

                    return;
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setTickerList(entities);
            setLoading(false);
        });
    };

    useEffect(() => {
        const fetchData = async ({ retry = true }) => {
            const { data, error } = await supabase.from('calendar_events').select('*');
            if (error) {
                if (retry) {
                    fetchData({ retry: false });
                    return;
                }
                logError(error.message, undefined, error);
                return;
            }
            if (!data) return;
            data.sort((a, b) => new Date(a.time_utc) - new Date(b.time_utc));
            setCalendarEvents(data);

            if (isLoading) {
                const uniqueCategories = [...new Set(data.map(event => event.category).filter(Boolean))].sort();
                const formattedCategories = {
                    label: null,
                    values: [
                        { label: 'Category', value: null },
                        ...uniqueCategories.map(category => ({
                            label: category,
                            value: category
                        }))
                    ]
                };
                const uniqueCountries = [...new Set(data.map(event => event.country).filter(Boolean))].sort();
                setCountries(uniqueCountries);
                setCategories(formattedCategories);
                setSelectedCalendarEvent(data[0]);
            }
            
            setLoading(false);
        };

        setSelectedTimeZone(detectUserTimeZone());
        fetchData({ retry: true });

        const intervalId = setInterval(() => {
            fetchData({ retry: true });
        }, 10 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (!calendarEvents.length) return;
        
        let filtered = [...calendarEvents];

        if (selectedTimeZone) {
            filtered = filtered.map(event => {
                const date = new Date(event.time_utc);
                const [sign, hours, minutes] = selectedTimeZone.match(/([+-])(\d{2}):(\d{2})/).slice(1);
                const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
                const originalTime = new Date(event.time_utc);
                date.setMinutes(date.getMinutes() + offsetInMinutes);
                return {
                    ...event,
                    time_utc: date.toISOString(),
                    originalTime 
                };
            });
        }

        if (selectedCategory) {
            filtered = filtered.filter(event => event.category === selectedCategory);
        }

        if (selectedImportance) {
            const filteredImportance = filtered.filter(event => Number(event.importance) === Number(selectedImportance));
            
            if (Number(selectedImportance) !== 0) {
                filtered = filteredImportance;
            }
        }

        if (selectedCountries.length > 0) {
            filtered = filtered.filter(event =>
                selectedCountries.some(country => country.value === event.country)
            );
        }
        
        setFilteredEvents(filtered);
        if (!showExpandedView && filtered.length > 0) {
            setSelectedCalendarEvent(filtered[0]);
        }
    }, [selectedTimeZone, selectedCategory, selectedCountries, selectedImportance, calendarEvents]);
    
    useEffect(() => {
        if (!isLoading) {
            fetchTickerList({ retry: true });
        }
    }, [isLoading, sort]);

    if (isLoading) {
        return (
            <ViewModule>
                <Loading />
            </ViewModule>
        );
    }

    return (
        <>
            <ViewModule style={{height: 'calc(100% - 40px)'}}>
                {(!isMobile || isTablet) && customerProfile?.accessLevels?.includes('pro') &&
                    <div style={{ position: 'absolute', top: 20, right: 65 }}>
                        <ExportToCsv handleExportData={handleExportData} />
                    </div>
                }
                <Header style={{ margin: 5 }}>
                    <div>
                        Calendar Events
                    </div>
                </Header>
                <CalendarFilter
                    categories={categories}
                    countries={countries.map(country => ({ value: country, label: country }))}
                    handleCategoryChange={handleCategoryChange}
                    handleCountryChange={handleCountryChange}
                    handleImportanceChange={handleImportanceChange}
                    handleTimeZoneChange={handleTimezoneChange}
                    selectedCategory={selectedCategory}
                    selectedCountries={selectedCountries}
                    selectedImportance={selectedImportance}
                    selectedTimeZone={selectedTimeZone}
                />
                <div style={{ display: 'flex', height: 'calc(100% - 30px)' }}>
                    <Wrapper>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: 'calc(100% - 20px)' }}>
                            <StockCardGridComponent height={`calc(100% - 25px)`} >
                                <CalendarTableView calendarEvents={filteredEvents} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedTimeZone={selectedTimeZone} />
                            </StockCardGridComponent>
                        </div>
                    </Wrapper>
                </div>
            </ViewModule>
            <Drawer open={showExpandedView} rotate={'true'} >
                <CalendarEvent title={`${selectedCalendarEvent?.event}`} showActive={true} selectedCalendarEvent={selectedCalendarEvent} />
                <FeedViewer tickerList={tickerList} title="News Feed" />
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    );
};

export default CalendarMonitor;