import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import {
    StyledLabel,
    StyledLabelInline,
    StyledSelect,
} from '../StyledComponents/UserFormStyledComponents';

const DropdownMenu = ({
    handleChange,
    filterKey,
    id,
    inline,
    label,
    options,
    value
}) => {
    const setValue = typeof value !== 'string' ? JSON.stringify(value) : value;

    return (
        <div style={{ display: inline && (!isMobile || isTablet) ? 'flex' : null,  width: '100%' }}>
            {label ? (
                inline ?
                    <StyledLabelInline>
                        {label}:
                    </StyledLabelInline> :
                    <StyledLabel>
                        {label}:
                    </StyledLabel>
                ) :
                null
            }
            <StyledSelect id={id} onChange={handleChange} data-key={filterKey} style={{ height: inline ? '24px':'44px', padding: inline && 0}} value={setValue} >
                {options?.map((option) => <option key={option.label} value={option.value}>{option.label}</option>)}
            </StyledSelect>
        </div>
    )
};

export default DropdownMenu;
