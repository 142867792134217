
import { isMobile, isTablet } from 'react-device-detect';

import { FooterModuleContainer } from "../StyledComponents/AppStyledComponents";
import { ClockIcon } from '../Icons/Icons';
import { AppColors } from '../StyledComponents/Graphics';

const Footer = () => {
    return (
        <FooterModuleContainer>
            <div style={{ display: 'flex', lineHeight: '17px' }}>
                <div style={{ display: 'flex' }}>
                        ©{new Date().getFullYear()} MarketReader, Inc.
                    <div className="h-4 mx-3 block border-color border-r border-white">

                    </div>
                    &nbsp;&nbsp;<a target="_blank" href="https://app.marketreader.com/MarketReader+TOS+-+Mar312023.pdf">Terms of Service</a>
                </div>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <div style={{ color: AppColors.MRWHITE, fontWeight: 'bold' }}>
                    <ClockIcon style={{ marginRight: 5 }} />
                        {(!isMobile || isTablet) ?
                            'Quantitative Equity Market Data from NASDAQ — Delayed 15 Minutes' :
                            'Del-15'
                        }
                </div>
                {(!isMobile || isTablet) ?
                    <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;Content on this platform is generated and summarized by automated systems and is not intended to be used as investment nor financial advice.
                    </> :
                    null
                }
            </div>
        </FooterModuleContainer>
    );
}
export default Footer;