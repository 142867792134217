import { useEffect, useState, useContext, useRef } from 'react';
import SearchDropdown from '../SearchDropdown';
import { StateContext } from '../../../Controllers/context/state';
import supabase from '../../../Controllers/utils/supabaseClient';
import StockChartToggle from './components/StockChartToggle/StockChartToggle';
import { PercentileMoves, InfoScore } from './constants';
import { Container, Items, TickerInfo, StockChartContainer } from './styled-components';
import Loading from '../LoadingSpinner';
import { fetchBars, fetchMarkers, fetchLiveOhlcvDailyData } from './components/StockChart/support';
import StockChart from './components/StockChart/StockChart';
import StockChartCarousel from './components/StockChartCarousel/StockChartCarousel';
import DropdownMenu from '../DropdownMenu';
import { capitalizeFirstLetter, getCurrentDate } from '../../../Controllers/utils';
import { Input } from '../../StyledComponents/UserFormStyledComponents';



const StockChartWrapper = ({ assetPage, assets, dateDisplay, handleDateChange, handleNavigation, isDelisted, lastDate, setLastDate, selectedDate, symbol, width }) => {
    const [carouselPlotLine, setCarouselPlotline] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [chartMinMax, setChartMinMax] = useState({ min: 0, max: 0 });
    const { chartType, setChartType, setChartTimeStamp, development, sort } = useContext(StateContext);
    const [clickedPoint, setClickedPoint] = useState(null);
    const [hoveredPoint, setHoverPoint] = useState(null);
    const [loading, setLoading] = useState(true);
    const [markerData, setMarkerData] = useState(null);
    const [selectedInfoScore, setSelectedInfoScore] = useState(InfoScore.values[chartType === 'D' ? 2 : 5].value);
    const [selectedPercentile, setSelectedPercentile] = useState(PercentileMoves.values[chartType === 'D' ? 3 : 4].value);
    const stockChartWrapperRef = useRef(null);
    const [tickerData, setTickerData] = useState({});
    const [dataFetched, setDataFetched] = useState(false);

    const handleChangePercentile = (e) => {
        setSelectedPercentile(e.target.value);
    };


    const formatSectorAndType = (gsector, type) => {
        const formattedGsector = capitalizeFirstLetter(gsector);

        let formattedType = type;
        if (type !== "ETF" && type !== "ETP") {
            formattedType = capitalizeFirstLetter(type);
        }

        if (formattedGsector && formattedType) {
            return `${formattedGsector}, ${formattedType}`;
        } else {
            return formattedGsector || formattedType || '';
        }
    }
    const handleChangeInfoScore = (e) => {
        setSelectedInfoScore(e.target.value);
    };

    // const lowerSelectedValues = () => {
    // 	const newImpactIndex = ImpactScore.values.findIndex((item) => item.value === selectedImpactScore) + 1;
    // 	const newSelectedPercentileIndex = PercentileMoves.values.findIndex((item) => item.value === selectedPercentile) + 1;

    //     if (newImpactIndex < ImpactScore.values.length) {
    // 		setSelectedImpactScore(ImpactScore.values[newImpactIndex].value);
    //         return;
    // 	}        
    //     if (newSelectedPercentileIndex < PercentileMoves.values.length) {
    // 		setSelectedPercentile(PercentileMoves.values[newSelectedPercentileIndex].value);
    // 	}
    // };

    const fetchLiveData = async () => {
        if (isDelisted) return;
        const res = await supabase.rpc('card_data', {
            _symbol: symbol,
            _corr_symbol: sort?.correlationAsset || 'SPY',
        });
        const { open, high, low, close, volume, time_utc, date_return_oc_percentile, return_oc_percentile } = res?.data?.[0];


        return {
            open,
            high,
            low,
            close,
            volume,
            time_utc: Math.floor(new Date(time_utc)?.getTime()),
            symbol,
            performance_stats: {
                date_return_oc_percentile,
                return_oc_percentile
            }
        };
    }

    const fetchTickerInfo = async () => {
        const res = await supabase.from('asset_symbols').select('*').eq('symbol', symbol);
        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error?.message, undefined, error);

            return;
        }
        if (res?.data?.length) {
            const { name, type, gsector, gsubind } = res.data[0];
            setTickerData({ name, type, gsector, gsubind });
        }
    };

    const fetchDailyData = async (endDate = null) => {
        setChartData(null);
        setLoading(true);
        const countBack = 280;
        endDate = null
        const endDateToUse = endDate
            ? Math.floor(endDate)
            : Math.floor(Date.now() / 1000);

        const dailyData = await fetchBars({
            count: countBack,
            endDate: endDateToUse,
            endPoint: 'ohlcv_1day_v2',
            symbol: symbol,
        });

        if (isDelisted) {
            setChartData({ data: dailyData, navigator: 'D' });
            setLoading(false);
            return;
        }

        const liveData = await fetchLiveData();
        const lastDailyDate = new Date(dailyData[dailyData?.length - 1]?.time_utc);
        const lastLiveDate = new Date(liveData?.time_utc);
        let data = [];
        if (lastDailyDate?.getDate() !== lastLiveDate?.getDate()) {
            data = [...dailyData, liveData];
        } else {
            dailyData[dailyData?.length - 1] = liveData;
            data = dailyData;
        }
        setChartData({ data, navigator: 'D' });
        setLoading(false);
    };



    const fetchIntraData = async (endDate = null) => {
        setChartData(null);
        setLoading(true);
        const countBack = 300;

        let endDateToUse;
        if (endDate) {
            const currentDate = new Date();
            const endDateObj = new Date(endDate * 1000);

            if (endDateObj?.toDateString() !== currentDate?.toDateString()) {
                endDateObj?.setUTCDate(endDateObj?.getUTCDate() + 1);
                endDateObj?.setUTCHours(0, 0, 0, 0);
                endDateToUse = Math.floor(endDateObj?.getTime() / 1000);
            } else {
                endDateToUse = Math.floor(endDate);
            }
        } else {
            endDateToUse = Math.floor(new Date().getTime() / 1000);
        }

        const intraData = await fetchBars({
            count: countBack,
            endDate: endDateToUse,
            endPoint: 'ohlcv_10min_v2',
            symbol: symbol,
        });
        setChartData({ data: intraData, navigator: '10m' });
        setLoading(false);
        return intraData;
    };

    const fetchDailyMarkers = async (getLastMarker = false) => {
        setMarkerData(null);
        const dateThreeMonthsAgo = new Date();
        dateThreeMonthsAgo?.setMonth(dateThreeMonthsAgo?.getMonth() - 11);
        const epochThreeMonthsAgo = Math.floor(dateThreeMonthsAgo?.getTime() / 1000);
        const flagData = await fetchMarkers({
            aggregate: 'dtm',
            endPoint: 'get_marker_timestamps',
            epoch: epochThreeMonthsAgo,
            retry: true,
            return_percentile_floor: selectedPercentile,
            min_information_level: selectedInfoScore,
            symbol: symbol,
        });


        const liveData = await fetchLiveData();
        const liveMarker = {
            ts: liveData?.time_utc,
            symbol: liveData?.symbol,
            aggr: 'dtm',
            performance_stats: liveData?.performance_stats
        }

        const currentDate = new Date(liveData?.time_utc);
        const lastMarkertTimestamp = flagData[flagData?.length - 1]?.ts;
        const lastMarkertDate = new Date(lastMarkertTimestamp);
        const diffInDays = Math.ceil((currentDate - lastMarkertDate) / (1000 * 60 * 60 * 24));

        if (diffInDays < 2) {
            flagData[flagData?.length - 1] = liveMarker;
        } else if (lastMarkertDate < currentDate && !isDelisted) {
            flagData.push(liveMarker);
        }
        setMarkerData(flagData);
    }

    const fetchIntraMarkers = async (epoch = null) => {
        setMarkerData(null);
        let epochToUse;

        if (epoch === null) {
            const currentDate = new Date();
            currentDate?.setDate(currentDate?.getDate() - 7);
            epochToUse = Math.floor(currentDate?.getTime() / 1000);
        } else {

            const epochDate = new Date(epoch * 1000);
            epochDate?.setDate(epochDate?.getDate() - 7);
            epochToUse = Math.floor(epochDate?.getTime() / 1000);
        }

        const flagData = await fetchMarkers({
            aggregate: '10min',
            endPoint: 'get_marker_timestamps',
            epoch: epochToUse,
            retry: true,
            return_percentile_floor: selectedPercentile,
            min_information_level: selectedInfoScore,
            symbol: symbol,
        });
        setMarkerData(flagData);
        return flagData;
    }

    const loadMoreData = async (min) => {
        const countBack = 60;
        const dailyData = await fetchBars({
            count: countBack,
            endDate: min / 1000,
            endPoint: 'ohlcv_1day_v2',
            symbol: symbol,
        });

        return dailyData;
    }

    const fetchData = async () => {
        await fetchTickerInfo();
        if (chartType === 'D') {
            await fetchLiveData();
            await fetchDailyData();
        } else {
            await fetchIntraData();
        }
    };

    const fetchMarkersData = async () => {
        if (chartType === 'D') {
            await fetchDailyMarkers();
        } else {
            await fetchIntraMarkers();
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            if (isDelisted) {
                await fetchTickerInfo();
                await fetchDailyMarkers(true);
            }

            if (isDelisted && lastDate) {
                if (chartType === '10m') {
                    await fetchIntraData(lastDate);
                    await fetchIntraMarkers(lastDate);
                } else if (chartType === 'D') {
                    await fetchDailyData();
                    await fetchDailyMarkers();
                }
            }
        };

        fetchData();
    }, [isDelisted, lastDate, chartType, symbol]);
    useEffect(() => {
        const fetchDataAndSetFlag = async () => {
            await fetchData();
            setDataFetched(true);
        };

        setDataFetched(false);
        fetchDataAndSetFlag();
    }, [symbol, chartType]);

    useEffect(() => {
        if (!dataFetched) return;

        const fetchDataFor10m = async () => {
            if (chartType === 'D') return;
            await fetchIntraData(selectedDate);
            await fetchIntraMarkers(selectedDate);
        };

        if (selectedDate) {
            fetchDataFor10m();
        }



    }, [selectedDate, chartType, symbol, lastDate, dataFetched]);

    useEffect(() => {
        if (isDelisted) return;

        if (chartData) {
            fetchMarkersData();
        }
    }, [chartData, chartType, isDelisted]);

    useEffect(() => {
        if (isDelisted || !assetPage) return;
        const isCurrentDay = new Date(selectedDate * 1000).toDateString() === new Date().toDateString();

        if (!isCurrentDay) return;

        let intervalId;
        if (chartType === '10m') {

            intervalId = setInterval(fetchData, 600000);

        }
        if (chartType === 'D') {
            intervalId = setInterval(async () => {
                const liveData = await fetchLiveData();

                setChartData(prevState => ({
                    ...prevState,
                    data: [
                        ...prevState.data.slice(0, -1),
                        liveData
                    ]
                }));
            }, 10 * 60 * 1000);

        }
        if (intervalId) {
            return () => clearInterval(intervalId);
        }
    }, [chartType, isDelisted, symbol]);

    useEffect(() => {
        if (markerData) {
            fetchMarkersData();
        }

    }, [selectedPercentile, selectedInfoScore]);

    useEffect(() => {
        setSelectedInfoScore(InfoScore?.values[chartType === 'D' ? 2 : 5]?.value);
        setSelectedPercentile(PercentileMoves?.values[chartType === 'D' ? 3 : 4]?.value);

    }, [chartType]);




    // useEffect(() => {
    //     if (development) {
    //         setSelectedImpactScore(ImpactScore.values[4].value);
    //         setSelectedPercentile(PercentileMoves.values[4].value);
    //     }
    //     else{
    //         if (markerData &&
    //             (chartMinMax.min !== 0 && chartMinMax.min !== undefined) &&
    //             (chartMinMax.max !== 0 && chartMinMax.max !== undefined)
    //         ) {
    //             const filteredData = markerData.filtered.filter((card) => card.ts >= chartMinMax.min && card.ts <= chartMinMax.max);

    //             if (filteredData.length > 0){
    //                 return;
    //             }
    //             lowerSelectedValues();
    //         }
    //     }
    // }, [chartMinMax, markerData]);

    // const filterData = (data) => {
    //     const percentile = JSON.parse(selectedPercentile);

    //     const filterMin = percentile[0] / 100;
    //     const filterMax = percentile[1] / 100;

    //     if(development){
    //         const filteredData = data.filter((item) => (item.performance_stats.date_return_oc_percentile <= filterMin ||
    //             item.performance_stats.date_return_oc_percentile >= filterMax)
    //         );  

    //         return filteredData;
    //     }

    //     const filteredData = data.filter((item) => (
    //         item.performance_stats.date_return_oc_percentile <= filterMin || 
    //         item.performance_stats.date_return_oc_percentile >= filterMax
    //     ));   

    //     return filteredData;
    // }



    return (
        <StockChartContainer ref={stockChartWrapperRef} width={width}>
            {
                loading ?
                    <Loading /> :
                    <>
                        <Container>
                            <TickerInfo>
                                <h1>{tickerData.name}</h1>
                                <h2>
                                    {formatSectorAndType(tickerData?.gsector, tickerData?.type)}
                                </h2>
                            </TickerInfo>
                            <Items style={{ flexWrap: 'wrap', gap: '10px' }}>
                                <StockChartToggle chartType={chartType} setChartType={setChartType} />
                                {assetPage && (
                                    <div style={{ width: '200px' }}>
                                        <SearchDropdown
                                            handleSubmit={handleNavigation}
                                            options={assets}
                                            position='right'
                                        />
                                    </div>
                                )}
                                <div style={{ width: '135px' }}>
                                    <DropdownMenu
                                        label={PercentileMoves?.label}
                                        handleChange={handleChangePercentile}
                                        options={PercentileMoves?.values}
                                        value={selectedPercentile}
                                    />
                                </div>
                                <div style={{ width: '135px' }}>
                                    <DropdownMenu
                                        label={InfoScore?.label}
                                        handleChange={handleChangeInfoScore}
                                        options={InfoScore?.values}
                                        value={selectedInfoScore}
                                    />
                                </div>
                                <div style={{ width: '135px' }}>
                                    <Input
                                        min={new Date("15 February, 2023").toISOString().split("T")[0]}
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={handleDateChange}
                                        style={{ height: '44px' }}
                                        type="date"
                                        value={dateDisplay}
                                    />
                                </div>
                            </Items>
                        </Container>
                        <>
                            <StockChart
                                carouselPlotLine={carouselPlotLine}
                                chartData={chartData}
                                height={stockChartWrapperRef?.current?.offsetHeight - 20}
                                hoverPoint={hoveredPoint}
                                loadMoreData={loadMoreData}
                                markerData={markerData}
                                selectedDate={selectedDate}
                                setChartMinMax={setChartMinMax}
                                setClickedPoint={setClickedPoint}
                                setHoverPoint={setHoverPoint}
                                symbol={symbol.toUpperCase()}
                                width={width}
                                isDelisted={isDelisted}
                                lastDate={lastDate}
                            />

                            {markerData &&
                                <StockChartCarousel
                                    chartMinMax={chartMinMax}
                                    clickedPoint={clickedPoint}
                                    hoveredPoint={hoveredPoint}
                                    markerData={markerData}
                                    setCarouselPlotline={setCarouselPlotline}
                                    setHoverPoint={setHoverPoint}
                                />
                            }
                        </>
                    </>
            }
        </StockChartContainer>
    );
};

export default StockChartWrapper;
