import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { StateContext } from '../../Controllers/context/state';
import supabase from '../../Controllers/utils/supabaseClient';
import { AuthContext } from '../../Controllers/context/auth';

const SliderWrapper = styled.div`
    width: 400px;
    margin-top: 10px;
`;

const SliderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
`;

const SliderLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
    color: white;
`;

const SliderContainer = styled.div`
    position: relative;
    width: 65%;
`;

const SliderMarks = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: repeating-linear-gradient(
        90deg,
        ${AppColors.MRBLUE} 0,
        ${AppColors.MRBLUE} 1px,
        transparent 1px,
        transparent 5%  // Adjust for distance between lines
    );
    transform: translateY(-50%);
    pointer-events: none;
`;

const SliderInput = styled.input`
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    accent-color: ${AppColors.MRBLUE};

    &:focus {
        outline: none;
    }
`;

const DimensionSlider = () => {
    const { user } = useContext(AuthContext);
    const {
        cardDimensions,
        logError,
        setCardDimensions,
        setSliderValue,
        sliderValue,
    } = useContext(StateContext);

    const dimensionMap = [
        { dimensionMultiplier: 1, state: -4, index: 0 },
        { dimensionMultiplier: 1, state: -3, index: 1 },
        { dimensionMultiplier: 1, state: -2, index: 2 },
        { dimensionMultiplier: 1, state: -1, index: 3 },
        { dimensionMultiplier: 1, state: 0, index: 4 },
        { dimensionMultiplier: 1.85, state: 1, index: 5 },
    ];

    const handleChange = (e) => {
        const { value } = e?.target;
        setSliderValue(value);
        setCardDimensions(preveState => ({
            ...preveState,
            data: dimensionMap[value]
        }));
    };

    const handleSubmit = ({ retry = true }) => {
        supabase.from('user_preferences').upsert({
            ...cardDimensions,
            data: cardDimensions?.data,
            type: 'cardSize',
            user_id: user?.id,
        }).eq('type', 'cardSize').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    handleSubmit({ retry: false });
    
                    return;
                }
    
                return;
            }
        });
    };

    return (
        <SliderWrapper>
            <SliderRow>
                <SliderContainer>
                    <SliderInput
                        type="range"
                        min="0"
                        max={dimensionMap.length - 1}
                        step="1"
                        value={sliderValue}
                        onChange={handleChange}
                        onMouseUp={() => handleSubmit({ retry: true })}
                    />
                    <SliderMarks />
                </SliderContainer>
                <SliderLabel>
                    Card Resize +/-
                </SliderLabel>
            </SliderRow>
        </SliderWrapper>
    );
};

export default DimensionSlider;
