const setSortTitle = (correlationAsset, key) => {

    const SortDisplay = {
        beta: {
            label: `Beta to ${correlationAsset}`,
        },
        close: {
            label: 'Price',
        },
        correlation: {
            label: `${correlationAsset} Correlation`,
        },
        date_return_oc_voladj:{
            label: 'Vol Adj Return',
        },
        date_return_oc: {
            label: 'Daily Return',
        },
        'date_return_oc*mcap_mm': {
            label: 'Market Cap ∆',
        },
        date_volume: {
            label: 'Volume',
        },
        date_volume_dollar: {
            label: 'Dollar Volume',
        },
    };

    return (
        <>
            {SortDisplay[key].label}
        </>
    );
};

export default setSortTitle;
