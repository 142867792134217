import { CancelButton, ConfirmationButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { ButtonContainer } from "../../../Global/StyledComponents/StockCardStyledComponents";

const ConfirmationWindow = ({handleCancel, handleConfirm, headline, subheading}) => {

    return (
        <div style={{ height: '150px', margin: '20px 20px 0px 20px', position: 'relative', textAlign: 'center' }}>
            {headline &&
                <div>
                    {headline}
                </div>
            }
            {subheading &&
                <div>
                    {subheading}
                </div>
            }
            <ButtonContainer style={{ right: -20 }}>
                <CancelButton onClick={handleCancel}>
                    Cancel
                </CancelButton>
                <ConfirmationButton onClick={handleConfirm}>
                    Confirm
                </ConfirmationButton>
            </ButtonContainer>
        </div>
    )
};

export default ConfirmationWindow;
