import { useState, useEffect, useCallback } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import supabase from '../utils/supabaseClient';

const useFetchCardSize = ({ logError }) => {
    const [cardDimensions, setCardDimensions] = useState({ data: { dimensionMultiplier: 1, state: 0, index: 4 } });
    const [cardSizeLoading, setCardSizeLoading] = useState(true);
    const [sliderValue, setSliderValue] = useState(4);

    const fetchCardSize = useCallback(async ({ retry = true }) => {     
        const res = await supabase.from('user_preferences').select('*').eq('type', 'cardSize');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchCardSize({ retry: false });
            }
        }

        if (res?.data?.length) {
            setCardDimensions((!isMobile || isTablet) ? res?.data[0] : { data: { dimensionMultiplier: 1, state: 0, index: 4 } });
            setSliderValue(res?.data[0]?.data.index);
        }
        setCardSizeLoading(false);

    }, []);

    useEffect(() => {
        fetchCardSize({ retry: true });
    }, []);

    return {
        cardDimensions,
        cardSizeLoading,
        setCardDimensions,
        setSliderValue,
        sliderValue,
    };
};

export default useFetchCardSize;
