import React, { useContext } from 'react';
import { AllColumns, ViewModule } from "../../Global/StyledComponents/AppStyledComponents";
import { BlankSpacer, Spacer } from '../../Global/StyledComponents/AppStyledComponents';
import AlertFeed from "../../Global/Views/FeedViewer/AlertFeed";
import Drawer from '../../Global/Components/Drawer';
import { StateContext } from '../../Controllers/context/state';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import { AuthContext } from '../../Controllers/context/auth';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';

const KnowledgeBase = () => {
    const { user } = useContext(AuthContext);
    const {
        development,
        newAlerts,
    } = useContext(StateContext);

    return (
        <>
            <ViewModule>
                <AllColumns>
                    <h1>Knowledge Base</h1>
                    <BlankSpacer />
                    <h2>Welcome to the MarketReader User Platform.</h2>
                    <BlankSpacer />
                    <p>This is the knowledge base for MarketReader users. The purpose of this page is to provide information on the latest updates, instructions, glossary, FAQs, and support.</p>
                    <Spacer />
                    <BlankSpacer />
                    <h2>Support and Feedback</h2>
                    <p>
                        Our team is available to help with any questions or issues you may have. Please reach out to&nbsp;
                        <a href = "mailto: support@marketreader.com">support@marketreader.com</a>
                    </p>
                    <p>
                        If you experience any issues or you would like to provide feedback, please access the&nbsp;
                        <a
                            className='underline'
                            href={
                                user?.hnssotoken ?
                                `https://feedback.marketreader.com/?sso_token=${user?.hnssotoken}` :
                                `https://feedback.marketreader.com/`
                            }
                            target='_blank'
                            rel='noreferrer'
                        >
                            Help and Feedback
                        </a>
                        &nbsp;page to report any issues you find.
                    </p>

                    <Spacer />
                    <BlankSpacer />
                    <h2>Instructions</h2>
                    <h3>Dashboard</h3>
                    <p>This dashboard displays today’s most unusual moves as determined by MarketReader’s proprietary filtering system. Each asset card includes live price data, and the order of the assets will update every 10 minutes as the system is refreshed with new analytics. Click on any card to see why the asset is moving.</p>
                    <img alt='Dashboard Monitor' src='./img/Dashboard.png' style={{ width: '50%'}} />
                    <h3>Macro Monitor</h3>
                    <p>This monitor displays ETFs that are moving unusually today, grouped by category. Click on a card to see more information.</p>
                    <img alt='ETF Monitor' src='./img/ETFMonitor.png' style={{ width: '50%'}} />
                    <h3>Smart Screener</h3>
                    <h4>Filter</h4>
                    <p>You can make filter and sort selections here in the left panel to view stocks that match the selected criteria. For example, find large cap stocks in the industrials sector with unusual volume today. Or find ETFs with unusual moves in the last 10 minutes.</p>
                    <h4>Sort</h4>
                    <p>You can sort your filtered selections here to view stocks in the order you prefer. The cards will then display your selected sort data in the grey area at the bottom of the card.</p>
                    <img alt='Smart Screener' src='./img/SmartScreener.png' style={{ width: '50%'}} />
                    <h4>Saved Screeners</h4>
                    <p>With this feature, you can save your screener's current filter and sort options with a unique name, so that you can easily come back to screeners that are significant to your needs.</p>
                    <img alt='Saved Screener' src='./img/SavedScreeners.png' style={{ width: '50%'}} />
                    <h3>Watchlists</h3>
                    <p>Within the watchlists tab, you can create custom watchlists with unique names and add assets that are relevant to your needs. You can also add assets directly from the screener to your watchlists.</p>
                    <img alt='Watchlist Monitor' src='./img/Watchlists.png' style={{ width: '50%'}} />
                    <h3>Intraday Chart</h3>
                    <p>The intraday chart allows you to see the ten minute space and access any info we have about ten minute moves by hovering over the glasses icons.</p>
                    <img alt='Chart' src='./img/Chart.png' style={{ width: '50%'}} />
                    <Spacer />
                    <BlankSpacer />
                    <h2 id="terms-of-use">Terms Of Use</h2>
                    <BlankSpacer />
                    <p>
                        This site is provided for informational purposes only. The information included in this site should not be used as the sole basis for making a decision as to whether or not to invest in any particular security. In making an investment decision, you must rely on your own examination of the securities and the terms of the offering. You should not construe the contents of these materials as legal, tax, investment or other advice, or a recommendation to purchase or sell any particular security.</p>

                    <p> The information included in this site is based upon information reasonably available to MarketReader as of the date noted herein. Furthermore, the information included in this site has been obtained from sources that MarketReader believes to be reliable; however, these sources cannot be guaranteed as to their accuracy or completeness. Information contained in this site does not purport to be complete, nor does MarketReader undertake any duty to update the information set forth herein. No representation, warranty or undertaking, express or implied, is given as to the accuracy or completeness of the information contained herein, by MarketReader, its members, partners or employees, and no liability is accepted by such persons for the accuracy or completeness of any such information.</p>

                    <p> This site contains certain “forward-looking statements,” which may be identified by the use of such words as “believe,” “expect,” “anticipate,” “should,” “planned,” “estimated,” “potential,” “outlook,” “forecast,” “plan” and other similar terms. Examples of forward- looking statements include, but are not limited to, estimates with respect to financial condition, results of operations, and success or lack of success of certain investment strategies. All are subject to various factors, including, but not limited to, general and local economic conditions, changing levels of competition within certain industries and markets, changes in interest rates, changes in legislation or regulation, and other economic, competitive, governmental, regulatory and technological factors affecting the operations of the companies identified herein, any or all of which could cause actual results to differ materially from projected results.</p>
                    <p>By creating an account, you agree to keep all text and image content on the platform strictly private, considering this is a very early iteration of the platform.</p>
                    <Spacer />
                    <BlankSpacer />
                    <h2>
                        <a
                            className="underline"
                            href="https://app.marketreader.com/MarketReader+TOS+-+Mar312023.pdf"
                            rel='noreferrer'
                            target="_blank"
                        >
                            Terms of Service {'>'} 
                        </a>
                    </h2>

                </AllColumns>
            </ViewModule>
            <Drawer rotate='true' >
                <FeedViewer title='News Feed'/>
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                {/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts'/> */}
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    )
}
export default KnowledgeBase;