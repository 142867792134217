import { useState, useEffect, useCallback } from 'react';
import supabase from '../utils/supabaseClient';

const useFetchWatchlists = ({ logError, setShowErrorWindow }) => {
    const [watchlists, setWatchlists] = useState([]);
    const [watchlistsLoading, setWatchlistsLoading] = useState(true);

    const fetchAllWatchlists = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('watchlists_with_stats').select('*');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllWatchlists({ retry: false });

                return;
            }

            setShowErrorWindow(true);

            return;
        }
        
        const watchlistsData = res?.data?.map((watchlist) => {
            return {
                label: watchlist?.meta?.name,
                value: JSON.stringify(watchlist),
            };
        });

        const allWatchlists = [
            {
                label: 'All Watchlists',
                value: null,
            },
          ...watchlistsData,
        ];

        setWatchlists(allWatchlists);
        setWatchlistsLoading(false);
    }, []);

    useEffect(() => {
        fetchAllWatchlists({ retry: true });
    }, []);

    return {
        fetchAllWatchlists,
        watchlists,
        watchlistsLoading,
    };
};

export default useFetchWatchlists;
