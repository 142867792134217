
import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import '../chart_support/hs_configs';
import {
    model_output_header,
    macro_sector_tags,
    calendar_model_argo,
    news_model_argo,
    factor_model_argo,
    news_newswhip_dtm_argo,
    twitter_model_argo,
    news_newsware_dtm_argo,
    agg_summary,
    factor_etf_argo
} from '../chart_support/model_formats.js';
import { ChartContainer } from '../../../Global/StyledComponents/AppStyledComponents';
import supabase from '../../../Controllers/utils/supabaseClient.js';
require('highcharts/modules/annotations-advanced')(Highcharts)
require('highcharts/modules/full-screen')(Highcharts)
require('highcharts/modules/series-label')(Highcharts)
require('highcharts/modules/accessibility')(Highcharts)


window.moment = Moment;
mTZ();
const queryParams = new URLSearchParams(window.location.search);
Highcharts.AST.allowedAttributes.push('onclick');

class Chart extends React.Component {
    afterChartCreated(chart) {
        this.internalChart = chart;
    }
    
    constructor(props) {
        super(props)
        this.chartRef = React.createRef();
        this.afterChartCreated = this.afterChartCreated.bind(this);
        
        document.addEventListener('click', () => {
            // this.internalChart.tooltip.hide(0);
        });

        this.state = {
            token: props?.session?.access_token,
            ticker: props.ticker,
            width: props.width,
            options: {
                navigator: {
                    baseSeries: 'price',
                    enabled:true,
                    height:20,
                    adaptToUpdatedData: true,
                },
                credits: {
                    // href: 'https://www.marketreader.com/'
                    text: 'marketreader.com',
                    style: { fontFamily: 'Mulish' },
                    position: {
                        align: 'center',
                        y: -200
                    }
                },
                tooltip: {
                    borderColor: '#5136d8',
                    backgroundColor: "rgba(255,255,255,100)",
                    valueDecimals: 1,
                    shared: false,
                    stickOnContact: false,
                    hideDelay: 5000,
                    useHTML: true,
                    split: false,
                    formatter: function () {
                        return this.point.text;
                    },
                    style: {
                        width: '450px'
                        , pointerEvents: 'auto'
                    },
                    align: 'top',
                    positioner: function(labelWidth, labelHeight, point) {
                        if (point.plotX > (this.chart.container.getBoundingClientRect().width/2) - 50) {
                            return {
                                x: 25, 
                                y: this.chart.container.getBoundingClientRect().top - 60
                            };
                        } else {
                            return {
                                x: this.chart.container.getBoundingClientRect().width - labelWidth - 75, 
                                y: this.chart.container.getBoundingClientRect().top - 60
                            };
                        }
                        
                    }
                },
                chart: {
                    // width: '100%',
                    backgroundColor: '#F7FDFA',
                    events: {
                        click: function() {
                            this.tooltip.hide();
                        },
                    },
                    marginRight: 55,
                    animation: true
                },
                title: {
                    text: '',
                    // width: '1000',
                    align: 'left',
                    useHTML: true,
                    widthAdjust: 0,
                    margin: 0,
                    widthAdjust: 200,
                    style: { 
                        width: '100%',
                        color: "#333333",
                        fontSize: "42px",
                        fontFamily: "Poppins"
                    }
                },
                yAxis: [{
                    height: '100%',
                    labels: { 
                        align: 'right',
                        overflow: 'allow' ,
                        style: {
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            // fontStyle: "bold"
                        },
                        formatter: function () {
                            return '$' + Highcharts.numberFormat(this.value, 2);
                        },
                        x: 40
                    },
                    minorTicks: true,
                    minTickInterval: 0.1,
                    maxPadding: 0.15,
                    minPadding: 0.15,
                }, {
                    top: '75%',
                    height: '25%',
                    offset: 0,
                    visible: false
                }],
                xAxis: { 
                    type: 'datetime',
                    ordinal: true,
                    minorTicks: true,
                    minTickInterval: 60000 * 50,
                    // min: (this.props.time_utc*1000) - 2 * 24 * 3600 * 1000,
                    plotBands: [],
                    plotLines: [],
                    labels: {
                        style: {
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                        },
                    },
                    dateTimeLabelFormats: {
                        minute: {
                            main: '%l:%M %p',
                        },
                        hour: {
                            main: "%l %p",
                        },
                    },
                },
                rangeSelector: {
                    enabled: false,
                },
                time: {
                    useUTC: false,
                    // , timezone: 'America/New_York'
                },
                stockTools: {
                    gui: {
                        enabled: true,
                        buttons: [ 'fullScreen', 'separator','typeChange' ],
                        definitions: {
                            typeChange: {
                                items: [ 'typeOHLC', 'typeLine', 'typeCandlestick' ],
                            },
                        },
                    },
                },
            },
        }
    }

    DTMoutputsToHTML(model_outputs, candle_data, date_start = 0) {
        
        var texts = [];
        
        // var order = ["argo_news-newsware-dtm","argo_news-newswhip", "argo_factor-micro", "argo_volume-model"];
        var order = ['news-newsware-dtm-dev', 'news-newswhip-dtm-dev', 'calendar-micro-dtm-dev', 'calendar-macro-dtm-dev', 'factor-explanatory-dtm-dev','factor-correlations-dtm-dev'
                    ,'news-newsware', 'news-newswhip', 'news-macronews', 'twitter-hottweets', 'twitter-crypto', 'calendar-micro', 'calendar-macro', 'factor-explanatory','factor-etf','factor-correlations'];
        var symbol = "";
        if (model_outputs === undefined) {
            return "";
        }
        model_outputs.forEach(function(mo) {
            mo.sort_order = order.indexOf(mo.model_name);
        });
        model_outputs.sort(function(a,b) {
            return a.sort_order - b.sort_order;
        });
        
        var groupedOutputs = {};
        model_outputs.forEach(function (model_output) {
            symbol = model_output.symbol;
            if (model_output.model_name in groupedOutputs) {
                groupedOutputs[model_output.model_name].push(model_output);
            } else {
                groupedOutputs[model_output.model_name] = [model_output];
            }
        });
        
        Object.keys(groupedOutputs).forEach(function (model_name) {    
            // TODO: Iterate through the names of the models, and send the contents located at groupedOutputs[name] to respective function
            var text = '';
            if (['argo_news-newswhip','news-newswhip-dtm-dev', 'news-newswhip'].includes(model_name)) {
                text += news_newswhip_dtm_argo(candle_data, groupedOutputs[model_name]);
            } else if (['argo_news-newsware-dtm','news-newsware-dtm-dev', 'news-newsware','news-macronews'].includes(model_name)) {
                text += news_newsware_dtm_argo(candle_data, groupedOutputs[model_name]);
            } else if (['argo_factor-micro','factor-explanatory-dtm-dev','factor-correlations-dtm-dev', 'factor-correlations'].includes(model_name)) {
                text += factor_model_argo(candle_data, groupedOutputs[model_name], true);
            } else if (['aggr-summarizer-all-dev', 'aggr-summarizer'].includes(model_name)) {
                text += agg_summary(candle_data, groupedOutputs[model_name], true);
            } else if (['factor-etf'].includes(model_name)) {
                text += factor_etf_argo(candle_data, groupedOutputs[model_name], true);
            } 

            // else if (['argo_volume-model'].includes(model_name)) {
            //     text += volume_model_argo(candle_data, groupedOutputs[model_name], true);
            // } 
            else if (['twitter-hottweets-dtm-dev','twitter-hottweets', 'twitter-crypto'].includes(model_name)) {
                text += twitter_model_argo(candle_data, groupedOutputs[model_name]);
            }
            else if (['calendar-macro-dtm-dev','calendar-micro-dtm-dev','calendar-micro', 'calendar-macro'].includes(model_name)) {
                text += calendar_model_argo(candle_data, groupedOutputs[model_name]);
            }
            else {
                text += model_name + '{unprocessed}';
            }
            text = "<span style='width:200px;padding-top:0px;font-size:14px;'>" + text + '</span>'
            texts.push(text);
        });
        
        
        var final = "<div style='padding-bottom:10px;font-family:Poppins SemiBold,sans-serif;font-weight:600; overflow: auto; max-height: 50vh; scrollbar-width: none; -ms-overflow-style: none; &::-webkit-scrollbar { display: none; }' class='hide-scrollbar'><div>";
        final += model_output_header(candle_data, model_outputs, symbol, true, date_start);
        final += macro_sector_tags(candle_data, true);
        final += "</div>";

        texts.forEach(function (text) {
            final += "<div><hr style='padding:0px;'>"+ text + "</div>"
        });
        final += '</div>';
        
        return final;
    }

    outputsToHTML(model_outputs, candle_data) {
        var texts = [];
        
        // var order = ["argo_news-newsware-hotnews-10min", "argo_calendar-micro","argo_calendar-macro", "argo_twitter-hottweets-10min", "lambda_twitter_model", 'argo_factor-micro', "lambda_volume_model"];
        var order = [,'aggr-summarizer', "news-newsware-10min-dev", "calendar-micro-10min-dev", "calendar-macro-10min-dev", "twitter-hottweets-10min-dev", "factor-explanatory-10min-dev",'factor-correlations-10min-dev'
                    ,"news-newsware", "calendar-micro", 'news-macronews', "calendar-macro", "twitter-hottweets",'twitter-crypto', "factor-explanatory",'factor-etf','factor-correlations'];
        var symbol = "";
        
        if (model_outputs.length === 0) {
            return "";
        }
        model_outputs.forEach(function(mo) {
            mo.sort_order = order.indexOf(mo.model_name);
        });
        model_outputs.sort(function(a,b) {
            return a.sort_order - b.sort_order;
        });
        var groupedOutputs = {};
        model_outputs.forEach(function (model_output) {
            symbol = model_output.symbol;
            if (model_output.model_name in groupedOutputs) {
                groupedOutputs[model_output.model_name].push(model_output);
            } else {
                groupedOutputs[model_output.model_name] = [model_output];
            }
        });
        Object.keys(groupedOutputs).forEach(function (model_name) {    
            // TODO: Iterate through the names of the models, and send the contents located at groupedOutputs[name] to respective function
            var text = '';
            if (['argo_news-newsware-hotnews-10min', 'argo_news-newsware-dtm', 'news-newsware-10min-dev', 'news-newsware','news-macronews'].includes(model_name)) {
                text += news_model_argo(candle_data, groupedOutputs[model_name]);
            } 
            else if (['twitter-hottweets-10min-dev', 'argo_twitter-hottweets-10min', 'argo_twitter-hottweets', 'twitter-hottweets', 'twitter-crypto'].includes(model_name) ) { 
                text += twitter_model_argo(candle_data, groupedOutputs[model_name]);
            } else if (model_name === 'lambda_calendar_model') {
                // text += calendar_model_lambda(candle_data, groupedOutputs[model_name]);
            } else if (['argo_factor-micro', 'factor-explanatory-10min-dev', 'factor-correlations-10min-dev', 'factor-correlations'].includes(model_name)) {
                text += factor_model_argo(candle_data, groupedOutputs[model_name]);
            } else if (['argo_calendar-micro','argo_calendar-macro','calendar-macro-10min-dev','calendar-micro-10min-dev','calendar-micro', 'calendar-macro'].includes(model_name)) {
                text += calendar_model_argo(candle_data, groupedOutputs[model_name]);
            } else if (['aggr-summarizer-all-dev', 'aggr-summarizer'].includes(model_name)) {
                text += agg_summary(candle_data, groupedOutputs[model_name], true);
            } else if (['factor-etf'].includes(model_name)) {
                text += factor_etf_argo(candle_data, groupedOutputs[model_name], true);
            } else {
                text += '';
            }
            text = "<span style='width:200px;padding-top:0px;font-size:14px;'>" + text + '</span>'
            texts.push(text);
        });
        var final = "<div style='padding-bottom:10px;font-family:Poppins SemiBold,sans-serif;font-weight:600; overflow: auto; max-height: 50vh; scrollbar-width: none; -ms-overflow-style: none; &::-webkit-scrollbar { display: none; }' class='hide-scrollbar'><div>";
        final += model_output_header(candle_data, model_outputs, symbol);
        final += macro_sector_tags(candle_data);
        final += "</div>";

        texts.forEach(function (text) {
            final += "<div><hr style='padding:0px;'>"+ text + "</div>"
        });
        final += '</div>';
        return final;
    }

    processAnnotations(model_output_data) {
        if (model_output_data.length < 1) {
            return;
        }
        var temp = this.state.options;
        

        var models_to_display_candle = [
            "argo_calendar-micro"
            , "argo_calendar-macro"
            , "argo_news-newsware-hotnews-10min"
            , 'argo_factor-micro'
            // , "lambda_volume_model"
            , "argo_volume-model"
            , 'argo_twitter-hottweets-10min'

            ,'calendar-macro-10min-dev'
            ,'calendar-micro-10min-dev'
            ,'factor-explanatory-10min-dev'
            ,'factor-correlations-10min-dev'
            ,'factor-movetype-10min-dev'
            ,'news-newsware-10min-dev'
            // ,'news-newswhip-10min-dev'
            ,'twitter-hottweets-10min-dev'

            ,'calendar-macro'
            ,'calendar-micro'
            ,'factor-explanatory'
            ,'factor-correlations'
            ,'factor-movetype'
            ,'news-newsware'
            // ,'news-newswhip'
            ,'twitter-hottweets'
            ,'news-macronews'
            ,'aggr-summarizer'
            ,'twitter-crypto'
            ,'factor-etf'

        ];
        var model_output_data_candle = model_output_data.filter(item => item.aggr === '10min');

        var models_to_display_dtm = [
            "argo_news-newswhip"
            , "argo_news-newsware-dtm"
            , "argo_factor-micro"
            , "argo_volume-model"

            ,'calendar-macro-dtm-dev'
            ,'calendar-micro-dtm-dev'
            ,'factor-explanatory-dtm-dev'
            ,'factor-correlations-dtm-dev'
            ,'factor-movetype-dtm-dev'
            ,'news-newsware-dtm-dev'
            ,'news-newswhip-dtm-dev'
            ,'twitter-hottweets-dtm-dev'
            ,'aggr-summarizer-all-dev'

            ,'calendar-macro'
            ,'calendar-micro'
            ,'factor-explanatory'
            ,'factor-correlations'
            ,'factor-movetype'
            ,'news-newsware'
            ,'news-newswhip'
            ,'twitter-hottweets'
            ,'aggr-summarizer'
            ,'news-macronews'
            ,'twitter-crypto'
            ,'factor-etf'
        ];
        var model_output_data_dtm = model_output_data.filter(item => item.aggr === 'dtm');

        model_output_data_candle = model_output_data_candle.filter(item => models_to_display_candle.includes(item.model_name));
        var groupedSignals = {};
        model_output_data_candle.forEach(function (model_output) {
            if (model_output.time_utc in groupedSignals) {
                groupedSignals[model_output.time_utc].push(model_output);
            } else {
                groupedSignals[model_output.time_utc] = [model_output];
            }
        });
        groupedSignals = Object.keys(groupedSignals).sort().reduce(
            (obj, key) => { 
              obj[key] = groupedSignals[key]; 
              return obj;
            }, 
            {}
          );
        
        var flags_up = [];
        var flags_down = [];
        for (const [time_utc, outputs] of Object.entries(groupedSignals)) {
            var candle = temp.series[0].data.findIndex(o => o.x === Date.parse(time_utc));
            var candle_data = temp.series[0].data[candle];
            if (typeof candle_data === 'undefined') {
                continue;
            }
            if (candle !== -1) {
                temp.series[0].data[candle]['color'] = '#5136d8';
                // temp.series[0].data[candle]['lineWidth'] = 3;
                if (temp.series[0].data[candle].return_oc > 0) {
                    temp.series[0].data[candle]['lineColor'] = '#5dd39e';
                } else {
                    temp.series[0].data[candle]['lineColor'] = '#ee6352';
                }
                temp.series[1].data[candle]['color'] = '#5136d8';
            }
            if (temp.series[0].data[candle]['open'] <= temp.series[0].data[candle]['close']) {
                temp.series.push({
                    type: 'flags'
                    , id: (candle_data.x/1000).toString()
                    , allowOverlapX: true
                    , data: [{x: Date.parse(time_utc),
                        title: " "
                        , test: 1
                        , tooltip: {
                            style: {
                                width: '750px'
                                , pointerEvents: 'auto'
                            }
                        }
                        , text: this.outputsToHTML(outputs, candle_data)}]
                    , pointPlacement: 'between'
                    , onSeries: 'price'
                    , linkedTo: 'price'
                    , onKey: 'close'
                    , useHTML: true
                    , style: { width: '100px' }
                    , dataLabels: {
                        allowOverlap: true
                    }
                    , shape: 'url(https://s3.us-east-2.amazonaws.com/www.marketreader.com/favicon.png)'
                    , zIndex: 100
                });
            } else {
                temp.series.push({
                    type: 'flags'
                    , id: (candle_data.x/1000).toString()
                    , allowOverlapX: true
                    , data: [{x: Date.parse(time_utc),
                        title: " "
                        , test: 1
                        , text: this.outputsToHTML(outputs, candle_data)}]
                    , pointPlacement: 'between'
                    , onSeries: 'price'
                    , linkedTo: 'price'
                    , onKey: 'close'
                    , useHTML: true
                    , style: { width: '100px' }
                    , dataLabels: {
                        allowOverlap: true
                    }
                    , y: 30
                    , shape: 'url(https://s3.us-east-2.amazonaws.com/www.marketreader.com/favicon.png)'
                    , zIndex: 100
                });
            }
        };


        model_output_data_dtm = model_output_data_dtm.filter(item => models_to_display_dtm.includes(item.model_name));

        // THIS IS FOR DTM SIGNALS
        groupedSignals = {};
        model_output_data_dtm.forEach(function (model_output) {
            if (model_output.time_utc in groupedSignals) {
                groupedSignals[model_output.time_utc].push(model_output);
            } else {
                groupedSignals[model_output.time_utc] = [model_output];
            }
        });
        
        groupedSignals = Object.keys(groupedSignals).sort().reduce(
            (obj, key) => { 
              obj[key] = groupedSignals[key].sort(function(a,b) {
                return b.confidence_metric - a.confidence_metric;
              }); 
              return obj;
            }, 
            {}
          );
        
        for (const ser of temp.series) {
            if (ser.type === 'line') {
                let times = Object.keys(groupedSignals).filter((time_utc) => ser.data[0].x <= Date.parse(time_utc) && Date.parse(time_utc) <= ser.data[1].x);
                times = times.sort((a,b) => {
                    return b.time_utc - a.time_utc;
                });
                if (times.length === 0) {
                    continue;
                }
                let time_utc = times[times.length -1];
                
                let outputs_obj = {};
                for (let i = 0; i < times.length; i++) {
                    groupedSignals[times[i]].map((item)=>{
                        outputs_obj[item['model_name']] = item
                    })
                }
                let outputs = [];
                Object.keys(outputs_obj).map((key) => {
                    outputs.push(outputs_obj[key]);
                })
                
                // let outputs = groupedSignals[time_utc];
                var candle = temp.series[0].data.findIndex(o => o.x === Date.parse(time_utc));
                var candle_data = temp.series[0].data[candle];
                if (outputs) {
                    temp.series.push({
                        type: 'flags'
                        , allowOverlapX: true
                        , data: [{
                            x: (ser.data[1].x),
                            // x: Date.parse(times[times.length -1]),
                            title: " "
                            , text: this.DTMoutputsToHTML(outputs, candle_data, ser.data[0].x)
                            , width: '15px'
                        }]
                        , pointPlacement: 'between'
                        , onSeries: ser.id
                        , linkedTo: 'price'
                        , onKey: 'y'
                        , useHTML: true
                        , dataLabels: {
                            allowOverlap: true
                        }
                        , y: -30
                        , shape: 'url(https://demo.marketreader.com/img/MarketReader_40_purple.png)'
                        , width: '15px'
                    });
                }

            }
        }
        
        this.setState({ options: temp });
        // THIS BIT HANDLES THE FIRST TOOLTIP TO HIGHLIGHT AFTER LOADING
        // for (const [series_id, series] of Object.entries(this.internalChart.series)) {
        //     if (series_id < 4) {
        //         continue;
        //     }
        //     var candle = this.internalChart.series[series_id].points.findIndex(o => o.category === this.props.time_utc*1000);
        //     if (candle < 0 ) {
        //         continue;
        //     } else {
        //         this.internalChart.tooltip.refresh(series.points[candle]);    
        //     }
        // }
    }

      processCandleData(data, symbol) {
        var temp = this.state.options;
        temp.series =  [
            { 
                type: 'candlestick'
                , pointPlacement: 'between'
                , dataGrouping: { enabled: false } 
                , id: 'price'
                , upColor: '#5dd39e'
                , color: '#ee6352'
                , data: [[]]
                , pointPadding: 0.001
                , enableMouseTracking: false
                , zIndex: 100
                , turboThreshold: 5000
            },
            {
                type: 'column'
                , pointPlacement: 'between'
                , dataGrouping: { enabled: false } 
                , id: 'volume'
                , name: 'Volume'
                , color: '#5dd39e'
                , data: []
                , yAxis: 1
                , pointPadding: 0.001
                , enableMouseTracking: false
                , opacity: 0.5
                , zIndex: 100
                , turboThreshold: 5000
            },
        ]
        
        temp.series[0].data = data.data.map((a) => ({
            x: new Date(a.time_utc).getTime(),
            open: a.open,
            high: a.high,
            low: a.low,
            close: a.close,
            return_oc: a.return_oc,
            return_oc_voladj: a.return_oc_voladj,
            date_return_oc: a.date_return_oc,
            date_return_oc_voladj: a.date_return_oc_voladj,
            volume_dollar_voladj: a.volume_dollar_voladj,
            date_volume_dollar_voladj: a.date_volume_dollar_voladj,
            date_return_oc_percentile: a.date_return_oc_percentile,
            date_volume_dollar_percentile: a.date_volume_dollar_percentile,
            date_high: a.date_high,
            market_date: new Date(a.market_date).toISOString(),
            residual_spy_return_oc_voladj: a.residual_spy_return_oc_voladj,
            residual_sector_return_oc_voladj: a.residual_sector_return_oc_voladj,
            spy_return_oc_voladj: a.spy_return_oc_voladj,
            spy_return_oc_percentile: a.spy_return_oc_percentile,
            sector_return_oc_voladj: a.sector_return_oc_voladj,
            sector_spy_residual_percentile: a.sector_spy_residual_percentile,
            dtm_override: a.dtm_override,
            residual_sector_return_oc_percentile: a.residual_sector_return_oc_percentile,
            date_spy_return_oc_percentile: a.date_spy_return_oc_percentile,
            date_sector_spy_residual_percentile: a.date_sector_spy_residual_percentile,
            date_residual_sector_return_oc_percentile: a.date_residual_sector_return_oc_percentile,
            liquidity_vhl_voladj: a.liquidity_vhl_voladj,
            date_liquidity_vhl_voladj: a.date_liquidity_vhl_voladj,
            date_range_hl_percentile: a.date_range_hl_percentile,
        }));

        temp.series[1].data = data.data.map((a) => ({
            x: new Date(a.time_utc).getTime(),
            y: a.volume,
        }));
        
        
        let extendedHours = [];
        let flipper = -1;
        let enter = -1;
        let exit = -1;
        data.data.forEach(item => {
            if (item.market_rth !== flipper) {
                if (flipper === -1) {
                    if (item.market_rth === false) {
                        enter = new Date(item.time_utc).getTime();
                    }
                } else if (flipper === false) {
                    exit = new Date(item.time_utc).getTime();
                    extendedHours.push({
                        // color: '#F7FCFD'
                        color: '#FBFCF7'
                        , to: exit, from: enter, fromISO: new Date(enter).toString(), toISO: new Date(exit).toString()});
                    temp.xAxis.plotLines.push({
                        width: 3
                        , dashStyle: 'ShortDot'
                        , color: '#000000'
                        , value: enter
                    })
                    enter = -1;
                } else if (flipper === true) {
                    enter = new Date(item.time_utc).getTime();
                    exit = -1;
                } 
            }
            flipper = item.market_rth;
        });
        if (enter !== -1 && exit === -1) {
            extendedHours.push({color: 'white', from: enter, fromISO: new Date(enter).toString(), toISO: new Date(exit).toString()});
        }
        temp.xAxis.plotBands = extendedHours;
        
        temp.title.text = `
        <div style="width: calc(180%); background-color: #F7FDFA; padding-top:5px; font-family: 'Poppins SemiBold', sans-serif; ">
        <div>
            <div style="width: fit-content; padding-left: 10px; display:inline-block;"><p id="ticker" style="font-size: 35px; margin-top: 0; margin-bottom: 0;"><b>${symbol}</b></p></div>
            <div style="width: calc(55%); padding-left: 5px; display:inline-block; vertical-align:middle; font-size:10px;">
                <span id="text" style="font-size: 16px; font-family: 'Mulish ExtraLight', sans-serif; margin-top: 0; margin-bottom: 0;"><b>${data.meta.name}</b></span> 
                <br style="padding:0px;"/>
                <span style="font-size:12px; font-family: 'Mulish ExtraLight', sans-serif; margin-top: 0; margin-bottom: 0; display:inline;">${data.meta.gsector}, ${data.meta.gsubind}. Intraday, 10min. </span>
            </div>
            <div style="width: calc(15%); display:inline-block; vertical-align:middle; "><img src="/img/MR_1line_Green.png" style="margin-top: 5px;max-width:200px;vertical-align:baseline;"></div>
        </div>

        `;
        
        temp.series[0].name = symbol;
        
        // THIS IS THE DTM BAR
        var last_bar = undefined;
        var first_bar = undefined;
        var price_hold = undefined;
        for (let i = data.data.length -1; 0 < i; i--) {
            
            if (data.data[i].dtm_override || ((data.data[i].date_return_oc_percentile >= 0.94 || data.data[i].date_return_oc_percentile <= 0.06)) || data.data[i].date_range_hl_percentile > 0.95) {
                
                if (last_bar === undefined) {
                    last_bar = new Date(data.data[i].time_utc).getTime();
                    price_hold = data.data[i].date_high*1.02;
                }
            }
            if (data.data[i-1].market_date !== data.data[i].market_date && last_bar !== undefined) {
                first_bar = new Date(data.data[i].time_utc).getTime();
                temp.series.push({
                    'type': 'line'
                    , 'id': new Date(data.data[i].market_date).toISOString()
                    , 'data': [
                        {x: first_bar, y:price_hold, marker: {
                            'enabled': true, 'symbol': 'rect_start', 'radius': 1, 'lineWidth': 2.1, 'lineColor': '#5F2BFF' 
                        }}
                        , {x:last_bar, y:price_hold, marker: {
                            'enabled': true, 'symbol': 'rect_end', 'radius': 1, 'lineWidth': 2.1, 'lineColor': '#5F2BFF' 
                        }}
                    ]
                    , 'lineWidth': 2
                    , pointPlacement: 'between'
                    , 'enableMouseTracking': false
                    , 'color': '#5F2BFF'
                    // , 'name': 'Unusual Return & \nVolume for the day.'
                    , 'name': ''
                    , label: {
                        connectorAllowed: false
                        ,boxesToAvoid: [{
                            left: 0, 
                            top: 0,
                            right: 100,
                            bottom: 400,
    
                        }],
                    }
                    , 'linkedTo': 'price'
                });
                first_bar = undefined;
                last_bar = undefined;
                price_hold = undefined;
            }
        }
        this.setState({ options: temp });
    }

    handleChartRef = (chart) => {
        this.internalChart = chart; // Step 2: Store the chart object in a class variable
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.width !== this.props.width) {
            const chart = this.chartRef.current.chart;
            this.setState({ width: this.props.width });
      
            chart.update({
              chart: {
                width: this.props.width,
              },
            });
      
            chart.reflow();
          }

        if (prevProps.ticker !== this.props.ticker) {
            // window.history.pushState('MarketReader', 'MarketReader', '/');
            // this.internalChart.tooltip.hide(0);
            this.componentDidMount();
        }

        if (prevProps.time_utc !== this.props.time_utc) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        const symbol = (this.props.ticker);
        const time_utc = this.props.time_utc;
        if (!symbol) {
            return;
        } else {
            
        }

        const { logError } = this.props;

        const fetchData = ({ retry = true }) => {
            supabase.rpc('chart_candle_data', {
                ticker: symbol, 
                epoch: time_utc,
            }).then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);

                    if (retry) {
                        fetchData({ retry: false })
                    }
        
                    return;
                }
                supabase.from('symbols').select('name, gsector, gsubind').eq('symbol', symbol).then((meta) => {
                    if (res?.error) {
                        const error = new Error(res?.error?.message);
                        logError(error.message, undefined, error);

                        if (retry) {
                            fetchData({ retry: false })
                        }
            
                        return;
                    }
    
                    const data = {
                        data: res?.data,
                        meta: meta?.data[0]
                    };
                    this.processCandleData(data, symbol);
                    supabase.rpc('chart_outputs', {
                        ticker: symbol, 
                        epoch: time_utc,
                    }).then((res) => {
                        if (res?.error) {
                            const error = new Error(res?.error?.message);
                            logError(error.message, undefined, error);

                            if (retry) {
                                fetchData({ retry: false })
                            }
                
                            return;
                        }
    
                        this.processAnnotations(res?.data);
                    });
                });
            });
        };

        fetchData({ retry: true });

    }

    render() {
        const { options, width } = this.state;
        return (
            <ChartContainer
                style={{ width: `${width}px`}}
            >
                <HighchartsReact
                    containerProps={{ style: { height: '75vh', width: `${width}px` }}}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                    updateArgs={[true, true, true]}
                    oneToOne={true}
                    ref={this.chartRef}
                />
            </ChartContainer>
        );
    }


}

export default Chart;


