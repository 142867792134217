import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: absolute;
    right: 20px;
`;

const Button = styled.div`
    height: 50px;
    cursor: pointer;
    padding: 15px 5px;
    background: ${AppColors.MRNAVY50};
    position: absolute;
    border-radius: 10px 0 0 10px;
    z-index: 1000;
    opacity: 0.5;

    &.hover {
        opacity: 1;
    }
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left: 10px solid ${AppColors.MRBLUE};
    transform: ${props => props.hideTabs ? null : 'rotate(180deg)'};
`;

const SideButton = ({ handleClick, hideTabs }) => {
    return (
        <Container>
            <Button
                onClick={handleClick}
            >
                <Triangle hideTabs={hideTabs} />
            </Button>
        </Container>
    );
};

export default SideButton;
