import React from 'react';
import DropdownMenu from '../../../Global/Components/DropdownMenu';
import MultiSelectDropdown from '../../../Global/Components/MultiSelectDropdown';
import { UTC_TIMEZONES, IMPORTANCE } from '../constants';

const CalendarFilter = ({ 
    categories,
    countries,
    handleCategoryChange,
    handleCountryChange,
    handleImportanceChange,
    handleTimeZoneChange,
    selectedCategory,
    selectedCountries,
    selectedImportance,
    selectedTimeZone,
}) => {

    return (
        <div style={{ padding: "2px 10px", display: 'flex', gap: '10px' }}>
            <div style={{ width: "180px" }}>
                <DropdownMenu
                    label={categories.label}
                    options={categories.values}
                    value={selectedCategory}
                    handleChange={handleCategoryChange}
                />
            </div>
            <div style={{ width: "180px" }}>
                <DropdownMenu
                    label={UTC_TIMEZONES.label}
                    options={UTC_TIMEZONES.values}
                    value={selectedTimeZone}
                    handleChange={handleTimeZoneChange}
                />
            </div>

            <div style={{ width: "180px" }}>
                <DropdownMenu
                    label={IMPORTANCE.label}
                    options={IMPORTANCE.values}
                    value={selectedImportance}
                    handleChange={handleImportanceChange}
                />
            </div>


            <div style={{ width: "180px", zIndex: 100000,}}>
                <MultiSelectDropdown
                    id="countries"
                    label={null}
                    options={countries}
                    placeholder="Select Countries"
                    value={selectedCountries.map(country => country.value)}
                    handleChange={handleCountryChange}
                />
            </div>
        </div>
    );
};

export default CalendarFilter;