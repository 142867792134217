const formatPrice = (price) => {
    let formattedPrice;
    price = Math.abs(price);

    if (price < 1000) {
        formattedPrice = price.toFixed(2);
    } else if (price < 10000) {
        formattedPrice = price.toFixed(1);
    } else if (price < 100000) {
        formattedPrice = Math.round(price);
    } else if (price < 1000000) {
        formattedPrice = (price / 1000).toFixed(1) + 'k';
    } else if (price < 1000000000) {
        formattedPrice = (price / 1000000).toFixed(1) + 'M';
    } else {
        formattedPrice = (price / 1000000000).toFixed(1) + 'B';
    }

    return '$' + formattedPrice;
};

export default formatPrice;
