import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { BlankSpacer } from '../../../Global/StyledComponents/AppStyledComponents';
import {
    FormWrapper,
    SubmitButton,
    Form,
    Header,
    Input,
    StyledLink,
    WarningText,
} from '../../../Global/StyledComponents/UserFormStyledComponents';
import supabase from '../../../Controllers/utils/supabaseClient';


const LoginForm = ({
    setAdmin,
    setCustomerProfile,
    setIsAuthenticated,
    setRefreshed,
    setSession,
    setSubscription,
    setUser
}) => {
    const [error, setError] = useState(null);
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const { data, error } = await supabase.auth.getSession();
            const session = data.session;
            const user = session?.user || undefined; 
            if (!user) {
                setIsAuthenticated(false);
                navigate('/login' + window.location.search);
                return;
            }
            setUser(user);
            setSession(session);
            
            
            if (user?.role === 'authenticated') {
                const profileRes = await supabase.from('profiles').select('*').eq('id', user.id);
                const { billing_email, regulatoryReport } = profileRes.data[0];
                setAdmin(billing_email === 'tech@marketreader.com');

                const profile = await supabase.functions.invoke('customerProfile', {
                    body: {
                        billingEmail: billing_email, 
                        userEmail: user.email,
                    },
                });
                setCustomerProfile(JSON.parse(profile?.data));

                const { accessLevels, subscriptions } = JSON.parse(profile?.data);
                setSubscription(subscriptions[0]);

                // datadogRum.setUser({
                //     email: user.email,
                //     plan: accessLevels,
                // })

                // if (
                //     !regulatoryReport &&
                //     (billing_email === user.email || !billing_email) &&
                //     !subscriptions?.length
                // ) {
                //     navigate('/regulatory');
    
                //     return;
                // }
                
                if (!subscriptions.length) {
                    navigate('/selectPlan')
                }

                if (accessLevels.includes('pro') || accessLevels.includes('basic')) {
                    /**
                     * THIS IS FOR HELLONEXT
                     */
                    function getQueryParameterByKey(key) { 
                        var pairStrings = window.location.search.slice(1).split('&'); 
                        var pairs = pairStrings.map(function(pair) { 
                        return pair.split('='); 
                    }); 
                    return pairs.reduce(function(value, pair) { 
                        if (value) return value; 
                        return pair[0] === key ? decodeURIComponent(pair[1]) : null; 
                    }, null); 
                    } 
                    
                    function getSSORedirectURL(ssoToken) { 
                        var redirectURL = getQueryParameterByKey('redirect').replace('redirectURL',''); 
                        var domain = getQueryParameterByKey('domain'); 
                        if (!redirectURL || redirectURL.indexOf('https://') !== 0 || !domain) { 
                            return null; 
                        } 
                        
                        return 'https://app.hellonext.co/redirects/sso?domain=' + domain + '&ssoToken=' + ssoToken + '&redirect=' + redirectURL; 
                    } 
                    
                    const ssoToken = await supabase.functions.invoke('hellonext', {
                        body: {
                            email: user.email,
                            // name: 'placeholder',
                            add_as_customer: true
                        }
                    });

                    setUser((prevUser) => ({ ...prevUser, hnssotoken: ssoToken.data }));                    
                    user.hnssotoken = ssoToken.data;
    
                    // const widget = window.HNWidget.init({
                    //     title: "Post Widget",
                    //     token: process.env.REACT_APP_HNWIDGET_TOKEN,
                    //     jwtToken: ssoToken,
                    //     // selector: "#widgetID", // Optional: Widget will open up when this element is clicked
                    //     placement: "right",
                    //     showOnlySubmission: true, 
                    //     bucketId: "13762",
                    //     launcherIconURL: '/img/bugicon.png'
                    // });
    
                    setIsAuthenticated(true);
    
                    var redirectURL = getSSORedirectURL(ssoToken.data); 
                    if (redirectURL) { 
                        window.location.assign(redirectURL); 
                        navigate(redirectURL);
                        return;
                    }
                    /*
                    * END
                    */
    
                   navigate(sessionStorage.getItem('previousPath') || '/');
                   sessionStorage.removeItem('previousPath');

                   if (!isMobile || isTablet) {
                       return;
                   } else {
                       window.location.reload(false);
                       return;
                   }
                } else {
                    navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });
                }

            } else {
                console.log(error);
            }
            setRefreshed(true);
            return;
        };
        getUser();
    }, []);

    const handleLoginCredentialsChange = (e) => {
        setLoginCredentials((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        window._hsq.push(["identify",{
            email: loginCredentials.email
        }]);

        supabase.auth.signInWithPassword(loginCredentials)
            .then(async (res) => {
                const { data, error } = res;
                const { session, user } = data;
                setUser(user);
                setSession(session);
                
                if (user?.role === 'authenticated') {
                    const profileRes = await supabase.from('profiles').select('*').eq('id', user.id);
                    const { billing_email, regulatoryReport } = profileRes.data[0];
                    setAdmin(billing_email === 'tech@marketreader.com');

                    const profile = await supabase.functions.invoke('customerProfile', {
                        body: {
                            billingEmail: billing_email, 
                            userEmail: user.email,
                        },
                    });
                    setCustomerProfile(JSON.parse(profile?.data));

                    const { accessLevels, subscriptions } = JSON.parse(profile?.data);

                    // if (
                    //     !regulatoryReport &&
                    //     (billing_email === user.email || !billing_email) &&
                    //     !subscriptions?.length
                    // ) {
                    //     navigate('/regulatory');
        
                    //     return;
                    // }
                    
                    if (!subscriptions.length) {
                        navigate('/selectPlan');

                        return;
                    }

                    if (subscriptions[0].status === 'paused' && sessionStorage.getItem('previousPath') === '/claimOffer') {
                        navigate('/claimOffer');
                        sessionStorage.removeItem('previousPath');
                        return;
                    }

                    if (accessLevels.includes('pro') || accessLevels.includes('basic')) {
                        /**
                         * THIS IS FOR HELLONEXT
                         */
                        function getQueryParameterByKey(key) { 
                            var pairStrings = window.location.search.slice(1).split('&'); 
                            var pairs = pairStrings.map(function(pair) { 
                            return pair.split('='); 
                        }); 
                        return pairs.reduce(function(value, pair) { 
                            if (value) return value; 
                            return pair[0] === key ? decodeURIComponent(pair[1]) : null; 
                        }, null); 
                        } 
                        
                        function getSSORedirectURL(ssoToken) { 
                            var redirectURL = getQueryParameterByKey('redirect'); 
                            var domain = getQueryParameterByKey('domain'); 
                            if (!redirectURL || redirectURL.indexOf('https://') !== 0 || !domain) { 
                                return null; 
                            } 
                            
                            return 'https://app.hellonext.co/redirects/sso?domain=' + domain + '&ssoToken=' + ssoToken + '&redirect=' + redirectURL; 
                        } 
                        
                        const ssoToken = await supabase.functions.invoke('hellonext', {
                            body: {
                                email: user.email,
                                // name: 'placeholder',
                                add_as_customer: true
                            }
                        });

                        user.hnssotoken = ssoToken.data;
    
                        // const widget = window.HNWidget.init({
                        //     title: "Post Widget",
                        //     token: process.env.REACT_APP_HNWIDGET_TOKEN,
                        //     jwtToken: ssoToken,
                        //     // selector: "#widgetID", // Optional: Widget will open up when this element is clicked
                        //     placement: "right",
                        //     showOnlySubmission: true, 
                        //     bucketId: "13762",
                        //     launcherIconURL: '/img/bugicon.png'
                        // });
    
                        setIsAuthenticated(true);
    
                        var redirectURL = getSSORedirectURL(ssoToken.data); 
                        if (redirectURL) { 
                            window.location.assign(redirectURL); 
                            navigate(redirectURL);
                        }
                        /*
                        * END
                        */
    
                        navigate(sessionStorage.getItem('previousPath') || '/');
                        sessionStorage.removeItem('previousPath');

                        if (!isMobile || isTablet) {
                            return;
                        } else {
                            window.location.reload(false);
                            return;
                        }
                    } else {
                        navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });

                        return;
                    }

                } else {
                    console.log(error);
                    setError(error);
                }
        });
    };

    return (
        <FormWrapper isMobile={isMobile && !isTablet}>
            <Header>Sign In to Your Account</Header>
            <Form onSubmit={handleSubmit}>
                <Input className='form'
                    autoComplete='email'
                    id='email'
                    type="email"
                    value={loginCredentials.email}
                    onChange={handleLoginCredentialsChange}
                    placeholder='Your Email'
                />
                <Input className='form'
                    id='password'
                    type="password"
                    value={loginCredentials.password}
                    onChange={handleLoginCredentialsChange}
                    placeholder='Password'
                />
                {error && <WarningText> Invalid email or password. Please try again.</WarningText>}
                <BlankSpacer/>
                <SubmitButton type="submit">Sign In</SubmitButton>
                
                <StyledLink to="/forgotPassword" >
                    <p>Forgot Password?</p>
                </StyledLink>
            </Form>
        </FormWrapper>
    );
};

export default LoginForm;
