import { useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import supabase from '../../Controllers/utils/supabaseClient';
import {
    FormWrapper,
    Form,
    Header,
    Input,
    MobileFormWrapper,
    StyledButton,
    StyledHyperLink,
    StyledLink,
    SubmitButton,
    SuccessMessage,
    WarningText,
    StyledLabel,
    SignUpContainer,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { Plans, PromoCodeCopy } from '../../Global/constants';
import PlanSelector from './components/PlanSelector';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';
import { AppColors } from '../../Global/StyledComponents/Graphics';

const PriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(!isMobile || isTablet) ? '100%' : '85%'};
    height: ${(!isMobile || isTablet) ? '100%' : null};
    flex-wrap: wrap;
    padding: ${isMobile && !isTablet ? null : '60px 0'};
`;

const StyledList = styled.ul`
    height: auto;
    margin: 0;
    padding: 0 20px;
    padding-top: 20px;
`;

const SignUpMonitor = ({
    plan,
    setPlan,
}) => {
    const code = new URLSearchParams(useLocation().search).get('code');
    const [promoMessage, setPromoMessage] = useState(null);
    const containerRef = useRef(null);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(!firstName || !lastName || !email);
    const [success, setSuccess] = useState(false);
    const [token, setToken] = useState('');

    useEffect(() => {
        setDisabled(!firstName || !lastName || !email)
    }, [firstName, lastName, email]);

    const handleApplyPromo = async ({ code, e }) => {
        e && e.preventDefault();
        setPromoMessage(null);

        const { data, error } = await supabase.functions.invoke('checkPromo', {
            body: {
                promoCode: code || promoCode,
            },
        });

        const { message, ok } = JSON.parse(data);

        if (error) {
            setPromoCodeValid(false);
            setPromoMessage({ message: 'There was an error applying your promo code. Please try again.' });

            return;
        }

        if (!ok) {
            setPromoCodeValid(false);
            setPromoMessage({ message: message });

            return;
        }

        if (ok) {
            setPromoCodeValid(true);
            setPromoMessage({ message: message });

            return;
        }
    };

    const handleChangePromoCode = (e) => {
        setPromoMessage(null);
        setPromoCode(e.target.value);
    };

    const handleChangeToken = (e) => {
        setToken(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(false);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleResend = () => {
        supabase.functions.invoke('sendInvite', {
            body: {
                userEmail: email.replace(/(\+.+@)/g, '@'),
            },
        });
    };

    const handleScroll = () => {
        containerRef?.current?.scrollIntoView({
            block: 'end',
            behavior: "smooth",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { data, error } = await supabase.functions.invoke('createCustomer', {
            body: {
                firstName,
                lastName,
                promoCode: promoCodeValid ? promoCode : null,
                userEmail: email.replace(/(\+.+@)/g, '@'),
            },
        });

        if (error) {
            setError(true);
            setErrorMessage('There was an error creating the customer. Please try again.');
            setDisabled(false);

            return;
        }

        const { message, ok } = JSON.parse(data)
        
        if (ok) {
            rewardful('convert', { email: email.replace(/(\+.+@)/g, '@') });
            setSuccess(true);
            setDisabled(false);

            return;
        }

        if (!ok) {
            setError(true);
            setErrorMessage(message);
            setDisabled(false);

            return;
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { data, error } = await supabase.auth.verifyOtp({ email: email, token: token, type: 'email'});

        if (data?.user?.role === 'authenticated') {
            navigate('/setPassword');
            setDisabled(false);

            return;
        }

        if (error) {
            setError(true);
            setErrorMessage(error.message);
            setDisabled(false);

            return;
        }
    };

    useEffect(() => {
        if (code) {
            setPromoCode(code);
            handleApplyPromo({ code });
        }
    }, [code]);

    useEffect(() => {
        containerRef?.current?.scrollIntoView({
            block: 'start',
        });
    }, [])

    return (
        <SignUpContainer code={code} isMobile={isMobile && !isTablet} ref={containerRef} signUp>
            <StyledButton
                onClick={() => navigate('/login')}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: 0,
                    left: '10px',
                }}
            >
                Back to Login
            </StyledButton>
            {!plan ?
                <PriceWrapper>
                        <PlanSelector setPlan={setPlan} plan={Plans.monthly.individual} planKey='individual' />
                        <PlanSelector setPlan={setPlan} plan={Plans.monthly.pro} planKey='pro' />
                    <div>
                    </div>
                </PriceWrapper> :
                <MobileFormWrapper code={code} isMobile={isMobile && !isTablet}>
                    <FormWrapper isMobile={isMobile && !isTablet}>
                        <WhiteTextGlassLogo title="Market Reader logo" />
                        {!success &&
                            <>
                                {/* {code ?
                                    <>
                                        <Header>
                                            {PromoCodeCopy.title}
                                        </Header>
                                        <p style={{ textAlign: 'center' }}>
                                            {PromoCodeCopy.description}
                                        </p>
                                        <StyledList>
                                            {PromoCodeCopy.list.map((item, index) => (
                                                <li style={{ marginBottom: '8px '}} key={index}>
                                                    {item}
                                                </li>
                                            ))}
                                        </StyledList>
                                    </> : */}
                                    <Header>
                                        Sign Up To Start Your Free Trial
                                    </Header>
                                <Form onSubmit={handleSubmit}>
                                    <Input
                                        className="form"
                                        onChange={handleFirstNameChange}
                                        onClick={handleScroll}
                                        placeholder="First Name"
                                        type="text"
                                        value={firstName}
                                    />
                                    <Input
                                        className="form"
                                        onChange={handleLastNameChange}
                                        onClick={handleScroll}
                                        placeholder="Last Name"
                                        type="text"
                                        value={lastName}
                                    />
                                    <Input
                                        className="form"
                                        onChange={handleEmailChange}
                                        onClick={handleScroll}
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                    />
                                    <form>
                                        <StyledLabel style={{ marginTop: 0, color: 'darkgray' }}>
                                            <b>
                                                Have a promo code?  Enter it here:
                                            </b>
                                        </StyledLabel>
                                        <div style={{ display: 'flex' }}>
                                            <Input
                                                className='form'
                                                placeholder='Enter Promo Code'
                                                type='text'
                                                value={promoCode}
                                                onChange={handleChangePromoCode}
                                                style={{ margin: 0 }}
                                            />
                                            <StyledButton disabled={!promoCode} onClick={(e) => handleApplyPromo({ e })} style={{ margin: 0, marginLeft: 10 }}>
                                                Apply
                                            </StyledButton>
                                        </div>
                                        {(promoCode && promoCodeValid && promoMessage?.message) ?
                                            <SuccessMessage style={{ color: AppColors.MRGREEN, fontSize: 14, fontWeight: 'bold', marginTop: 5 }}>
                                                {promoMessage.message}
                                            </SuccessMessage> :
                                            (promoCode && !promoCodeValid && promoMessage?.message) ?
                                                <WarningText style={{ marginTop: '5px' }}>
                                                    {promoMessage.message}
                                                </WarningText> :
                                                null
                                        }
                                    </form>
                                    {error &&
                                        <WarningText>
                                            {errorMessage}
                                        </WarningText>
                                    }
                                    <SuccessMessage>
                                        By clicking sign up you are agreeing to MarketReader’s&nbsp;
                                        <StyledHyperLink
                                            className="underline"
                                            href="https://app.marketreader.com/MarketReader+TOS+-+Mar312023.pdf"
                                            rel='noreferrer'
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </StyledHyperLink>
                                        , as well as receiving product and marketing communications from MarketReader, Inc. You may unsubscribe from these communications at any time.
                                    </SuccessMessage>
                                    <SubmitButton disabled={disabled} type="submit">
                                        Sign Up
                                    </SubmitButton>
                                </Form>
                            </>
                        }
                        {success &&
                            <>
                                <Header><br />Verify Email</Header>
                                <SuccessMessage>
                                    Enter the 6-digit code sent to {email}
                                </SuccessMessage>
                                <SuccessMessage>
                                    Be sure to check your spam folder if you do not see the email in your inbox.
                                </SuccessMessage>
                                <Form onSubmit={handleVerify}>
                                    <Input
                                        className='form'
                                        type="text"
                                        value={token}
                                        onChange={handleChangeToken}
                                        placeholder='Paste the 6 digit code from the email you received.'
                                    />
                                    {error && <WarningText style={{ padding: 15 }}>{errorMessage}</WarningText>}
                                    <SubmitButton disabled={disabled} type="submit">Verify</SubmitButton>
                                </Form>
                                <StyledLink onClick={handleResend}>Resend Code</StyledLink>
                        </>
                        }
                    </FormWrapper>
                    <SuccessMessage>
                        Questions? Contact&nbsp;
                        <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
                    </SuccessMessage>
                </MobileFormWrapper>
            }
        </SignUpContainer>
    );
};

export default SignUpMonitor;
