const formatTimeToTimezone = (inputTime) => {
    // Extract time directly from the ISO string
    const timeComponent = inputTime.split('T')[1];
    const hours = parseInt(timeComponent.slice(0, 2));
    
    // Convert to 12-hour format
    const hours12 = hours % 12 || 12;
    const minutes = timeComponent.slice(3, 5);
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${hours12}:${minutes}${ampm}`;
};

export default formatTimeToTimezone;