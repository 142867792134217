const formatDollarVolume = (dollar) => {
    let dollarVolume;

    if (dollar < 1000) { 
        dollarVolume = dollar.toFixed(1);
    } else if (dollar < 1000000) { 
        dollarVolume = (dollar / 1000).toFixed(1) + 'k';
    } else if (dollar < 1000000000) {
        dollarVolume = (dollar / 1000000).toFixed(1) + 'M';
    } else {
        dollarVolume = (dollar / 1000000000).toFixed(1) + 'B';
    }

    return '$' + dollarVolume;

}; 

export default formatDollarVolume;
