import { useContext, useState } from "react";
import styled from 'styled-components';

import { AuthContext } from "../../../Controllers/context/auth";
import { CheckboxItem, CheckboxLabel, ConfirmationButton, Header, SubmitButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { ButtonContainer } from "../../../Global/StyledComponents/StockCardStyledComponents";
import { MarketReaderWatermark } from "../../../Global/Logos/Logos";
import supabase from "../../../Controllers/utils/supabaseClient";
import { StateContext } from "../../../Controllers/context/state";

const Container = styled.div`
    height: 331px;
    width: 600px;
    margin: 20px 20px 0px 20px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ManageSubscriptionPopUp = ({ setShowManageSubscriptionPopUp }) => {
    const [disabled, setDisabled] = useState(false);
    const [showAgain, setShowAgain] = useState(true);
    const { user } = useContext(AuthContext);
    const {
        setCustomerProfile,
    } = useContext(StateContext);

    const handleConfirm = (e) => {
        e.preventDefault();
        setDisabled(true);
        supabase.from('profiles').update({
            manage_subscription: showAgain,
        }).eq('id', user?.id).select('manage_subscription').then((res) => {
            if (res?.data?.length) {
                setShowManageSubscriptionPopUp(false);
            }
            setDisabled(false);
        });
        return;
    };

    const handleSelect = () => {
        setShowAgain(prevState => !prevState)
    };

    const getCustomerProfile = async () => {
        const profileRes = await supabase.from('profiles').select('*').eq('id', user.id);
        const { billing_email } = profileRes?.data?.[0];

        const profile = await supabase.functions.invoke('customerProfile', {
            body: {
                billingEmail: billing_email, 
                userEmail: user.email,
            },
        });
        setCustomerProfile(JSON.parse(profile?.data));
    }

    const manageSubscription = async () => {
        setDisabled(true);
        await supabase.functions.invoke('customerPortal', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            const { session } = JSON.parse(res.data);
            window.open(session.url, '_blank');
            setDisabled(false);

            setTimeout(() => {
                getCustomerProfile();
            }, 60000);
        });
    };

    return (
        <Container>
            <MarketReaderWatermark />
            <Header
                style={{ fontFamily: 'Poppins', fontSize: '36px', fontWeight: 600, lineHeight: '36px' }}
            >
                Your Free Trial Is Expiring Soon!
            </Header>
            <Header
                style={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, lineHeight: '20px' }}
            >
                Add a payment method to your account below to continue your subscription without interruption.
            </Header>
            <br />
            <SubmitButton disabled={disabled} onClick={manageSubscription} style={{ width: 'fit-content' }}>
                Add Payment Method
            </SubmitButton>
            <ButtonContainer style={{ right: -20 }}>
                <CheckboxItem
                    style={{ border: 'none', margin: '5px' }}
                    onClick={handleSelect}
                >
                    <input 
                        type="checkbox"
                        id='checkbox'
                        checked={!showAgain}
                    />
                    <CheckboxLabel>Don't Show Again</CheckboxLabel>
                </CheckboxItem>
                <ConfirmationButton disabled={disabled} style={{ padding: '0 20px' }} onClick={handleConfirm}>
                    OK
                </ConfirmationButton>
            </ButtonContainer>
        </Container>
    )
};

export default ManageSubscriptionPopUp;
