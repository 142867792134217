import React, { useContext } from 'react';
import Row from './components/Row';
import {
    TableContainer,
    TableHeader,
} from './styledComponents';
import { setSortTitle } from '../../../Controllers/utils';
import { StateContext } from '../../../Controllers/context/state';

const TableView = ({
    tickerList,
    watchlistCard = false,
    watchlistDashboard = false,
    watchlistItems,
}) => {
    const {
        sort,
        selectedWatchlist,
    } = useContext(StateContext);

    return (
        <TableContainer>
            <thead>
                <tr>
                    {watchlistCard && selectedWatchlist.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' &&
                        <TableHeader>
                            
                        </TableHeader>
                    }
                    <TableHeader>Symbol</TableHeader>
                    <TableHeader>Name</TableHeader>
                    {watchlistDashboard &&
                        <TableHeader>Watchlists</TableHeader>
                    }
                    <TableHeader>Country</TableHeader>
                    <TableHeader>Sector</TableHeader>
                    <TableHeader>Price</TableHeader>
                    <TableHeader>Return (Daily)</TableHeader>
                    <TableHeader>Percentile Return</TableHeader>
                    <TableHeader>
                        {sort.key === 'symbol' ?
                            <>
                                Symbol
                            </> :
                            setSortTitle(sort.correlationAsset, sort.key)
                        }
                    </TableHeader>
                    <TableHeader>Insights</TableHeader>
                </tr>
            </thead>
            <tbody>
                {tickerList?.map((ticker) => {
                    const item = watchlistItems?.filter(({ symbol }) => symbol === ticker)[0];

                    return (
                        <Row
                            key={ticker}
                            requestedTicker={ticker}
                            watchlistCard={watchlistCard}
                            watchlistDashboard={watchlistDashboard}
                            watchlistItem={item}
                        />
                    )
                })}
            </tbody>
        </TableContainer>
    );

}

export default TableView;
