import { useContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { CardContainer, IconBox } from '../../StyledComponents/StockCardStyledComponents';
import { CenteredDiv } from '../../StyledComponents/AppStyledComponents';
import { DeleteIcon } from '../../Icons/Icons';
import { StateContext } from '../../../Controllers/context/state';
import { StyledLabel } from '../../StyledComponents/UserFormStyledComponents';
import supabase from '../../../Controllers/utils/supabaseClient';

const SavedScreenerView = ({ handleSelectScreener }) => {
    const {
        fetchAllSavedScreeners,
        logError,
        savedScreeners,
        setShowConfirmationWindow,
    } = useContext(StateContext);

    const handleDeleteSavedScreener = (value) => {
        const { id, meta } = value;

        setShowConfirmationWindow({
            show: true,
            handleCancel: () => {setShowConfirmationWindow(false);},
            handleConfirm: () => {
                supabase.from('user_preferences').delete().eq('id', id).then((res) => {
                    if (res?.error) {
                        const error = new Error(res?.error?.message);
                        logError(error.message, undefined, error);
            
                        return;
                    }

                    fetchAllSavedScreeners({ retry: true });
                    setShowConfirmationWindow(false)
                });
            },
            headline: `Are you sure you want to delete ${meta.name}?`,
        });
    };

    return (
            <>
                <CenteredDiv style={{height: 'auto', padding: (!isMobile || isTablet) ? null : '20px' }}>
                    This is your saved screener page. You can select or delete saved screeners below or make filter and sort selections in the left panel to view stocks that match the selected criteria.
                </CenteredDiv>
                {
                    
                    savedScreeners?.filter(screener => screener.value)
                    .sort((a, b) => JSON.parse(a.value).meta.name.localeCompare(JSON.parse(b.value).meta.name))
                    .sort((a, b) => (
                        (JSON.parse(b.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') === (JSON.parse(a.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') ? 0 :
                        (JSON.parse(b.value).user_id === 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a') ? -1 : 1)
                    )
                    .map((screener) => {
                        const value = JSON.parse(screener.value);

                        return (
                            screener.value ?
                                <CardContainer
                                    key={screener.label}
                                    percentileMove='EPIS'
                                    style={{ height: '120px', cursor: 'pointer', position:'relative' }}
                                >
                                    <CenteredDiv onClick={() => handleSelectScreener(null, screener.value)}>
                                        <StyledLabel style={{ margin: 0 }}>
                                            {screener.label}
                                            <div style={{ borderBottom: '2px solid white' }}></div>
                                        </StyledLabel>
                                    </CenteredDiv>
                                    {value.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' && 
                                        <IconBox style={{ position: 'absolute', bottom: 0, right: 0 }} onClick={() => handleDeleteSavedScreener(value)}>
                                            <DeleteIcon />
                                        </IconBox> 
                                    }
                                </CardContainer> :
                                null
                            
                        );
                    })
                }
            </>
    )
};

export default SavedScreenerView;
