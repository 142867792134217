import { Link } from 'react-router-dom';
import { useState } from 'react';
import Chart from "../../AssetMonitor/components/Chart"
import { CTAButton } from "../../../Global/StyledComponents/AppStyledComponents";
import { AppColors } from "../../../Global/StyledComponents/Graphics";
import TVChartContainer from "../../../Global/Components/TradingViewChart";
import StockChartWrapper from '../../../Global/Components/StockChartWrapper/StockChartWrapper';
import getCurrentDate from '../../../Controllers/utils/getCurrentDate';

const ModalChartContainer = ({ assets, development, fullNameKey, session, setShowChart, symbol }) => {
    const [dateDisplay, setDateDisplay] = useState(new Date().toISOString().split('T')[0]);
    const [lastDate, setLastDate] = useState(Math.ceil(getCurrentDate().getTime() / 1000));
    const handleClose = () => {
        setShowChart(false);
    };

    const handleDateChange = (e) => {
		const newDate = e.target.value;
		setDateDisplay(newDate);
        setLastDate(new Date(newDate).getTime() / 1000);
	};

    return (
        <div style={{ padding: '45px', paddingBottom: 0, position: 'relative', height: '100%' }}>
            <StockChartWrapper
                assets={assets}
                symbol={symbol}
                lastDate={lastDate}
                dateDisplay={dateDisplay}
                handleDateChange={handleDateChange}
                selectedDate={lastDate}
            /> 
               {/* <TVChartContainer height='69vh' readOnly symbol={fullNameKey?.[symbol.toUpperCase()]} />  */}
               {/* <Chart session={session} ticker={symbol} time_utc={new Date().getTime()/1000}  /> */}
            <Link onClick={handleClose} style={{ top: -5, color: 'white', position: 'absolute', left: '0' }} to={`/ticker/${symbol}`}>
                <CTAButton style={{ background: AppColors.MRNAVY40, color: 'white' }}>
                    Open Asset Page
                </CTAButton>
            </Link>
        </div>
    )
};

export default ModalChartContainer;
