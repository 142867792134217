import { useContext, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import {
    AddIcon,
    AddToListIcon,
    CopyIcon,
    OpenArrowLeftIcon
} from '../../Global/Icons/Icons';
import AlertFeed from "../../Global/Views/FeedViewer/AlertFeed";
import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import FeedViewer from "../../Global/Views/FeedViewer/FeedViewer";
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import Pagination from '../../Global/Components/PaginationFooter';
import SortMenu from '../../Global/Components/SortMenu';
import { StateContext } from '../../Controllers/context/state';
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import { StockCardGrid, ViewModule } from "../../Global/StyledComponents/AppStyledComponents";
import { StyledButton, StyledLabel } from '../../Global/StyledComponents/UserFormStyledComponents';
import TableView from '../../Global/Views/TableView/TableView';
import ToggleSwitch from '../../Global/Components/ToggleSwitch';
import WatchlistMenu from './components/WatchlistMenu';
import WatchlistView from '../../Global/Views/WatchlistView/WatchlistView';
import SortBar from '../../Global/Components/SortBar';
import supabase from '../../Controllers/utils/supabaseClient';
import AddToWatchlistForm from '../../Global/Components/AddToWatchlistForm';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';

const WatchlistMonitor = () => {
    const [intervalId, setIntervalId] = useState(null);
    const intervalIdToCancel = useRef();
    intervalIdToCancel.current = intervalId;
    const limit = 50;
    const [paginatedList, setPaginatedList] = useState([]);
    const scrollRef = useRef();
    const [showCreateWatchlist, setShowCreateWatchlist] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [watchlistTickers, setWatchlistTickers] = useState([]);

    const {
        currentWatchlistPage,
        customerProfile,
        development,
        expandedViewTicker,
        logError,
        newAlerts,
        offSet,
        refreshRate,
        selectedWatchlist,
        setCardData,
        setCurrentWatchlistPage,
        setLoadingExport,
        setOffset,
        setShowAddToWatchlistPopup,
        setShowCopyWatchlistPopup,
        setShowErrorWindow,
        setShowExportPopUp,
        setSelectedWatchlist,
        setSort,
        setSortWatchlistsByReturn,
        setVisibleCards,
        setShowWatchlistWindow,
        showExpandedView,
        sort,
        sortWatchlistsByReturn,
        viewStyle,
    } = useContext(StateContext);

    useEffect(() => {
        setPaginatedList(watchlistTickers?.slice(offSet, (offSet + limit)))
    }, [offSet]);

    useEffect(() => {
        setTotalPages(Math.ceil(watchlistTickers?.length / limit));
    }, [watchlistTickers]);

    const handleSelectWatchList = (e, value) => {
        const watchlist = value ?? e.target.value;
        if (watchlist === 'All Watchlists') {
            setSelectedWatchlist(null);
            setWatchlistTickers([]);
            setPaginatedList([]);

        } else {
            const parsedWatchlist = JSON.parse(watchlist);
            setSelectedWatchlist(parsedWatchlist);
            setCurrentWatchlistPage(1);
        }
    };

    const handleSortWatchList = ({ retry = true }) => {
        if (selectedWatchlist) {
            const { id } = selectedWatchlist;
            supabase.rpc('getfilteredsortedlist', { request : {
                watchlist: id,
                sort,
            }}).then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);
    
                    if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                        handleSortWatchList({ retry: false });

                        return;
                    }

                    if (res?.error?.message !== 'FetchError: Failed to fetch') {
                        setShowErrorWindow(true);
                    }
        
                    return;
                }

                const { entities } = res?.data?.[0]?.symbol;
                setWatchlistTickers(entities);
                setPaginatedList(entities?.slice(offSet, (offSet + limit)));
            });
        }
    };

    const handleRefresh = () => {
       handleSortWatchList({ retry: true });
    };

    const handleShowForm = () => {
        setShowWatchlistWindow(prevState => !prevState);
    };

    useEffect(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        if (refreshRate) {
            const intervalId = setInterval(handleRefresh, refreshRate);
            setIntervalId(intervalId);
        }
    }, [refreshRate]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdToCancel.current);
        };
    }, []);

    useEffect(() => {
        handleSortWatchList({ retry: true });
    }, [sort, selectedWatchlist]);

    const firstPage = () => {
        setCurrentWatchlistPage(1);
        setOffset(0);
        scrollRef?.current.scrollTo(0, 0);
    };

    const lastPage = () => {
        setCurrentWatchlistPage(totalPages);
        setOffset((limit * totalPages) - limit);
        scrollRef?.current.scrollTo(0, 0);
    };

    const nextPage = () => {
        setCurrentWatchlistPage(prevState => prevState += 1);
        setOffset(prevState => prevState += limit);
        scrollRef?.current.scrollTo(0, 0);
    };

    const prevPage = () => {
        setCurrentWatchlistPage(prevState => prevState -= 1);
        setOffset(prevState => prevState -= limit);
        scrollRef?.current.scrollTo(0, 0);
    };

    const handleAddToWatchList = (e) => {
        setShowAddToWatchlistPopup(true);
    };

    const handleCopyWatchList = () => {
        setShowCopyWatchlistPopup(true);
    };

    const handleExportData = () => {
        setVisibleCards({});
        setLoadingExport(true);
        watchlistTickers.forEach(async (ticker, index) => {
            const res = await supabase.rpc('card_data', {
                _symbol: ticker,
                _corr_symbol: sort.correlationAsset || 'SPY', 
            });

            setCardData(prevState => ({
                ...prevState,
                [ticker]: res.data[0],
            }));
            setVisibleCards(prevState => ({
                ...prevState,
                [ticker]: ticker,
            }));

            if (index === watchlistTickers.length - 1) {
                setLoadingExport(false);
            }
        });

        setShowExportPopUp(true);
    };

    return (
        <>
            <Drawer>
                <WatchlistMenu
                    label='Your Watchlists'
                    handleSelectWatchList={handleSelectWatchList}
                    title='My Watchlists'
                />
            </Drawer>

            <ViewModule>
                {selectedWatchlist ?
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', justifyContent: 'center', padding: '0px 20px', paddingBottom: '2px', position: 'relative' }}>
                        {selectedWatchlist &&
                            <h1 style={{ textAlign: 'center' }}>
                                {selectedWatchlist.meta.name}
                            </h1>
                        }
                        {selectedWatchlist.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' ?
                            <div style={{ display: 'flex', margin: '5px auto', position: (!isMobile || isTablet) ? 'absolute' : 'relative', right: (!isMobile || isTablet) ? 10 : null }}>
                                {customerProfile?.accessLevels?.includes('pro') &&
                                    <StyledButton
                                        onClick={handleCopyWatchList}
                                        style={{ margin: 0 }}
                                >
                                        Copy Watchlist <CopyIcon/>
                                    </StyledButton>
                                }
                                <StyledButton
                                    onClick={handleAddToWatchList}
                                    style={{ margin: 0, marginLeft: 10 }}
                                >
                                    Add to Watchlist <AddIcon/>
                                </StyledButton>
                                {(!isMobile || isTablet) && customerProfile?.accessLevels?.includes('pro') &&
                                    <ExportToCsv handleExportData={handleExportData}/>
                                }
                            </div> :
                                <div style={{ display: 'flex', margin: '5px auto', position: (!isMobile || isTablet) ? 'absolute' : 'relative', right: (!isMobile || isTablet) ? 10 : null }}>
                                    {customerProfile?.accessLevels?.includes('pro') &&
                                        <StyledButton
                                            onClick={handleCopyWatchList}
                                            style={{ margin: 0 }}
                                    >
                                            Copy Watchlist <CopyIcon />
                                        </StyledButton>
                                    }
                                    {(!isMobile || isTablet) && customerProfile?.accessLevels?.includes('pro') &&
                                        <ExportToCsv handleExportData={handleExportData} />
                                    }
                                </div>
                        }
                        <div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
                            <StyledButton
                                onClick={() => setSelectedWatchlist(null)}
                                style={{ margin: '0 auto', marginBottom: (!isMobile || isTablet) ? 0 : 10, width: 'fit-content' }}
                            >
                                <OpenArrowLeftIcon style={{ marginLeft: 0 }} />
                            </StyledButton>
                            <SortBar firstPage={firstPage} />
                        </div>
                    </div> :
                    <div
                        style={{ display: 'flex', padding: (!isMobile || isTablet) ? null : '0px 20px', paddingBottom: '2px', position: 'relative' }}
                    >
                        <StyledLabel style={{ margin: 0, marginRight: 10 }}>
                            Sort By Average Return:
                        </StyledLabel>
                        <ToggleSwitch
                            handleClick={() => setSortWatchlistsByReturn(prevState => !prevState)}
                            isOn={sortWatchlistsByReturn}
                            onOffSwitch
                        />
                    </div>
                }
                <div style={{ height: 'calc(100% - 110px)' }}>
                    {selectedWatchlist ?
                            <StockCardGridComponent ref={scrollRef}>
                                {viewStyle?.data?.showCards ?
                                    <StockCardView tickerList={paginatedList} watchlistCard /> :
                                    <TableView tickerList={paginatedList} watchlistCard />
                                }
                            </StockCardGridComponent> :
                            <StockCardGrid ref={scrollRef}>
                                <WatchlistView handleSelectWatchList={handleSelectWatchList} />
                            </StockCardGrid>
                    }
                    {!selectedWatchlist &&
                        <div style={{ width: '100%' }}>
                                <StyledButton onClick={handleShowForm}>
                                    Add New Watchlist <AddToListIcon />
                                </StyledButton>
                        </div>
                    }
                </div>
                {totalPages > 1 && selectedWatchlist ?
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            currentPage={currentWatchlistPage}
                            totalPages={totalPages}
                            firstPage={firstPage}
                            lastPage={lastPage}
                            nextPage={nextPage}
                            prevPage={prevPage}
                        />
                    </div> :
                    null
                }
            </ViewModule>

            <Drawer open={showExpandedView} rotate={'true'} >
                <FeedViewer tickerList={selectedWatchlist?.data} title='News Feed' />
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <ExpandedView title={`${expandedViewTicker} Summary`}/>
                <AlertFeed id='alerts' number={newAlerts} title={'Watchlist Alerts'}/>
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    )
    }
export default WatchlistMonitor;


