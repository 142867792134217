import styled from 'styled-components';
import { AppColors, CardColors } from './Graphics';
import hexToRgba from '../../Controllers/utils/hexToRgba';

export const BottomRow = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	bottom: -1px;
`;

export const CardContainer = styled.div`
	background: ${AppColors.MRNAVY15};
	display: flex;
	height: ${({ cardDimensions, watchlistDashboard }) =>
		watchlistDashboard
			? '240px'
			: cardDimensions?.dimensionMultiplier > 1
			? `${cardDimensions?.dimensionMultiplier * 215}px`
			: cardDimensions?.state === -1
			? '205px'
			: cardDimensions?.state === -2
			? '190px'
			: cardDimensions?.state === -3
			? '170px'
			: cardDimensions?.state === -4
			? '80px'
			: '215px'};
	margin: 10px;
	color: ${AppColors.MRWHITE};
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	border: 1px solid ${AppColors.MRNAVY40};
	width: ${({ cardDimensions }) =>
		cardDimensions?.dimensionMultiplier > 1
			? `${cardDimensions?.dimensionMultiplier * 322.5}px`
			: cardDimensions?.state < 0
			? '300px'
			: '322.5px'};
`;

export const ButtonContainer = styled(CardContainer)`
	bottom: 0;
	height: auto;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	width: fit-content;
`;

export const CardWrapper = styled.div`
	content: 'Stock Card';
	position: relative;
	width: 100%;
	height: 100%;
`;

export const CompanyName = styled.div`
	float: left;
	width: 210px;
	height: 18px;
	clear: both;
	text-align: left;
	padding-bottom: 3px;

	h2 {
		text-align: left;
		font-size: 14px;
		line-height: 20px;
		margin: 0px;
		font-family: 'Mulish', Arial, sans-serif;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: keep-all;
	}
`;

export const CompanySector = styled.div`
	float: left;
	width: calc(85%);
	height: 18px;
	clear: both;
	text-align: left;
	padding-bottom: 3px;

	h2 {
		text-align: left;
		font-size: 10px;
		line-height: 20px;
		font-weight: 400;
		margin: 0px;
		font-family: 'Mulish', Arial, sans-serif;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: keep-all;
	}
`;

export const Description = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	padding: 5px;
	margin: 0px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	display: ${(props) => (props.expanded ? 'block' : 'none')};
	overflow: hidden;
	border: 1px solid ${AppColors.MRNAVY40};
`;

export const DisplayInlineHalfLT = styled.h4`
	display: inline-grid;
	margin: 0;
	padding: 6px 3%;
	width: ${({ space }) => (space ? [space] : 'fit-content')};
	min-width: 105px;
	border-radius: 0px 7px 0px 7px;
	background: ${(props) => CardColors[props.percentileMove]};
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	line-height: 14px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.02em;
	color: ${AppColors.MRWHITE};
`;

export const DisplayInlineHalfLTop = styled(DisplayInlineHalfLT)`
	border-radius: 0 0 7px 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DisplayInlineHalfRT = styled.h4`
	display: inline-grid;
	margin: 0;
	padding: 6px 3%;
	width: ${({ space }) => (space ? [space] : 'fit-content')};
	color: ${(props) => AppColors[props.moveColor]};
	font-family: Arial, Helvetica, sans-serif;
	font-size: ${({ table }) => (table ? '14px' : '11px')};
	line-height: 14px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.02em;
`;

export const ExplanationRow = styled(BottomRow)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	height: auto;
	overflow: hidden;
	width: 100%;
	gap: 10px;
`;

export const ExplanationTag = styled.div`
	line-height: 20px;
	background: ${AppColors.NEUTRAL};
	width: auto;
	height: 20px;
	border-radius: 10px;
	font-size: 11px;
	padding: 0px 10px;
	text-transform: uppercase;
	text-align: center;
	color: ${AppColors.MRLITE90};
	font-family: Mulish, sans-serif;
	letter-spacing: 0.03em;
	cursor: default;
`;

export const FlexInteractionRow = styled(BottomRow)`
	width: 30%;
	align-items: center;
	justify-content: end;
`;

export const FullText = styled.div`
	display: block;
	grid-column: span 5;
	grid-row: 1;

	h1 {
		font-size: 16px;
		text-align: left;
		line-height: 28px;
		font-family: 'Poppins', Arial, Helvetica, sans-serif;
		font-weight: 800;
		letter-spacing: 0px;
		text-transform: uppercase;
	}
	h2 {
		font-size: 16px;
		text-align: left;
		line-height: 28px;
		font-family: 'Poppins', Arial, Helvetica, sans-serif;
		font-weight: 700;
		letter-spacing: 0px;
		text-transform: uppercase;
	}

	p {
		padding: 10px 0px;
		font-size: 16px;
		text-align: left;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: 400;
		letter-spacing: 0px;
	}
`;

export const Headline = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	padding: 0px;
	margin: 0px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	word-break: keep-all;
	overflow: hidden;
	display: block;
	max-height: 97px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&&::-webkit-scrollbar {
		display: none;
	}
`;

export const HeadlineWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: 280px;
`;

export const IconBox = styled.div`
	background: ${AppColors.MRGREY};
	border-radius: 7px 0px 7px 0px;
	cursor: pointer;
	height: ${(props) => (props.cardDimensions?.state > -2 ? '40px' : '26px')};
	width: ${(props) => props.width || '50px'};
	display: inline-grid;
	justify-content: center;
	align-items: center;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export const MetricRow = styled(BottomRow)`
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	justify-content: space-between;
	margin-top: 5px;
	position: ${({ cardDimensions }) =>
		cardDimensions ? 'absolute' : 'relative'};
	bottom: 0;
`;

export const MiddleRow = styled.div`
	height: 85px;
	padding: 10px;
`;

export const MoveClassificationTag = styled(ExplanationTag)`
	background: ${(props) => CardColors[props.tagColor]};
	cursor: context-menu;
`;

export const MoveClassRow = styled(BottomRow)`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	height: auto;
	width: 100%;
	gap: 10px;
`;

export const SparklineRow = styled(MiddleRow)`
    padding: 0px !important;
    height: 50px;
`;

export const SparklineWrapper = styled.div`
	shape-outside: margin-box;
	float: right;
	width: ${(props) => (props.summary ? '40%' : '100%')};
	padding: 5px;
	margin-left: 7px;
	margin-bottom: 7px;
	height: ${(props) => (props.summary ? '93px' : '275px')};
	border: 1px solid ${AppColors.MRNAVY40};
	border-radius: 5px;
	display: flex;
	align-items: center;
`;

export const StockInfoContainer = styled.div`
	width: 100%;
	padding-top: 3px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: ${({ moveColor }) => AppColors[moveColor]};

	h3 {
		color: ${({ moveColor }) => AppColors[moveColor]};
	}
`;

export const StockInfoTextBox = styled.h3`
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	margin: 0px;
	padding: 0 2px;
	color: ${({ moveColor }) => (moveColor ? AppColors[moveColor] : 'inherit')};
`;

export const StockInfoTextBoxCenter = styled(StockInfoTextBox)`
	text-align: center;
`;

export const StockInfoTextBoxLeft = styled(StockInfoTextBox)`
	width: fit-content;
	text-align: left;
	text-transform: uppercase;
	color: ${(props) => (props.showWhite ? 'white' : 'inherit')} !important;
`;

export const StockInfoTextBoxRight = styled(StockInfoTextBox)`
	text-align: right;
	float: right;
`;

export const Summary = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	padding: 0px;
	margin: 0px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	word-break: keep-all;
	display: block;
	max-height: 275px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&&::-webkit-scrollbar {
		display: none;
	}
`;

export const SummaryRow = styled(MiddleRow)`
    display: block;
    width: 100%;
    white-space: normal;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    height: auto;
    position: relative;
`;

export const Timestamp = styled.p`
	text-align: left;
	padding-top: 2px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	line-height: 16px;
	color: ${AppColors.MRWHITE};
	float: left;
`;

export const TopRow = styled.div`
	color: ${AppColors.MRWHITE};
	cursor: pointer;
	position: relative;
	width: 100%;
	height: auto;
	padding: 6px 10px 0px 10px;
	overflow: hidden;
`;
