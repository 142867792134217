import styled from "styled-components";
import { AppColors } from "../StyledComponents/Graphics";

const PaginationContainer = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const PaginationItem = styled.li`
  margin: auto 0;
  padding: 0;
  text-align: center;
  line-height: 45px;
  color: ${AppColors.MRWHITE};
`;

const PaginationLink = styled.button`
  background-color: transparent;
  border: none;
  color: ${AppColors.MRWHITE};
  cursor: pointer;
  margin: 0 5px;
  padding: 5px 7px;
  text-decoration: none;
  font-size: 25px;
  line-height: 45px;

  &:hover {
    text-decoration: underline;
  }
`;

const FirstPageButton = ({ onClick }) => (
  <PaginationItem>
    <PaginationLink onClick={onClick}>&laquo;&laquo;</PaginationLink>
  </PaginationItem>
);

const PrevButton = ({ onClick }) => (
  <PaginationItem>
    <PaginationLink onClick={onClick}>&laquo;</PaginationLink>
  </PaginationItem>
);

const NextButton = ({ onClick }) => (
  <PaginationItem>
    <PaginationLink onClick={onClick}>&raquo;</PaginationLink>
  </PaginationItem>
);

const LastPageButton = ({ onClick }) => (
  <PaginationItem>
    <PaginationLink onClick={onClick}>&raquo;&raquo;</PaginationLink>
  </PaginationItem>
);

const Pagination = ({ currentPage, totalPages, firstPage, lastPage, nextPage, prevPage }) => {

  const renderPageNumbers = () => {

    return (
      <PaginationItem>
        {`${currentPage}/${totalPages}`}
      </PaginationItem>
    );
  };

  const renderFirstPageButton = () => {
    if (currentPage > 1) {
      return <FirstPageButton onClick={firstPage} />;
    }

    return null;
  };

  const renderPrevButton = () => {
    if (currentPage > 1) {
      return <PrevButton onClick={prevPage} />;
    }

    return null;
  };

  const renderNextButton = () => {
    if (currentPage < totalPages) {
      return <NextButton onClick={nextPage} />;
    }

    return null;
  };

  const renderLastPageButton = () => {
    if (currentPage < totalPages) {
      return <LastPageButton onClick={lastPage} />;
    }

    return null;
  };

  return (
    <PaginationContainer>
      {renderFirstPageButton()}
      {renderPrevButton()}
      {renderPageNumbers()}
      {renderNextButton()}
      {renderLastPageButton()}
    </PaginationContainer>
  );
};

export default Pagination;
