import { GreenOrangeBar, NavyGreenBar } from "../../StyledComponents/Graphics";
import { TrackingModuleContainer } from '../../StyledComponents/AppStyledComponents';
import NavigationMenu from '../NavigationMenu/NavigationMenu';

const MobileTrackingModule = () => {

    return (
        <TrackingModuleContainer >
            <NavyGreenBar style={{ padding: 0 }}>
                <NavigationMenu />
            </NavyGreenBar>
        </TrackingModuleContainer>

    );
};
export default MobileTrackingModule;