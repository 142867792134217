import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import supabase from '../../Controllers/utils/supabaseClient';
import {
    SubmitButton,
    Container,
    FormWrapper,
    Form,
    Header,
    Input,
    WarningText,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { WhiteTextGlassLogo } from "../../Global/Logos/Logos";

const OTPVerificationMonitor = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const userEmail = searchParams.get('email');
    const [email, setEmail] = useState(userEmail);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const disabled = !email || !token;

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const handleChangeToken = (e) => {
        setToken(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { data, error } = await supabase.auth.verifyOtp({ email: email, token: token, type: 'email'});

        if (data?.user?.role === 'authenticated') {
            navigate('/setPassword');
        }

        if (error) {
            setError(true);
            setErrorMessage(error.message);
        }
    };

    return (
        <Container style={{ height: '100vh' }}>
            <FormWrapper>
                <WhiteTextGlassLogo title="Market Reader logo" />
                <Header><br />Verify Email</Header>
                <Form onSubmit={handleSubmit}>
                    <Input
                        className='form'
                        type="text"
                        value={email}
                        onChange={handleChangeEmail}
                        placeholder='Your Email'
                    />
                    <Input
                        className='form'
                        type="text"
                        value={token}
                        onChange={handleChangeToken}
                        placeholder='Paste the 6 digit code from the email you received.'
                    />
                    {error && <WarningText style={{ padding: 15 }}>{errorMessage}</WarningText>}
                    <SubmitButton disabled={disabled} type="submit">Verify</SubmitButton>
                </Form>
            </FormWrapper>
        </Container>
    );
};

export default OTPVerificationMonitor;
