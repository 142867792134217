import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { AppColors } from '../../StyledComponents/Graphics';

export const DropdownMenu = styled.div`
    position: absolute;
    top: calc(100%);
    z-index: 9999;
    width: fit-content;
    min-width: ${(!isMobile || isTablet) ? '300px' : '250px'};
    background-color: rgba(13, 34, 51, .8);
    border: 1px solid ${AppColors.MRNAVY40};
    padding: 10px;
    right: ${props => props.left ? null : '-2px'};
    left: ${props => props.left ? '-2px' : null};
`;

export const DropdownProfileMenu = styled.div`
    position: absolute;
    top: calc(100%);
    left: -2px;
    z-index: 9999;
    width: fit-content;
    height: auto;
    background-color: rgba(13, 34, 51, .8);
    border: 1px solid ${AppColors.MRNAVY40};
    padding: 10px;

`;

export const NavigationContainer = styled.div`
    width: ${(!isMobile || isTablet) ? null : '100%'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${AppColors.MRNAVY20};

    a.active {
        font-weight: 600;
        font-style: bold;
        background-color: ${AppColors.MRNAVY40};
    }
    ul {
        margin: 0px;
        padding: 0px;
    }

    li {
        display: inline;
        list-style-type: none;
    }

    li a {
        font-size: 14px;
        font-weight: 500;
    }

    .dropdown-toggle {
        cursor: context-menu;
    }

    .dropdown-toggle:hover, .hoverButton:hover{
        background-color: ${AppColors.MRNAVY20};
        color: ${AppColors.MRLITE80};
    }

    .dropdown-item {
        display: block;
        padding: 10px;
        height: auto;
        text-decoration: none;
        color: ${AppColors.MRWHITE};
        background-color: ${AppColors.MRNAVY18};
        border: 1px solid  ${AppColors.MRNAVY40};
        border-bottom: none;
        width: 100%;
        white-space: nowrap;
    }

    .dropdown-item:hover {
        background-color: ${AppColors.MRNAVY50};
    }

    .dropdown-close {
        display: block;
        border-top: 1px solid  ${AppColors.MRNAVY40};
        padding: 10px;
        height: 40px;
        width: 100%;
    }
`;

export const NavivationItem = styled.div`
    width: ${(!isMobile || isTablet) ? null : '100%'};
    height: ${(!isMobile || isTablet) ? '56px' : '36px'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    background-color: transparent;
    border: none;
    border-left: 2px solid ${AppColors.MRNAVY20};
    color: ${AppColors.MRNAVY18};
    padding-inline: 20px;
    position: relative;

    :hover {
        background-color: transparent;
        svg {
            fill: white;
        }
        border-left: 2px solid ${AppColors.MRNAVY20};
    }

    ::placeholder { 
        color: ${AppColors.MRNAVY20};
    }

    input {
        color: ${AppColors.MRNAVY20};
    }

    input::placeholder { 
        color: ${AppColors.MRNAVY20};
    }
`;
