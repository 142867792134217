import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { Container, StyledButton } from "../../Global/StyledComponents/UserFormStyledComponents";
import QuestionnaireForm from "../../Global/Components/QuestionnaireForm";
import { RegulatoryQuestions } from "../../Global/constants";
import supabase from "../../Controllers/utils/supabaseClient";

const RegulatoryMonitor = ({
    getUser,
    setIsAuthenticated,
    setUser,
    user
}) => {
    const navigate = useNavigate()

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            setIsAuthenticated(false);
            setUser({});
            navigate('/login');
        });
    };

    const handleSubmit = (data) => {
        supabase.from('profiles').update({
            regulatoryReport: data
        }).eq('id', user.id).then((res) => {
            getUser();
            // navigate('/selectPlan');
        })
    };

    return (
        <Container isMobile={isMobile && !isTablet}>
            <StyledButton
                onClick={handleLogout}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: '10px',
                    right: '20px',
                }}
            >
                Logout
            </StyledButton>
            <QuestionnaireForm
                description={RegulatoryQuestions.description}
                handleSubmit={handleSubmit}
                header={RegulatoryQuestions.header}
                questions={RegulatoryQuestions.questions}
                title={RegulatoryQuestions.title}
            />
        </Container>
    );
};

export default RegulatoryMonitor;
