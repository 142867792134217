import { useContext } from "react";
import { SettingsIcon } from "../../../Icons/Icons";
import { CheckboxItem, CheckboxLabel } from "../../../StyledComponents/UserFormStyledComponents";
import DimensionSlider from "../../DimensionSlider";
import ViewSwitch from "../../ViewSwitch";
import { DropdownMenu, NavivationItem } from "../StyledComponents";
import { StateContext } from "../../../../Controllers/context/state";
import supabase from "../../../../Controllers/utils/supabaseClient";

const SettingsMenu = ({
    handleMouseLeave,
    handleToggleSettingsMenu,
    settingsMenuOpen,
    user,
}) => {
    const {
        logError,
        showWelcomeWindow,
        setShowWelcomeWindow,
    } = useContext(StateContext);

    const handleChange = () => {
        supabase.from('profiles').update({
            welcome_page: !showWelcomeWindow,
        }).eq('id', user?.id).select('welcome_page').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                return;
            }

            setShowWelcomeWindow(prevState => !prevState);
        });
        return;
    }
    return (
        <NavivationItem
        className="hoverButton"
        id='profileMenu'
        onClick={handleToggleSettingsMenu}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'pointer' }}
    >
        <SettingsIcon />
        {settingsMenuOpen &&
            <DropdownMenu
                onMouseLeave={handleMouseLeave}
            >
                <ul>
                    <li className='dropdown-item'>
                        <DimensionSlider />
                    </li>
                    <li className='dropdown-item'>
                        <ViewSwitch />
                    </li>
                    <li>
                        <CheckboxItem onClick={handleChange}>
                            <input 
                                type="checkbox"
                                id='checkbox'
                                checked={showWelcomeWindow}
                            />
                            <CheckboxLabel>
                                Show Welcome Window
                            </CheckboxLabel>
                        </CheckboxItem>

                    </li>
                </ul>

            </DropdownMenu>    
        }
    </NavivationItem>
    );
};

export default SettingsMenu;
