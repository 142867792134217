import { useContext, useEffect, useState } from 'react';
import styled from'styled-components';
import moment from'moment';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import { AppColors } from '../../Global/StyledComponents/Graphics';
import { DataOutputContainer, ViewModule } from '../../Global/StyledComponents/AppStyledComponents';
import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import { filterFeedDuplicates, formatReturn, formatTime, round } from '../../Controllers/utils';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import { StateContext } from '../../Controllers/context/state';
import supabase from '../../Controllers/utils/supabaseClient';
import { Timestamp } from '../../Global/Views/FeedViewer/components/NewsFeed';

const FeedContainer = styled(DataOutputContainer)`
    border: 1px solid ${() => AppColors.MRNAVY40};
    height: 100%;
`;

const StyledBold = styled.b`
    color: ${(props) => AppColors[props.moveColor]};
`;

const NewsFeedMonitor = () => {
    const [feed, setFeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const {
        development,
        expandedViewTicker,
        logError,
        newAlerts,
        setExpandedViewTicker,
        setShowExpandedView,
        showExpandedView,
    } = useContext(StateContext);


    const setMoveColor = (data) => {
        let moveColor;
    
        if (round(data.date_return_oc * 100, 1) > 0 ) {
            moveColor ='MRGREEN';
        } else if (round(data.date_return_oc * 100, 1) < 0){
            moveColor ='MRRED';
        } else if (round(data.date_return_oc * 100, 1) === 0 ){
            moveColor ='MRLITE80';
        }

        return moveColor;
    };

    const handleOpenSummary = (symbol) => {
        setExpandedViewTicker(symbol);
        setShowExpandedView(true);
    };

    const getSummaries = ({ retry = true }) => {
        supabase.from('get_summaries').select('*').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    getSummaries({ retry: false });
                }
    
                return;
            }
            const filtered = filterFeedDuplicates(res?.data
                .filter((item) => item.aggr === '10min')
                .filter((item) => item.type !== 'ETP')
                .sort((a, b) => new Date(b.insert_timestamp) - new Date(a.insert_timestamp))
            );
            setFeed(filtered);
            setLoading(false);
        });
    };

    useEffect(() => {
        getSummaries({ retry: true });
        const intervalId = setInterval(() => getSummaries({ retry: true }), 60000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    const filterBreakingNews  = (feed) => {
        const filtered = filterFeedDuplicates(feed
            .filter((item) => item.aggr === '10min')
            .filter((item) => item.type !== 'ETP')
            .sort((a, b) => new Date(b.insert_timestamp) - new Date(a.insert_timestamp))
        );

        return filtered;
    };

    return (
        <>
            <ViewModule>
                {loading ?
                    <Loading /> :
                    <FeedContainer>
                        {
                            filterBreakingNews(feed).map((item, index) => {
                                    return (
                                        <>
                                            <div key={item + index} onClick={() => handleOpenSummary(item.symbol)} style={{ cursor: 'pointer', display: 'flex', borderBottom: `1px solid ${AppColors.MRGREY}` }}>
                                                <p style={{ margin: '5px', fontSize: '16px' }}>
                                                    <StyledBold moveColor={setMoveColor(item)}>{item.symbol} {formatReturn(item.date_return_oc)}</StyledBold>
                                                    <b>
                                                        &nbsp;[{formatTime(item.insert_timestamp)}]:&nbsp;
                                                    </b>
                                                    {item.headline}&nbsp;
                                                    <Timestamp>
                                                        ({moment(item.insert_timestamp).fromNow()})
                                                    </Timestamp>
                                                </p>
                                            </div>
                                        </>
                                    )
                                })
                        }
                    </FeedContainer>
                }
            </ViewModule>
            <Drawer open={showExpandedView} rotate={'true'} >
                <ExpandedView title={`${expandedViewTicker} Summary`} />
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                {/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts' /> */}
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    );
};

export default NewsFeedMonitor;