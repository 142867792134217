import { useContext } from 'react';
import { StateContext } from '../../Controllers/context/state';
import supabase from '../../Controllers/utils/supabaseClient';
import ToggleSwitch from './ToggleSwitch';
import { AuthContext } from '../../Controllers/context/auth';

const ViewSwitch = () => {
    const { user } = useContext(AuthContext);
    const {
        logError,
        setViewStyle,
        viewStyle,
    } = useContext(StateContext);

    const toggle = ({ retry = true }) => {
        setViewStyle(prevState => {
            supabase.from('user_preferences').upsert({
                ...viewStyle,
                data: {
                    showCards: !prevState?.data?.showCards
                },
                type: 'viewStyle',
                user_id: user?.id,
            }).eq('type', 'viewStyle').then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);

                    if (retry) {
                        toggle({ retry: false });
                    }
                }
            });

            return {
                ...viewStyle,
                data: {
                    showCards: !prevState?.data?.showCards,
                },
            };
        });
    };

    return (
        <ToggleSwitch
            handleClick={() => toggle({ retry: true })}
            isOn={viewStyle?.data?.showCards}
            offLabel={'Table'}
            onLabel={'Cards'}
        />
    );
};

export default ViewSwitch;
