import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AuthContext } from "../../../Controllers/context/auth";
import { CheckboxItem, CheckboxLabel, ConfirmationButton, Header } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { ButtonContainer } from "../../../Global/StyledComponents/StockCardStyledComponents";
import { MarketReaderWatermark } from "../../../Global/Logos/Logos";
import SearchDropdown from "../../../Global/Components/SearchDropdown";
import supabase from "../../../Controllers/utils/supabaseClient";

const Container = styled.div`
    height: 555px;
    width: 995px;
    margin: 20px 20px 0px 20px;
    position: relative;
    text-align: center;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
   
    width: 100%;
    height: 50%;
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const WelcomeWindow = ({ assets, setShowWelcomeWindow }) => {
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [showAgain, setShowAgain] = useState(true);
    const { user } = useContext(AuthContext);

    const handleConfirm = () => {
        setDisabled(true);
        supabase.from('profiles').update({
            welcome_page: showAgain,
        }).eq('id', user?.id).select('welcome_page').then((res) => {
            if (res?.data?.length) {
                setShowWelcomeWindow(false);
            }
            setDisabled(false);
        });
        return;
    };

    const handleSearchSubmit = (value) => {
        navigate(`/ticker/${value}`);
        setShowWelcomeWindow(false);
    };

    const handleSelect = () => {
        setShowAgain(prevState => !prevState)
    };
    
    const handleUnusualMoves = () => {
        navigate('/unusualMoves');
        setShowWelcomeWindow(false);
    }

    const handleEtf = () => {
        navigate('/etf');
        setShowWelcomeWindow(false);
    };

    const handleWatchlists = () => {
        navigate('/watchlists');
        setShowWelcomeWindow(false);
    };

    return (
        <Container>
            <MarketReaderWatermark />
            <Header style={{ fontFamily: 'Poppins', fontSize: '46px', fontWeight: 600, lineHeight: '46px' }}>Welcome To MarketReader</Header>
            <br />
            <Header style={{ fontSize: '26px', lineHeight: '26px' }}>
                Choose an option below to get started, or&nbsp;
                <a
                    href='https://21404769.fs1.hubspotusercontent-na1.net/hubfs/21404769/Videos/Demo%20Videos/1.11.24%20MarketReader%20Intro%20Video.mp4'
                    style={{ fontSize: '26px', cursor: 'pointer' }}
                    target='_blank'
                    rel='noreferrer'
                >
                    watch a 2-minute demo video.
                </a>
            </Header>
            <GridContainer>
                <ItemWrapper onClick={handleUnusualMoves}>
                    <div style={{ width: '50px', height: '50px' }}>
                        <img src='./img/UnusualMovesIcon.svg' />
                    </div>
                    <Header>See Today's Most Unusual Moves {'>'}</Header>
                </ItemWrapper>
                <ItemWrapper style={{ cursor: 'default' }}>
                    <div style={{ width: '50px', height: '50px' }}>
                        <img src='./img/SearchIcon.svg' />
                    </div>
                    <br />
                    <div style={{ width: '75%' }}>
                        <SearchDropdown
                            handleSubmit={handleSearchSubmit}
                            options={assets}
                            position='left'
                        />
                    </div>
                </ItemWrapper>
                <ItemWrapper onClick={handleEtf}>
                    <div style={{ width: '50px', height: '50px' }}>
                        <img src='./img/GlobeIcon.svg' />
                    </div>
                    <Header>Explore the Macro ETF Monitor {'>'}</Header>
                </ItemWrapper>
                <ItemWrapper onClick={handleWatchlists}>
                    <div style={{ width: '50px', height: '50px' }}>
                        <img src='./img/ListIcon.svg' />
                    </div>
                    <br />
                    <Header>Create a Custom Watchlist {'>'}</Header>
                </ItemWrapper>
            </GridContainer>
            <ButtonContainer style={{ right: -20 }}>
                <CheckboxItem
                    style={{ border: 'none', margin: '5px' }}
                    onClick={handleSelect}
                >
                    <input 
                        type="checkbox"
                        id='checkbox'
                        checked={!showAgain}
                    />
                    <CheckboxLabel>Don't Show Again</CheckboxLabel>
                </CheckboxItem>
                <ConfirmationButton disabled={disabled} style={{ padding: '0 20px' }} onClick={handleConfirm}>
                    OK
                </ConfirmationButton>
            </ButtonContainer>
        </Container>
    )
};

export default WelcomeWindow;
