import React, { useEffect, useRef } from 'react';
import { AppColors } from '../../../Global/StyledComponents/Graphics';
import Flag from 'react-world-flags';
import { formatDateTime, formatDateYearTimeTmz } from '../../../Controllers/utils';
import styled from 'styled-components';

const CalendarEventWrapper = styled.div`
    height: 100%;
    overflow: auto; 
    max-width: ${props => props.width || '392px'};
    border: 1px solid ${AppColors.MRNAVY40};
    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const EventItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${AppColors.MRNAVY40};
    
    &:last-child {
        border-bottom: none;  // Remove border from last item
    }
`;

const CalendarEvent = ({ selectedCalendarEvent }) => {
    const importanceEvents = (importance) => {
        switch (importance) {
            case 3:
                return 'High Impact Event';
            case 2:
                return 'Medium Impact Event';
            case 1:
                return 'Low Impact Event';
        }
    }

    return (
        <CalendarEventWrapper>
            <EventItem>
                <div style={{ fontSize: '1.25rem', fontWeight: 'bold', display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    {selectedCalendarEvent.event}
                    <Flag 
                        code={selectedCalendarEvent.country_code} 
                        fallback={selectedCalendarEvent.country} 
                        style={{
                            marginLeft: 15,
                            height: 30,
                            width: 30,
                        }} 
                    />
                </div>
                <p style={{ fontSize: '12px', marginBottom: 5 }}>Last Updated on: {formatDateYearTimeTmz(selectedCalendarEvent.publication_time)}</p>
                <p>
                    {selectedCalendarEvent.summary.split('\n').map((paragraph, i) => (
                        <React.Fragment key={i}>
                            {paragraph}
                            <br />
                        </React.Fragment>
                    ))}
                </p>
                <p style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>Category: {selectedCalendarEvent.category}</p>
                <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Source: {selectedCalendarEvent.source}</p>
                <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Status: {selectedCalendarEvent.status}</p>
                {selectedCalendarEvent.actual && (
                    <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Actual: {selectedCalendarEvent.actual}</p>
                )}
                {selectedCalendarEvent.forecast && (
                    <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Forecast: {selectedCalendarEvent.forecast}</p>
                )}
                <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Importance: {importanceEvents(selectedCalendarEvent.importance)}</p>
                {/* <p style={{ fontSize: '0.875rem', fontWeight: 'bold', marginTop: 10 }}>Event Time: {formatDateYearTimeTmz(selectedCalendarEvent.time_utc)}</p> */}
            </EventItem>
        </CalendarEventWrapper>
    );
};

export default CalendarEvent;