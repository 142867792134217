import { useContext, useEffect, useState, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import Drawer from '../../Global/Components/Drawer';
import {
    ETFColumn,
    ETFGrid,
    TitleRow,
} from '../ETFMonitor/StyledComponents';
import ETFCard from '../ETFMonitor/Components/ETFCard/ETFCard';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import { MarketReaderWatermark } from  '../../Global/Logos/Logos';
import { StateContext } from '../../Controllers/context/state';
import { ViewModule } from '../../Global/StyledComponents/AppStyledComponents';
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import supabase from '../../Controllers/utils/supabaseClient';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';

const MacroMonitor = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [tickerList, setTickerList] = useState([]);
    const {
        customerProfile,
        development,
        expandedViewTicker,
        logError,
        newAlerts,
        showExpandedView,
    } = useContext(StateContext);
    const ETFGridRef = useRef(null);
    useEffect(() => {
        const fetchEntities = ({ retry = true }) => {
            supabase.from('entity_ui_sets').select('data').eq('name', 'marketreadermacromonitor').then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);

                    if (retry) {
                        fetchEntities({ retry: false });
                    }
        
                    return;
                }

                const { entities } = res?.data?.[0]?.data;
                const list = Object.values(entities).flat().map(item => item?.symbol).filter(item => item);

                setTickerList(list);
                setData(entities);
                setLoading(false);
            });
        };

        fetchEntities({ retry: true });
    }, []);

    return (
        <>
            <ViewModule>
                {(!isMobile || isTablet) && customerProfile?.accessLevels?.includes('pro') &&
                    <div style={{ position: 'absolute', top: 20, right: 65 }}>
                        <ExportToCsv />
                    </div>
                }
                {(!isMobile || isTablet) &&
                    <MarketReaderWatermark/>
                }
                {loading ?
                    <Loading /> :
                    (!isMobile || isTablet) ?
                        <ETFGrid ref={ETFGridRef} className='ETFGrid'>
                            <ETFColumn>
                                <TitleRow>Global<br />Commodities</TitleRow>
                                {
                                    data.commmodities.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>Crypto<br />Currency</TitleRow>
                                {
                                    data.crypto.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>Foreign<br />Exchange</TitleRow>
                                {
                                    data.fx.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>Fixed<br/>Income</TitleRow>
                                {
                                    data.fi.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>Equity<br/>Indices</TitleRow>
                                {
                                    data.equity.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>International<br />Equity Indices</TitleRow>
                                {
                                    data.equity_int.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>U.S.<br />Equity Sectors</TitleRow>
                                {
                                    data.equity_sectors.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                            <ETFColumn>
                                <TitleRow>U.S. Equity<br />Sub-Sectors</TitleRow>
                                {
                                    data.equity_subsectors.map(({name, symbol}) => <ETFCard etfGridRef={ETFGridRef} key={symbol} name={name} position='left' requestedTicker={symbol} />)
                                }
                            </ETFColumn>
                        </ETFGrid> :
                        <StockCardGridComponent>
                            <StockCardView tickerList={tickerList} />
                        </StockCardGridComponent>
                }
            </ViewModule>
            <Drawer open={showExpandedView} rotate='true' >
                <FeedViewer tickerList={tickerList} title='News Feed' />
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <ExpandedView title={`${expandedViewTicker} Summary`}/>
                {/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts'/> */}
                <MacroSummariesFeed title="Macro Overview"/>
            </Drawer>
        </>
    );
}
export default MacroMonitor;