export const PercentileMoves = {
    label: null,
    values: [
        {
            label: 'Top 1% Moves',
            value: 1,
        },
        {
            label: 'Top 3% Moves',
            value: 3,
        },
        {
            label: 'Top 5% Moves',
            value: 5,
        },
        {
            label: 'Top 10% Moves',
            value: 10,
        },
        {
            label: 'Top 20% Moves',
            value: 20,
        },
    ],
};

export const InfoScore = {
    label: null,
    values: [
        {
            label: 'Very High Info Score',
            value: 5,
        },
        {
            label: 'High or better Info Score',
            value: 4,
        },
        {
            label: 'Medium or better Info Score',
            value: 3,
        },
        {
            label: 'Low or better Info Score',
            value: 2,
        },
        {
            label: 'Very Low or better Info Score',
            value: 1,
        },
        {
            label: 'All',
            value: 0,
        },
    ],
};