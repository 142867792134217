const formatDateTime = (inputDate) => {
    const date = new Date(inputDate);

    const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
    const dayOfMonth = date.getDate();
    const daySuffix = ["th", "st", "nd", "rd"][
        dayOfMonth % 10 === 1 &&
        dayOfMonth !== 11 ? 1 :
        dayOfMonth % 10 === 2 &&
        dayOfMonth !== 12 ? 2 :
        dayOfMonth % 10 === 3 &&
        dayOfMonth !== 13 ? 3 : 0
    ];
    const monthName = monthNames[date.getMonth()];

    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";


    const outputDate = `${monthName} ${dayOfMonth}${daySuffix} ${hours}:${minutes} ${ampm}`;

    return outputDate;
};

export default formatDateTime;
