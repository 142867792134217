import { useState, useEffect, useCallback } from 'react';
import supabase from '../utils/supabaseClient';

const useFetchAllWatchlistTickers = ({ logError, setShowErrorWindow }) => {
    const [watchlistTickers, setWatchlistTickers] = useState([]);
    const [watchlistTickersLoading, setWatchlistTickersLoading] = useState(true);

    const fetchAllWatchlistTickers = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('get_all_symbols_by_user').select('*');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllWatchlistTickers({ retry: false });

                return;
            }

            setShowErrorWindow(true);

            return;
        }
        
       setWatchlistTickers(res?.data?.filter(item => item.notifications));
       setWatchlistTickersLoading(false);
    }, []);

    useEffect(() => {
        fetchAllWatchlistTickers({ retry: true });
    }, []);

    return {
        fetchAllWatchlistTickers,
        watchlistTickers,
        watchlistTickersLoading,
    };
};

export default useFetchAllWatchlistTickers;
