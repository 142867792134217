import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import App from './App/App'; //THIS IS THE IMPUT AREA


// import OnHoverEx from './OnHoverEx';
 //THIS IMPORTS THE COMPONENT FOR ACCESS TO USE LIKE IN AS3 

 
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// DO NOT ADD ANY FUNCTIONS IN HERE THIS IS JUST THE SHELL VIEW OF THE ENTIRE APP/DASHBOARD 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//npm start to initialize in development 
// node index.js to start the express server first 