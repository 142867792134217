const formatVolume = (volume) => {
    let dateVolume;

    if (volume < 1000) {
        dateVolume = volume.toFixed(1);
    } else if (volume < 1000000) { 
        dateVolume = (volume / 1000).toFixed(1) + 'k';
    } else if (volume < 1000000000) {
        dateVolume = (volume / 1000000).toFixed(1) + 'M';
    } else {
        dateVolume = (volume / 1000000000).toFixed(1) + 'B';
    }

    return dateVolume;
}; 

export default formatVolume;
