import { useContext, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

import {
    CancelButton,
    Form,
    FormWrapper,
    Header,
    Input,
    MobileFormWrapper,
    StyledLabel,
    StyledValue,
    SubmitButton,
} from "../../../Global/StyledComponents/UserFormStyledComponents";
import { WhiteTextGlassLogo } from "../../../Global/Logos/Logos";
import supabase from "../../../Controllers/utils/supabaseClient";
import { StateContext } from "../../../Controllers/context/state";
import { AppColors } from "../../../Global/StyledComponents/Graphics";

const OrganizationManager = () => {
    const [billingEmail, setBillingEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organization, setOrganization] = useState(null);
    const [userEmail, setUserEmail] = useState('');

    const {
        logError
    } = useContext(StateContext);

    const handleChange = (e) => {
        setBillingEmail(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleUserEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        
        const res = await supabase.functions.invoke('addOrgUser', {
            body: {
                billingEmail,
                firstName,
                lastName,
                userEmail,
            },
        });
        
        const { ok } = JSON.parse(res.data);

        if (ok) {
            setUserEmail('');
            setFirstName('');
            setLastName('');
            handleSubmit();
        }
    };

    const handleSubmit = async (e) => {
        e && e.preventDefault();

        const { data, error } = await supabase.from('auth_profiles').select('*').eq('billing_email', billingEmail);

        if (error) {
            logError(error);
        } else {
            setOrganization(data);
        }
    };

    const removeUser = async (user) => {
        const res = await supabase.functions.invoke('updateOrgUser', {
            body: {
                firstName: user.first_name,
                lastName: user.last_name,
                userEmail: user.email,
                userId: user.id,
            },
        });

        const { ok } = JSON.parse(res.data);

        if (ok) {
            handleSubmit();
        }
    }

    return (
        <MobileFormWrapper
            style={{
                width: '100%',
                maxHeight: '100%',
            }}
            isMobile={isMobile && !isTablet}
        >
            <FormWrapper
                style={{
                    width: 'fit-content',
                    minWidth: '400px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                isMobile={isMobile && !isTablet}
            >
                <WhiteTextGlassLogo title="Market Reader logo" style={{ flexShrink: 0 }} />
                <div style={{ overflowY: 'auto', flexGrow: 1 }}>
                    <Header>Organization Manager</Header>
                    {organization ?
                        <>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid white',
                                borderRadius: '10px',
                                padding: '10px',
                                paddingTop: '0',
                                maxHeight: '500px',
                                overflowY: 'auto',
                            }}>
                                <Header style={{
                                    position: 'sticky',
                                    top: '0',
                                    backgroundColor: AppColors.MRNAVY40,
                                    width: '100%',
                                    margin: '0',
                                    padding: '10px 0',
                                }}>
                                    Billing Email: {billingEmail}
                                </Header>
                                {organization?.map(user => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            borderRadius: '10px',
                                            width: '100%',
                                            alignItems: 'center',
                                            height: 'auto',
                                        }}
                                    >
                                        <CancelButton
                                            onClick={() => {
                                                removeUser(user);
                                            }}
                                            style={{
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                            }}
                                        >
                                            Remove
                                        </CancelButton>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                border: '1px solid white',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                margin: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            {user.first_name && user.last_name && (
                                                <>
                                                    <StyledLabel style={{ margin: 0 }}>
                                                        Name
                                                    </StyledLabel>
                                                    <StyledValue>
                                                        {user.first_name} {user.last_name}
                                                    </StyledValue>
                                                </>
                                            )}
                                            <StyledLabel style={{ margin: 0 }}>
                                                Email
                                            </StyledLabel>
                                            <StyledValue style={{ margin: 0 }}>
                                                {user.email}
                                            </StyledValue>
                                        </div>
                                    </div>
                                ))}
                            </div> 
                            <Form onSubmit={handleAddUser}>
                                <Header>Add New User To Organization</Header>
                                <Input
                                    className="form"
                                    placeholder="User Email"
                                    type="text"
                                    value={userEmail}
                                    onChange={handleUserEmailChange}
                                />
                                <Input
                                    className="form"
                                    placeholder="User First Name"
                                    type="text"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                />
                                <Input
                                    className="form"
                                    placeholder="User Last Name"
                                    type="text"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                />
                                <SubmitButton
                                    type="submit"
                                >
                                    Add User
                                </SubmitButton>
                            </Form>
                        </> :
                        <Form onSubmit={handleSubmit}>
                            <Input
                                className="form"
                                placeholder="Organization Billing Email"
                                type="text"
                                value={billingEmail}
                                onChange={handleChange}

                            />
                            <SubmitButton
                                type="submit"
                            >
                                Look Up Organization
                            </SubmitButton>
                        </Form>
                    }
                </div>
            </FormWrapper>
        </MobileFormWrapper>
    );
};

export default OrganizationManager;
