import React, { useContext, useEffect, useState } from 'react';
import { AppColors } from '../../StyledComponents/Graphics';
import { CalendarIcon } from '../../Icons/Icons';
import { CTAButton, DataOutputContainer, Line } from '../../StyledComponents/AppStyledComponents';
import Flag from 'react-world-flags';
import { FeedItem } from './components/NewsFeed';
import { formatDateYearTimeTmz } from '../../../Controllers/utils';
import { Header } from '../../StyledComponents/UserFormStyledComponents';
import Loading from '../../Components/LoadingSpinner';
import { StateContext } from '../../../Controllers/context/state';
import supabase from '../../../Controllers/utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const CalendarFeed = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { logError, setSelectedCalendarEvent, setShowCalendarEventPopUp } = useContext(StateContext);

    const fetchCalendarEvents = async ({ retry = true }) => {
        const { data, error } = await supabase.from('calendar_events').select('*');
        if (error) {
            if (retry) {
                fetchCalendarEvents({ retry: false });
                return;
            }
            logError(error.message, undefined, error);
            return;
        }
        if (!data) return;
        data.sort((a, b) => new Date(a.time_utc) - new Date(b.time_utc));

        const now = new Date();
        const sixHoursAgo = new Date(now.getTime() - (6 * 60 * 60 * 1000));
        const twoHoursAhead = new Date(now.getTime() + (2 * 60 * 60 * 1000));

        const timeFilteredData = data.filter(event => {
            const eventTime = new Date(event.time_utc);
            return eventTime >= sixHoursAgo && eventTime <= twoHoursAhead;
        });

        let filteredData = timeFilteredData.filter((event) =>
            event.importance === 3
        );

        if (filteredData.length === 0) {
            filteredData = timeFilteredData.filter((event) =>
                event.importance === 2
            );
        }

        setIsLoading(false);
        if (filteredData.length === 0) return;
        setCalendarEvents(filteredData);
    };

    const handleExploreEvents = () => {
        navigate('/calendar');
    }

    const handleEventClick = (event) => {
        setSelectedCalendarEvent(event);
        setShowCalendarEventPopUp(true);
    }

    useEffect(() => {
        fetchCalendarEvents({ retry: true });
    }, []);

    if (isLoading) return <Loading />;


    return (
        <DataOutputContainer border={true}>
            <Header style={{ position: 'sticky', top: 0, zIndex: 1000, margin: 0, padding: 10, backgroundColor: AppColors.MRNAVY }}>
                <div>
                    Macro Events
                </div>
                <CTAButton style={{ display: 'flex', alignItems: 'center' }} onClick={handleExploreEvents}>
                    <h2>
                        Explore Events
                    </h2>
                    <div style={{ marginLeft: 10 }}>
                        <CalendarIcon />
                    </div>
                </CTAButton>
            </Header>
            {calendarEvents.length > 0 && calendarEvents.map((event) => (
                <>
                    <FeedItem key={`${event.symbol}-${event.time_utc}`} style={{ flexDirection: 'column' }} onClick={() => handleEventClick(event)}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center', paddingBottom: 5 }}>
                            {event.event}
                            <Flag
                                code={event.country_code}
                                fallback={event.country}
                                style={{
                                    marginLeft: 15,
                                    height: 30,
                                    width: 30,
                                }}
                            />
                        </div>
                        <p style={{ fontSize: '14px' }}>{event.headline}</p>
                        <p style={{ fontSize: '12px', marginTop: 5 }}>Event Time: {formatDateYearTimeTmz(event.time_utc)}</p>
                    </FeedItem>
                    <Line />
                </>
            ))}
            {calendarEvents.length === 0 &&
                <>
                    <FeedItem key={`no-events-found`} style={{ flexDirection: 'column' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 5 }}>
                            <p style={{ textAlign: 'center' }}>No events found in the last 6 hours or next 2 hours</p>
                        </div>
                    </FeedItem>
                    <Line />
                </>
            }
        </DataOutputContainer>
    );
};

export default CalendarFeed;