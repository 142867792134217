import StockCard from '../../Components/StockCard/StockCard';


const StockCardView = ({
    correlatedAsset,
    readOnly,
    tickerList,
    watchlistCard = false,
    watchlistDashboard = false,
    watchlistItems,
}) => {
    return (
            <>
                {
                    tickerList?.map((ticker) => {
                        const item = watchlistItems?.filter(({ symbol }) => symbol === ticker)[0];
                        
                        return (
                            <StockCard
                                correlatedAsset={correlatedAsset}
                                key={ticker}
                                readOnly={readOnly}
                                requestedTicker={ticker}
                                watchlistCard={watchlistCard}
                                watchlistDashboard={watchlistDashboard}
                                watchlistItem={item}
                            /> 
                        );
                    })
                }
            </>
    )
};

export default StockCardView;
