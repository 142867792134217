import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { AppColors } from '../StyledComponents/Graphics';
import { BlankSpacer } from '../StyledComponents/AppStyledComponents';
import {
    StyledHyperLink,
    SubmitButton,
    SuccessMessage
} from '../StyledComponents/UserFormStyledComponents';

const FormWrapper = styled.div`
   width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: ${AppColors.MRLITE90};
`;

const FormContainer = styled.div`
    width: ${(!isMobile || isTablet) ? '700px' : 'auto'};
    padding: 10px;
    margin: 0 auto;
`;

const Title = styled.h2`
    margin-bottom: 10px;
    text-align: center;
`;

const Description = styled.div`
    margin-bottom: 20px;
`;

const QuestionsSubtitle = styled.h3`
    margin-bottom: 10px;
    margin-left: 0;
`;

const QuestionContainer = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const QuestionText = styled.p`
    margin-bottom: 5px;
`;

const AnswerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RadioButton = styled.input`
    margin: 0 10px;
`;

const AnswerLabel = styled.label`
    font-size: 14px;
`;

const QuestionnaireForm = ({ description, handleSubmit, header, questions, title }) => {
    const [answers, setAnswers] = useState({});
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

    useEffect(() => {
        const areAllQuestionsAnswered = Object.keys(answers).length === questions.length;
        setAllQuestionsAnswered(areAllQuestionsAnswered);
    }, [answers, questions]);

    const handleAnswerChange = (questionIndex, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionIndex]: answer
        }));
    };

    return (
        <FormWrapper isMobile={isMobile && !isTablet}>
            {(isMobile && !isTablet) &&
                <>
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                </>
            }
            {title && <Title>{title}</Title>}
            <FormContainer>
                {description && 
                    <Description>
                        {description.map(item => (
                            <>
                                <p>
                                    {item}
                                </p>
                                <BlankSpacer />
                            </>
                        ))}
                    </Description>}
                {header &&
                    <QuestionsSubtitle>
                        {header}
                    </QuestionsSubtitle>}
                {questions.map((question, index) => (
                    <QuestionContainer key={index}>
                        <QuestionText>{question}</QuestionText>
                        <AnswerContainer>
                            <RadioButton
                                type="radio"
                                id={`question-${index + 1}-true`}
                                name={`question-${index + 1}`}
                                checked={answers[index + 1] === true}
                                onChange={() => handleAnswerChange(index + 1, true)}
                            />
                            <AnswerLabel htmlFor={`question-${index + 1}-true`}>YES</AnswerLabel>
                            <RadioButton
                                type="radio"
                                id={`question-${index + 1}-false`}
                                name={`question-${index + 1}`}
                                checked={answers[index + 1] === false}
                                onChange={() => handleAnswerChange(index + 1, false)}
                            />
                            <AnswerLabel htmlFor={`question-${index + 1}-false`}>NO</AnswerLabel>
                        </AnswerContainer>
                    </QuestionContainer>
                ))}
                <SubmitButton disabled={!allQuestionsAnswered} onClick={() => handleSubmit(answers)}>
                    Submit
                </SubmitButton>
            </FormContainer>
            <SuccessMessage>
                Questions? Contact&nbsp;
                <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
            </SuccessMessage>
        </FormWrapper>
    );
};

export default QuestionnaireForm;
