import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import { AuthContext } from '../../Controllers/context/auth';
import { ViewModule } from '../../Global/StyledComponents/AppStyledComponents';
import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import { StateContext } from '../../Controllers/context/state';
import { useParams } from 'react-router-dom';
import StockChartWrapper from '../../Global/Components/StockChartWrapper/StockChartWrapper';
import TickerNotFound from '../../Global/Components/TickerNotFound';
import { getCurrentDate } from '../../Controllers/utils';
import MonthlySummaryWrapper from '../../Global/Components/MonthlySummaryWrapper/MonthlySummaryWrapper';
import { isMobile, isTablet } from 'react-device-detect';

const AssetMonitor = () => {

    const [dateDisplay, setDateDisplay] = useState(new Date().toISOString().split("T")[0]);
    const [doesExist, setDoesExist] = useState(true);
    const [isDelisted, setIsDelisted] = useState(false);
	const [lastDate, setLastDate] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date().getTime() / 1000);
    const [startingWidth, setStartingWidth] = useState();
    const params = useParams();
	
	const starSegments = params['*'] ? params['*'].split('/') : [];
	const lastSegment = starSegments[starSegments.length - 1];
	const isDate = /^\d{4}-\d{2}-\d{2}$/.test(lastSegment);
	const symbolParts = isDate ? starSegments.slice(0, -1) : starSegments;
	const symbol = params.symbol + (symbolParts.length > 0 ? `/${symbolParts.join('/')}` : '');
    
	const {
		assets,
        activeAssets,
        development,
		expandedViewTicker,
		fullNameKey,
		isLoading,
		logError,
		newAlerts,
		setExpandedViewTicker,
        setShowExpandedView,
        showExpandedView,
	} = useContext(StateContext);
	const navigate = useNavigate();
	const { session } = useContext(AuthContext);
	const [width, setWidth] = useState();


	const handleDateChange = (e) => {
		const newDate = e.target.value;
		setDateDisplay(newDate);
		setSelectedDate(new Date(newDate).getTime() / 1000);

		const currentDate = getCurrentDate().toISOString().split('T')[0];

		let newUrl = `/ticker/${symbol}`;
		if (newDate !== currentDate) {
			newUrl += `/${newDate}`;
		}

		navigate(newUrl, { replace: true });
	};

	const handleSearchSubmit = (value) => {
		navigate(`/ticker/${value}`);
	};

	useEffect(() => {
		setStartingWidth(window.innerWidth - 554);
		setExpandedViewTicker(symbol);
		setShowExpandedView(true);
	}, [symbol]);

	useEffect(() => {
		const checkAssetExistence = async () => {
            setIsDelisted(false);
			const assetExists = activeAssets.some(asset => asset.symbol === symbol);
			if (assetExists) {
				setDoesExist(true);
				setIsDelisted(false);

			} else {
                const assetExists = assets.some(asset => asset.symbol === symbol);
				setDoesExist(assetExists);
				setIsDelisted(assetExists);
			}
		};

		checkAssetExistence();
	}, [symbol, assets, activeAssets]);


    useEffect(() => {
        if(isDelisted) return;

        const date = isDate ? new Date(lastSegment) : getCurrentDate();
        const epochDate = Math.ceil(date.getTime() / 1000);
        setSelectedDate(epochDate);
        setDateDisplay(date.toISOString().split("T")[0]);
    }, [lastSegment, isDate]);

	if (isLoading) {
		return (
			<ViewModule>
				<Loading />
			</ViewModule>
		);
	}

	return (
		<>
			<ViewModule>
                {doesExist ? (
				<div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ 
                          height: '100%', 
                          width: `450px`, 
                          minWidth: '450px',
                          marginRight: '10px' 
                      }}>
                        <MonthlySummaryWrapper ticker={symbol} />
                        {/* <ExpandedView assetPage  /> */}
				    </div>
					<div>
						<StockChartWrapper
                            assetPage
                            assets={assets}
                            dateDisplay={dateDisplay}
                            handleDateChange={handleDateChange}
                            handleNavigation={handleSearchSubmit}
                            isDelisted={isDelisted}
                            lastDate={lastDate}
                            selectedDate={selectedDate}
                            setLastDate={setLastDate}
                            symbol={symbol}
                            width={width}
						/>
					</div>
				</div>
                ) : (
                    <TickerNotFound  text={'Invalid asset or not currently covered by MarketReader. Please check your spelling and try again.'}  handleNavigation={handleSearchSubmit} />
                )}
			</ViewModule>
            
			
			<Drawer open={showExpandedView} rotate={'true'} setWidth={setWidth} startingWidth={startingWidth}>
				<ExpandedView open={showExpandedView} title={`${expandedViewTicker} Today`} showActive={true} assetPage />
				<FeedViewer title='News Feeds' />
				{development && <MacroMovers title='Macro Movers' />}
				{/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts' /> */}
				<MacroSummariesFeed title='Macro Overview' />
			</Drawer>
		</>
	);
};

export default AssetMonitor;
