import React, { useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { StyledLabel } from '../StyledComponents/UserFormStyledComponents';

const SliderWrapper = styled.div`
    width: 100%;
`;

const SliderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
`;

const SliderLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
`;

const SliderInput = styled.input`
    width: 75%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    accent-color: ${AppColors.MRBLUE};

    &:focus {
        outline: none;
    }
`;

const DoubleSlider = ({ handleChange, id, label }) => {
    const [range, setRange] = useState({ lower: -1, upper: 1 });

    const handleRangeChange = (event) => {
        const { name, value } = event.target;

        setRange(prevRange => {
            const newRange = { ...prevRange, [name]: parseFloat(value) };

            if(newRange.lower < newRange.upper){
                return newRange;
            }

            return prevRange;
        });
    };

    const handleMouseUp = () => {
        handleChange(id, range);
    };

    return (
        <SliderWrapper>
            {label &&
                <StyledLabel>
                    {label}
                </StyledLabel>
            }
            <SliderRow>
                <SliderInput
                    type="range"
                    min="-1"
                    max="1"
                    step="0.01"
                    name="lower"
                    value={range.lower}
                    onChange={handleRangeChange}
                    onMouseUp={handleMouseUp}
                />
                <SliderLabel>
                    Min: {range.lower}
                </SliderLabel>
            </SliderRow>
            <SliderRow>
                <SliderInput
                    type="range"
                    min="-1"
                    max="1"
                    step="0.01"
                    name="upper"
                    value={range.upper}
                    onChange={handleRangeChange}
                    onMouseUp={handleMouseUp}
                />
                <SliderLabel>
                    Max: {range.upper}
                </SliderLabel>
            </SliderRow>
        </SliderWrapper>
    );
};

export default DoubleSlider;
