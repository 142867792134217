import React from 'react';
import { TableCell, TableRow } from '../../TableView/styledComponents';
import Flag from 'react-world-flags';
import CalendarImportance from './CalendarImportance';

const CalendarTableRow = React.forwardRef(({ event, onClick }, ref) => {
    return (
        <>
            <TableRow ref={ref} onClick={onClick}>
                <TableCell>
                    <CalendarImportance 
                        importance={event.importance} 
                        time_utc={event.time_utc} 
                    />
                </TableCell>
                <TableCell>
                    <Flag 
                        code={event.country_code} 
                        fallback={event.country} 
                        style={{
                            marginLeft: 15,
                            height: 30,
                            width: 30,
                        }} 
                    />
                </TableCell>
                <TableCell>{event.event}</TableCell>
                <TableCell>{event.category}</TableCell>
                <TableCell>{event.source}</TableCell>
                <TableCell>{event.status}</TableCell>
                <TableCell>{event.headline}</TableCell>
                <TableCell>{event.actual}</TableCell>
                <TableCell>{event.forecast}</TableCell>
            </TableRow>
        </>
    );
}) ;

export default CalendarTableRow;