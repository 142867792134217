import { ETFBottomRow, ETFCardWrapper, ETFName, ETFTopRow } from '../../../StyledComponents';
import { ClockIcon } from '../../../../../Global/Icons/Icons';
import ETFInfo from './ETFInfo';
import { CFDs } from '../../../../../Global/constants';

const ETFFrontView = ({ data, handleExpand, name }) => {

    return (
        <ETFCardWrapper onClick={handleExpand} title='Click to view more data &#013; over on the right side module.'>

            <ETFTopRow >
                <ETFName>
                    {name}
                </ETFName>
                {!CFDs.includes(data.type) &&
                    <ClockIcon />
                }
            </ETFTopRow>


            <ETFBottomRow >
                <ETFInfo data={data} />
            </ETFBottomRow>



        </ETFCardWrapper>
    );
};

export default ETFFrontView;
