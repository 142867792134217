import { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

const SparkLine = ({ expanded, series, sparklineContainerRef, summary }) => {

    const seriesData = series ? series.map((item) => [new Date(item.ts * 1000), parseFloat(item.p)]).reverse() : [];
    const seriesMin = Math.min(...seriesData.map((item) => item[1]));
    const seriesMax = Math.max(...seriesData.map((item) => item[1]));

    const LineColor = 'rgba(255, 255, 255, 0.6)';
    const width = sparklineContainerRef?.current?.clientWidth - 10;

    const defaultOptions = {
        accessibility: {
            enabled: false,
        },
        chart: {
            backgroundColor: null,
            borderWidth: 0,
            type: 'area',
            margin: [0, -4, 0, -4],
            height: sparklineContainerRef?.current?.clientHeight || 70,
            width: width ? width : expanded ? 356 : summary ? 218 : 562,
            style: {
                overflow: 'visible',
            },
            skipClone: true,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            visible: false,
            min: seriesMin,
            max: seriesMax,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            series: {
                animation: false,
                lineColor: {
                    linearGradient: { x1: 1, x2: 0, y1: 1, y2: 1 },
                    stops: [
                        [0, LineColor ],
                        [1, 'rgba(255, 255, 255, 0.7)'],
                    ],
                },
                lineWidth: 2,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 3,
                    }
                },
                marker: {
                    enabled: false,
                },
                fillOpacity: 0.25,
            },
        },
        series: [{
            data: seriesData,
            color: {
                linearGradient: { x1: 1, x2: 1, y1: 0, y2: 1 },
                stops: [
                    [1, 'rgba(155, 155, 155, 0)'],
                ],
            },
        }],
    };

    const chartComponentRef = useRef(null);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={defaultOptions}
            ref={chartComponentRef}
        />
    )

};

export default SparkLine