import { useState, useEffect, useCallback, useContext } from 'react';
import supabase from '../utils/supabaseClient';
import { AuthContext } from '../context/auth';

const useFetchNotificationSettings = ({ logError }) => {
    const { user } = useContext(AuthContext);
    const [notificationSettings, setNotificationSettings] = useState({ data: {
        watchlistNotifications: false,
        breakingNewsNotifications: false,
        watchlistNotificationsLastChecked: user?.last_sign_in_at,
    }});
    const [notificationsLoading, setNotificationsLoading] = useState(true);

    const fetchNotificationSettings = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('user_preferences').select('*').eq('type', 'notificationSettings');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchNotificationSettings({ retry: false });

                return;
            }
        }

        const settings = res?.data[0];
        const data = {
            ...settings?.data,
            watchlistNotificationsLastChecked: settings?.data?.watchlistNotificationsLastChecked || user?.last_sign_in_at,
        };
        setNotificationSettings({
            ...settings,
            data: data,
        });
        setNotificationsLoading(false);
        
    }, [user]);
    
    useEffect(() => {
        fetchNotificationSettings({ retry: true });
    }, [user]);

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else if (notificationSettings?.data?.watchlistNotifications || notificationSettings?.data?.breakingNewsNotifications) {
            Notification.requestPermission();
        }
    }, [notificationSettings])
    
    return {
        notificationSettings,
        notificationsLoading,
        setNotificationSettings,
    };
};

export default useFetchNotificationSettings;
