import { useContext } from "react";
import supabase from "../../../../Controllers/utils/supabaseClient";
import { ProfileIcon } from "../../../Icons/Icons";
import { StateContext } from "../../../../Controllers/context/state";
import { useNavigate } from "react-router-dom";
import { DropdownProfileMenu, NavivationItem } from "../StyledComponents";
import { AuthContext } from "../../../../Controllers/context/auth";

const ProfileMenu = ({
    handleMouseLeave,
    handleSupport,
    handleToggleProfileMenu,
    profileMenuOpen,
    setProfileMenuOpen,
}) => {
    const navigate = useNavigate()
    const {
        setUser,
        user,
    } = useContext(AuthContext);

    const {
        admin,
        logError,
        customerProfile,
        setIsAuthenticated,
    } = useContext(StateContext);

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            setProfileMenuOpen(false);
            setIsAuthenticated(false);
            setUser({});
            navigate('/login');
        });
    };

    const manageSubscription = async ({ retry = true }) => {
        await supabase.functions.invoke('customerPortal', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    manageSubscription({ retry: false });
                }
    
                return;
            }
            setProfileMenuOpen(false);
            const { session } = JSON.parse(res?.data);
            window.open(session?.url, '_blank');
        });
    };

    return (
        <NavivationItem
            className="hoverButton"
            id='profileMenu'
            onClick={handleToggleProfileMenu}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer' }}
        >
            <ProfileIcon />
            {
                profileMenuOpen && (
                    <DropdownProfileMenu
                        id='profileMenu'
                        onMouseLeave={handleMouseLeave}
                    >
                        <ul>
                            <li className='dropdown-item'
                                onClick={handleSupport}
                            >
                                Help & Feedback
                            </li>
                            <li
                                className='dropdown-item'
                                onClick={handleLogout}
                            >
                                Logout
                            </li>
                            {customerProfile?.profile &&
                                <li
                                    className='dropdown-item'
                                    onClick={() => manageSubscription({ retry: true })}
                                >
                                    Manage Subscription
                                </li>
                            }
                            {admin &&
                                <li
                                    className='dropdown-item'
                                    onClick={() => navigate('/admin')}
                                >
                                    Manage Users
                                </li>
                            }                        
                        </ul>
                    </DropdownProfileMenu>
                )
            }
        </NavivationItem>
    )
};

export default ProfileMenu;
