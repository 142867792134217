import { useState, useEffect, useCallback } from 'react';
import supabase from '../utils/supabaseClient';

const useFetchViewStyle = ({ logError }) => {
    const [viewStyleLoading, setViewStyleLoading] = useState(true);
    const [viewStyle, setViewStyle] = useState({ data: { showCards: true }});

    const fetchViewStyle = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('user_preferences').select('*').eq('type', 'viewStyle');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchViewStyle({ retry: false });

                return;
            }
        }

        setViewStyle(res?.data[0]);
        setViewStyleLoading(false);
    }, []);

    useEffect(() => {
        fetchViewStyle({ retry: true });
    }, []);

    return {
        setViewStyle,
        viewStyle,
        viewStyleLoading,
    };
};

export default useFetchViewStyle;
