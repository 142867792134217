import { useContext } from "react";
import DropdownMenu from "../../../Global/Components/DropdownMenu";
import { StateContext } from '../../../Controllers/context/state';
import AddToWatchlistForm from "../../../Global/Components/AddToWatchlistForm";

const WatchlistMenu = ({ handleSelectWatchList }) => {
    const {
        selectedWatchlist,
        watchlists
    } = useContext(StateContext);

    return (
        <>
            <DropdownMenu
                handleChange={handleSelectWatchList}
                id='watchlists'
                label='Select A Watchlist'
                options={watchlists}
                value={selectedWatchlist}
            />
            <br/>
            <center>Or</center>
            <AddToWatchlistForm createNew />
    </> 
    )
};

export default WatchlistMenu;
