const formatMarketCap = (mCapMM) => {
    const sign = mCapMM < 0 ? '-' : '+';
    let marketCap;
    mCapMM = Math.abs(mCapMM);

    if (mCapMM < 1) { 
        marketCap = (mCapMM * 1000).toFixed(1) + 'k';
    } else if (mCapMM < 1000) {
        marketCap = mCapMM.toFixed(1) + 'M';
    } else {
        marketCap = (mCapMM / 1000).toFixed(1) + 'B';
    }

    return sign + marketCap;

}; 

export default formatMarketCap;
