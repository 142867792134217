import { useLocation, useNavigate } from "react-router-dom";
import supabase from "../../Controllers/utils/supabaseClient";
import { WhiteTextGlassLogo } from "../../Global/Logos/Logos";
import { SubmitButton, Container, FormWrapper, Header, StyledHyperLink } from "../../Global/StyledComponents/UserFormStyledComponents";
import { StyledButton } from "../../Global/StyledComponents/UserFormStyledComponents";

const AccessDeniedMonitor = () => {
    const { state } = useLocation();
    const { email, billingEmail } = state?.user;
    const navigate = useNavigate();

    const manageSubscription = async () => {
        await supabase.functions.invoke('customerPortal', {
            body: {
                userEmail: email,
            },
        }).then(res => {
            const { session } = JSON.parse(res.data);
            window.open(session.url, '_blank');
        });
    }

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            navigate('/login');
        })
    };

    return (
        <Container>
            <StyledButton
                onClick={handleLogout}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: '10px',
                    left: '20px',
                }}
            >
                Back to Login
            </StyledButton>
            <FormWrapper>
                <WhiteTextGlassLogo title="Market Reader logo" />
                {email === billingEmail ?
                    <>
                        <Header>
                            Your subscription has ended.
                            <br/>
                            <br/>
                            You may manage your subscription and payment methods at the link below.
                        </Header>
                        <br/>
                        <SubmitButton onClick={manageSubscription}>
                            Customer Portal
                        </SubmitButton>
                        <br/>
                    </> :
                    <>
                        <Header>
                            Your subscription has ended.
                            <br/>
                            <br/>
                            Please contact the administrator of your account.
                        </Header>
                    </>
                }
                <Header>
                    For further assistance please email
                    <br/>
                    <StyledHyperLink href="mailto: support@marketreader.com">Support@marketreader.com</StyledHyperLink>.
                </Header>
            </FormWrapper>
        </Container>
    )
};

export default AccessDeniedMonitor;
