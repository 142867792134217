import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';

const ToggleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    width: 75px;
    height: 25px;
    background-color: ${AppColors.MRNAVY20};
    border-radius: 15px;
    border: 1px solid  ${props => (!props.isOn && props.onOffSwitch) ? AppColors.MRGREY : AppColors.MRBLUE};
    cursor: pointer;
`;

const ToggleLabel = styled.div`
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    color: ${AppColors.MRLITE80};

    ${props => props.isOn ? `
        left: 10px;
    ` : `
        right: 10px;
    `}
`;

const ToggleInput = styled.input`
    display: none;
`;

const ToggleButton = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${props => (!props.isOn && props.onOffSwitch) ? AppColors.MRGREY : AppColors.MRBLUE};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease;

    ${props => props.isOn ? `
        right: 0px;
    ` : `
        left: 0px;
    `}
`;

const ToggleSwitch = ({
    handleClick,
    id,
    isOn,
    offLabel = 'Off',
    onLabel = 'On',
    onOffSwitch,
    value,
}) => {

    return (
        <div>
            <ToggleRow
                id={id}
                isOn={isOn}
                onClick={(e) => handleClick(e, value)}
                onOffSwitch={onOffSwitch}
            >
                <ToggleLabel id={id} isOn={isOn}>
                    {isOn ? onLabel : offLabel}
                </ToggleLabel>
                <ToggleButton id={id} isOn={isOn} onOffSwitch={onOffSwitch}/>
                <ToggleInput id={id} type="checkbox" checked={isOn} readOnly />
            </ToggleRow>
        </div>
    );
};

export default ToggleSwitch;
