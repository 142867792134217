import { useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { StyledLabel } from '../StyledComponents/UserFormStyledComponents';

const ComponentWrapper = styled.div`
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ToggleButton = styled.button`
    width: 50%;
    cursor: pointer;
    border: 1px solid ${AppColors.MRNAVY60};
    border-radius: 0;
    background: ${props => props.active ? AppColors.MRBLUE : AppColors.MRNAVY15};
    color: ${props => props.active ? AppColors.MRNAVY15 : AppColors.MRLITE90};
    font-size: 14px;
    line-height: 24px;
    padding: 10px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background-color: ${AppColors.MRNAVY60};
        color: ${AppColors.MRNAVY20};
    }
`;

const ToggleComponentWrapper = ({ label, leftLabel, rightLabel, children }) => {
    const [selected, setSelected] = useState({left: true});

    return (
        <ComponentWrapper>
            {label &&
                <StyledLabel>
                    {label}
                </StyledLabel>
            }
            <ButtonContainer>
                <ToggleButton
                    active={selected.left}
                    onClick={() => setSelected({left: true})}
                >
                    {leftLabel}
                </ToggleButton>
                <ToggleButton
                    active={selected.right}
                    onClick={() => setSelected({right: true})}
                >
                    {rightLabel}
                </ToggleButton>
            </ButtonContainer>
            {selected.left ? children[0] : children[1]}
        </ComponentWrapper>
    );
};

export default ToggleComponentWrapper;
