import { useEffect, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { AppColors } from "../../../Global/StyledComponents/Graphics";
import { SubmitButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import supabase from "../../../Controllers/utils/supabaseClient";

const Interval = styled.p`
line-height: 20px;
padding-left: 10px;
width: 40px;
`;

const PlanWrapper = styled.div`
    background-color: ${(!isMobile || isTablet) ? AppColors.MRNAVY20 : AppColors.MRNAVY30};
    border-radius: 10px;
    color: ${AppColors.MRWHITE};
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 250px;
    margin: 10px;
`;

const PriceBlock = styled.div`
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: center;
`;

const StyledList = styled.ul`
    height: auto;
    margin: 0;
    padding: 0 20px;
`;

const PlanView = ({
    disabled,
    getUser,
    plan,
    planKey,
    promoData,
    readOnly,
    setDisabled,
    trialStatus,
    user,
}) => {
    const {
        buttonText,
        description,
        includes,
        interval,
        price,
        priceModel,
        title,
    } = plan;
    const [readMore, setReadMore] = useState(true);

    const handleContact = () => {
        window.open('https://marketreader.com/contact-us/', '_blank')
    };

    const handleReadMore = () => {
        setReadMore(prevState => !prevState);
    };

    const handleSubmit = async () => {
        setDisabled(true)
        const { data, error } = await supabase.functions.invoke('createSubscriptionV2', {
            body: {
                priceModel,
                promoId: promoData?.id,
                trialStatus,
                userEmail: user?.email,
            },
        });

        const { ok, session } = JSON.parse(data);

        if (ok && session) {
            window.open(session?.url, '_blank');
            setDisabled(false);
            return;
        }

        if (ok) {
            supabase.from('profiles').update({
                trial_status: true
            }).eq('id', user?.id).then((res) => {
                getUser();
            });
            return;
        }
        setDisabled(false);
        return;
    };

    useEffect(() => {
        if (window.innerHeight < 1000) {
            setReadMore(false);
        }
    }, [window]);

    return (
        <PlanWrapper>
            <h2 style={{ marginTop: 0 }}>
                {title}
            </h2>
            <p style={{ height: 150 }}>
                {description}
            </p>
            {planKey === 'individual' &&
                <PriceBlock>
                    <div style={{ fontSize: '48px', fontWeight: 'bold' }}>{price}</div>
                    <div style={{ fontSize: '48px', fontWeight: 'bold', marginLeft: '10px' }}>{price}</div>
                    <Interval>{interval}</Interval>
                </PriceBlock>
            }
            {planKey !== 'individual' &&
                <div style={{ margin: '20px 0' }}>
                    {!readOnly &&
                    <SubmitButton onClick={handleContact}>Contact Us</SubmitButton>
                    }
                </div>
            }
            <h3>This includes:</h3>
            <StyledList style={{ height: readMore ? null : '110px', overflow: 'hidden' }}>
                {includes.map((item, index) => (
                    <li style={{ marginBottom: '8px '}} key={index}>
                        {item}
                    </li>
                ))}
            </StyledList>
                {window.innerHeight < 1100 &&
                    <div onClick={handleReadMore} style={{ cursor: 'pointer' }}>
                        {readMore ? 'Read Less...' : 'Read More...'}
                    </div>
                }
        </PlanWrapper>
    );
};

export default PlanView;
