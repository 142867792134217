import { useContext, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { StateContext } from '../../Controllers/context/state';
import { AppColors } from '../StyledComponents/Graphics';
import SideButton from './SideButton';

const AlertNumber = styled.div`
    background-color: ${AppColors.MRRED};
    padding: 0 7px;
    border-radius: 15px;
    margin-left: 5px;
`;

const DrawerWrapper = styled.div`
    top: 0;
    left: 0;
    height: 100%;
    position: ${props => (!isMobile || isTablet) ? null : props.rotate ? 'absolute' : null};
    width: ${props => ((!isMobile || isTablet) ? (props.isOpen ? props.drawerWidth : 0) : props.isOpen ? '100vw' : 0)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: transform 0.3s ease-in-out;
    transform: translateX(${props => (props.rotate && props.isOpen ? '0' : props.isOpen ? '0' : '-400px')});
    z-index: 999;
    background-color: rgba(13, 34, 51, .95);
    border-radius: 0px 0px 7px 0px;
    border: 1px solid ${AppColors.MRNAVY30};
    padding: 0px;
`;

const DrawerTabWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* transition: transform 0.3s ease-in-out; */
    transform: rotate(90deg) translateX(100%) translateY(${props => props.isOpen && props.rotate ? '-400px' : '0'});
    transform-origin: top right;
    width: 0;
    margin-left: ${props => (props.isOpen && props.rotate ? `calc(${props.drawerWidth} - 400px + 45px)` : props.isOpen ? `calc(${props.drawerWidth} + 45px)` : '45px')};
`;

const DrawerTab = styled.div`
    display: flex;
    justify-content: center;
    padding: ${(!isMobile || isTablet) ? '10px' : '4px 5px'};
    cursor: pointer;
    background-color: ${props => props.isActive && props.isOpen ?
        AppColors.MRNAVY50 :
        props.id === 'alerts' ?
            AppColors.MRNAVY30 :
            AppColors.MRNAVY30
    };
    color: ${props => (props.isActive && props.isOpen) || props.id === 'alerts' ?
        AppColors.MRWHITE :
        AppColors.MRLITE90
    };
    font-weight: ${props => props.isActive && props.isOpen ? 700 : 400};
    text-transform: uppercase;
    font-size: 14px;
    line-height: ${(!isMobile || isTablet) ? '24px' : '14px'};
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid ${props => AppColors.MRNAVY40};
    border-radius: 0 0 7px 7px;
    width: fit-content;
    white-space: nowrap;
    margin-right: 5px;
    margin-top: ${(!isMobile || isTablet) ? null : '21px'};
    transform: rotateY(${props => (props.rotate ? '3.142rad' : '0')}) rotate(180deg);
    &:hover {
        background-color: ${AppColors.MRNAVY60};
        color: ${AppColors.MRNAVY20};
    }
`;

const DrawerContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  transform: rotateY(${props => (props.rotate ? '3.142rad' : '0')});
  content: 'Drawer Module';
  overflow: hidden;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    z-index: 799;
    transform:  rotateY(${props => (props.rotate ? '3.142rad' : '0')});
`;

const ScrollableContainer = styled.div`
    width: 100%;
    height: 100%;
`;


const ResizeHandle = styled.div`
    position: absolute;
    right: 0;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
`;

const Drawer = ({ children, filterOpen, open, rotate, setFilterOpen, setWidth, startingWidth }) => {
    const [drawerWidth, setDrawerWidth] = useState('400px');
    const [dragging, setDragging] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const ref = useRef(null);
    const [tabs, setTabs] = useState([]);
    const {
        expandedViewTicker,
        handleSetLastChecked,
        setShowExpandedView,
    } = useContext(StateContext)

    useEffect(() => {
        if (Array.isArray(children)) {
            setTabs(children.filter(child => !!child));
        } else {
            setTabs([children]);
        }

        if (filterOpen && (!isMobile || isTablet)) {
            setActiveTab(0);
            setIsOpen(filterOpen);
        }
    }, [children]);

    useEffect(() => {
        return () => {
            setShowExpandedView(false);
        }
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!dragging) return;
            const movement = rotate ? -e.movementX : e.movementX;
            setDrawerWidth(prevWidth => Math.min(Math.max(parseInt(prevWidth) + movement, 400), 600) + 'px');
            if (setWidth) setWidth(prevWidth => Math.max(Math.min(parseInt(prevWidth) - movement, startingWidth - 400), startingWidth - 600));
        }

        const handleMouseUp = () => {
            if (dragging) {
                setDragging(false);
            }
        }

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
    }, [dragging, rotate]);

    useEffect(() => {
        if (rotate) {
            setIsOpen(open);
            const index = tabs.findIndex(tab => tab.props.title.includes('Summary') || tab.props.showActive);
            setActiveTab(index);
        }

        if (open && setWidth ) {
            setWidth(window.innerWidth - 954);
        }
    }, [open, expandedViewTicker]);

    useEffect(() => {
        if (Array.isArray(children)) {
            children?.forEach((child, index) => {
                if (child?.props?.open) {
                    setActiveTab(index);
                    setIsOpen(true);
                }
            });
        }
    }, [])

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleMouseDown = () => {
        setDragging(true);
    };

    const handleTabClick = ({ id, index }) => {
        setIsOpen(() => index === activeTab && isOpen ? false : true);
        if (setFilterOpen) {
            setFilterOpen(index === activeTab && isOpen ? false : true)
        }
    
        if (!isOpen) {
            setDrawerWidth('400px');
            if (setWidth) setWidth(prevWidth => prevWidth - 400);
        }

        if (isOpen) {
            if (setWidth) setWidth(prevWidth => index === activeTab && isOpen ? startingWidth : prevWidth);
        }
    
        if (rotate) {
            setShowExpandedView(prevState => index === activeTab && isOpen ? false : prevState);
        }
        setActiveTab(index);
    
        if (id === 'alerts') {
            handleSetLastChecked({ retry: true });
        }
    };

    return (
        <Wrapper ref={ref} rotate={rotate}>
            <DrawerWrapper
                drawerWidth={drawerWidth}
                isOpen={isOpen}
                rotate={rotate}
                style={{ userSelect: dragging ? 'none' : 'auto' }}
            >
                <ScrollableContainer>
                    {tabs[activeTab] && (
                        <DrawerContent rotate={rotate} >{tabs[activeTab]}</DrawerContent>
                        )}
                </ScrollableContainer>
                {(!isMobile || isTablet) ?
                    null :
                    <SideButton handleClick={handleClose} rotate={rotate} />
                }
                <ResizeHandle onMouseDown={handleMouseDown} />
            </DrawerWrapper>
            <DrawerTabWrapper drawerWidth={drawerWidth} isOpen={isOpen} rotate={rotate} >
                {tabs.map((tab, index) => (
                    <DrawerTab
                        id={tab?.props?.id}
                        index={index}
                        isActive={activeTab === index}
                        isOpen={isOpen}
                        key={index}
                        onClick={() => handleTabClick({ id: tab?.props?.id, index })}
                        rotate={rotate}
                    >
                        <div>
                            {tab?.props?.title}
                        </div>
                        {tab?.props?.number ?
                            <AlertNumber>
                                {tab?.props?.number}
                            </AlertNumber> :
                            null
                        }
                    </DrawerTab>
                ))}
            </DrawerTabWrapper>
        </Wrapper>
    );
};

export default Drawer;
