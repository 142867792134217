import React, { useEffect } from 'react';
import { ModalContainer, ModalOverlay } from '../../../Global/StyledComponents/AppStyledComponents';
import { CloseIcon } from '../../../Global/Icons/Icons';

const Modal = ({ children, height, image = false, onClose, width }) => {
    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalContainer height={height} image={image} onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }} width={width}>
                <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
                    <CloseIcon onClick={handleClose} />
                </div>
                {children}
            </ModalContainer>
        </ModalOverlay>
    );
};

export default Modal;
