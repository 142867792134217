import styled from "styled-components";
import { AppColors } from "../../StyledComponents/Graphics";

export const StyledName = styled.h2`
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0px;
    font-family: Mulish, Arial, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    max-width: 150px;
`;
export const StyledSector = styled.h2`
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0px;
    font-family: Mulish, Arial, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    max-width: 150px;
`;

export const TableContainer = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableCell = styled.td`
    position: relative;
    padding: 2px 10px;
    width: fit-content;
    min-width: ${props => props.minWidth ? props.minWidth : null};
`;

export const TableHeader = styled.th`
    padding: 2px 10px;
    text-align: left;
    position: sticky;
    top: 0;
    background-color: ${AppColors.MRNAVY18};
    z-index: 1000;
`;

export const TableRow = styled.tr`
    border-top: 1px solid #eee;
    cursor: pointer;
    &:hover {
        background-color: ${AppColors.MRNAVY30};
    }
`;
