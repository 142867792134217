import { useContext, useRef, useState } from 'react';
import Flag from 'react-world-flags';
import { string_to_unicode_variant } from "string-to-unicode-variant";

import {
    CardWrapper,
    CompanyName,
    CompanySector,
    MetricRow,
    SummaryRow,
    TopRow,
    DisplayInlineHalfLT,
    DisplayInlineHalfRT,
    Timestamp,
    Headline,
    HeadlineWrapper,
    IconBox,
    SparklineWrapper,
    Summary,
} from '../../../StyledComponents/StockCardStyledComponents';
import {
    ExtendedHoursIcon,
    DeleteIcon,
    ClockIcon,
    CopyIcon,
    AddIcon,
} from '../../../Icons/Icons';

import StockInfo from '../../StockInfo';
import {
    ordinalSuffixOf,
    round,
    setSortDisplay,
    formatDateTime,
    copyTextToClipboard,
    formatMarketCap
} from '../../../../Controllers/utils';
import { StateContext } from '../../../../Controllers/context/state';
import supabase from '../../../../Controllers/utils/supabaseClient';
import MoveClassificationView from './MoveClassifications';
import SparkLine from '../../SparkLine';
import { CFDs } from '../../../constants';
import WatchlistNames from './WatchlistNames';
import Tooltip from '../../Tooltip';
import { AuthContext } from '../../../../Controllers/context/auth';

const MainView = ({
    data,
    handleExpand,
    percentileMove,
    readOnly,
    watchlistCard,
    watchlistDashboard,
    watchlistItem,
}) => {
    const sparklineContainerRef = useRef();
    const [isCopied, setIsCopied] = useState(false);

    const {
        cardDimensions,
        customerProfile,
        fetchAllWatchlists,
        logError,
        sort,
        selectedWatchlist,
        setExpandedViewTicker,
        setSelectedWatchlist,
        setShowConfirmationWindow,
        setShowWatchlistPopup,
        showCorrelatedAssetsPopup,
    } = useContext(StateContext);

    const { user } = useContext(AuthContext);
    let moveColor;
    let plusSign = '';
    
    if (round(data?.date_return_oc * 100, 1) > 0 ) {
        plusSign = '+'
        moveColor ='MRGREEN';
    } else if (round(data?.date_return_oc * 100, 1) < 0){
        moveColor ='MRRED';
    } else if (round(data?.date_return_oc * 100, 1) === 0 ){
        moveColor ='MRLITE80';
    }

    const handleAdd = (e) => {
        setExpandedViewTicker(data?.symbol);
        setShowWatchlistPopup(prevState => !prevState);
    }

    const handleCopyClick = () => {
        const text = `${data?.name} (${data?.symbol}) [${plusSign}${round(data?.date_return_oc * 100, 1)}%, ${formatMarketCap(data?.date_return_oc * data?.mcap_mm)}]\n${data?.gsector, data?.gsubind}\n${data?.dtm_summary?.dtm_summary} \n—${string_to_unicode_variant(`Source: MarketReader Inc @ ${formatDateTime(data?.dtm_summary?.time_utc)}`, 'italic')}`
        copyTextToClipboard(text).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    const fetchWatchlist = ({ retry = true }) => {
        supabase.from('user_preferences').select('*').eq('id', selectedWatchlist?.id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    fetchWatchlist({ retry: false });
    
                    return;
                }

                return;
            }

            fetchAllWatchlists({ retry: true });
            setSelectedWatchlist(res?.data[0]);
        });
    };

    const handleDeleteFromWatchList = () => {
        const watchlistTickers = selectedWatchlist?.data?.filter((ticker) => ticker !== data?.symbol.toUpperCase());

        if (watchlistTickers.length) {
            const updatedWatchlist = {
                data: watchlistTickers,
                id: selectedWatchlist?.id,
                meta: selectedWatchlist?.meta,
                user_id: selectedWatchlist?.user_id,
            };

            setShowConfirmationWindow({
                handleCancel: () => setShowConfirmationWindow(false),
                handleConfirm: () => {
                    supabase.from('user_preferences').update(updatedWatchlist)
                        .eq('id', selectedWatchlist?.id).then((res) => {
                            if (res?.error) {
                                const error = new Error(res?.error?.message);
                                logError(error.message, undefined, error);
                    
                                return;
                            }
    
                            fetchWatchlist({ retry: true });
                            setShowConfirmationWindow(false);
                        });
                },
                headline: `Are you sure you want to delete ${data?.symbol} from your watchlist?`,
                show: true,
            });
        } else {
            setShowConfirmationWindow({
                handleCancel: () => setShowConfirmationWindow(false),
                handleConfirm: () => {
                    supabase.from('user_preferences').delete().eq('id', selectedWatchlist?.id).then((res) => {
                        if (res?.error) {
                            const error = new Error(res?.error?.message);
                            logError(error.message, undefined, error);
                
                            return;
                        }
    
                        fetchAllWatchlists({ retry: true });
                        setSelectedWatchlist(null);
                        setShowConfirmationWindow(false);
                    });
                },
                headline: `Are you sure you want to delete ${data?.symbol}?`,
                show: true,
                subheading: 'This will also delete the watchlist.'
            });
        }
    };

    return (
        <CardWrapper>
            <TopRow 
                onClick={readOnly ? null : handleExpand}
                style={{ cursor: readOnly ? 'default' : 'pointer' }}
            >
                <div style={{ display: 'flex' }}>
                    {!CFDs.includes(data?.type) &&
                        <ClockIcon style={{ marginRight: 5 }}/>
                    }
                    <CompanyName cardDimensions={cardDimensions}>
                        <h2>{data?.name}</h2>
                    </CompanyName>
                </div>
                <Flag
                    code={data?.meta_data?.country}
                    fallback={
                        <div 
                            style={{
                                height: 25,
                                position: 'absolute',
                                right: 10,
                                top: !data?.market_rth && cardDimensions?.data?.state > -3 ? 1 : 6,
                                width: 25,
                            }}
                        >
                            {data?.meta_data?.country}
                        </div>
                    }
                    style={{
                        height: 25,
                        position: 'absolute',
                        right: 10,
                        top: !data?.market_rth && cardDimensions?.data?.state > -3 ? 1 : 6,
                        width: 25,
                    }}
                />
                {cardDimensions?.data?.state >= 0 &&
                    <CompanySector cardDimensions={cardDimensions?.data}>
                        {CFDs.includes(data?.type) ?
                                (data?.type ==='currency_fiat' ?
                                    <h2>FX</h2> :
                                    null
                                ) :
                                <h2>{data?.gsector}, {data?.gsubind}</h2>
                            }
                    </CompanySector>
                }
                {!data?.market_rth && cardDimensions?.data?.state > -3 &&
                    <ExtendedHoursIcon cardDimensions={cardDimensions?.data}>EXT</ExtendedHoursIcon>
                }
                <StockInfo cardDimensions={cardDimensions?.data} data={data} />
            </TopRow>
            <SummaryRow
                onClick={readOnly ? null : handleExpand}
                style={{ cursor: readOnly ? 'default' : 'pointer' }}
            >
                {/* {cardDimensions?.data?.state > 0 &&
                    <MoveClassificationView
                        macro={data?.move_classification.dtm_macro}
                        sector={data?.move_classification.dtm_sector}
                        micro={data?.move_classification.dtm_micro}
                        percentile={data?.date_return_oc_percentile}
                        type={data?.type}
                    />
                } */}
                {watchlistDashboard &&
                    <WatchlistNames watchlistItem={watchlistItem} />
                }
                {cardDimensions?.data?.state > -4 && cardDimensions?.data?.state < 1 &&
                    <Headline>
                        {data?.dtm_summary?.dtm_headline}
                    </Headline>
                }
                {/* {data?.dtm_summary?.time_utc && cardDimensions?.data?.state === 0 && 
                    <Timestamp>
                        {formatDateTime(data.dtm_summary?.time_utc)}
                    </Timestamp>
                } */}
                {cardDimensions?.data?.state === 1 &&
                    <HeadlineWrapper>
                        <SparklineWrapper summary={data?.dtm_summary?.dtm_summary} ref={sparklineContainerRef}>
                            <SparkLine series={data?.spark_data} sparklineContainerRef={sparklineContainerRef} summary={data?.dtm_summary?.dtm_summary}/>
                        </SparklineWrapper>
                        <Summary>
                            {data?.dtm_summary?.dtm_summary}
                            <br />
                            {data?.dtm_summary?.time_utc &&
                                <Timestamp>
                                    {formatDateTime(data?.dtm_summary?.time_utc)}
                                </Timestamp>
                            }
                        </Summary>
                    </HeadlineWrapper>

                }
            </SummaryRow>
            <MetricRow cardDimensions={cardDimensions?.data}>
                <DisplayInlineHalfLT cardDimensions={cardDimensions?.data} percentileMove={percentileMove} space={cardDimensions?.data?.state > -1 ? '34%' : '30%'}>
                    {ordinalSuffixOf(round(data?.date_return_oc_percentile * 100, 0))}
                    {cardDimensions?.data?.state > -2 ? <br /> : null}
                    {cardDimensions?.data?.state > -1 ? 'Percentile Return' : ' Pctl Rtn'}

                </DisplayInlineHalfLT>
                <DisplayInlineHalfRT cardDimensions={cardDimensions?.data} moveColor={moveColor} space='35%'>
                    {sort?.key === 'symbol' ?
                        <>
                            Symbol
                            <br />
                            {sort?.asc ? 'A-Z' : 'Z-A'}
                        </> :
                        setSortDisplay(data, sort?.key, cardDimensions?.data?.state, showCorrelatedAssetsPopup)
                    }
                    
                </DisplayInlineHalfRT>
                {!readOnly &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '25%', height: cardDimensions?.state > -2 ? '40px' : '26px' }}>
                        {watchlistCard ?
                            <>
                                {selectedWatchlist?.user_id !== 'd21e72b8-a5f8-411a-aa32-1dd21f9a3e0a' && 
                                    <Tooltip position={'bottom'} width='50%' text='Remove from Watchlist'>
                                        <IconBox cardDimensions={cardDimensions?.data} onClick={handleDeleteFromWatchList} width='100%'>
                                            <DeleteIcon />
                                        </IconBox>
                                    </Tooltip>
                                }
                            </> :
                            <Tooltip position={'bottom'}  width='50%' text='Add to Watchlist'>
                                <IconBox cardDimensions={cardDimensions?.data} onClick={handleAdd} width='100%'>
                                    <AddIcon color />
                                </IconBox>
                            </Tooltip>

                        }
                        {customerProfile?.accessLevels?.includes('pro') &&
                            <Tooltip position={'bottom'}  width='50%' text='Copy to Clipboard'>
                                <IconBox cardDimensions={cardDimensions?.data} onClick={handleCopyClick} width='100%'>
                                    <CopyIcon selected={isCopied} />
                                </IconBox>
                            </Tooltip>
                        }
                    </div>
                }
            </MetricRow>
        </CardWrapper>
    );
};

export default MainView;
