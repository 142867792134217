import supabase from '../../../../Controllers/utils/supabaseClient.js';

// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['10', '1D'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        { value: 'NASDAQ', name: 'NASDAQ', desc: 'NASDAQ'},
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'Assets', value: 'Assets'}
    ],
    supports_marks: true,
    supports_timescale_marks: true,
};

const getAllSymbols = async ({ retry = true }) => {
        const res = await supabase.from('symbols').select('*');

        if (res?.error) {
            if (retry) {
                return getAllSymbols({ retry: false });
            }

            if (confirm(`There was an error loading the chart data.\n\nIf the error persists, please take a screenshot and send to support@marketreader.com\n\nError Message: ${res?.error?.message}`)
            ) {
                window.location.reload();
            } else {
                window.location.reload();
            }

            return;
        }

    

    const chartSymbols = res?.data?.map(item => ({
        description: `${item.symbol} - ${item.name}`,
        exchange: 'NASDAQ',
        full_name: `${item.symbol} - ${item.name}`,
        symbol: item.symbol,
        type: item.type,
    }));

    return chartSymbols;
}

export default {
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        const symbols = await getAllSymbols({ retry: true });
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        const symbols = await getAllSymbols({ retry: true });
        const symbolItem = symbols.find(({ full_name }) => {  
            return full_name === symbolName
        });
        if (!symbolItem) {
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }

        const symbolInfo = {
            ticker: symbolItem.symbol,
            name: symbolItem.full_name,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_intraday: true,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };

        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { countBack, to } = periodParams;
        let chartData;

        const fetchBars = async ({ count, endDate, endPoint, retry = true, symbol }) => {
            const { data, error } = await supabase
                    .rpc(endPoint, {
                        amount: count,
                        ticker: symbol,
                        epoch_date: endDate,
                    });

                    
            if (error) {
                if (retry) {
                        return fetchBars({ count, endDate, endPoint, retry: false, symbol });
                }

                if (confirm(`There was an error loading the chart data.\n\nIf the error persists, please take a screenshot and send to support@marketreader.com\n\nError Message: ${error?.message}`)
                ) {
                    window.location.reload();
                } else {
                    window.location.reload();
                }

                return;
            }

            return data;
        };
        
        try {
            if (resolution === '10') {
                const data = await fetchBars({
                    count: countBack,
                    endDate: to,
                    endPoint: 'ohlcv_10min_v2',
                    retry: true,
                    symbol: symbolInfo.ticker,

                });

                chartData = data;
            }

            if (resolution === '1D') {
                const data = await fetchBars({
                    count: countBack + 1,
                    endDate: to,
                    endPoint: 'ohlcv_1day_v2',
                    retry: true,
                    symbol: symbolInfo.ticker,

                });

                chartData = data;
            }

            if (chartData.length === 0) {
                onHistoryCallback([], { noData: true });
                return;
            }

            let bars = [];
            chartData.forEach(bar => {
                if (bar.time_utc < to * 1000) {
                    bars = [...bars, {
                        time: bar.time_utc,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }];
                }
            });

            onHistoryCallback(bars, { noData: false });
        } catch (error) {
            onErrorCallback(error);
        }
    },
    getTimescaleMarks: async (
        symbolInfo,
        startDate,
        endDate,
        onDataCallback,
        resolution
    ) => {
        let markerData = [];
        const fetchMarkers = async ({ aggregate, endPoint, epoch, retry = true, symbol }) => {
            const headers = aggregate ? {
                _aggr: aggregate,
                _symbol: symbol,
                _epoch: epoch,
            } : 
            {
                _symbol: symbol
            };
            const { data, error } = await supabase
                    .rpc(endPoint, headers);

                    
            if (error) {
                if (retry) {
                        return fetchMarkers({ aggregate, endPoint, epoch, retry: false, symbol });
                }

                if (confirm(`There was an error loading the chart data.\n\nIf the error persists, please take a screenshot and send to support@marketreader.com\n\nError Message: ${error?.message}`)
                ) {
                    window.location.reload();
                } else {
                    window.location.reload();
                }

                return;
            }

            return data;
        };

        if (resolution === '10') {
            const data = await fetchMarkers({
                aggregate: 'dtm',
                endPoint: 'get_marker_timestamps',
                epoch: startDate,
                retry: true,
                symbol: symbolInfo?.ticker,
            });

            markerData = data;
        }

        if (resolution === '1D') {
            const data = await fetchMarkers({
                endPoint: 'get_monthly_marker_timestamps',
                retry: true,
                symbol: symbolInfo?.ticker,
            });

            markerData = data;
        }

        const markers = markerData?.map(item => ({
            id: JSON.stringify({
                aggr: item.aggr,
                symbol: item.symbol,
                timestamp: item.ts,
            }),
            imageUrl: '../logo192.png',
            time: item.ts / 1000,
            color: 'black',
            labelFontColor: 'black',
        }));
    
        onDataCallback(markers);
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
    unsubscribeBars: (subscriberUID) => {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
};
