import { useState, useContext, useEffect, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import { OpenArrowLeftIcon } from '../../Global/Icons/Icons';
import {
    CenteredDiv,
    StockCardGrid,
    ViewModule,
} from '../../Global/StyledComponents/AppStyledComponents';
import { DefaultSort } from '../../Global/constants';
import Drawer from '../../Global/Components/Drawer';
import DropdownMenu from '../../Global/Components/DropdownMenu';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import FilterMenu from './components/FilterMenu';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import { identicalObjects } from '../../Controllers/utils';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import Pagination from '../../Global/Components/PaginationFooter';
import SavedScreenerView from '../../Global/Views/SavedScreenerView/SavedScreenerView';
import SortMenu from '../../Global/Components/SortMenu';
import { StateContext } from '../../Controllers/context/state';
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import { Header, StyledButton } from '../../Global/StyledComponents/UserFormStyledComponents';
import TableView from '../../Global/Views/TableView/TableView';
import SortBar from '../../Global/Components/SortBar';
import supabase from '../../Controllers/utils/supabaseClient';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';

const FilterWrapper = styled.div`
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;
`;

const ScreenerMonitor = () => {
    const [filterOpen, setFilterOpen] = useState(true)
    const [totalPages, setTotalPages] = useState(0);
    const limit = 50;
    const [intervalId, setIntervalId] = useState(null);
    const intervalIdToCancel = useRef();
    intervalIdToCancel.current = intervalId;
    const [loading, setLoading] = useState(true);
    const [paginatedList, setPaginatedList] = useState([])
    const scrollRef = useRef();
    const {
        customerProfile,
        currentScreenerPage,
        development,
        etfConstituents,
        expandedViewTicker,
        filter,
        filterOptions,
        logError,
        newAlerts,
        offSet,
        refreshRate,
        savedScreeners,
        selectedScreener,
        setCurrentScreenerPage,
        setEtfConstituents,
        setFilter,
        setOffset,
        setRefreshRate,
        setSelectedScreener,
        setShowErrorWindow,
        setSort,
        setTickerList,
        viewStyle,
        showExpandedView,
        sort,
        tickerList,
    } = useContext(StateContext);

    const handleBackToSavedScreeners = () => {
        setFilter(null);
        setSelectedScreener(null);
    };

    const handleFilter = ({ id, value }) => {
        firstPage(0);
        setFilter((prevState) => {
            let tempState = prevState;

            if (value === 'Any') {
                delete tempState[id];
                return { ...tempState };
            }

            return {
                ...prevState,
                [id]: value,
            };
        });
        setEtfConstituents(null);
    };

    const handleRefresh = ({ retry = true }) => {
        setLoading(true);
        supabase.rpc('getfilteredsortedlist', { request: {
            symbols_list: etfConstituents?.constituents,
            filter: filter || {},
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    handleRefresh({ retry: false });

                    return;
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setTickerList(entities);
            setPaginatedList(entities?.slice(offSet, (offSet + limit)));
            setTotalPages(Math.ceil(entities?.length / 50));
            setLoading(false);
        });
    };

    const handleSelectScreener = (e, value) => {
        const screener = value ?? e.target.value;
        if (screener === 'Select a saved screener') {
            setSelectedScreener(null);
            setFilter(null);
            setRefreshRate(null);
            setEtfConstituents(null);
        } else {
            const parsedScreener = JSON.parse(screener);
            const { filter, sort, refreshRate } = parsedScreener.data;
            setSelectedScreener(parsedScreener);
            setFilter(filter);
            setSort(sort);
            setEtfConstituents(null);
            setRefreshRate(refreshRate);
            setCurrentScreenerPage(1);
        }
    };

    useEffect(() => {
        return () => {
            clearInterval(intervalIdToCancel.current);
        };
    }, []);

    useEffect(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        if (refreshRate) {
            const intervalId = setInterval(() => handleRefresh({ retry: true }), refreshRate);
            setIntervalId(intervalId);
        }
    }, [refreshRate]);

    useEffect(() => {
        const fetchData = ({ retry = true }) => {
            supabase.rpc('getfilteredsortedlist', { request: {
                symbols_list: etfConstituents?.constituents,
                filter: filter || {},
                sort,
            }}).then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);
    
                    if (retry) {
                        fetchData({ retry: false });
                    }
        
                    return;
                }
    
                const { entities } = res?.data?.[0]?.symbol;
                setTickerList(entities);
                setPaginatedList(entities?.slice(offSet, (offSet + limit)));
                setTotalPages(Math.ceil(entities?.length / 50));
                setLoading(false);
            });
        };

        fetchData({ retry: true });
    }, [etfConstituents, filter, sort]);

    useEffect(() => {
        setPaginatedList(tickerList.slice(offSet, (offSet + limit)))
    }, [offSet]);

    const firstPage = () => {
        setCurrentScreenerPage(1);
        setOffset(0);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const lastPage = () => {
        setCurrentScreenerPage(totalPages);
        setOffset((limit * totalPages) - limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const nextPage = () => {
        setCurrentScreenerPage(prevState => prevState += 1);
        setOffset(prevState => prevState += limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const prevPage = () => {
        setCurrentScreenerPage(prevState => prevState -= 1);
        setOffset(prevState => prevState -= limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    return (
        <>
            <Drawer filterOpen={filterOpen} setFilterOpen={setFilterOpen}>
                <FilterWrapper
                    title='Filter Menu'
                >
                    <Header>
                        Number of Assets: {tickerList?.length}
                    </Header>
                    <div style={{ padding: '0 10px' }}>
                        <DropdownMenu
                            handleChange={handleSelectScreener}
                            id='screeners'
                            label='Saved Screeners'
                            options={savedScreeners}
                            value={selectedScreener}
                        />
                    </div>
                    <FilterMenu
                        handleRefresh={handleRefresh}
                        filter={filter}
                        filterOptions={filterOptions}
                        handleFilter={handleFilter}
                        setFilter={setFilter}
                    />
                </FilterWrapper>
            </Drawer>
            <ViewModule>
                {(filter || etfConstituents || refreshRate || selectedScreener || (sort.correlationAsset && sort.correlationAsset !== 'SPY')) &&
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', justifyContent: 'center', padding: '0px 20px', paddingBottom: '2px', position: 'relative' }}>
                        {(filter || selectedScreener) &&
                            <>
                                {selectedScreener ?
                                    <h1 style={{ textAlign: 'center' }}>
                                        {selectedScreener.meta.name}
                                        {
                                            (!identicalObjects(selectedScreener?.data?.filter, filter) ||
                                            !identicalObjects(selectedScreener?.data?.sort, sort) ||
                                            (selectedScreener?.data.refreshRate && selectedScreener?.data.refreshRate !== refreshRate)) &&
                                            ' (Unsaved Changes)'
                                        }
                                    </h1> :
                                    ((filter && Object.keys(filter).length) ||
                                    (!identicalObjects(DefaultSort, sort)) ||
                                    (refreshRate)) ?
                                        <h1 style={{ textAlign: 'center' }}>
                                            (Unsaved Changes)
                                        </h1> :
                                        null
                                }
                                <div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
                                    <StyledButton
                                        onClick={handleBackToSavedScreeners}
                                        style={{ margin: '0 auto', width: 'fit-content' }}
                                    >
                                        <OpenArrowLeftIcon style={{ marginLeft: 0 }} />
                                    </StyledButton>
                                    <SortBar firstPage={firstPage} />
                                    {(!isMobile || isTablet) && customerProfile?.accessLevels?.includes('pro') &&
                                        <div style={{ position: 'absolute', top: 0, right: 20 }}>
                                            <ExportToCsv />
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {etfConstituents &&
                            <h1 style={{ textAlign: 'center' }}>
                                ETF Constituents For {etfConstituents.name} ({etfConstituents.symbol})
                            </h1>}
                    </div>
                }
                {loading ?
                    <Loading /> :
                    (<>
                        <div style={{ height: 'calc(100% - 110px)'}}>
                            {(filter || selectedScreener || (sort.correlationAsset && sort.correlationAsset !== 'SPY')) ?
                                (paginatedList?.length ?
                                    <StockCardGridComponent>
                                        {viewStyle?.data?.showCards ?
                                            <StockCardView tickerList={paginatedList} /> :
                                            <TableView tickerList={paginatedList} />}
                                    </StockCardGridComponent> :
                                    <CenteredDiv ref={scrollRef} style={{ padding: (!isMobile || isTablet) ? null : '20px' }}>
                                        No stocks available for selected criteria. Please try different filter selections.
                                    </CenteredDiv>
                                ) :
                                <StockCardGrid ref={scrollRef}>
                                    <SavedScreenerView
                                        handleSelectScreener={handleSelectScreener}
                                    />
                                </StockCardGrid>
                            }
                        </div>
                        {totalPages > 1 && (filter || sort.correlationAsset !== 'SPY') ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Pagination
                                    currentPage={currentScreenerPage}
                                    totalPages={totalPages}
                                    firstPage={firstPage}
                                    lastPage={lastPage}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                />
                            </div> :
                            null
                        }
                    </>
                    )
                }
            </ViewModule>
            <Drawer open={showExpandedView} rotate={'true'} >
                <FeedViewer tickerList={tickerList} title='News Feed' />
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <ExpandedView title={`${expandedViewTicker} Summary`} />
                {/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts' /> */}
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    )
}

export default ScreenerMonitor;
