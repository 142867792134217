import React, { useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';

const ToggleWrapper = styled.div`
    width: fit-content;
    background-color: ${AppColors.MRNAVY40};
    border: 1px solid ${AppColors.MRNAVY50};
    border-radius: 5px;
    padding: 1px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
    margin: 10px;
`;

const ToggleSlider = styled.div`
    width: fit-content;
    background-color: ${AppColors.MRGREEN};
    border-radius: 4px;
    text-align: center;
    color: ${AppColors.MRNAVY};
    padding: 10px;
    transition: transform 0.2s;
    position: absolute;
    right: ${props => props.isToggled ? '1px' : null };
    left: ${props => !props.isToggled ? '1px' : null };
`;

const ToggleOption = styled.span`
    padding: 10px;
    width: fit-content;
    text-align: center;
    color: ${AppColors.MRLITE80};
`;

const ToggleSlide = ({ handleChange, left, right, toggled = false }) => {
    const [isToggled, setIsToggled] = useState(() => toggled);

    const toggleSwitch = () => {
        setIsToggled(!isToggled);
        handleChange();
    };

    return (
        <ToggleWrapper onClick={toggleSwitch} isToggled={isToggled}>
            <ToggleOption>{left}</ToggleOption>
            <ToggleOption>{right}</ToggleOption>
            <ToggleSlider isToggled={isToggled}>
                {isToggled ? right : left}
            </ToggleSlider>
        </ToggleWrapper>
    );
};

export default ToggleSlide;
