import { useState, useEffect, useCallback } from 'react';
import supabase from '../utils/supabaseClient';

const useFetchSavedScreeners = ({ logError }) => {
    const [screenersLoading, setScreenersLoading] = useState(true);
    const [savedScreeners, setSavedScreeners] = useState([]);

    const fetchAllSavedScreeners = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('user_preferences').select('*').eq('type', 'screener');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllSavedScreeners({ retry: false });

                return;
            }
        }
        
        const savedScreeners = res?.data?.map((screener) => {
            return {
                label: screener.meta.name,
                value: JSON.stringify(screener),
            };
        });

        const allSavedScreeners = [
            {
                label: 'Select a saved screener',
                value: null,
            },
          ...savedScreeners,
        ];

        setSavedScreeners(allSavedScreeners);
        setScreenersLoading(false);
    }, []);

    useEffect(() => {
        fetchAllSavedScreeners({ retry: true });
    }, []);

    return {
        fetchAllSavedScreeners,
        savedScreeners,
        screenersLoading,
    };
};

export default useFetchSavedScreeners;
