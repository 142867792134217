import { useEffect, useState } from "react";
import { ordinalSuffixOf, round, setSortDisplay } from "../../../Controllers/utils";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import {
    CardContainer,
    CardWrapper,
    CompanyName,
    CompanySector,
    DisplayInlineHalfLT,
    DisplayInlineHalfRT,
    Headline,
    MetricRow,
    SummaryRow,
    TopRow
} from "../../../Global/StyledComponents/StockCardStyledComponents";
import StockInfo from "../../../Global/Components/StockInfo";
import { CFDs } from "../../../Global/constants";
import { ClockIcon } from "../../../Global/Icons/Icons";

const SampleCardContainer = styled(CardContainer)`
    cursor: ${props => props.readOnly ? 'default' : 'pointer'};
    *,
    ::before,
    ::after {
    box-sizing: border-box; /* 1 */
    }
`;

const SampleCard = ({ data, readOnly }) => {
    const [moveColor, setMoveColor] = useState('MRLITE80');
    const [percentileMove, setpercentileMove] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (round(data.date_return_oc * 100, 1) > 0 ) {
            setMoveColor('MRGREEN');
        } else if (round(data.date_return_oc * 100, 1) < 0){
            setMoveColor('MRRED');
        }

        const percentile = ( round(data.date_return_oc_percentile * 100,2) ) ;
                        
        if (percentile >= 97) {
            setpercentileMove('ALPH');
        } else if (percentile >= 93) {
            setpercentileMove('BETA');
        } else if (percentile >= 84) {
            setpercentileMove('GAMM');
        } else if (percentile >= 68) {
            setpercentileMove('DELT');
        } else if (percentile >= 34) {
            setpercentileMove('EPIS');
        } else if (percentile >= 16) {
            setpercentileMove('IOTA');
        } else if (percentile >= 7) {
            setpercentileMove('KAPP');
        } else if (percentile >= 3) { 
            setpercentileMove('LAMB');
        } else if (percentile >= 0) { 
            setpercentileMove('ZETA');
        }
    }, [data]);

    const handleClick = () => {
        navigate('/signup')
    };

    return (
        <SampleCardContainer onClick={readOnly ? null : handleClick} readOnly>
            <CardWrapper>
                <TopRow style={{ cursor: readOnly ? 'default' : 'pointer' }}>
                    <div style={{ display: 'flex' }}>
                        {!CFDs.includes(data.type) &&
                            <ClockIcon style={{ marginRight: 5 }}/>
                        }
                        <CompanyName>
                            <h2>{data.name}</h2>
                        </CompanyName>
                    </div>
                    <CompanySector><h2>{data.gsector}, {data.gsubind}</h2></CompanySector>
                    <StockInfo data={data} />
                </TopRow>

                <SummaryRow style={{ cursor: readOnly ? 'default' : 'pointer' }}>
                    <Headline>
                        {data.dtm_summary?.dtm_headline}&nbsp;
                    </Headline>
                    {/* {data?.dtm_summary?.time_utc && 
                        <Timestamp>
                            {formatDateTime(data.dtm_summary?.time_utc)}
                        </Timestamp>
                    } */}
                </SummaryRow>

                <MetricRow cardDimensions >
                    <DisplayInlineHalfLT percentileMove={percentileMove} space='40%'>
                        {ordinalSuffixOf(round(data.date_return_oc_percentile * 100, 0))}
                        <br />
                        Percentile Return

                    </DisplayInlineHalfLT>
                    <DisplayInlineHalfRT moveColor={moveColor} space='42%'>
                        {
                            setSortDisplay(data, 'date_return_oc_voladj')
                        }
                    </DisplayInlineHalfRT>
                </MetricRow>
                
            </CardWrapper>
        </SampleCardContainer>
    )
};

export default SampleCard;
