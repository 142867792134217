import React, { useContext, useEffect, useRef, useState } from 'react';
import { widget } from '../../../../charting_library';
import Datafeed from './support/datafeed.js'
import { StateContext } from '../../../Controllers/context/state.js';
import { localEpochToUTC } from "../../../Controllers/utils";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const TVChartContainer = ({ height, readOnly, symbol, width }) => {
	const chartContainerRef = useRef();
	const {
		setChartInfo,
		setChartTimeStamp,
		setMonthlySummaryTimestamp,
		setShowChartInfoWindow,
		setShowMonthlySummary,
	} = useContext(StateContext);
	const [chartData, setChartData] = useState(null);
	const [barClicked, setBarClicked] = useState(false);
	const [resolution, setResolution] = useState('10min');

	const handleMouseDown = () => {
		setChartTimeStamp({aggr: resolution, timestamp: localEpochToUTC(chartData.time)});
		setBarClicked(false);
	};

	useEffect(() => {
		if (barClicked && !readOnly) {
			handleMouseDown();
		}
	}, [barClicked]);


	const defaultProps = {
		symbol: symbol || 'SPY - SPDR S&P 500 ETF Trust',
		interval: '10',
		libraryPath: '/charting_library/',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	
	useEffect(() => {
		const widgetOptions = {
			symbol: defaultProps.symbol,
			datafeed: Datafeed,
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'utc',
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['two_character_bar_marks_labels', 'study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			theme: 'dark',
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			const activeChart = tvWidget.activeChart();

			tvWidget.subscribe('onTimescaleMarkClick', (markId) => {
				const info = JSON.parse(markId);

				if (info.aggr === 'dtm') {
					setChartInfo(info);
					setShowChartInfoWindow(true);
				} else {
					setMonthlySummaryTimestamp(info.timestamp)
					setShowMonthlySummary(true);
				}
			});

			let barIndicatorId;
			let timestamp;

			activeChart.crossHairMoved().subscribe(
				null,
				({ time }) => {
					timestamp = time
					setChartData({ time });
				}
			);

			activeChart.onIntervalChanged().subscribe(null, (interval) => {
				setResolution(interval === 10 ? '10min' : 'dtm');
			});

			tvWidget.subscribe('mouse_down', () => {
				if (barIndicatorId) activeChart.removeEntity(barIndicatorId);

				barIndicatorId = tvWidget.chart().createShape(
					{ time: localEpochToUTC(timestamp) },
					{
						shape: "vertical_line",
						color: 'red',
					}
				);
				setBarClicked(true);
			});
		});


		return () => {
			tvWidget.remove();
		};
	}, [symbol]);

	return (
		<div
			ref={chartContainerRef}
			style={{ height: height, width: width }}
		></div >
	);
};

export default TVChartContainer;