
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { AppColors } from "../../../Global/StyledComponents/Graphics";
import {
    CTAButton,
    CardGridModalWrapper,
    StockCardGrid,
} from "../../../Global/StyledComponents/AppStyledComponents";
import { DefaultSort } from "../../../Global/constants";
import { Header } from "../../../Global/StyledComponents/UserFormStyledComponents";
import Pagination from "../../../Global/Components/PaginationFooter";
import { StateContext } from "../../../Controllers/context/state";
import StockCardView from '../../../Global/Views/StockCardView/StockCardView';
import supabase from "../../../Controllers/utils/supabaseClient";

const EtfConstituentsPopUp = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 50;
    const navigate = useNavigate();
    const [offSet, setOffset] = useState(0);
    const [paginatedList, setPaginatedList] = useState([]);
    const scrollRef = useRef();
    const [tickerList, setTickerList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const {
        etfConstituentsTemp,
        expandedViewTicker,
        logError,
        setEtfConstituents,
        setFilter,
        setShowIndustryPeers,
        setSelectedScreener,
        setShowEtfConstituentsPopup,
        setSort,
    } =  useContext(StateContext);

    useEffect(() => {
        const fetchData = ({ retry = true }) => {
            supabase.rpc('getfilteredsortedlist', { request: {
                filter: {},
                symbols_list: etfConstituentsTemp?.constituents,
                sort: DefaultSort,
            }}).then((res) => {
                if (res?.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);

                    if (retry) {
                        fetchData({ retry: false });

                        return;
                    }

                    setShowEtfConstituentsPopup(false);
        
                    return;
                }
    
                const { entities } = res?.data?.[0]?.symbol;
                setTickerList(entities);
                setPaginatedList(entities?.slice(offSet, (offSet + limit)));
                setTotalPages(Math.ceil(entities?.length / 50));
            });
        };

        fetchData({ retry: true });
    }, [expandedViewTicker]);

    useEffect(() => {
        setPaginatedList(tickerList.slice(offSet, (offSet + limit)))
    }, [offSet]);

    const handleOpenScreener = () => {
        setEtfConstituents(etfConstituentsTemp);
        setShowIndustryPeers(false);
        setFilter({});
        setSort(DefaultSort);
        setSelectedScreener(null);
        setShowEtfConstituentsPopup(false);
        navigate('/screener');
    };

    const firstPage = () => {
        setCurrentPage(1);
        setOffset(0);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const lastPage = () => {
        setCurrentPage(totalPages);
        setOffset((limit * totalPages) - limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const nextPage = () => {
        setCurrentPage(prevState => prevState += 1);
        setOffset(prevState => prevState += limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    const prevPage = () => {
        setCurrentPage(prevState => prevState -= 1);
        setOffset(prevState => prevState -= limit);
        scrollRef?.current?.scrollTo(0, 0);
    };

    return (
        <CardGridModalWrapper>
            <CTAButton onClick={handleOpenScreener} style={{ top: -20, color: 'white', background: AppColors.MRNAVY40, position: 'absolute', left: 0, width: 'fit-content' }}>
                Open In Screener
            </CTAButton>
            <Header style={{ paddingTop: (!isMobile || isTablet ) ? null : 30}}>
                ETF Constituents For {etfConstituentsTemp.name} ({etfConstituentsTemp.symbol})
            </Header>
            <StockCardGrid ref={scrollRef} style={{ height: (!isMobile || isTablet ) ? '90%' : '75%' }}>
                <StockCardView readOnly tickerList={paginatedList} />
            </StockCardGrid>
            {totalPages > 1 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        firstPage={firstPage}
                        lastPage={lastPage}
                        nextPage={nextPage}
                        prevPage={prevPage}
                    />
                </div>
            }
        </CardGridModalWrapper>
    )
};

export default EtfConstituentsPopUp;
