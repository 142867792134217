import {
    SubmitButton,
    Container,
    FormWrapper,
} from "../../Global/StyledComponents/UserFormStyledComponents";
import { useSearchParams } from "react-router-dom";
import { WhiteTextGlassLogo } from "../../Global/Logos/Logos";

const AuthConfirmationMonitor = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const confirmation_url = searchParams.get('confirmation_url');

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.replace(`${confirmation_url}/setPassword`);
    };

    return (
        <Container>
            <FormWrapper>
                <WhiteTextGlassLogo />
                <br />
                <SubmitButton onClick={handleSubmit}>Set Password</SubmitButton>
            </FormWrapper>
        </Container>
    );
};

export default AuthConfirmationMonitor;
