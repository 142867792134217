import { AppColors } from '../../../StyledComponents/Graphics';
import styled from 'styled-components';


const StockChartContainer = styled.div`
	background: #0a1926;
    width:${({ width }) => (width)}px;
    height: 100%;
`;

const StockSelect = styled.select`
	background-color: ${AppColors.MRNAVY15};
	color: ${AppColors.MRLITE90};
	border: 1px solid ${AppColors.MRNAVY60};
	z-index: 999;
	margin: 0 5px;
	padding: 5px;
	height: 44px;
`;

const TickerInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 10px;

	h1 {
		font-size: 16px;
		line-height: 28px;
		margin: 0;
	}

	h2 {
		font-size: 12px;
		margin: 0;
	}

	@media (max-width: 768px) {
		margin: 0;
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
    padding:10px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const Items = styled.div`
	display: flex;
    gap: 10px;
    
	@media (max-width: 768px) {
		margin-right: 0;
	}
`;


export { Container, Items, TickerInfo, StockChartContainer, StockSelect };