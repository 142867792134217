
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window.moment = Moment;
mTZ();
const queryParams = new URLSearchParams(window.location.search);

export function model_output_header(candle_data, model_outputs, symbol, dtm = false, dtm_start = 0) {
    // HERE WE HANDLE THE HEADER FOR THE TOOLTIP
    
    var final = '';
    if (dtm === false) {
        final += "<div style='font-size:14px;'><b><img src='https://demo.marketreader.com/img/MarketReader_400.png' width=25 style='display:inline;vertical-align:text-bottom;'> " + 
            Moment(new Date(model_outputs[0].time_utc).toLocaleString("en-US")).format("MMM Do") + 
            ", " + 
            Moment(new Date(model_outputs[0].time_utc).toLocaleString("en-US")).format("h:mma") +
            " - " + 
            Moment(new Date(new Date(model_outputs[0].time_utc).getTime() + (10*60000)).toLocaleString("en-US")).format("h:mma")
            +"</b>";
    } else {
            final += "<div style='font-size:14px;'><b><img src='https://demo.marketreader.com/img/MarketReader_400_purple.png' width=25 style='display:inline;vertical-align:text-bottom;'> " + 
            Moment(new Date(dtm_start).toLocaleString("en-US")).format("MMM Do") + 
            ", " + 
            Moment(new Date(dtm_start).toLocaleString("en-US")).format("h:mma") +
            " - " + 
            Moment(new Date(model_outputs[0].time_utc).toLocaleString("en-US")).format("MMM Do") + 
            ", " + 
            Moment(new Date(new Date(model_outputs[0].time_utc).getTime() + (10*60000)).toLocaleString("en-US")).format("h:mma")
            +"</b>";
    }
    // final += `<span onclick="copyToClipboard('${window.location.origin}/?symbol=${symbol}&time_utc=${candle_data.x/1000}','${symbol}',${candle_data.x})" style='float:right;'>
    //     <img width='25px;' src='/img/icons8-copy-link-48.png'>
    //     </span>`

    if (dtm === false) {
        final += "<div style='text-indent:0em;padding-left:1.5em;font-size:12px;'>";
        var up = '';
        if (candle_data.return_oc > 0) {
            final += "<svg style='fill:#2E875F;vertical-align:middle;display:inline;' height='20px' width='25px'><path d='M0 0h4v4H0z' fill='none'></path><path d='M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z'></path></svg>";
            up = '+';
        } else {
            final += "<svg style='fill:#EE6352;vertical-align:middle;display:inline;' height='20px' width='25px'><path d='M0 0h24v24H0z' fill='none'></path><path d='M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z'></path></svg>";
            up = '';
        }
        try {
            final += up + 
            ((candle_data.return_oc * 100) / 1).toFixed(1) + '% over 10min <span style="font-size:10px;">(' + 
            up + candle_data.return_oc_voladj.toFixed(1) + 
            'σ, Volume: ' + candle_data.volume_dollar_voladj.toFixed(1) + 'σ)</span></div>';
        } catch { }
    } else {

    }
    final += "<div style='text-indent:0em;padding-left:1.5em;font-size:12px;'>";
    if (candle_data.date_return_oc > 0) {
        final += "<svg style='fill:#2E875F;;vertical-align:middle;display:inline;' height='20px' width='25px'><path d='M0 0h4v4H0z' fill='none'></path><path d='M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z'></path></svg>";
        up = '+';
    } else {
        final += "<svg style='fill:#EE6352;;vertical-align:middle;display:inline;' height='20px' width='25px'><path d='M0 0h24v24H0z' fill='none'></path><path d='M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z'></path></svg>";
        up = '';
    }
    try {
        final += up + ((candle_data.date_return_oc * 100) / 1).toFixed(1) + '% from close <span style="font-size:10px;">(' + 
        up + candle_data.date_return_oc_voladj.toFixed(1) + 
        'σ, Volume: ' + candle_data.date_volume_dollar_voladj.toFixed(1) + 
        'σ)</span></div>';
    } catch { }
    
    final += "</div>"
    return final;
}

export function agg_summary(candle_data, model_output, dtm=false) {
    var final = '';
    final += '<div style="display:flex;">';
    final += model_output[0].data_out.summary;
    final += '</div>';
    return final;
}

export function macro_sector_tags(candle_data, dtm=false) {
    var final = '';
    /*
    THIS SECTION IS FOR THE MACRO/SECTOR FLAGS
    */
    final += '<div style="display:flex;">'
    try {
        if (candle_data.spy_return_oc_percentile < 0.05 || 0.95 < candle_data.spy_return_oc_percentile) {
            final += "<div style='padding:5px;'><span style='border-radius:10px;background:#5F2BFF;color:#FFFFFF;padding-left:5px;padding-right:5px;width:200px;height:5px;'>";
            final += 'Macro Move';// <span style="font-size:12px;">(SPY ' + (candle_data.spy_return_oc_voladj>0?'+':'') + candle_data.spy_return_oc_voladj.toFixed(1) + 'σ)</span>';
            final += "</div>";
        }
        if (candle_data.sector_spy_residual_percentile < 0.05 || 0.95 < candle_data.sector_spy_residual_percentile) {
            final += "<div style='padding:5px;'><span style='border-radius:10px;background:#5F2BFF;color:#FFFFFF;padding-left:5px;padding-right:5px;width:200px;height:5px;'>";
            final += 'Sector Move';// <span style="font-size:12px;">(' + (candle_data.sector_return_oc_voladj>0?'+':'') + candle_data.sector_return_oc_voladj.toFixed(1) + 'σ)</span>';
            final += "</div>";
        }
    
        var macro =  Math.round((dtm ? candle_data.date_spy_return_oc_percentile : candle_data.spy_return_oc_percentile)*100);
        if (candle_data.date_spy_return_oc_percentile == null) {
            macro = 0;
        }
        var sector = Math.round((dtm ? candle_data.date_sector_spy_residual_percentile : candle_data.sector_spy_residual_percentile)*100);
        var micro = Math.round((dtm ? candle_data.date_residual_sector_return_oc_percentile : candle_data.residual_sector_return_oc_percentile)*100);
        final += '</div>';
        final += '<div style="horizontal-align:center; font-size:10px; padding-left:1.5em">';
        final += `<span style="padding:5px;">Macro Score: ${macro}</span> | <span style="padding:5px;">Sector Score: ${sector}</span> | <span style="padding:5px;">Micro Score: ${micro}</span>`;
    } catch {}
    final += '</div>';

    return final;
}

export function news_model_argo(candle_data, model_output) {
    var text = '';
    text += ((candle_data.spy_return_oc_percentile > 0.05 && 0.95 > candle_data.spy_return_oc_percentile) && (candle_data.sector_spy_residual_percentile > 0.05 && 0.95 > candle_data.sector_spy_residual_percentile) ? '<span style="color:#5F2BFF;">' : '<span>');
    text += "<i>News:</i><br/>";
    text += '</span>';
    if (model_output[0].data_out.hasOwnProperty('summary') || 'summary' in model_output[0].data_out) {
        // if (model_output[0].data_out['headlines'].length>1) {
            var summary = (model_output[0].data_out.summary.replace(/\n/g,"<br/>")).replace('<br/><br/>', '');
            text += `
                <div style="text-indent:-1.5em;padding-left:1.5em;font-size:14px;color:#000000;">
                <img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline" />
                <span style="font-size:12px"> ` + summary + `</span>
                </div>`;
        // } else {
        //     model_output[0].data_out['headlines'].forEach(function(newsItem) {    
        //         text += '<div style="text-indent:-1.5em;padding-left:1.5em;padding-top:5px;font-size:12px;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=15 style="vertical-align:middle;display:inline"><b> ' + newsItem.headline + '</b></div>';
        //     })
        // }
    } else {
        model_output.forEach(function(newsItem) {
            text += '<div style="text-indent:-1.5em;padding-left:1.5em;padding-top:5px;font-size:12px;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=15 style="vertical-align:middle;display:inline"><b> ' + newsItem.data_out.headline + '</b></div>';
        })
    }
    return text;
}
export function news_newswhip_dtm_argo(candle_data, model_output) {
    var text = '';
    text += "<i>Summarized Articles:</i><br/>";
    text += '</span>';
    model_output.forEach(function(newsItem) {
        if (newsItem.data_out.hasOwnProperty('summary')) {
            try {
                var summary = (newsItem.data_out.summary.text.replace(/\n/g,"<br/>")).replace('<br/><br/>', '');
                text += '<div style="text-indent:-1.5em;padding-left:1.5em;font-size:14px;color:#000000;"><img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><span style="font-size:12px"> ' + summary + '</span></div>';
            } catch {
                var summary = (newsItem.data_out.summary.replace(/\n/g,"<br/>")).replace('<br/><br/>', '');
                text += '<div style="text-indent:-1.5em;padding-left:1.5em;font-size:14px;color:#000000;"><img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><span style="font-size:12px"> ' + summary + '</span></div>';
            }
            // newsItem.data_out.articles.forEach(function(item){
            //     text += `<div style="text-indent:-1.5em;padding-left:1.5em;padding-top:5px;font-size:14px;color:#000000;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=15 style="vertical-align:middle;"> ${item.headline} (<a href='${item.link}'>link</a>)</div>`
            // })
        } 
    })
    return text;
}
export function news_newsware_dtm_argo(candle_data, model_output) {
    var text = '';
    text += "<i>In the Headlines:</i><br/>";
    // text += '</span>';
    model_output.forEach(function(newsItem) {
        if ('summary' in newsItem.data_out) {
            var summary = (newsItem.data_out.summary.replace(/\n/g,"<br/>")).replace('<br/><br/>', '');
            text += `
                <div style="text-indent:-1.5em;padding-left:1.5em;font-size:14px;color:#000000;">
                <img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><span style="font-size:12px" />
                <span style="font-size:12px"> ` + summary + `</span>
                </div>`;
            // newsItem.data_out.articles.forEach(function(item){
            //     text += `<div style="text-indent:-1.5em;padding-left:1.5em;padding-top:5px;font-size:14px;color:#000000;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=15 style="vertical-align:middle;"> ${item.headline} (<a href='${item.link}'>link</a>)</div>`
            // })
        } 
    })
    return text;
}

export function calendar_model_argo(candle_data, model_output) {
    var text = '';
    
    text += "<i>Events:</i><br/>";
    
    var event = model_output[0].data_out;
    // text += '<li style="text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;">';
    var newOutput = false;
    try {
        newOutput = event.events.length ?? event.summary;
    } catch (error) {
        console.log(error);
    }

    if (newOutput) {
        text += `<div style='text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;'><img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><b> ` + event.summary + `</b></div>`;
        // text += `<div style='text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;'><b>`;
        // text += event.summary;
        // console.log(event);
        // text += '</span></div>';
        // event.summary 
        // event.events.forEach((event) =>{
        //     text += `<div style='text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;'><b>`;
        //     text += ((candle_data.spy_return_oc_percentile < 0.05 || 0.95 < candle_data.spy_return_oc_percentile) ? '<span style="color:#5F2BFF;">' : '<span>') 
        //     // text += 'Macro - ';
        //     text += event.event.event;
        
        //     if (event.event.actual != null) {
        //         var actual = event.event.actual;
        //         var forecast = event.event.forecast;
        //         if (event.event.unit === '%') {
        //             actual *= 100;
        //             forecast *= 100;
        //         }
        //         actual = (actual>0?'+':'') + actual.toFixed(1) + event.event.unit;
        //         text += ': ' + actual
        //         if (forecast) {
        //             forecast = (forecast>0?'+':'') + forecast.toFixed(1) + event.event.unit;
        //             text += '  <span style="font-size:12px;">(' + forecast + ' exp.)</span>'
        //         }
        //     }
        //     text += '</span></div>';
        // })
    } else if (event.event_type === 'Macro') {
        text += ((candle_data.spy_return_oc_percentile < 0.05 || 0.95 < candle_data.spy_return_oc_percentile) ? '<span style="color:#5F2BFF;">' : '<span>') 
        // text += 'Macro - ';
        text += event.event.event;
    
        if (event.event.actual != null) {
            var actual = event.event.actual;
            var forecast = event.event.forecast;
            if (event.event.unit === '%') {
                actual *= 100;
                forecast *= 100;
            }
            actual = (actual>0?'+':'') + actual.toFixed(1) + event.event.unit;
            text += ': ' + actual
            if (forecast) {
                forecast = (forecast>0?'+':'') + forecast.toFixed(1) + event.event.unit;
                text += '  <span style="font-size:12px;">(' + forecast + ' exp.)</span>'
            }
        }
        text += '</span>';
    } else if (event.event_type === 'Micro' && event.event_name === 'Earnings') {
        text += event.event_name; 
        if (event.event.epsestimate !== null) {
            if (event.event.epsactual !== null) {
                text += ' ' + (event.event.epsactual>=event.event.epsestimate?'<span style="color:#2E875F;">Beat</span> ':'<span style="color:#EE6352;">Miss</span> ') + '- EPS: ' + (event.event.epsactual<=0?"":"+") + event.event.epsactual.toFixed(2) + ' <span style="font-size:12px;">(' + (event.event.epsestimate<=0?"":"+") + event.event.epsestimate.toFixed(2) + ' est.)</span>';
            } else {
                text += ' - EPS: <span style="font-size:12px;">(' + (event.event.epsestimate<=0?"":"+") + event.event.epsestimate.toFixed(2) + ' est.)</span>';
            }
        }   
    }
    try {
        text += '<br/><span style="font-size:12px;">Event Adjusted Return: ' + (event.stats.return_oc_voladj > 0 ? '+' : '') + event.stats.return_oc_voladj.toFixed(1) + 'σ, Volume:'+event.stats.volume_voladj.toFixed(1)+'σ</span>';
    } catch {
        try {
            text += '<br/><span style="font-size:12px;">Event Adjusted Return: ' + (event.stats.return_oc_voladj > 0 ? '+' : '') + event.stats.return_oc_voladj.toFixed(1) + 'σ, Volume:'+event.stats.volume_voladj.toFixed(1)+'σ</span>';    
        } catch (error) {
        }
    }
    
    text += '</li>';
    return text;
}

export function factor_etf_argo(candle_data, model_output, dtm=false) {
    var text = '';

    // var factors = model_output;
    var newOutput = false;
    var summary = undefined;

    try {
        text += ((candle_data.spy_return_oc_percentile > 0.05 && 0.95 > candle_data.spy_return_oc_percentile) && (candle_data.sector_spy_residual_percentile > 0.05 && 0.95 > candle_data.sector_spy_residual_percentile) ? '<span style="color:#5F2BFF;">' : '<span>');
    } catch {}
    
    text += "<i>ETF Constituents:</i><br/>";
    text += '</span>';

    // try {
    if ('summary' in model_output[0].data_out) {
        summary = model_output[0].data_out.summary;
        text += `<div style='text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;'><img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><b> ` + summary + `</b></div>`;
    }
    return text;

}

export function factor_model_argo(candle_data, model_output, dtm=false) {
    var text = '';

    // var factors = model_output;
    var newOutput = false;
    var summary = undefined;

    try {
        text += ((candle_data.spy_return_oc_percentile > 0.05 && 0.95 > candle_data.spy_return_oc_percentile) && (candle_data.sector_spy_residual_percentile > 0.05 && 0.95 > candle_data.sector_spy_residual_percentile) ? '<span style="color:#5F2BFF;">' : '<span>');
    } catch {}
    
    text += "<i>Correlations:</i><br/>";
    text += '</span>';

    // try {
    if ('summary' in model_output[0].data_out) {
        summary = model_output[0].data_out.summary;
        if (model_output[0].data_out.top_explanations) {
            var factors = model_output[0].data_out.top_explanations;
        } else {
            try {
                var factors = JSON.parse(model_output[0].data_out.explanations);
            } catch (error) {
                var factors = model_output[0].data_out.explanations;
            }
        }
        text += `<div style='text-indent:-1.5em;padding-left:1.5em;display:block;font-size:12px;'><img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><b> ` + summary + `</b></div>`;
        newOutput = true;
        try {
            factors = factors.sort(function(a,b) {
                return b.output_rank - a.output_rank
            }) 
        } catch (error) {
            try {
                factors = factors.sort(function(a,b) {
                    return b.hit_ratio - a.hit_ratio;
                })
            
                factors = factors.sort(function(a,b) {
                    return b.causation_confidence - a.causation_confidence;
                })
            } catch (error) {
                return text;
            }
        }
    } else {
        var factors = model_output;
        factors = factors.sort(function(a,b) {
            return b.data_out.hit_ratio - a.data_out.hit_ratio;
        })
    
        factors = factors.sort(function(a,b) {
            return b.data_out.causation_confidence - a.data_out.causation_confidence;
        })
    }    

    
    var count = 0;
    var moved = 0;
    var driven = 0;
    
    var text_moved = '';
    var text_driven = '';
    factors.forEach(function(item) {
        var text_fm = '';
        var driver = false;
        var explanatory = (newOutput) ? item : item.data_out;
        var exp_return_oc = (dtm) ? explanatory.exp_date_return_oc : explanatory.exp_return_oc;
        var exp_return_oc_voladj = (dtm) ? explanatory.exp_date_return_oc_voladj : explanatory.exp_return_oc_voladj;
        var exp_volume_dollar_voladj = (dtm) ? explanatory.exp_date_volume_dollar_voladj : explanatory.exp_volume_dollar_voladj;
        var exp_range_hl_voladj =(dtm) ? explanatory.exp_date_liquidity_vhl_voladj : explanatory.exp_liquidity_vhl_voladj;
        
        if (count < 3 ) {
            
            if ((explanatory.causation_confidence != 'undefined' && explanatory.causation_confidence > 0)) {
                driver = true;
                driven = 1;
            } else {
                driver = false;
                moved = 1;
            }
            text_fm += "<div style='text-indent:-2em;padding-left:1.5em;padding-bottom:0.5em;font-size:14px;'>"
            if (exp_return_oc > 0) {
                text_fm += "<svg style='fill:#2E875F;display:inline;vertical-align:middle;' xlmns='http://www.w3.org/2000/svg' height='20px' viewBox='0 0 4 4' width='25px'><path d='M0 0h4v4H0z' fill='none'></path><path d='M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z'></path></svg>";
            } else {
                text_fm += "<svg style='fill:#EE6352;display:inline;vertical-align:middle' xlmns='http://www.w3.org/2000/svg' height='20px' viewBox='0 0 24 24' width='25px'><path d='M0 0h24v24H0z' fill='none'></path><path d='M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z'></path></svg>";
            }
            text_fm += explanatory.exp_name;
            if (explanatory.exp_type === 'Sector') {
                text_fm += " (Sector)"; 
            } else { 
                text_fm += " (" + explanatory.exp_symbol + '</a>)'; 
            }
            
            if (explanatory.correlation) {
                text_fm += '<br/><span style="font-size:12px;">Correlation: ' + Math.round(explanatory.correlation*100)/100 + '</span>';
            } else {
                text_fm += '<br/><span style="font-size:12px;">Correlation: ' + Math.round(explanatory.hit_ratio*100) + '%</span>';
            }

            if ('sympathy_news' in explanatory && explanatory.sympathy_news !== null) {
                
                if (explanatory.news_by_association) {
                    explanatory.sympathy_news = JSON.parse(explanatory.news_by_association);
                } else if (typeof explanatory.sympathy_news === 'string') {
                    explanatory.sympathy_news = JSON.parse(explanatory.sympathy_news);
                } 

                if (explanatory.sympathy_news.hasOwnProperty('summary')) {
                    text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;font-size:12px;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=12 style="vertical-align:middle;display:inline;"> ' + (driver ? '<span style="color:#5F2BFF;">':'') + explanatory.sympathy_news.summary + '</div>';
                    // text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=15><b> ' + explanatory.sympathy_news.news.headlines[0].headline + '</b></div>';
                } else {
                    try {
                        var exp_news_count = 0;
                        explanatory.sympathy_news.news.headlines.forEach(function(newsitem) {
                            if (exp_news_count < 3) {
                                text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;font-size:12px;color:#5F2BFF;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=12 style="vertical-align:middle;display:inline;"> ' + newsitem.headline + '</div>';
                                exp_news_count += 1;
                            }
                        });
                    } catch {
                        var exp_news_count = 0;
                        explanatory.sympathy_news.news.forEach(function(newsitem) {
                            if (exp_news_count < 3) {
                                text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;font-size:12px;color:#5F2BFF;"><img src="https://img.icons8.com/metro/2x/google-news.png" width=12 style="vertical-align:middle;display:inline;"> ' + newsitem.headline + '</div>';
                                exp_news_count += 1;
                            }
                        });
                    }
                }
            }
            text_fm += '<div style="display:flex;">'; // CORRELATION & FLAG WRAPPER
            try {
                text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;padding-right:5px;font-size:12px;"><b>Return: ' + (exp_return_oc_voladj > 0 ? '+':'') + exp_return_oc_voladj.toFixed(1) + 'σ</b> ('+ (exp_return_oc > 0 ? '+':'') + (exp_return_oc*100).toFixed(1) + '%' + ')</div>';
            } catch {}
            try {
                text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;padding-right:5px;font-size:12px;"><b>Volume: ' + exp_volume_dollar_voladj.toFixed(1) + 'σ</b></div>';
            } catch {}
            try {
                text_fm += '<div style="text-indent:-1.5em;padding-left:1.5em;padding-right:5px;font-size:12px;"><b>Range: ' + exp_range_hl_voladj.toFixed(1) + 'σ</b></div>';
            } catch {}
            text_fm += '</div>'; // CORRELATION & FLAG WRAPPER

            // if (explanatory.causation_confidence != 'undefined' && explanatory.causation_confidence > 0) {
                text_fm += '<span style="font-size:10px;">[Causation Confidence: '+ (Math.round(explanatory.causation_confidence*100,0)) +'%]</span>';
            // }

            text_fm += '</div>';
            count += 1;
            if (driver) {
                text_driven += text_fm;
            } else {
                text_moved += text_fm;
            }
        }
    });
    if (driven > 0) {
        text += '<i>Potentially Driven By: </i><br/>';
        text += text_driven;
    }
    if (moved > 0) {
        if (driven > 0) {
            text += '<hr/>';
            text += '<i>Also Moving With: </i><br/>';
        } else {
            text += '<i>Moving With: </i><br/>';
        }
        text += text_moved;
    }
    return text;
}


export function twitter_model_argo(candle_data, model_output) {
    var text = '';
    try {
        text += ((candle_data.spy_return_oc_percentile > 0.05 && 0.95 > candle_data.spy_return_oc_percentile) && (candle_data.sector_spy_residual_percentile > 0.05 && 0.95 > candle_data.sector_spy_residual_percentile) ? '<span style="color:#5F2BFF;">' : '<span>');
    } catch {}
    text += "<i>Social Media:</i><br/>";
    text += '</span>';
    text += `<div style='text-indent:-1.5em;padding-left:1.5em;padding-bottom:5px;display:block;font-size:12px;'>
    <img src="https://s3.us-east-2.amazonaws.com/demo.marketreader.com/img/MarketReader_400_purple.png" width=15 style="vertical-align:middle;display:inline;"><b> ` + model_output[0].data_out.summary +`</b></div>`;// +`<br/>[Confidence: "+ parseFloat(tweet.confidence_metric).toFixed(3)            +"]</b></div>`;

    // var count = 0;
    // console.log(text);

    // try {
    //     var tweets = JSON.parse(model_output[0].data_out.tweet_data);
    // } catch (error) {
    //     var tweets = model_output[0].data_out.tweet_data;
    // }
    
    //  tweets.forEach(function(tweet) {
    //     if (count < 3) {
    //         var tweet_text = tweet.full_text;
    //         text += "<div style='text-indent:-1.5em;padding-left:1.5em;padding-bottom:5px;display:block;font-size:12px;'><img src='https://twitter.com/favicon.ico' style='vertical-align:middle;display:inline;'><b>" +tweet_text +"</b></div>";
    //         count += 1;
    //     }
    // });

    // text += '</div>';
    return text;
}

