import { useState, useEffect, useCallback } from 'react';
import supabase from '../utils/supabaseClient';

export const useFetchActiveAssets = ({ logError, setShowErrorWindow }) => {
    const [activeAssets, setActiveAssets] = useState([]);
    const [activeAssetsLoading, setActiveAssetsLoading] = useState(true);

    const getAssetSymbols = useCallback(async ({ retry = true }) => {
        try {
            const { data, error } = await supabase
            .from('symbols')
            .select(`*`);
            if (error) throw error;
            
            setActiveAssets(data);
            setActiveAssetsLoading(false);
            return data;
        } catch (error) {
            if (retry) {
                return getAssetSymbols({ retry: false });
            }
            logError('Error fetching asset symbols:', error);
            setShowErrorWindow(true);
            setActiveAssetsLoading(false);
            return [];
        }
    }, []);

    useEffect(() => {
        getAssetSymbols({ retry: true });
    }, []);

    return { activeAssets, activeAssetsLoading };
};

export default useFetchActiveAssets;
