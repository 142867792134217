import { useEffect, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

import { AppColors } from '../../Global/StyledComponents/Graphics';
import { BlankSpacer } from "../../Global/StyledComponents/AppStyledComponents";
import {
    Container,
    Form,
    Input,
    MobileFormWrapper,
    StyledButton,
    StyledHyperLink,
    StyledLabel,
    SuccessMessage,
    WarningText
} from "../../Global/StyledComponents/UserFormStyledComponents";
import Footer from "../../Global/Components/Footer";
import Loading from "../../Global/Components/LoadingSpinner";
import { Plans } from "../../Global/constants";
import PlanView from "./components/PlanView";
import SampleView from "../LoginMonitor/components/SampleView";
import supabase from "../../Controllers/utils/supabaseClient";
import ToggleSlide from "../../Global/Components/ToggleSlide";

const PlanSelectionMonitor = ({ getUser, setIsAuthenticated, setUser, user }) => {
    const [disabled, setDisabled] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [promoCode, setPromoCode] = useState('');
    const [promoData, setPromoData] = useState(null);
    const [success, setSuccess] = useState(false);
    const [trialStatus, setTrialStatus] = useState(false);
    const [yearly, setYearly] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        supabase.from('profiles').select('*').then((res) => {
            const { trial_status } = res?.data?.[0];

            setTrialStatus(trial_status);
            setLoading(false);
        });
    }, []);

    const handleChange = () => {
        setYearly(prevState => !prevState)
    };

    const handleChangePromoCode = (e) => {
        setPromoCode(e.target.value);
        setInvalid(false);
    };

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            setIsAuthenticated(false);
            setUser({});
            navigate('/login');
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        supabase.functions.invoke('promoCodes', {
            body: {
                promoCode
            },
        }).then((res) => {
            if (res?.error) {
                console.log(res.error)

                return;
            }
            const { ok, promotionCodes } = JSON.parse(res?.data)

            if (ok) {
                setSuccess(true);
                setPromoData(promotionCodes?.data?.[0]);
                return;
            } else {
                setInvalid(true);
            }
        });
    }

    if (loading) {
        return (
            <Container>
                <Loading />
            </Container>
        );
    }

    return (
        <Container isMobile={isMobile && !isTablet}>
            <StyledButton
                onClick={handleLogout}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: '10px',
                    right: '20px',
                }}
            >
                Logout
            </StyledButton>
            {(!isMobile || isTablet) &&
                <SampleView readOnly />
            }
            <MobileFormWrapper isMobile={isMobile && !isTablet} style={{ height: (isMobile && !isTablet) ? null : 'calc(100vh - 40px)', minWidth: (!isMobile || isTablet) ? '660px' : null, paddingBottom: '40px'}}>
                {(isMobile && !isTablet) &&
                    <>
                        <BlankSpacer />
                        <BlankSpacer />
                        <BlankSpacer />
                        <BlankSpacer />
                        <BlankSpacer />
                        <BlankSpacer />
                        <BlankSpacer />
                    </>
                }
                <div style={{ display: 'flex', flexDirection: (!isMobile || isTablet) ? 'row' : 'column',  }}>
                    {Object.keys(Plans.monthly).map(key => (
                            <PlanView
                                disabled={disabled}
                                getUser={getUser}
                                key={key}
                                plan={Plans.monthly[key]}
                                promoData={promoData}
                                setDisabled={setDisabled}
                                trialStatus={trialStatus}
                                user={user}
                            />
                        ))
                    }
                </div>
                <SuccessMessage>
                    Questions? Contact&nbsp;
                    <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
                </SuccessMessage>
            </MobileFormWrapper>
            <Footer />
        </Container>
    );
};

export default PlanSelectionMonitor;
