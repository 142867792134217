import { CancelButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { ButtonContainer } from "../../../Global/StyledComponents/StockCardStyledComponents";

const ErrorWindow = () => {
    const reloadPage = () => {
        window.location.reload(false);
    };

    return (
        <div style={{ height: 'auto', margin: '20px 20px 0px 20px', paddingBottom: '80px', position: 'relative', textAlign: 'center' }}>
            We are having difficulty connecting to the server.
            <br />
            Please refresh the page to continue.
            <br />
            <br />
            If the issue persists come back later to try again or reach out to us at <a href="mailto: support@marketreader.com" style={{ fontSize: '16px' }}>support@marketreader.com</a>.
            <ButtonContainer style={{ right: -20 }}>
                <CancelButton onClick={reloadPage}>
                    Refresh
                </CancelButton>
            </ButtonContainer>
        </div>
    )
};

export default ErrorWindow;
