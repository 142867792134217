import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { OpenArrowDownIcon } from '../../Icons/Icons';
import hexToRgba from '../../../Controllers/utils/hexToRgba';
import { AppColors } from '../../StyledComponents/Graphics';

const StockCardGridWrapper = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   height: ${props => props.height || '100%'};
`;

const StockCardGrid = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: start;
   gap: 0px;
   overflow-y: auto;
   height: 100%;

   scrollbar-width: none;
   -ms-overflow-style: none;

   &::-webkit-scrollbar {
       display: none;
       overflow: hidden;
   }
`;

const BottomBar = styled.div`
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 60px;
   background: ${hexToRgba(AppColors.MRNAVY, 0.25)};
   backdrop-filter: blur(10px);
   display: flex;
   justify-content: center;
   align-items: center;
   visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const ScrollButton = styled.div`
   background: none;
   border: none;
   color: white;
   font-size: 24px;
   cursor: pointer;
`;

const StockCardGridComponent = ({ children, height }) => {
    const gridRef = useRef(null);
    const [isBottomVisible, setIsBottomVisible] = useState(false);

    const checkVisibility = () => {
        if (gridRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
            setIsBottomVisible(scrollTop + clientHeight < scrollHeight);
        }
    };

    useEffect(() => {
        checkVisibility();

        const handleScroll = () => {
            checkVisibility();
        };

        const currentGridRef = gridRef.current;
        if (currentGridRef) {
            currentGridRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentGridRef) {
                currentGridRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        checkVisibility();
    }, [children]);

    const scrollDown = () => {
        const { scrollTop, clientHeight, scrollHeight } = gridRef.current;
        const newScrollTop = Math.min(scrollTop + clientHeight, scrollHeight - clientHeight);
        gridRef.current.scrollTo({ top: newScrollTop, behavior: 'smooth' });
    };

    return (
        <StockCardGridWrapper height={height}>
            <StockCardGrid ref={gridRef}>
                {children}
            </StockCardGrid>
            <BottomBar isVisible={isBottomVisible}>
                <ScrollButton onClick={scrollDown}>
                    <OpenArrowDownIcon />
                </ScrollButton>
            </BottomBar>
        </StockCardGridWrapper>
    );
};

export default StockCardGridComponent;
