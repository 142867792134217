const filterFeedDuplicates = (array) => {
    const filteredArray = array.filter((item, index, self) => {
        const duplicateIndex = self.findIndex(otherItem =>
            otherItem.insert_timestamp === item.insert_timestamp && otherItem.symbol === item.symbol
        );
        return duplicateIndex === index;
    });
  
    return filteredArray;
};

export default filterFeedDuplicates;
