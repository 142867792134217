import React, { useContext, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../Controllers/context/auth';
import {
    DropdownMenu,
    NavigationContainer,
    NavivationItem
} from './StyledComponents';
import NavMenu from './components/NavMenu';
import NotificationMenu from './components/NotificationMenu';
import ProfileMenu from './components/ProfileMenu';
import SearchDropdown from '../SearchDropdown';
import { SearchIcon } from '../../Icons/Icons';
import SettingsMenu from './components/SettingsMenu';
import { StateContext } from '../../../Controllers/context/state';

const NavigationMenu = () => {
    const navigate = useNavigate();
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

    const { user } = useContext(AuthContext);

    const {
        assets,
        development,
        searching,
        setExpandedViewTicker,
        setSearching,
        setShowExpandedView,
        watchlistTickers,
    } = useContext(StateContext);

    const searchRef = useRef(null);

    const handleMouseLeave = () => {
       setNavMenuOpen(false);
       setNotificationMenuOpen(false)
       setProfileMenuOpen(false);
       setSearching(false);
       setSettingsMenuOpen(false);
    };

    const handleSearchSubmit = (value) => {
        setSearching(false);
        if (!isMobile || isTablet) {
            navigate(`/ticker/${value}`);
        } else {
            setExpandedViewTicker(value);
            setShowExpandedView(true);
        }
    };

    const handleSupport = async () => {
        if (user.hnssotoken) {
            window.open(`https://feedback.marketreader.com/?sso_token=${user.hnssotoken}`, '_blank');
        } else {
            window.open(`https://feedback.marketreader.com/`, '_blank');
        }
    };

    const handleToggleNavMenu = () => {
        setNavMenuOpen(prevState => !prevState);
    };

    const handleToggleNotificationMenu = () => {
        setNotificationMenuOpen(true);
    };

    const handleToggleProfileMenu = () => {
        setProfileMenuOpen(prevState => !prevState);
    };

    const handleToggleSettingsMenu = () => {
        setSettingsMenuOpen(true);
    };

    return (
        <NavigationContainer className="dropdown">
            <ProfileMenu
                handleMouseLeave={handleMouseLeave}
                handleSupport={handleSupport}
                handleToggleProfileMenu={handleToggleProfileMenu}
                profileMenuOpen={profileMenuOpen}
                setProfileMenuOpen={setProfileMenuOpen}
            />
            {(!isMobile || isTablet) &&
                <SettingsMenu
                    handleMouseLeave={handleMouseLeave}
                    handleToggleSettingsMenu={handleToggleSettingsMenu}
                    settingsMenuOpen={settingsMenuOpen}
                    user={user}
                />
            }
            {(!isMobile || isTablet) ?
                <NavivationItem
                    style={{ width: '300px' }}
                >
                    <SearchDropdown
                        handleSubmit={handleSearchSubmit}
                        options={assets}
                        position='left'
                        searchRef={searchRef}
                    />
                </NavivationItem> :
                <NavivationItem
                    id='searchContainer'
                >
                    <SearchIcon
                            onClick={() => setSearching(prevState => !prevState)}
                        />
                    {searching &&
                        <DropdownMenu left={true}>
                            <SearchDropdown
                                handleSubmit={handleSearchSubmit}
                                options={assets}
                                position='left'
                                searchRef={searchRef}
                            /> 
                        </DropdownMenu>
                    }
                </NavivationItem>
            }
            <NotificationMenu
                handleMouseLeave={handleMouseLeave}
                handleToggleNotificationMenu={handleToggleNotificationMenu}
                notificationMenuOpen={notificationMenuOpen}
            />
            <NavMenu
                development={development}
                handleMouseLeave={handleMouseLeave}
                handleSupport={handleSupport}
                handleToggleNavMenu={handleToggleNavMenu}
                navMenuOpen={navMenuOpen}
                watchlistTickers={watchlistTickers}
            />
        </NavigationContainer>
    );
};

export default NavigationMenu;