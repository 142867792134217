import { useContext, useState } from "react";
import styled from "styled-components";

import { Header, StyledButton, TextArea, WarningText } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { StateContext } from "../../../Controllers/context/state";
import supabase from "../../../Controllers/utils/supabaseClient";
import { BlankSpacer, ExpandedBox } from "../../../Global/StyledComponents/AppStyledComponents";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    justify-content: center;
    align-items: center;
`;

export const FeedbackModal = () => {
    const [error, setError] = useState(false)
    const [feedbackText, setFeedBackText] = useState('');
    const {
        feedbackData,
        logError,
        setShowFeedbackModal,
        summaryInReview,
    } = useContext(StateContext);

    const handleFeedback = (e) => {
        const { value } = e.target;

        setFeedBackText(value);
    };

    const handleSubmitFeedback = (e) => {
        e.preventDefault();
        const submitFeedback = ({ retry = false }) => {
            supabase.from('user_feedback').upsert({
                ...feedbackData,
                feedback: feedbackText,
            }).eq('id', feedbackData.id).select().then(res => {
                if (res?.error) {
                    if (retry) {
                        submitFeedback({ retry: false });

                        return;
                    }

                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);
                    setError(true);
                    return;
                }

            });
            setShowFeedbackModal(false)
        };

        if (feedbackText?.length) {
            submitFeedback({ retry: true });
        } else {
            setShowFeedbackModal(false);
        }

    };

    return (
        <Container>
            <Header>
                Your feedback helps us improve. Please share a some details about what could be better about this summary.
            </Header>
            <ExpandedBox>
                {summaryInReview}
            </ExpandedBox>
            <BlankSpacer />
            <TextArea
                onChange={handleFeedback}
                value={feedbackText}
            />
            {error &&
                <WarningText>
                    Feedback Submission Failed. Please try again.
                </WarningText>
            }
            <StyledButton onClick={handleSubmitFeedback}>
                {feedbackText.length ? 
                    'Submit Comments' :
                    'Submit Without Comment'
                }
            </StyledButton>
        </Container>
    )
};