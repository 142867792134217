export const UTC_TIMEZONES = {
    label: null,
    values: [
        { label: 'UTC-12:00', value: '-12:00' },
        { label: 'UTC-11:00', value: '-11:00' },
        { label: 'UTC-10:00', value: '-10:00' },
        { label: 'UTC-09:00', value: '-09:00' },
        { label: 'UTC-08:00', value: '-08:00' },
        { label: 'UTC-07:00', value: '-07:00' },
        { label: 'UTC-06:00', value: '-06:00' },
        { label: 'UTC-05:00', value: '-05:00' },
        { label: 'UTC-04:00', value: '-04:00' },
        { label: 'UTC-03:00', value: '-03:00' },
        { label: 'UTC-02:00', value: '-02:00' },
        { label: 'UTC-01:00', value: '-01:00' },
        { label: 'UTC+00:00', value: '+00:00' },
        { label: 'UTC+01:00', value: '+01:00' },
        { label: 'UTC+02:00', value: '+02:00' },
        { label: 'UTC+03:00', value: '+03:00' },
        { label: 'UTC+04:00', value: '+04:00' },
        { label: 'UTC+05:00', value: '+05:00' },
        { label: 'UTC+06:00', value: '+06:00' },
        { label: 'UTC+07:00', value: '+07:00' },
        { label: 'UTC+08:00', value: '+08:00' },
        { label: 'UTC+09:00', value: '+09:00' },
        { label: 'UTC+10:00', value: '+10:00' },
        { label: 'UTC+11:00', value: '+11:00' },
        { label: 'UTC+12:00', value: '+12:00' },
    ]
};


export const IMPORTANCE = {
    label: null,
    values: [
        { label: 'High Impact', value: 3 },
        { label: 'Medium Impact', value: 2 },
        { label: 'Low Impact', value: 1 },
        { label: 'All Events', value: 0 },
    ]
};