import styled from 'styled-components';
export const SliderContainer = styled.div.attrs({ 'data-testid': 'chart-carousel' })`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
`;

export const CardsContainer = styled.div`
	display: flex;
	transition: transform 0.5s ease-in-out;
	justify-content: center;
	z-index: 10;
`;

export const NavButton = styled.button`
	background: linear-gradient(
		130deg,
		rgba(134, 151, 166, 0.2) 30%,
		rgba(134, 151, 166, 0.3) 65%,
		rgba(134, 151, 166, 0.5) 98%
	);
	color: #ccc;
	border: none;
	padding: 10px;
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.5rem;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	z-index: 1000;

	&:first-of-type {
		left: 10px; 
	}

	&:last-of-type {
		right: 10px; 
	}

	&:hover {
		background: linear-gradient(
			130deg,
			rgba(134, 151, 166, 0.2) 30%,
			rgba(134, 151, 166, 0.3) 65%,
			rgba(134, 151, 166, 0.5) 98%
		);
		color: #fff;
	}
`;

export const BlurryEdge = styled.div`
	position: absolute;
	top: 0;
	width: 300px;
	height: 100%;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(10px);
	background: rgba(14, 32, 47, 0.1);

	@media (max-width: 768px) {
		display: none !important;
	}
`;

export const LeftEdge = styled(BlurryEdge)`
	left: 0;
	display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const RightEdge = styled(BlurryEdge)`
	right: 0;
	display: ${({ show }) => (show ? 'flex' : 'none')};
`;