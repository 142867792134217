import { useEffect, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import {  useNavigate } from 'react-router-dom';

import {
    FormWrapper,
    Header,
    MobileFormWrapper,
    StyledButton,
    StyledHyperLink,
    SuccessMessage,
    SignUpContainer,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';
import HubSpotContactForm from './components/HubspotContactForm';
import SampleView from '../LoginMonitor/components/SampleView';

const ContactForm = () => {
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const handleScroll = () => {
        containerRef?.current?.scrollIntoView({
            block: 'end',
            behavior: "smooth",
        });
    };

    useEffect(() => {
        containerRef?.current?.scrollIntoView({
            block: 'start',
        });
    }, [])

    return (
        <SignUpContainer isMobile={isMobile && !isTablet} ref={containerRef} signUp>
            <StyledButton
                onClick={() => navigate('/login')}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: 0,
                    left: '10px',
                }}
            >
                Back to Login
            </StyledButton>
            {(!isMobile || isTablet) &&
                <SampleView trialPage />
            }
            <MobileFormWrapper isMobile={isMobile && !isTablet}>
                <FormWrapper isMobile={isMobile && !isTablet}>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                    <Header>
                        Request Trial Access
                    </Header>
                    <br />
                    <HubSpotContactForm />
                </FormWrapper>
                <SuccessMessage>
                    Questions? Contact&nbsp;
                    <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
                </SuccessMessage>
            </MobileFormWrapper>
        </SignUpContainer>
    );
};

export default ContactForm;
