import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../../../../../Controllers/context/state';
import StockChartCard from '../StockChartCard/StockChartCard';
import { OpenArrowLeftIcon, OpenArrowRightIcon } from '../../../../Icons/Icons';
import Loading from '../../../LoadingSpinner';
import { SliderContainer, CardsContainer, NavButton, LeftEdge, RightEdge } from './styled-components';

const StockChartCarousel = ({
    chartMinMax,
	clickedPoint,
	hoveredPoint,
	markerData,
    setCarouselPlotline,
    setHoverPoint,
}) => {

    const [loading, setLoading] = useState(true);
	const [selectedSlide, setSelectedSlide] = useState(0);
	const [slides, setSlides] = useState([]);
    const { setChartInfo, setShowChartInfoWindow } = useContext(StateContext);

	useEffect(() => {
		if (markerData == null) {
            setLoading(true);
		}

		const fetchData = async () => {
            setLoading(true);

            const filteredData = markerData.filter(
                (card) => card.ts >= chartMinMax.min && card.ts <= chartMinMax.max
            );
            filteredData.sort((a, b) => b.ts - a.ts);

            const newSlides = filteredData.reduce((accumulator, currentValue) => {
                accumulator.push([currentValue]);
                return accumulator;
            }, []);

            setSlides(newSlides);
            setSelectedSlide(0);
            setLoading(false);
		};

		if (markerData != null) {
			fetchData();
		}
	}, [chartMinMax,markerData]);

    useEffect(() => {
        if (slides[selectedSlide]) {
            const newHoverPoint = slides[selectedSlide][0];
            setCarouselPlotline((prevHoverPoint) => {
                return prevHoverPoint?.ts !== newHoverPoint.ts ? newHoverPoint : prevHoverPoint;
            });
        } else {
            setCarouselPlotline(null);
        }
    }, [selectedSlide, slides]);

	useEffect(() => {
		if (hoveredPoint) {
			slides.forEach((slide, index) => {
				const foundObject = slide.find((item) => item.ts === hoveredPoint?.ts);
				if (foundObject) {
					setSelectedSlide(index);
				}
			});
		}else{
            setHoverPoint(null);
        }
	}, [hoveredPoint, slides]);

	useEffect(() => {
		if (clickedPoint) {
			slides.forEach((slide) => {
				const clickedCard = slide.find((item) => item.ts === clickedPoint);
				if (clickedCard) {
					setChartInfo(clickedCard);
					setShowChartInfoWindow(true);
				}
                
			});
		}
	}, [clickedPoint, slides, setChartInfo, setShowChartInfoWindow]);

	const nextSlide = () => {
		if (selectedSlide < slides.length - 1) {
			setSelectedSlide((prevSelected) => prevSelected + 1);
		}
	};

	const prevSlide = () => {
		if (selectedSlide > 0) {
			setSelectedSlide((prevSelected) => prevSelected - 1);
		}
	};

	if (loading) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Loading />
			</div>
		);
	}

	return (
		<div style={{ padding: '10px 0 0 0' }}>
			<SliderContainer>
				<LeftEdge show={selectedSlide < slides.length - 1}>
					{selectedSlide < slides.length - 1 && (
						<StockChartCard
							cardData={slides[selectedSlide + 1][0]}
							isHovered={false}
							blurred
						/>
					)}
				</LeftEdge>

				<NavButton
					onClick={nextSlide}
					style={{
						visibility:
							selectedSlide === slides.length - 1 ? 'hidden' : 'visible',
					}}
				>
					<OpenArrowLeftIcon />
				</NavButton>

				<CardsContainer>
					{slides[selectedSlide] &&
						slides[selectedSlide].map((card) => (
							<StockChartCard key={card.ts} cardData={card} isHovered={true} />
						))}
				</CardsContainer>

				<NavButton
					onClick={prevSlide}
					style={{ visibility: selectedSlide === 0 ? 'hidden' : 'visible' }}
				>
					<OpenArrowRightIcon />
				</NavButton>

				<RightEdge show={selectedSlide > 0}>
					{selectedSlide > 0 && (
						<StockChartCard
							cardData={slides[selectedSlide - 1][0]}
							isHovered={false}
							blurred
						/>
					)}
				</RightEdge>
			</SliderContainer>
		</div>
	);
};

export default StockChartCarousel;
