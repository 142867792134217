import React from 'react'
import { AppColors } from '../../../StyledComponents/Graphics';
import { formatTimeToTimezone } from '../../../../Controllers/utils';
import styled from 'styled-components';

const ImportanceText = styled.span`
    display: inline-block;
    padding: 2px 4px;
    border-radius: 3px;
    text-align: center;
    color: white;
    ${({ importance }) => {
        switch (importance) {
            case 3:
                return `
                    background-color: ${AppColors.MRRED};
                    font-weight: bold;
                `;
            case 2:
                return `
                    font-weight: bold;
                `;
        }
    }}
`;

const CalendarImportance = ({ importance, time_utc }) => {
    return (
        <ImportanceText importance={importance}>
            {formatTimeToTimezone(time_utc)}
        </ImportanceText>
    );
};

export default CalendarImportance;