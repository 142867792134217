import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';


import CountdownProgressBar from './CountdownProgressBar';
import { AppColors, NavyGreenBar } from "../StyledComponents/Graphics";
import { NavivationItem } from './NavigationMenu/StyledComponents';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import { StateContext } from '../../Controllers/context/state';
import { TrackingModuleContainer } from '../StyledComponents/AppStyledComponents';
import { WhiteTextGlassLogo } from "../Logos/Logos";
import supabase from '../../Controllers/utils/supabaseClient';
import { isMobile, isTablet } from 'react-device-detect';
import { SubmitButton } from '../StyledComponents/UserFormStyledComponents';
import { AuthContext } from '../../Controllers/context/auth';

const TrackingModule = () => {
    const [disabled, setDisabled] = useState(false);
    const {
        customerProfile,
        logError,
        setCustomerProfile,
        setShowManageSubscriptionPopUp,
        setTrialCancelled,
        trialCanceled,
    } = useContext(StateContext);
    const { user } = useContext(AuthContext);
    const { pathname } = useLocation();
    const PAGES = {
        '': 'Dashboard',
        'admin': 'Admin',
        'etf': 'ETF Monitor',
        'faqs': 'Knowledge and Support',
        'macro': 'Macro Monitor',
        'news': 'Market-Wide News',
        'unusualMoves': 'Unusual Moves',
        'screener': 'Smart Screener',
        'watchlists': 'Watchlists',
    };


    useEffect(() => {
        supabase.from('profiles').select('manage_subscription').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                return;
            }
            setShowManageSubscriptionPopUp(res?.data[0]?.manage_subscription && (!isMobile || isTablet));
        });
    }, []);

    const cancelSubscription = async () => {
        setDisabled(true);
        const { data, error } = await supabase.functions.invoke('cancelSchedule', {
            body: {
                userEmail: user?.email,
            },
        })

        if (error) {
            const error = new Error(error?.message);
            logError(error.message, undefined, error);
            setDisabled(false);
            return;
        }

        const { ok } = JSON.parse(data);

        if (ok) {
            setTrialCancelled(true);
        }
        setDisabled(false);
    };

    const getCustomerProfile = async () => {
        const profileRes = await supabase.from('profiles').select('*').eq('id', user.id);
        const { billing_email } = profileRes?.data?.[0];

        const profile = await supabase.functions.invoke('customerProfile', {
            body: {
                billingEmail: billing_email, 
                userEmail: user.email,
            },
        });
        setCustomerProfile(JSON.parse(profile?.data));
    }

    const manageSubscription = async () => {
        setDisabled(true);
        await supabase.functions.invoke('customerPortal', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            const { session } = JSON.parse(res.data);
            window.open(session.url, '_blank');
            setDisabled(false);

            setTimeout(() => {
                getCustomerProfile();
            }, 60000);
        });
    };

    return (
        <TrackingModuleContainer>
            <NavyGreenBar >
                <NavLink to='/' style={{ marginRight: '10px' }}>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                </NavLink>
                <NavivationItem style={{ minWidth: '345px', width: '25%' }}>
                    {customerProfile?.subscriptions?.[0]?.status === 'trialing' &&
                        <>
                            <CountdownProgressBar
                                startEpoch={customerProfile?.subscriptions?.[0]?.trial_start}
                                endEpoch={customerProfile?.subscriptions?.[0]?.trial_end}
                                label="Free Trial"
                            />
                            {!customerProfile?.hasPaymentMethod ?
                                <SubmitButton
                                    onClick={manageSubscription}
                                    style={{ fontSize: '13px', lineHeight: '14px', margin: '0 10px', width: 'fit-content' }}
                                >
                                    Add Payment Method
                                </SubmitButton> :
                                trialCanceled ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}>
                                        <p style={{ color: AppColors.MRGREEN, fontWeight: 'bold', fontSize: '18px', lineHeight: '18px', margin: '0 10px' }}>
                                            Canceled!
                                        </p>
                                    </div> :
                                    <SubmitButton
                                        disabled={disabled}
                                        onClick={cancelSubscription}
                                        style={{ fontSize: '13px', lineHeight: '14px', margin: '0 10px', width: 'fit-content' }}
                                    >
                                        Cancel Subscription
                                    </SubmitButton>
                            }
                        </>
                    }
                </NavivationItem>
                <NavivationItem style={{ color: ' white', width: 'fit-content', whiteSpace: 'nowrap' }}>
                    {PAGES[pathname.split('/')[1]]}
                </NavivationItem>
                <NavigationMenu />
            </NavyGreenBar>
        </TrackingModuleContainer>

    );
};

export default TrackingModule;