import { useContext } from "react";
import { NotificationIcon } from "../../../Icons/Icons";
import ToggleSwitch from "../../ToggleSwitch";
import { StateContext } from "../../../../Controllers/context/state";
import supabase from "../../../../Controllers/utils/supabaseClient";
import { DropdownMenu, NavivationItem } from "../StyledComponents";
import { AuthContext } from "../../../../Controllers/context/auth";

const NotificationMenu = ({
    handleMouseLeave,
    handleToggleNotificationMenu,
    notificationMenuOpen,
}) => {
    const { user } = useContext(AuthContext);
    const {
        logError,
        notificationSettings,
        setNotificationSettings,
        setShowNotificationWindow,
    } = useContext(StateContext);
    
    const toggleNotifications = (e) => {
        const { id } = e.target;
        if (!notificationSettings.data[id]) {
            Notification.requestPermission();
            setShowNotificationWindow(true);
        }

        setNotificationSettings(prevState => {
            supabase.from('user_preferences').upsert({
                ...notificationSettings,
                data: {
                    ...notificationSettings.data,
                    [id]: !prevState.data[id]
                },
                type: 'notificationSettings',
                user_id: user.id,
            }).eq('type', 'notificationSettings').then((res) => {
                if (res.error) {
                    const error = new Error(res?.error?.message);
                    logError(error.message, undefined, error);
                }
            });

            return {
                ...notificationSettings,
                data: {
                    ...notificationSettings.data,
                    [id]: !prevState.data[id],
                },
            };
        });
    };

    return (
        <NavivationItem
            className="hoverButton"
            onClick={handleToggleNotificationMenu}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer' }}
        >
            <NotificationIcon
                on={notificationSettings.data.watchlistNotifications || notificationSettings.data.breakingNewsNotifications}
            />
            {
                notificationMenuOpen && (
                    <DropdownMenu
                        id='profileMenu'
                        onMouseLeave={handleMouseLeave}
                    >
                        <ul>
                            <li className='dropdown-item'>
                                Watchlist Alerts
                                <ToggleSwitch
                                    handleClick={toggleNotifications}
                                    id='watchlistNotifications'
                                    isOn={notificationSettings?.data?.watchlistNotifications}
                                    onOffSwitch
                                />
                            </li>
                            <li className='dropdown-item'>
                                Breaking News Alerts
                                <ToggleSwitch
                                    handleClick={toggleNotifications}
                                    id='breakingNewsNotifications'
                                    isOn={notificationSettings?.data?.breakingNewsNotifications}
                                    onOffSwitch
                                />
                            </li>
                            <li
                                className='dropdown-item'
                                onClick={() => setShowNotificationWindow(true)}
                            >
                                Help
                            </li>
                        </ul>
                    </DropdownMenu>
                )
            }
        </NavivationItem>
    );
};

export default NotificationMenu;
