import React, { useEffect, useState } from 'react';
import Highcharts, { animObject } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

(function(H) {
    H.wrap(
      H.Series.prototype,
      'destroy',
      function(proceed, ...args) {
        var chart = this.chart,
          hoverSeries = chart.hoverSeries;
  
        chart.hoverSeries = null;
        proceed.apply(this, args);
        chart.hoverSeries = hoverSeries;
  
      }
    );
  })(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.callout = function (x, y, w, h, options) {
    var arrowLength = 6, halfDistance = 6, r = Math.min((options && options.r) || 0, w, h), safeDistance = r + halfDistance, anchorX = options && options.anchorX, anchorY = options && options.anchorY, path;
    path = [
        ['M', x + r, y],
        ['L', x + w - r, y],
        ['C', x + w, y, x + w, y, x + w, y + r],
        ['L', x + w, y + h - r],
        ['C', x + w, y + h, x + w, y + h, x + w - r, y + h],
        ['L', x + r, y + h],
        ['C', x, y + h, x, y + h, x, y + h - r],
        ['L', x, y + r],
        ['C', x, y, x, y, x + r, y] // top-left corner
    ];
    // Anchor on right side
    if (anchorX && anchorX > w) {
        // Chevron
        // if (anchorY > y + safeDistance &&
        //     anchorY < y + h - safeDistance) {
        //     path.splice(3, 1, ['L', x + w, anchorY - halfDistance], ['L', x + w + arrowLength, anchorY], ['L', x + w, anchorY + halfDistance], ['L', x + w, y + h - r]);
        //     // Simple connector
        // }
        // else {
            path.splice(3, 1, ['L', x + w, h / 2], ['L', anchorX, anchorY], ['L', x + w, h / 2], ['L', x + w, y + h - r]);
        // }
        // Anchor on left side
    }
    else if (anchorX && anchorX < 0) {
        // // Chevron
        // if (anchorY > y + safeDistance &&
        //     anchorY < y + h - safeDistance) {
        //     path.splice(7, 1, ['L', x, anchorY + halfDistance], ['L', x - arrowLength, anchorY], ['L', x, anchorY - halfDistance], ['L', x, y + r]);
        //     // Simple connector
        // }
        // else {
            path.splice(7, 1, ['L', x, h / 2], ['L', anchorX, anchorY], ['L', x, h / 2], ['L', x, y + r]);
        // }
    }
    else if ( // replace bottom
    anchorY &&
        anchorY > h &&
        anchorX > x + safeDistance &&
        anchorX < x + w - safeDistance) {
        path.splice(5, 1, ['L', anchorX + halfDistance, y + h], ['L', anchorX, y + h + arrowLength], ['L', anchorX - halfDistance, y + h], ['L', x + r, y + h]);
    }
    else if ( // replace top
    anchorY &&
        anchorY < 0 &&
        anchorX > x + safeDistance &&
        anchorX < x + w - safeDistance) {
        path.splice(1, 1, ['L', anchorX - halfDistance, y], ['L', anchorX, y - arrowLength], ['L', anchorX + halfDistance, y], ['L', w - r, y]);
    }
    return path;
}

Highcharts.SVGRenderer.prototype.symbols.rect_end = function(x, y, w, h) {
    return [
        'M', x + w / 2, y + h / 2,
        'L', x + w, y + h / 2,
        'M', x + w, y + h / 2,
        'L', x + w, y + h + 4,
        'z'
        ];
};
Highcharts.SVGRenderer.prototype.symbols.rect_start = function(x, y, w, h, options) {
    return [
        'M', x, y + h / 2,
        'L', x + w / 2, y + h / 2,
        'M', x, y + h / 2,
        'L', x, y + h + 4,
        'z'
        ];
};
if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols.rect = Highcharts.SVGRenderer.prototype.symbols.rect;
}