import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { AppColors } from "../../Global/StyledComponents/Graphics";
import { CenteredDiv } from "../../Global/StyledComponents/AppStyledComponents";
import { Container, FormWrapper, Header, StyledButton, SubmitButton } from "../../Global/StyledComponents/UserFormStyledComponents";
import Loading from '../../Global/Components/LoadingSpinner';
import { MarketReaderWatermark } from "../../Global/Logos/Logos";
import supabase from "../../Controllers/utils/supabaseClient";

const ClaimOfferMonitor = ({ customerProfile, setIsAuthenticated, setUser, user }) => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [paymentInfo, setPaymentInfo] = useState(false);

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            setIsAuthenticated(false);
            setUser({});
            sessionStorage.removeItem('previousPath');
            navigate('/login');
        });
    };

    const handleClaimOffer = async () => {
        await supabase.functions.invoke('claimOffer', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            const { session } = JSON.parse(res?.data);
            window.open(session?.url, '_blank');
            navigate('/');
        });
    };

    const handleApplyCoupon = async () => {
        await supabase.functions.invoke('applyCoupon', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            const { session } = JSON.parse(res?.data);
            window.open(session?.url, '_blank');
            navigate('/');
        });
    };

    useEffect(() => {
        sessionStorage.setItem('previousPath', location.pathname);
    }, []);

    useEffect(() => {
        if (customerProfile?.profile?.invoice_settings?.default_payment_method) {
            setPaymentInfo(true);
        }

        if (customerProfile?.profile) {
            setLoading(false);
        }
    }, [customerProfile])

    return (
        <Container style={{ background: AppColors.MRNAVY }}>
            {loading ?
                <Loading /> :
                <>
                    <StyledButton
                        onClick={handleLogout}
                        style={{
                            color: 'white',
                            padding: '10px',
                            position: 'absolute',
                            top: 0,
                            left: '10px',
                        }}
                    >
                        Back to Login
                    </StyledButton>
                    {paymentInfo ?
                        <FormWrapper>
                            <MarketReaderWatermark />
                            <Header style={{ fontSize: '26px', lineHeight: '32px' }}>
                                Special Offer: Get One Month of MarketReader Free
                            </Header>
                            <CenteredDiv style={{ fontSize: '20px', lineHeight: '24px' }}>
                                Claim the offer below to apply a $99 credit to your account and receive 30 days of free access to the MarketReader platform.
                            </CenteredDiv>
                            <br />
                            <br />
                            <SubmitButton onClick={handleApplyCoupon} style={{ fontSize: '24px', width: 'fit-content'}}>
                                Apply Coupon
                            </SubmitButton>
                        </FormWrapper> :
                        <FormWrapper>
                            <MarketReaderWatermark />
                            <Header style={{ fontSize: '26px', lineHeight: '32px' }}>
                                Special Offer: Get Your First Month Free
                            </Header>
                            <CenteredDiv style={{ fontSize: '20px', lineHeight: '24px' }}>
                                Enter your payment information to begin your MarketReader subscription at no cost for the first 30 days.
                            </CenteredDiv>
                            <CenteredDiv style={{ fontSize: '20px', fontWeight: 'bold', margin: '15px 0', lineHeight: '24px' }}>
                                To claim the offer, click below to proceed to the payment information page.
                            </CenteredDiv>
                            <CenteredDiv style={{ fontSize: '20px', lineHeight: '24px' }}>
                                Your payment method will not be charged until after the first month. You may cancel at any time.
                            </CenteredDiv>
                            <br />
                            <br />
                            <SubmitButton onClick={handleClaimOffer} style={{ fontSize: '24px', width: 'fit-content'}}>
                                Claim Offer
                            </SubmitButton>
                        </FormWrapper>
                    }
                </>
            }
        </Container>
    );

};

export default ClaimOfferMonitor;