import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { StyledLabel } from '../StyledComponents/UserFormStyledComponents';

const RadioGroupWrapper = styled.div`
    width: 400px;
    margin-top: 10px;
`;

const RadioButtonRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

const RadioButtonLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
`;

const RadioButtonInput = styled.input`
    accent-color: ${AppColors.MRBLUE};
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const RadioButtonGroup = ({ filter, groupLabel, options }) => {
    const defaultKey = Object.keys(options).find(key => options[key].default);
    const [selectedOption, setSelectedOption] = useState(defaultKey || '');
    useEffect(() => {
        setSelectedOption(Object.keys(options).find(key => options[key].value === filter?.marketrth))
    }, [filter]);

    const handleChange = (key) => {
        setSelectedOption(key);
        options[key].setOption();
    };

    return (
        <RadioGroupWrapper>
            <StyledLabel>{groupLabel}:</StyledLabel>
            {Object.entries(options).map(([key, { label }]) => (
                <RadioButtonRow onClick={() => handleChange(key)} key={key}>
                    <RadioButtonInput
                        type="radio"
                        id={key}
                        name="radioGroup"
                        checked={selectedOption === key}
                    />
                    <RadioButtonLabel htmlFor={key}>{label}</RadioButtonLabel>
                </RadioButtonRow>
            ))}
        </RadioGroupWrapper>
    );
};

export default RadioButtonGroup;
