const colorNameFromPercentile = (percentile) => {
    if (percentile >= 97) {
        return 'ALPH';
    } else if (percentile >= 93) {
        return 'BETA';
    } else if (percentile >= 84) {
        return 'GAMM';
    } else if (percentile >= 68) {
        return 'DELT';
    } else if (percentile >= 34) {
        return 'EPIS';
    } else if (percentile >= 16) {
        return 'IOTA'; 
    } else if (percentile >= 7) {
        return 'KAPP';
    } else if (percentile >= 3) { 
        return 'LAMB';
    } else if (percentile >= 0) { 
        return 'ZETA';
    }
};

export default colorNameFromPercentile;
