import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';

const ProgressBarWrapper = styled.div`
    width: 100%;
    margin-left: 10px;
`;

const ProgressBarRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ProgressBarLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    color: ${AppColors.MRWHITE};
    white-space: nowrap;
`;

const ProgressContainer = styled.div`
    width: 100%;
    height: 15px;
    background-color: ${AppColors.MRGREY};
    border-radius: 10px;
    overflow: hidden;
`;

const ProgressFill = styled.div`
    width: ${(props) => props.width}%;
    height: 100%;
    background-color: ${AppColors.MRBLUE};
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
`;

const CountdownProgressBar = ({ startEpoch, endEpoch, label }) => {
    const [daysLeft, setDaysLeft] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const start = startEpoch * 1000;
        const end = endEpoch * 1000;
        const now = Date.now();

        const daysRemaining = Math.ceil((end - now) / (1000 * 60 * 60 * 24));
        const progressPercentage = Math.min(100, Math.max(0, ((now - start) / (end - start)) * 100));

        setDaysLeft(daysRemaining);
        setProgress(progressPercentage);
    }, [startEpoch, endEpoch]);

    return (
        <ProgressBarWrapper>
            <ProgressBarRow>
                <ProgressBarLabel style={{ marginRight: '10px' }}>
                    {label}
                </ProgressBarLabel>
                <br />
                <ProgressBarLabel>
                    {`${daysLeft} Days Remaining`}
                </ProgressBarLabel>
            </ProgressBarRow>
            <ProgressBarRow>
                <ProgressContainer>
                    <ProgressFill width={progress} />
                </ProgressContainer>

            </ProgressBarRow>
        </ProgressBarWrapper>
    );
};

export default CountdownProgressBar;
