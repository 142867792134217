import React from 'react';
import styled from 'styled-components';
import { ToggleButton } from '../../../../StyledComponents/UserFormStyledComponents';

const ToggleContainer = styled.div`
    display: flex;
    height: 44px;
    margin: 0;
`;

const StockChartToggle = ({ chartType, setChartType }) => {
    return (
        <ToggleContainer>
            <ToggleButton isActive={chartType === 'D'} onClick={()=> setChartType('D')}>D</ToggleButton>
            <ToggleButton isActive={chartType === '10m'} onClick={()=> setChartType('10m')}>10m</ToggleButton>
        </ToggleContainer>
    );
}

export default StockChartToggle;