import { useContext } from "react";
import moment from 'moment';

import { Line } from '../../StyledComponents/AppStyledComponents';
import {
    FeedContainer,
    FeedItem,
    Timestamp
} from "./components/NewsFeed";
import { formatDateYearTimeTmz } from "../../../Controllers/utils";
import { StateContext } from "../../../Controllers/context/state";
import { Header } from "../../StyledComponents/UserFormStyledComponents";

const MacroSummariesFeed = () => {
    const {
        development,
        macroSummaries,
        macroSummary,
    } = useContext(StateContext);

    const createFeedItems = () => {
        if (development) {
            return (
                macroSummaries?.map((item, index) => (
                    <>
                        <FeedItem key={item.type + index} >
                            <p style={{ margin: 0 }}>
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    display: 'block',
                                    marginBottom: '10px',
                                }}>
                                    {item.data.headline}
                                </span>
                                <span style={{ fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                    Last Updated on: {formatDateYearTimeTmz(item.time_utc)}
                                </span>
                                {item.data.summary.split('\n').map((paragraph) => (
                                    <>
                                        {paragraph}
                                        <br />
                                    </>
                                ))}&nbsp;
                                <Timestamp>({moment(item.time_utc).fromNow()})</Timestamp>
                            </p>
                        </FeedItem>
                        <Line />
                    </>
                ))
            );
        }

        return (
            <>
                <FeedItem>
                    <p style={{ margin: 0 }}>
                        <span style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            display: 'block',
                            marginBottom: '10px',
                        }}>
                            {macroSummary?.data?.headline}
                        </span>
                        <span style={{ fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                            Last Updated on: {formatDateYearTimeTmz(macroSummary?.time_utc)}
                        </span>
                        {macroSummary?.data?.summary.split('\n').map((paragraph) => (
                            <>
                                {paragraph}
                                <br />
                            </>
                        ))}&nbsp;
                        <Timestamp>({moment(macroSummary?.time_utc).fromNow()})</Timestamp>
                    </p>
                </FeedItem>
                <Line />
            </>
        );
    };

    return (
        <div style={{ height: 'calc(100% - 45px)' }}>
            <Header style={{ margin: 5 }}>
                Macro Overview
            </Header>
            <br />
            <FeedContainer  title='Macro Overview'>
                {createFeedItems()}
            </FeedContainer>
        </div>
    )
};

export default MacroSummariesFeed;