const detectUserTimeZone = () => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = -offsetMinutes / 60;
    
    const sign = offsetHours >= 0 ? '+' : '-';
    const absoluteHours = Math.abs(offsetHours);
    const hours = String(Math.floor(absoluteHours)).padStart(2, '0');
    const minutes = String(Math.abs(offsetMinutes % 60)).padStart(2, '0');
    const offsetString = `${sign}${hours}:${minutes}`;

    return offsetString;
};

export default detectUserTimeZone;