const formatDateYearTimeTmz = (inputDate) => {
    const date = new Date(inputDate);

    const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
    const dayOfMonth = date.getDate();
    const daySuffix = ["th", "st", "nd", "rd"][
        dayOfMonth % 10 === 1 &&
        dayOfMonth !== 11 ? 1 :
        dayOfMonth % 10 === 2 &&
        dayOfMonth !== 12 ? 2 :
        dayOfMonth % 10 === 3 &&
        dayOfMonth !== 13 ? 3 : 0
    ];
    
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();
    
    // Format time
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    // Get timezone offset
    const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

    const outputDate = `${monthName} ${dayOfMonth}${daySuffix}, ${year} at ${formattedHours}:${formattedMinutes} ${ampm} ${timezone}`;

    return outputDate;
};

export default formatDateYearTimeTmz;