import { useContext, useEffect, useRef } from 'react';

import { ButtonContainer } from '../../../Global/StyledComponents/StockCardStyledComponents';
import {
    CancelButton,
    ConfirmationButton,
    StyledButton,
    StyledLabel,
} from '../../../Global/StyledComponents/UserFormStyledComponents';
import CheckboxList from '../../../Global/Components/CheckboxList';
import { DEFAULT_FILTERS, EXPLANATION_FILTERS } from '../constants';
import { DefaultSort, RefreshRates } from '../../../Global/constants';
import DoubleSlider from '../../../Global/Components/DoubleSlider';
import DropdownMenu from '../../../Global/Components/DropdownMenu';
import { identicalObjects } from '../../../Controllers/utils';
import { FilterContainer } from '../../../Global/StyledComponents/AppStyledComponents';
import MultiSelectDropdown from '../../../Global/Components/MultiSelectDropdown';
import RadioGroup from '../../../Global/Components/RadioGroup';
import { MoreInfoIcon, RefreshIcon } from '../../../Global/Icons/Icons';
import SearchDropdown from '../../../Global/Components/SearchDropdown';
import { StateContext } from '../../../Controllers/context/state';
import ToggleComponentWrapper from '../../../Global/Components/ToggleComponentWrapper';
import Tooltip from '../../../Global/Components/Tooltip';

const FilterMenu = ({
    handleFilter,
    handleRefresh,
}) => {
    const {
        assets,
        development,
        filter,
        filterOptions,
        refreshRate,
        setRefreshRate,
        selectedScreener,
        setShowSaveScreenerPopup,
        setFilter,
        setSort,
        sort,
    } = useContext(StateContext);
    const searchRef = useRef(null);

    const MarketTimeOptions = {
        marketHours: {
            label: 'Market Hours Only',
            setOption:() => setFilter(prevState => ({ ...prevState, marketrth: 'market_rth = ANY(ARRAY[true])' })),
            value: 'market_rth = ANY(ARRAY[true])',
        },
        extendeHours: {
            label: 'Extended Hours Only',
            setOption: () => setFilter(prevState => ({ ...prevState, marketrth: 'market_rth = ANY(ARRAY[false])' })),
            value: 'market_rth = ANY(ARRAY[false])',
        },
        both: {
            label: 'All Hours',
            setOption: () => setFilter(prevState => {
                let tempState = prevState;
                delete tempState?.marketrth;


                return { ...tempState };
            }),
            default: true,
            value: undefined,
        },
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        handleFilter({ id, value });
    };

    const handleExclude = (id, selectedOptions) => {
        const value = selectedOptions.length ? selectedOptions.map(item => item.value).join(' AND ') : 'Any';

        handleFilter({ id, value });
    };

    const handleInclude = (id, selectedOptions) => {
        const value = selectedOptions.length ? selectedOptions.map(item => item.value).join(' OR ') : 'Any';

        handleFilter({ id, value });
    };

    const handleMultiSelectChange = (id, selectedOptions) => {
        const value = selectedOptions.length ? selectedOptions.map(item => '(' + item.value + ')').join(' OR ') : 'Any';

        handleFilter({ id, value });
    };

    const handleCorrelationChange = (id, value) => {
        setFilter((prevState) => ({
            ...prevState,
            [id]: `(correlation BETWEEN ${value.lower} AND ${value.upper})`
        }));
    };

    const handleRevert = () => {
        if (selectedScreener &&
            (!identicalObjects(filter, selectedScreener?.data?.filter) ||
                !identicalObjects(sort, selectedScreener?.data?.sort))
        ) {
            setFilter(selectedScreener?.data?.filter);
            setSort(selectedScreener?.data?.sort);
        } else {
            setFilter({});
            setSort(DefaultSort);
        }

    };

    const handleSetRefreshRate = (e) => {
        const rate = e.target.value;
        if (rate === 'Off (Manual)') {
            setRefreshRate(null);
        } else {
            setRefreshRate(rate);
        }
    };

    const handleSubmit = (value) => {
        setSort((prevState) => ({
            ...prevState,
            correlationAsset: value,
            key: 'correlation',
        }));
    };

    useEffect(() => {
        setRefreshRate(selectedScreener?.data?.refreshRate || null);
        setSort(selectedScreener?.data?.sort || sort);
    }, [selectedScreener])

    return (
        <div>
            <FilterContainer>
                <div style={{ width: '100%' }}>
                <DropdownMenu
                    label={RefreshRates.label}
                    options={RefreshRates.values}
                    handleChange={handleSetRefreshRate}
                    value={refreshRate}
                    disa
                />
                <StyledButton onClick={() => handleRefresh({ retry: true })}>Refresh Cards Displayed <RefreshIcon/></StyledButton>
                <StyledLabel style={{ width: '100%' }}>
                    Correlation Asset:
                </StyledLabel>
                <SearchDropdown
                    handleSubmit={handleSubmit}
                    options={assets}
                    placeholder={sort.correlationAsset}
                    position='right'
                    searchRef={searchRef}
                />
                    <DoubleSlider
                        handleChange={handleCorrelationChange}
                        id='correlation'
                        label='Set Correlation Range:'
                    />
                    <RadioGroup filter={filter} groupLabel={'Selected Hours'} options={MarketTimeOptions} />
                    <DropdownMenu
                        id={'marketReaderHot'}
                        key={'marketReaderHot'}
                        label={DEFAULT_FILTERS?.marketReaderHot?.label}
                        handleChange={handleChange}
                        options={DEFAULT_FILTERS?.marketReaderHot?.values}
                        value={filter?.marketReaderHot ? filter?.marketReaderHot : null}
                    />
                    {development ?
                        <DropdownMenu
                            id={'impactScore'}
                            key={'impactScore'}
                            label={DEFAULT_FILTERS?.impactScore?.label}
                            handleChange={handleChange}
                            options={DEFAULT_FILTERS?.impactScore?.values}
                            value={filter?.impactScore ? filter?.impactScore : null}
                        /> :
                        <div style={{ position: 'relative'}}>
                            <div style={{ position: 'absolute', top: 0, left: 195 }}>
                                <Tooltip
                                    position='top'
                                    text="This measurement reflects the level of information provided in MarketReader's explanation.  These levels allow the user to filter out statements containing information of lesser impact."
                                    tooltipWidth={250}
                                >
                                    <MoreInfoIcon />

                                </Tooltip>
                            </div>
                            <DropdownMenu
                                id={'informationScore'}
                                key={'informationScore'}
                                label={DEFAULT_FILTERS?.informationScore?.label}
                                handleChange={handleChange}
                                options={DEFAULT_FILTERS?.informationScore?.values}
                                value={filter?.informationScore ? filter?.informationScore : null}
                            />
                        </div>
                    }
                    <DropdownMenu
                        id={'marketCap'}
                        key={'marketCap'}
                        label={DEFAULT_FILTERS?.marketCap.label}
                        handleChange={handleChange}
                        options={DEFAULT_FILTERS?.marketCap.values}
                        value={filter?.marketCap ? filter?.marketCap : null}
                    />
                    {Object.keys(filterOptions).map((key) => (
                        <div>
                            <MultiSelectDropdown
                                id={key}
                                key={key}
                                label={filterOptions[key].label}
                                handleChange={handleMultiSelectChange}
                                options={filterOptions[key].values}
                                value={filter?.[key] ? filter?.[key] : []}
                            />
                        </div>
                    ))}
                    <ToggleComponentWrapper
                        label='Explanations'
                        leftLabel='Exclude'
                        rightLabel='Show Only'
                    >
                        <CheckboxList
                            handleChange={handleExclude}
                            id='excludedExplanations'
                            key='excludedExplanations'
                            options={EXPLANATION_FILTERS.excludedExplanations.values}
                            value={filter?.excludedExplanations ? filter?.excludedExplanations : ''}
                        />
                        <CheckboxList
                            handleChange={handleInclude}
                            id='includedExplanations'
                            key='includedExplanations'
                            options={EXPLANATION_FILTERS.includedExplanations.values}
                            value={filter?.includedExplanations ? filter?.includedExplanations : ''}
                        />
                    </ToggleComponentWrapper>
                </div>
            </FilterContainer>
            {(!selectedScreener && ((filter && Object.keys(filter).length) ||
                !identicalObjects(sort, DefaultSort) ||
                refreshRate)) || 
                (selectedScreener &&
                    (!identicalObjects(selectedScreener?.data?.filter, filter) ||
                        !identicalObjects(selectedScreener?.data?.sort, sort) ||
                        (refreshRate && selectedScreener?.data.refreshRate !== refreshRate))) ?
                <ButtonContainer>
                    <CancelButton
                        onClick={handleRevert}
                    >
                        Revert
                    </CancelButton>

                    <ConfirmationButton
                        onClick={() => setShowSaveScreenerPopup(true)}
                    >
                        Save Selected Options
                    </ConfirmationButton>
                </ButtonContainer> :
                null
            }
        </div>
    )
};

export default FilterMenu;
