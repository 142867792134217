import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from "react-device-detect";

import '../css/marketReaderStyle.css';
import { GlobalStyles } from './Global/StyledComponents/GlobalStyles';
import {
    AppFrame,
    MonitorContainer,
} from './Global/StyledComponents/AppStyledComponents'

import { AuthProvider } from './Controllers/context/auth';
import { StateProvider } from './Controllers/context/state';
import supabase from './Controllers/utils/supabaseClient';

import AssetMonitor from './Monitors/AssetMonitor/AssetMonitor';
import DashboardMonitor from './Monitors/DashboardMonitor/DashboardMonitor';
import CalendarMonitor from './Monitors/CalendarMonitor/CalendarMonitor';
import ETFMonitor from './Monitors/ETFMonitor/ETFMonitor';
import Footer from './Global/Components/Footer';
import KnowledgeBase from './Monitors/KnowledgeBaseMonitor/KnowledgeBase';
import MacroMonitor from './Monitors/MacroMonitor/MacroMonitor';
import MobileTrackingModule from './Global/Components/Mobile/MobileTrackingModule';
import OTPVerificationMonitor from './Monitors/OTPVerificationMonitor/OTPVerificationMonitor';
import PlanSelectionMonitor from './Monitors/PlanSelectionMonitor/PlanSelectionMonitor';
import ScreenerMonitor from './Monitors/ScreenerMonitor/ScreenerMonitor';
import ContactForm from './Monitors/ContactForm/ContactForm';
import TrackingModule from './Global/Components/TrackingModule';
import WatchlistMonitor from './Monitors/WatchlistMonitor/WatchlistMonitor';

import ForgotPasswordMonitor from './Monitors/ForgotPasswordMonitor/ForgotPasswordMonitor'
import LoginMonitor from './Monitors/LoginMonitor/LoginMonitor';
import SetPasswordMonitor from './Monitors/SetPasswordMonitor/SetPasswordMonitor';
import ProtectedRoute from './Global/Components/ProtectedRoute';

import { datadogRum } from '@datadog/browser-rum';
import AuthConfirmationMonitor from './Monitors/AuthConfirmationMonitor/AuthConfirmationMonitor';
import RegulatoryMonitor from './Monitors/RegulatoryMonitor/RegulatoryMonitor';
import Modals from './Monitors/ModalsMonitor';
import AccessDenied from './Monitors/AccessDeniedMonitor/AccessDeniedMonitor';
import NewsFeedMonitor from './Monitors/NewsFeedMonitor/NewsFeedMonitor';
import UnusualMovesMonitor from './Monitors/UnusualMovesMonitor/UnusualMovesMonitor';
import ClaimOfferMonitor from './Monitors/ClaimOfferMonitor/ClaimOfferMonitor';
import AdminMonitor from './Monitors/AdminMonitor/AdminMonitor';
import SignUpMonitor from './Monitors/SignUpMonitor/SignUpMonitor';
import VerifyMonitor from './Monitors/VerifyMonitor/VerifyMonitor';


datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service:'marketreader',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input',
    enableExperimentalFeatures: ['clickmap']
});
    
datadogRum.startSessionReplayRecording();

const noRedirectPaths = [
    '/addUser',
    '/authConfirm',
    '/forgotPassword',
    '/setPassword',
    '//setPassword',
    '/signup',
    '/verifyToken',
    '/verifyAccount',
    '/requestDemo',
];

const redirectToDashboard = [
    '/selectPlan',
    '/regulatory',
];

const App = () => {
    const [admin, setAdmin] = useState(false);
    const [customerProfile, setCustomerProfile] = useState([]);
    const development = ['dev', 'localhost'].some(env => window.location.href.includes(env));
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [refreshed, setRefreshed] = useState(false);
    const [session, setSession] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [user, setUser] = useState(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [preventSelection, setPreventSelection] = useState(true);
    const [plan, setPlan] = useState(null);
    const [trialCanceled, setTrialCanceled] = useState(false);
    
    useEffect(() => {
        if (pathname === '//setPassword') {
            navigate('/setPassword');
        }
    }, [pathname]);

    const getUser = async () => {
        const { data, error } = await supabase.auth.getSession();
        const session = data?.session;
        const user = session?.user || undefined; 
        if (!user) {
            setIsAuthenticated(false);
            if (!noRedirectPaths.includes(pathname)) {
                navigate('/login' + window.location.search)
            }
            return;
        }
        setUser(user);
        setSession(session);
        
        if (user?.role === 'authenticated') {
            const profileRes = await supabase.from('profiles').select('*').eq('id', user.id);
            const { billing_email } = profileRes?.data?.[0];
            setAdmin(billing_email === 'tech@marketreader.com');

            const profile = await supabase.functions.invoke('customerProfile', {
                body: {
                    billingEmail: billing_email, 
                    userEmail: user.email,
                },
            });
            setCustomerProfile(JSON.parse(profile?.data));

            const { accessLevels, subscriptions } = JSON.parse(profile?.data);
            setSubscription(subscriptions[0]);

            datadogRum.setUser({
                email: user.email,
                plan: accessLevels,
            })

            function gtag() {
                window.dataLayer.push(arguments);
            };

            gtag('set', {
                'user_id': user.id
            });
            // window.dataLayer.push({
            //     'event' : 'login',
            //     'userId' : user.id //this number must be replaced with an actual User ID
            //   })
            // if (
            //     !regulatoryReport &&
            //     (billing_email === user.email || !billing_email) &&
            //     !subscriptions?.length
            // ) {
            //     navigate('/regulatory');

            //     return;
            // }
            
            if (!subscriptions.length) {
                navigate('/selectPlan')

                return;
            }

            if (subscriptions[0].status === 'paused' && pathname === '/claimOffer') {
                return;
            }

            if (accessLevels.includes('pro') || accessLevels.includes('basic')) {
                // await supabase.functions.invoke('hubspotVisitorToken', {
                //     body: { email: user.email }
                // }).then(res => {
                //     let token = JSON.parse(res.data)['token'];
                //     // window.HubSpotConversations.widget.remove();
                //     // window.hsConversationsSettings = {
                //     //     disableAttachment: false
                //     //     , identificationEmail: user.email
                //     //     , identificationToken: token
                //     // };
                //     // window.HubSpotConversations.widget.load();
                // });


                const ssoToken = await supabase.functions.invoke('hellonext', {
                    body: {
                        email: user.email,
                        // name: 'placeholder',
                        add_as_customer: true
                    }
                });

                setUser((prevUser) => ({ ...prevUser, hnssotoken: ssoToken.data }));
                user.hnssotoken = ssoToken.data;
                
                // if (!isMobile || isTablet) {
                //     const widget = window.HNWidget.init({
                //         title: "Post Widget",
                //         token: process.env.REACT_APP_HNWIDGET_TOKEN,
                //         jwtToken: ssoToken.data,
                //         // selector: "#widgetID", // Optional: Widget will open up when this element is clicked
                //         placement: "right",
                //         showOnlySubmission: true, 
                //         bucketId: "13762",
                //         launcherIconURL: '/img/bugicon.png'
                //     });
                // }

                setIsAuthenticated(true);
                navigate(redirectToDashboard.includes(pathname) ? '/' : pathname);
            } else {
                navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });

                return;
            }

        } else {
            console.log(error);
        }
        setRefreshed(true);
        return;
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (customerProfile) {
            const { accessLevels, subscriptions } = customerProfile;

            if (subscriptions?.[0]?.cancel_at_period_end) {
                setTrialCanceled(true);
            }
    
            if (accessLevels?.includes('pro')) {
                setPreventSelection(false);
            }
        }
    }, [customerProfile]);

    return (
        <Routes>
            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} refreshed={refreshed} />} >
                <Route path='*' element={
                    <AuthProvider values={{
                        customerProfile,
                        session,
                        setSession,
                        setUser,
                        user,
                    }}>
                        <StateProvider 
                            values={{
                                admin,
                                customerProfile,
                                session,
                                setCustomerProfile,
                                setIsAuthenticated,
                                setSession,
                                setTrialCanceled,
                                setUser,
                                subscription,
                                trialCanceled,
                                user,
                            }}
                        >
                            <AppFrame>
                                <GlobalStyles preventSelection={preventSelection} />
                                {(!isMobile || isTablet) ?
                                    <TrackingModule /> :
                                    <MobileTrackingModule />
                                }
                                <MonitorContainer>
                                        <Routes>
                                            {admin &&
                                                <Route path="/admin" element={<AdminMonitor />} />
                                            }
                                            <Route exact path="/" element={<DashboardMonitor />} />
                                            <Route path="/etf" element={<ETFMonitor />} />
                                            <Route path="/faqs" element={<KnowledgeBase />} />
                                            <Route path="/macro" element={<MacroMonitor />} />
                                            <Route path="/news" element={<NewsFeedMonitor />} />
                                            <Route path="/screener" element={<ScreenerMonitor />} />
                                            <Route path="/watchlists" element={<WatchlistMonitor />} />
                                            {(!isMobile || isTablet) &&
                                                <>
                                                    <Route path="/ticker/:symbol/*" element={<AssetMonitor />} />
                                                    <Route path="/unusualMoves" element={<UnusualMovesMonitor />} />
                                                    <Route path="/calendar" element={<CalendarMonitor />} />
                                                </>
                                            }
                                        </Routes>
                                        <Modals />
                                </MonitorContainer>
                                <Footer />
                            </AppFrame>
                        </StateProvider>
                    </AuthProvider>
                }/>
            </Route>
            <Route path='/accessDenied' element={<AccessDenied />} />
            <Route path='/authConfirm' element={<AuthConfirmationMonitor />} />
            <Route path="/claimOffer" element={
                <ClaimOfferMonitor
                    customerProfile={customerProfile}
                    setIsAuthenticated={setIsAuthenticated}
                    setUser={setUser}
                    user={user}
                />
            }/>
            <Route path='/requestDemo' element={<ContactForm />} />
            <Route path='/forgotPassword' element={<ForgotPasswordMonitor />} />
            <Route path='/login' element={
                <LoginMonitor
                    setAdmin={setAdmin}
                    setCustomerProfile={setCustomerProfile}
                    setIsAuthenticated={setIsAuthenticated}
                    setRefreshed={setRefreshed}
                    setSession={setSession}
                    setSubscription={setSubscription}
                    setUser={setUser}
                    user={user}
                />
            }/>
            <Route path='/regulatory' element={
                <RegulatoryMonitor
                    getUser={getUser}
                    setIsAuthenticated={setIsAuthenticated}
                    setUser={setUser}
                    user={user} 
                    />
            } />
            <Route path="/selectPlan" element={
                <PlanSelectionMonitor
                    getUser={getUser}
                    setIsAuthenticated={setIsAuthenticated}
                    setUser={setUser}
                    user={user}
                />
            }/>
            <Route path='/setPassword' element={
                <SetPasswordMonitor
                    plan={plan}
                    session={session}
                    setCustomerProfile={setCustomerProfile}
                    setIsAuthenticated={setIsAuthenticated}
                    setSession={setSession}
                    setSubscription={setSubscription}
                    setUser={setUser}
                />
            }/>
            <Route path='/signup' element={<ContactForm />} />
            <Route path='/verifyToken' element={<OTPVerificationMonitor />} />
            <Route path='/verifyAccount' element={<VerifyMonitor />} />
        </Routes>
    );
}

export default App;